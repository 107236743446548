import React from 'react';
import styles from './SharingOnPlayerTabs.scss';
import cx from 'classnames';
import Icon from '../../../../components/Icon';
import { BooleanInput, Option, TextInput } from '../../../../components/PropertyEditor';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

export default function SharingOnPlayerTabs({ project, handleBoolChange, updateProject }) {
  const handleShareDataChange = (val, key) => {
    const { share_data, id } = project;
    const value = typeof val === 'boolean' ? (val ? 1 : 0) : val;

    updateProject({
      share_data: { ...share_data, [key]: value },
      id: id,
    });
  };

  return (
    <div className="grid">
      <div className="col12">
        <Option
          style={{ padding: '10px' }}
          label="Allow Video to be shared?"
          value={project.allow_share}
          Component={BooleanInput}
          onChange={(e, val) => handleBoolChange(val, 'allow_share')}
          helpText="When enabled a share icon will show in the player controls and users will be able to post the video to social media via the share page"
        />
      </div>

      <div className="col8">
        <Tabs defaultTab="facebook" style={{ display: 'flex' }} selectedTabClassName={styles.active}>
          <TabList className={cx('react-tabs__tab-list', styles.tabList)}>
            <Tab>
              <h4 className="faded-heading">
                <Icon icon={['fab', 'facebook']} /> Facebook
              </h4>
            </Tab>
            <Tab>
              <h4 className="faded-heading">
                <Icon icon={['fab', 'linkedin-in']} /> LinkedIn
              </h4>
            </Tab>
            <Tab>
              <h4 className="faded-heading">
                <Icon icon={['fab', 'twitter']} /> Twitter
              </h4>
            </Tab>
            <Tab>
              <h4 className="faded-heading">
                <Icon icon={['fab', 'pinterest-p']} /> Pinterest
              </h4>
            </Tab>
            <Tab>
              <h4 className="faded-heading">
                <Icon icon={'at'} /> Email
              </h4>
            </Tab>
          </TabList>

          <TabPanel style={{ width: '60%' }}>
            <SharingPlatformOptions
              project={project}
              platformName="facebook"
              onShareDataChange={handleShareDataChange}
            ></SharingPlatformOptions>
          </TabPanel>

          <TabPanel style={{ width: '60%' }}>
            <SharingPlatformOptions project={project} platformName="linkedin" onShareDataChange={handleShareDataChange}>
              <Option
                label="Title"
                value={project.share_data.linkedin.title}
                Component={TextInput}
                onChange={(e, val) =>
                  handleShareDataChange({ ...getProjectShareData(project, 'linkedin'), title: val }, 'linkedin')
                }
              />
            </SharingPlatformOptions>
          </TabPanel>

          <TabPanel style={{ width: '60%' }}>
            <SharingPlatformOptions
              project={project}
              platformName="twitter"
              onShareDataChange={handleShareDataChange}
            />
          </TabPanel>

          <TabPanel style={{ width: '60%' }}>
            <SharingPlatformOptions
              project={project}
              platformName="pinterest"
              onShareDataChange={handleShareDataChange}
            />
          </TabPanel>

          <TabPanel style={{ width: '60%' }}>
            <SharingPlatformOptions project={project} platformName="email" onShareDataChange={handleShareDataChange}>
              <Option
                label="Email Subject"
                value={project.share_data.email.subject}
                Component={TextInput}
                onChange={(e, val) =>
                  handleShareDataChange({ ...getProjectShareData(project, 'email'), subject: val }, 'email')
                }
              />
              <Option
                label="Email Body"
                value={project.share_data.email.body}
                Component={TextInput}
                onChange={(e, val) =>
                  handleShareDataChange({ ...getProjectShareData(project, 'email'), body: val }, 'email')
                }
              />
            </SharingPlatformOptions>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

function SharingPlatformOptions({ children, project, platformName, onShareDataChange }) {
  const projectShareData = getProjectShareData(project, platformName) || {};

  return (
    <React.Fragment>
      <Option
        label={`Allow Share Via ${platformName[0].toUpperCase() + platformName.substr(1)}?`}
        value={projectShareData ? projectShareData.use : false}
        Component={BooleanInput}
        onChange={(e, val) => onShareDataChange({ ...projectShareData, use: val }, platformName)}
      />
      <Option
        label="Tooltip Text"
        value={projectShareData ? projectShareData.text : platformName}
        Component={TextInput}
        onChange={(e, val) => onShareDataChange({ ...projectShareData, text: val }, platformName)}
      />
      {children}
    </React.Fragment>
  );
}

function getProjectShareData(project, platformName) {
  if (project && !project.legacy && project.share_data && project.share_data[platformName])
    return project.share_data[platformName];
  else return {};
}
