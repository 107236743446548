import React from 'react';
import IconButton from 'components/Buttons/IconButton';
import styles from './PreviewPageUrl.scss'
import {sharePageUrl} from 'modules/project/projectUtils';
import {whiteLabelSelector} from "../../pageLoader/pageLoaderSelectors";
import {connect} from 'react-redux'

@connect(whiteLabelSelector, {})
export default class PreviewPageUrl extends React.Component {

  constructor(props) {
    super(props);

    let url = false;

    if (props.project.storage_path) {
      const whitelabel = (props.whitelabel) ? props.whitelabel.domain : false;
      url = sharePageUrl(props.project, whitelabel);
    }
    this.state = { url }
  }

  componentDidMount() {
    const {url: sharePageUrl} = this.state;
    const {project} = this.props;

    // Update project.share_data.url if the share page url has been updated/changed
    if (! project.legacy && sharePageUrl !== project.share_data.url) {
      this.props.updateUrl(sharePageUrl);
    }
  }

  render(){
    const {project} = this.props;
    const {url} = this.state;

    return (
      <div className="form-control">
        <h4 className="faded-heading">URL</h4>
        {url && project.published_at?
          <div className={styles.wrapper}>
            <div className={styles.input}>
              <input type="text" defaultValue={url} />
            </div>
            <div className={styles.buttons}>
              {/*<IconButton icon="clipboard">Copy</IconButton>*/}
              <IconButton icon="link" onClick={()=>{
                let win = window.open(url, '_blank');
                setTimeout(()=>{
                  win.focus();
                })

              }}>Open Link</IconButton>
            </div>
          </div>
          : <p style={{marginTop:'5px'}}><em>Publish project to generate your share page link</em></p>
        }
      </div>
    )
  }
}