import {takeLatest, put} from 'redux-saga/effects';
import {LOGOUT} from 'modules/auth/auth';
import {error} from 'utils/alert';

const TOKEN = 'auth-token';

export default function* logoutSaga() {
  yield takeLatest(LOGOUT, logoutUser);
}

function* logoutUser() {
  debugger;
  console.log('logout');
  // TODO Come back to why this doesn't work hardcoded this into the component for now
  // const res = yield fetch('logout', {
  //   method: 'POST',
  // });
  //
  //
  // window.localStorage.removeItem('auth-token');
  //
  // const {status} = yield res.json();
  //
  // if (status === 'OK'){
  //   //window.location.href = '/login';
  // }else {
  //   error({text: 'Error logging you out of the system'})
  // }
}
