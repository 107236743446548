import React, { Component } from 'react';
import cx from 'classnames';
import Icon from 'components/Icon';
import Rodal from 'components/Rodal';
import Button from 'components/Buttons/Button';
// import IconButton from 'components/Buttons/IconButton';
import UploadVideoButton from './UploadVideoButton';
import styles from './MediaLibrary.scss';

class ReplaceMediaModal extends Component {
  state = {
    showMediaZone: false
  };

  onDone = () => {
    const {updateSelectedMedia, close, media: {id}} = this.props;
    updateSelectedMedia(id);
    close();
  };

  render() {
    const { media, show, close } = this.props;
    const { showMediaZone } = this.state;
    const addMediaZoneClassList = cx(styles.uploadVideoButton, { [styles.hideAddMediaZone]: !showMediaZone });
    return (
      <Rodal visible={show} onClose={close} height={300} width={450}>
        <div className="modal-heading">
          <Icon name="cloud-upload" /> Replace your video's Source
        </div>
        <div className="modal-body" style={{ paddingTop: 40, position: 'relative', height: 200 }}>
          <UploadVideoButton
            show={showMediaZone}
            mediaToReplace={media}
            className={addMediaZoneClassList}
            onDone={this.onDone}
            toggleMediaZone={() => this.setState({ showMediaZone: !showMediaZone })}
          />
        </div>

        <div className="modal-footer">
          <Button onClick={close} style={{ float: 'left' }}>
            Cancel
          </Button>
        </div>
      </Rodal>
    );
  }
}

export default ReplaceMediaModal;
