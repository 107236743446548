import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import Card from '../../../components/Card';
import PageBody from 'components/PageBody';
import { updateSelectedMedia, updateMediaItem } from 'modules/media/media';
import { mediaLibrarySelector } from 'modules/media/mediaSelectors';
// import RelativeDate from '../../../components/date/RelativeDate';
import Button from 'components/Buttons/Button';
import styles from '../../project/components/ProjectsPage.scss';
import FilterInput from 'components/FilterInput';
import EditMediaModal from './EditMediaModal';
import { Option, SelectInput } from 'components/PropertyEditor';
import getAsset from 'utils/getAsset';
import UploadVideoButton from "./UploadVideoButton";
import LibraryEditMediaModal from "./LibraryEditMediaModal";

@connect(
  mediaLibrarySelector,
  { updatePageLoadingState, updateSelectedMedia, updateMediaItem }
)
export default class VideosPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredVideos: props.media,
      filtering: false,
      orderBy: 'created_at',
      sortOrder: 'desc',
      selectedProject: null,
      showEditModal: false,
      showMediaZone: false,
      editMediaProps: {
        editMediaName: false,
        media: null
      }
    };
  }

  handleEditVideo = (media) => () => {
     // this.props.updateSelectedMedia(mediaId);
    // Prevents the modal showing empty
    // setTimeout(() => {
    //   this.setState({ showEditModal: true });
    // }, 100);
    this.setState({
      editMediaProps: {
        editMediaName: true,
        media
      }
    })
  };

  updateMedia = (mediaName, mediaId ) => {
    this.props.updateMediaItem(mediaId, {name: mediaName});
    this.closeEditMediaModal();
  };

  closeEditMediaModal = () => {
    this.setState({
      editMediaProps: {
        editMediaName: false,
        media: null
      }
    })
  };

  stopVideoEdit = () => {
    this.setState({ showEditModal: false });
  };

  handleFilter = ({ filteredData, filtering }) => {
    this.setState({ filteredVideos: filteredData, filtering });
  };

  get filters() {
    const { selectedProject, orderBy } = this.state;
    return (
      <div className={cx(styles.filterRow, 'grid')}>
        <div className={cx(styles.filterRow_sortBy, 'col12')} style={{marginBottom: '15px'}}>
          <FilterInput
            data={this.props.media}
            filterKey="name"
            onFilter={this.handleFilter}
            placeholder="Filter Videos..."
          />
        </div>
        <div className={cx(styles.filterRow_sortBy, 'col12')} style={{marginBottom: '15px'}}>
          <div>
            <label style={{paddingTop: '5px'}}>Filter By</label>
          </div>
          <Option
            placeholder="All Projects"
            Component={SelectInput}
            value={selectedProject}
            className={styles.sortOptions}
            options={this.projectsSelectList()}
            onChange={(_, value) => this.setState({ selectedProject: value })}
            clearable={false}
            searchable={false}
          />
        </div>
        <div className={cx(styles.filterRow_sortBy, 'col12')} >
            <label style={{paddingTop: '5px'}}>Sort By</label>
          <Option
              Component={SelectInput}
              value={orderBy}
              className={styles.sortOptions}
              options={filterOptions()}
              onChange={(_, value) => this.setState({ orderBy: value, sortOrder: ['name', 'projectTitle'].includes(value) ? 'asc' : 'desc' })}
              clearable={false}
              searchable={false}
          />
        </div>
      </div>
    );

    function filterOptions() {
      return [
        {
          label: 'Video Name',
          value: 'name',
          clearableValue: false
        },
        {
          label: 'Uploaded Date',
          value: 'created_at',
          clearableValue: false
        },
        {
          label: 'Project',
          value: 'projectTitle',
          clearableValue: false
        },
      ];
    }
  }

  projectsSelectList = () => {
    const defaultOption = [{ label: 'All Projects', value: null, clearableValue: false }];
    const { projects } = this.props;

    let list = projects.map(project => ({
      label: project.title,
      value: project.id,
      clearableValue: false
    }));

    return defaultOption.concat(list);
  };

  get videosList() {
    const { filteredVideos, filtering, orderBy, selectedProject, sortOrder } = this.state;
    /* as videos data can arrive after first mounting we need to update
		list but only when not filtering which would show everything if filter not matched */
    let videos = filtering ? filteredVideos : this.props.media;

    if (selectedProject) {
      videos = _filter(videos, ['project_id', selectedProject]);
    }

    const sortedVideos = _orderBy(videos, [orderBy], sortOrder);

    return sortedVideos.map(item => (
      <ul className="videos_list" key={item.id}>
        <VideoCard
          video={item}
          mainAction={this.handleEditVideo(item)}
        />
      </ul>
    ));
  }

  onDoneUploadVideo = () => {
    // TODO
    // const {updateSelectedMedia, close, media: {id}} = this.props;
    // updateSelectedMedia(id);
    // close();
  };

  render() {
    const { showEditModal, showMediaZone } = this.state;

    return (
      <PageBody
          heading="Media Library"
          right={
            // <AccountNav active="api" />
              <div>
                <UploadVideoButton
                    mediaLibraryPage={true}
                    show={showMediaZone}
                    showUploadFileArea
                    onDone={this.onDoneUploadVideo}
                    toggleMediaZone={() => this.setState({ showMediaZone: !showMediaZone })}
                />
              </div>
          }
      >
        <div className="grid">
          <div className="col8">{this.videosList}</div>
          <div className="col4">{this.filters}</div>
        </div>
        <LibraryEditMediaModal
            {...this.state.editMediaProps}
            updateMedia={this.updateMedia}
            closeModal={this.closeEditMediaModal}
        />
        {/*{showEditModal && (*/}
        {/*  <EditMediaModal media={this.props.selectedMedia} show={showEditModal} close={this.stopVideoEdit} />*/}
        {/*)}*/}
      </PageBody>
    );
  }
}

function VideoCard({ video, mainAction }) {
  return (
      <li className="grid" style={{borderBottom: '1px solid #ccc'}}>
        <div className="col2">
          <img src={video.thumbnail_url || getAsset('/img/no-thumbnail.png')}  className="img-fluid mb" style={{borderRadius: '15px', boxShadow: '0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1)'}} />
        </div>
        <div className="col7">
          <h3 style={{marginBottom: 0, marginTop: '10px'}}>{video.name}</h3>
          <h4 style={{marginTop: '5px'}}>{video.projectTitle}</h4>
        </div>
        <div className="col3" style={{paddingTop: '10px'}}>
          <Button icon="pen-alt" primary onClick={mainAction} right >
            Edit
          </Button>
        </div>
      </li>
  );
}
