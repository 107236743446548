import {createSelector} from 'reselect';
import {toJS} from "utils/immutableUtils";
import {userSelector} from 'modules/auth/authSelectors'

const agencyStateSelector = state => state.agency;

export const agencySelector = createSelector(agencyStateSelector, (state, user)=>{
  return {
    agency: toJS(state.get('data')),
    errors: state.get('errors').toJS()
  }
});
