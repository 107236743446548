import axios from 'axios';
import config from 'config';

export const Categories = [
    'fashion', 'nature', 'backgrounds', 'science',
    'education', 'people', 'feelings', 'religion',
    'health', 'places', 'animals', 'industry', 'food',
    'computer', 'sports', 'transportation', 'travel',
    'buildings', 'business', 'music'
];

export default params => {
    return axios(`${config.PXA.ROOT}/videos` , {
                params: {
                    key: config.PXA.API_KEY,
                    ...params
                }
            }).then(res => res.data)
                .catch(() => {
                    throw new Error('load stock videos.');
                });
};

