import React from 'react';
import PropTypes from 'prop-types';
// import cx from 'classnames';
// import Button from 'components/Buttons/Button';
import PreviewProjectButton from './PreviewProjectButton';
import PageBody from 'components/PageBody';
import ProjectNav from './ProjectNav';
import ReactTooltip from 'react-tooltip';
import styles from './ProjectPage.scss';
import PublishButton from 'modules/project/components/PublishButton';
// import Icon from 'components/Icon';
import { useSelector } from 'react-redux';
import { whiteLabelSelector } from 'modules/pageLoader/pageLoaderSelectors';

const proptypes = {
  /** The header of the specific page route */
  header: PropTypes.string.isRequired,
  /** small description of the page */
  headerInfo: PropTypes.any,
  /** Current Project */
  project: PropTypes.object.isRequired,
  // Plus all props that pagebody might need or pass down */
};

export default function ProjectPage({ project, children, header, headerInfo, active, meta, ...props }) {
  const { whitelabel } = useSelector(whiteLabelSelector);

  return (
    <PageBody
      heading={getHeader(header, headerInfo)}
      right={getRightHeader({ ...props, active, project })}
      meta={getMetaData(project, whitelabel)}
      {...props}
    >
      {children}
    </PageBody>
  );
}

function getMetaData({ id, title, description, legacy }, whitelabel) {
  const icon = legacy
    ? 'https://s3.us-east-2.amazonaws.com/interactrapp.com/interactr-icon.png'
    : 'https://s3.us-east-2.amazonaws.com/interactrapp.com/evolution-icon.png';
  const tooltip = legacy ? 'Interactr Legacy Project' : 'Interactr Evolution Project';

  return (
    <div className={styles.meta_wrapper}>
      <ReactTooltip id="project-page" />
      <div className={styles.meta_title}>
        <h2>
          {whitelabel === false && (
            <span>
              <img
                src={icon}
                style={{ maxWidth: '30px', position: 'relative', top: '8px', left: 0 }}
                data-tip={tooltip}
                data-for="project-page"
              />
              &nbsp;|&nbsp;
            </span>
          )}
          {title}
        </h2>
      </div>
      {/*{!!description && <small className={cx('ellipsis-fade', styles.meta_description)}>{description}</small>}*/}
    </div>
  );
}

function getHeader(header, info) {
  return (
    <div className={styles.header_wrapper}>
      <h2 className={styles.header}>{header}</h2>
      {/*<small className={styles.header_info}>{info}</small>*/}
    </div>
  );
}

function getRightHeader(props) {
  const { project, active, id, buttons } = props;
  return (
    <div>
      <ProjectNav active={active} project={project} />
      {buttons ? buttons : projectButtons(project.id)}
    </div>
  );
}

function projectButtons(projectId) {
  return (
    <div style={{ marginTop: '35px', marginRight: '25px' }}>
      <PreviewProjectButton projectId={projectId} className={styles.meta_preview_btn}>
        Preview Project
      </PreviewProjectButton>
      <PublishButton projectId={projectId} isPublished={false} unpublishOption={false}>
        Publish Changes
      </PublishButton>
    </div>
  );
}

ProjectPage.propTypes = proptypes;
