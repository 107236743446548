import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as externalLinks from 'utils/externalLinks';
import styles from './AccountAvatar.scss';
import MenuItem from './MenuItem';
import Icon from 'components/Icon';
import { logout } from 'utils/logout';
import cx from 'classnames';
import { usersSelector } from 'modules/user/userSelectors';
import { loginAsUser } from 'modules/user/user';
import ReactTooltip from 'react-tooltip';
import Button from 'components/Buttons/Button';
import IconButton from 'components/Buttons/IconButton';

const _propTypes = {
  user: PropTypes.object.isRequired
};
function Avatar({ user, users, loginAsUser, parentData }) {
    const isAgency = !!user.is_agency;
    const isntSubUser = !user.parent_user_id;
    const parentName = parentData ? parentData.name : '';


    return (
        <div className={styles.my_account_dropdown}>
          <div className={styles.user_info}>
            <article className={cx(styles.avatar, { [styles.avatarHover]: user.is_agency})}>
                <img src={user.gravatar} alt="avatar picture" />
                {isAgency && (
                    <DropdownMenu
                        user={user}
                        usersList={users}
                        isntSubUser={isntSubUser}
                        loginAsUser={loginAsUser}
                    />
                )}
            </article>

            <span className={styles.name}>
                {parentName ? (
                    <div>
                        <p className="mb">{parentName} ></p>
                        <p className="mb" style={{marginTop: 0}}>{user.name}</p>
                    </div>
                ): user.name}
            </span>
          </div>
        </div>
    );
}

const DropdownMenu = ({ usersList, ...props }) => {
    return (
        <div className={cx(styles.my_account_dropdown_menu)}>
            <ul className={styles.sub_list}>
               <li className={styles.sub_list_header}>SWITCH USER</li>
                <li style={{paddingTop: '10px', paddingLeft: '20px'}}>
                    {usersList.map((user) => (
                        <UserItem {...props} user={user} key={user.id} />
                    ))}
                </li>
             </ul>
            <hr />
            <ul className={styles.sub_list}>
             <MenuItem icon="sign-out" className={styles.sub_list_item} onClick={logout}>
               Logout
             </MenuItem>
            </ul>
         </div>
    );
};

const UserItem = ({user, loginAsUser}) => {
    let saveInStorage = true;

    return (
        <div key={'sub_user_' + user.id}>
            <div style={{borderBottom: '#ccc solid 1px', marginBottom: '10px', paddingBottom: '5px'}} className="clearfix">
                <div className="grid">
                    <div className="col7">
                        <span>{user.name}</span>
                    </div>
                    <div className="col5">
                        <Button
                            small
                            onClick={() => loginAsUser(user.id, saveInStorage)}
                            primary
                            icon="sign-in-alt"
                            rightIcon
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </div>

            <ReactTooltip id="sub-user" multiline={false} />

        </div>
    )
}

Avatar.propTypes = _propTypes;
export default connect(usersSelector, { loginAsUser })(Avatar);

// const OldDropdownMenu = ({ user, open }) => {
//   const isAdmin = !!user.superuser;
//   const isntSubUser = !user.parent_user_id;
//   return (
//     <div className={cx(styles.my_account_dropdown_menu, { [styles.open]: open })}>
//       <ul className={styles.sub_list}>
//         <li className={styles.sub_list_header}>ACCOUNT</li>
//         <MenuItem to={'/account/preferences'} icon="sliders-h" className={styles.sub_list_item}>
//           Account Preferences
//         </MenuItem>
//         <MenuItem href={externalLinks.facebook} icon={['fab', 'facebook']} className={styles.sub_list_item}>
//           Facebook
//         </MenuItem>
//         {isntSubUser && (
//           <MenuItem href={externalLinks.docsLink} icon="life-ring" className={styles.sub_list_item}>
//             Help Desk
//           </MenuItem>
//         )}
//       </ul>
//       {isAdmin ? (
//         <ul className={styles.sub_list}>
//           <li className={styles.sub_list_header}>SWITCH USER</li>
//           <MenuItem to={'/admin'} className={styles.sub_list_item}>
//             Manage Users
//           </MenuItem>
//         </ul>
//       ) : null}
//       <hr />
//       <ul className={styles.sub_list}>
//         <MenuItem icon="sign-out" className={styles.sub_list_item} onClick={logout}>
//           Logout
//         </MenuItem>
//       </ul>
//     </div>
//   );
// };

