import React from 'react';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { connect } from 'react-redux';
import PageLoader from 'components/PageLoader';
import { publishedProjectUrl } from 'modules/project/projectUtils';
import config from 'config';
import styles from './sharePage.scss';
import ReactTooltip from 'react-tooltip';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';
import axios from 'axios';
import { error } from 'utils/alert';
import RelativeDate from 'components/date/RelativeDate';
// import StatItem from 'modules/stat/components/statItem';
import { ShareButtons, ShareCounts, generateShareIcon } from 'react-share';
import ShareMeta from './ShareMeta';
const FacebookIcon = generateShareIcon('facebook');
const TwitterIcon = generateShareIcon('twitter');
const LinkedinIcon = generateShareIcon('linkedin');
const RedditIcon = generateShareIcon('reddit');
const EmailIcon = generateShareIcon('email');
import SharePagePlayer from './SharePagePlayer';
import AppLogo from '../../../components/AppLogo';
import {whiteLabelSelector} from "../../pageLoader/pageLoaderSelectors";

// TODO: Go functional here and clean up this mess
@connect(
  whiteLabelSelector,
  { updatePageLoadingState }
)
export default class SharePage extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      gotData: false,
      projectId: 0,
      projectName: '',
      projectDescription: '',
      created: '',
      logo: '',
      company: '',
      otherVideos: [],
      comments: [],
      likes: 0,
      plays: 0,
      allowComments: false,
      showOtherVideos: false,
      newCommentName: '',
      newCommentEmail: '',
      newCommentText: '',
      liked: false,
      projectHash: props.location.pathname.split('/').pop(),
      hide_logo: true,
      club: null,
      evolution_club: 0,
      evolution_pro:0
    };
  }

  componentDidMount() {
    if (!this.state.gotData) {
      this.props.updatePageLoadingState(true);

      const url = config.API.ROOT + 'share/' + this.state.projectHash;

      axios
        .get(url)
        .then(response => {
          this.props.updatePageLoadingState(false);
          const data = response.data;

          this.setState({
            projectName: data.projectName,
            projectDescription: data.projectDescription,
            created: data.created.date,
            logo: data.logo,
            company: data.company,
            otherVideos: data.otherVideos,
            comments: data.comments,
            likes: data.likes,
            allowComments: data.allowComments,
            showOtherVideos: data.showOtherVideos,
            projectId: data.projectId,
            hide_logo: data.hide_logo,
            club: data.club,
            evolution_pro: data.evolution_pro,
            evolution_club: data.evolution_club
          });
        })
        .catch(() => {
          this.props.updatePageLoadingState(false);
          error({ text: 'Unable to get data' });
        });
    }
  }

  likeThis = () => {
    const url = config.API.ROOT + 'like/' + this.state.projectHash;

    axios
      .get(url)
      .then(response => {
        this.props.updatePageLoadingState(false);
        const data = response.data;
        window.localStorage.setItem('interactr2-' + this.state.projectHash + '-liked', true);
        this.setState({ liked: true });
        this.setState({ likes: data.likes });
      })
      .catch(() => {
        this.props.updatePageLoadingState(false);
        error({ text: 'Unable to get data' });
      });
  };

  unlikeThis = () => {
    const url = config.API.ROOT + 'unlike/' + this.state.projectHash;

    axios
      .get(url)
      .then(response => {
        this.props.updatePageLoadingState(false);
        const data = response.data;
        window.localStorage.removeItem('interactr2-' + this.state.projectHash + '-liked');
        this.setState({ liked: false });
        this.setState({ likes: data.likes });
      })
      .catch(() => {
        this.props.updatePageLoadingState(false);
        error({ text: 'Unable to get data' });
      });
  };

  hasLikedThis = () => {
    if (this.state.liked) return true;

    const hasLikedBefore = window.localStorage.getItem('interactr2-' + this.state.projectHash + '-liked');

    return hasLikedBefore;
  };

  postComment = async () => {
    this.props.updatePageLoadingState(true);

    if (!this.validateInputs()) {
      return;
    }

    const url = config.API.ROOT + 'comment/' + this.state.projectHash;
    const postData = {
      name: this.state.newCommentName,
      email: this.state.newCommentEmail,
      text: this.state.newCommentText,
      hash: this.state.projectHash
    };

    try {
      const {data} = await axios.post(url, postData);

      this.setState({
        comments: data.comments,
        newCommentName: '',
        newCommentEmail: '',
        newCommentText: ''
      });
    } catch (e) {
      error({ text: 'Unable to create comment' });
    }

    this.props.updatePageLoadingState(false);
  };

  validateInputs = () => {
    if (!this.state.newCommentName.length) {
      this.props.updatePageLoadingState(false);
      error({ text: 'Please enter your name' });
      return false;
    }

    if (!this.state.newCommentEmail.length) {
      this.props.updatePageLoadingState(false);
      error({ text: 'Please enter your email' });
      return false;
    }

    if (!this.state.newCommentText.length) {
      this.props.updatePageLoadingState(false);
      error({ text: 'Please enter a comment' });
      return false;
    }

    return true;
  };

  videoSharePage = hash => {
    const urlSplit = window.location.href.split('/');
    urlSplit[4] = hash;
    return urlSplit.join('/');
  };

  getCurrentDomainName = () => {
    return window.host;
  };

  embedCode = () => {
    let url = publishedProjectUrl(this.state.projectHash);
    const urlSplit = window.location.href.split('/');
    if (urlSplit[2] === '0.0.0.0:9198') {
      // remove the https when local or this wont work
      url = url.replace('https', 'http');
    }
    return <SharePagePlayer src={url} />;
  };

  renderComments = comments => {
    return (
      <div>
        <h2 style={{ marginBottom: '0px', paddingLeft: '20px' }}>Comments ({comments.length})</h2>
        <div className="clearfix">
          {comments.map(comment => {
            return (
              <div className={styles.comment} key={comment.id}>
                <div className={styles.commentImage}>
                  <img src={comment.image} />
                </div>
                <div className={styles.commentText}>
                  <div className={styles.commentName}>{comment.name}</div>
                  <div className={comment.commentBody} dangerouslySetInnerHTML={{ __html: comment.text }} />
                  <div className={styles.commentDate}>
                    <RelativeDate data={comment.created_at.date} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.newComment}>
          <h3 className="form-heading">Add A New Comment</h3>
          <div className="form-control">
            <label>Name</label>
            <input
              type="text"
              value={this.state.newCommentName}
              onChange={e => this.setState({ newCommentName: e.target.value })}
            />
          </div>
          <div className="form-control">
            <label>Email</label>
            <input
              type="email"
              value={this.state.newCommentEmail}
              onChange={e => this.setState({ newCommentEmail: e.target.value })}
            />
          </div>
          <div className="form-control">
            <label>Comment</label>
            <textarea
              value={this.state.newCommentText}
              onChange={e => this.setState({ newCommentText: e.target.value })}
            />
          </div>
          <div className="form-control">
            <Button secondary onClick={() => this.postComment()}>
              Add Comment
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderMoreVideos = showOtherVideos => {
    if (!showOtherVideos) return null;

    const { logo, company, otherVideos } = this.state;

    const headingStyle = logo ? {} : { paddingLeft: 0 };

    return (
      <div className={styles.right}>
        <p style={{ marginBottom: '5px', marginTop: 0 }}>More From</p>
        <h3 className={styles.brandText} style={headingStyle}>
          {logo && <img src={logo} className={styles.brand} />}
          {company}
        </h3>

        {otherVideos.length ? (
          otherVideos.map(video => {
            return (
              <a className={styles.otherVideo} href={this.videoSharePage(video.hash)} key={video.hash}>
                <div className={styles.otherVideoThumb}>
                  <div className={styles.overlay}>
                    <Button
                      secondary
                      icon="eye"
                      onClick={() => {
                        window.location.href = this.videoSharePage(video.hash);
                      }}
                    >
                      View
                    </Button>
                  </div>
                  <img src={video.image} />
                </div>
                <div className={styles.otherVideoMeta}>
                  <h4>{video.name}</h4>
                  <p>
                    <RelativeDate date={video.created_at.date} />
                  </p>
                  <div className={styles.otherVideoLogo}>{logo && <img src={logo} />}</div>
                </div>
              </a>
            );
          })
        ) : (
          <p>No Videos</p>
        )}
      </div>
    );
  };

  renderShareSection = () => {
    const {
      FacebookShareButton,
      LinkedinShareButton,
      TwitterShareButton,
      RedditShareButton,
      EmailShareButton
    } = ShareButtons;

    const { FacebookShareCount, LinkedinShareCount, RedditShareCount } = ShareCounts;

    const { projectName } = this.props;

    return (
      <div style={{ float: 'left' }}>
        <ul className={styles.social}>
          <li style={{ height: '22px' }}>
            <span className={styles.shareCounter}>
              <strong>Share:</strong>
            </span>
          </li>
          <li>
            <FacebookShareButton url={window.location.href} quote={projectName} hastag={'#InteractiveVideo'}>
              <span className={styles.shareIcon}>
                <FacebookIcon size={25} round={true} />
              </span>
              <span className={styles.shareCounter}>
                <FacebookShareCount url={window.location.href} />
              </span>
            </FacebookShareButton>
          </li>
          <li>
            <TwitterShareButton
              url={window.location.href}
              title={projectName}
              via={this.getCurrentDomainName()}
              hastags={['#InteractiveVideo']}
            >
              <span className={styles.shareIcon}>
                <TwitterIcon size={25} round={true} />
              </span>
            </TwitterShareButton>
          </li>
          <li>
            <LinkedinShareButton url={window.location.href} title={projectName} description="Interactive Video">
              <span className={styles.shareIcon}>
                <LinkedinIcon size={25} round={true} />
              </span>
              <span className={styles.shareCounter}>
                <LinkedinShareCount url={window.location.href} />
              </span>
            </LinkedinShareButton>
          </li>
          <li>
            <RedditShareButton url={window.location.href} title={projectName}>
              <span className={styles.shareIcon}>
                <RedditIcon size={25} round={true} />
              </span>
              <span className={styles.shareCounter}>
                <RedditShareCount url={window.location.href} />
              </span>
            </RedditShareButton>
          </li>
          <li>
            <EmailShareButton url={window.location.href} subject={projectName} body="Interactive Video">
              <span className={styles.shareIcon}>
                <EmailIcon size={25} round={true} />
              </span>
            </EmailShareButton>
          </li>
        </ul>
      </div>
    );
  };

  render() {
    const {
      projectName,
      projectDescription,
      created,
      logo,
      company,
      otherVideos,
      plays,
      likes,
      comments,
      allowComments,
      showOtherVideos,
      gotPlays,
      projectId,
      google_image_url,
      facebook_image_url,
      twitter_image_url,
      hide_logo,
      club,
      evolution_pro,
      evolution_club
    } = this.state;
    const {whitelabel} = this.props;

    // Club, Pro OR evolution club users can hide branding
    const nobrand = parseInt(hide_logo) === 1 && ( club || evolution_pro || evolution_club ) ;

    let customHeaderStyles = {};

    if (whitelabel) {
      customHeaderStyles.background = whitelabel.background_colour;
    }

    return (
      <div>
        <ShareMeta
          title={projectName}
          description={projectDescription}
          googleImage={google_image_url}
          facebookImage={facebook_image_url}
          twitterImage={twitter_image_url}
          url={window.location.href}
        />

          <div className={styles.header} style={customHeaderStyles}>
            <AppLogo nobrand={nobrand} />
          </div>

        <div className={styles.top}>
          <div className={styles.container}>{this.embedCode()}</div>
        </div>

        <div className={styles.bottomWrapper}>
          <div className={styles.bottom}>
            <div className={styles.left}>
              <div className={styles.meta}>
                <h2>{projectName}</h2>
                <div className={styles.stats}>
                  {/*<RelativeDate date={created} />*/}
                  <ReactTooltip />
                  {/*{this.renderShareSection()}*/}
                  <ul className={styles.share}>
                    {/* <li data-tip="Plays">
                      <Icon icon="play" />
                      <StatItem projectId={projectId} />
                    </li> */}
                    <li data-tip="Likes" style={{cursor:'pointer'}}>
                      {this.hasLikedThis() ? (
                        <Icon icon={['fas', 'heart']} onClick={() => this.unlikeThis()} />
                      ) : (
                        <Icon icon='heart' onClick={() => this.likeThis()} />
                      )}

                      {likes}
                    </li>
                    {allowComments ? (
                      <li data-tip="Comments">
                        <Icon icon="comment" />
                        <ReactTooltip /> {comments.length}
                      </li>
                    ) : null}
                  </ul>
                </div>

                {projectDescription ? <p>{projectDescription}</p> : null}
              </div>

              <div className={styles.comments}>
                {allowComments ? (
                  this.renderComments(comments)
                ) : (
                  <p>
                    <em>Comments Disabled</em>
                  </p>
                )}
              </div>
            </div>

            {this.renderMoreVideos(showOtherVideos)}
          </div>
        </div>
      </div>
    );
  }
}
