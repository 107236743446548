import {take, call, put, select} from 'redux-saga/effects';
import {END_DRAG, DRAG} from 'modules/composer/composer';
import {NODE, findDragData} from 'modules/composer/dragging';
import {
  moveNode as moveNodeAction,
  updateNode
} from 'modules/node/node';
import {nodeSelector} from 'modules/node/nodeSelectors';

export default function* moveNode(action, {id}) {
  // const {node} = yield select(nodeSelector, {id});

  // We keep track of pos and add deltas to it during drag
  // let pos = node.get('pos').toJS();

  while (true) {
    action = yield take([DRAG, END_DRAG]);
    if (action.type === END_DRAG) {
      const {node: {posX, posY}} = yield select(nodeSelector, {id});

      // sync to server
      yield put(updateNode(id, {posX, posY}));
      break;
    }
    const {deltaX, deltaY} = action.info;
    yield put(moveNodeAction(id, deltaX, deltaY));
  }
}
