import React from 'react';
import ClickableElementProperties from './ClickableElementProperties';
import {
  Section,
  Option,
  TextInput
} from 'components/PropertyEditor';
import {connect} from "react-redux";

export default class TriggerElementProperties extends React.Component {
  handleUpload = ({src}) => {
    this.props.updateElement({src});
  };

  handleError = error => {
    toastr.error('Upload failed', error);
  };

  handleNameChange = (e) => {
    this.props.updateElement({
      name: e.target.value
    })
  }

  render() {
    const {node, interaction} = this.props;
    const {src, name} = this.props.element;
    const isInteractionLayer = (node.interaction_layer_id === interaction.id);

    return (
      <div>
        {/* <Section title="Image" icon="image" /> */}
        <ClickableElementProperties {...this.props} actionTitle="On trigger" />
        {/*{*/}
        {/*  (! isInteractionLayer ) &&*/}
        {/*  <Option*/}
        {/*      label="Name"*/}
        {/*      value={name}*/}
        {/*      Component={TextInput}*/}
        {/*      onChange={this.handleNameChange}*/}
        {/*  />*/}
        {/*}*/}
      </div>
    );
  }
}
