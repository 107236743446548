import { takeLatest, put } from 'redux-saga/effects';
import { VIEW_PROJECTS_PAGE, VIEW_PROJECT_PAGE, receiveProjects, receiveProjectGroups } from 'modules/project/project';
import { VIEW_MODAL_PAGE, receiveModals } from 'modules/modal/modal';
import { VIEW_STATS_LIST_PAGE } from 'modules/stat/stat';
import fetch from 'utils/saga/fetch';
import { receiveElements } from 'modules/element/element';
import { processElements } from 'modules/element/elementUtils';
import { updatePageLoadingState } from 'modules/pageLoader';
import { error } from 'utils/alert';

export default function* fetchProjectsSaga() {
  yield takeLatest(
    [
      // VIEW_PROJECTS_PAGE,
      VIEW_PROJECT_PAGE,
      VIEW_STATS_LIST_PAGE,
      VIEW_MODAL_PAGE
    ],
    fetchProjects
  );
}

function* fetchProjects({ folder }) {
  const url = folder ? `projects/${folder}` : `projects`
  yield put(updatePageLoadingState(true))
  // console.log('Fetching project from saga')
  const res = yield fetch(url)
  if(res.ok) {
    const  { projects, projectGroups } = yield res.json();
  
    let data = projects.reduce((modals, project) => {
      return modals.concat(project.modals);
    }, []);
  
    const [modals, elements] = processElements(data);
  
    yield put(receiveProjects(projects));
    yield put(receiveProjectGroups(projectGroups));
    yield put(receiveElements(elements));
    yield put(receiveModals(modals));

    yield put(updatePageLoadingState(false))
  } else {
    error({text : 'Failed to fetch projects data'})
    yield put(updatePageLoadingState(false))
  }
}
