import React from 'react';
import {
  Section,
  Option,
  SizeInput,
  PositionInput,
  BooleanInput,
  TextInput,
  SelectInput
} from 'components/PropertyEditor';
import DropMediaImageZone from 'modules/media/components/DropMediaImageZone';
import DropImageZone from 'modules/media/components/DropImageZone';
import DropFileZone from 'modules/media/components/DropFileZone';
import {connect} from 'react-redux';
import {isSuperuserSelector} from 'modules/auth/authSelectors';

@connect(isSuperuserSelector, {})
export default class TemplateProperties extends React.Component {
  changeHandler = name => (e, value) => {
    this.props.update({[name]: value});
  };

  handleImage = ({src}) => {
    this.props.update({template_image_url: src});
  };

  handleFile = ({src}) => {
      const data = {};

      if (src.includes('.mp4')) {
          data.template_image_url = null;
          data.template_video = src;
      } else {
          data.template_image_url = src;
          data.template_video = null;
      }

      this.props.update(data);
  };

  render() {
    const {item, superuser, languageOptions, modalProperties} = this.props;

    if (!item) return null;

    const {
        is_template,
        evolution_pro_template,
        template_name,
        template_image_url,
        template_video,
        downloadable_assets,
        club_template,
        preview_url,
        local_template,
        language_id,
        evolution_template
    } = item;

    if (! superuser) return null;

    return (
        <div style={{maxWidth: '650px'}}>
            <Section title="Admin" icon="lock" >
                <p>
                    This area is admin only. Any enabled templates will be shown to all
                    users.
                </p>
                <Option
                    label="Is template"
                    value={is_template}
                    Component={BooleanInput}
                    onChange={this.changeHandler('is_template')}
                />
                {modalProperties && (
                    <Option
                        label="Evolution Pro template"
                        value={evolution_pro_template}
                        Component={BooleanInput}
                        onChange={this.changeHandler('evolution_pro_template')}
                    />
                )}
                <Option
                    label="Template name"
                    value={template_name}
                    Component={TextInput}
                    onChange={this.changeHandler('template_name')}
                />
                {!modalProperties && (
                    <Option
                        label="Template Language"
                        Component={SelectInput}
                        value={language_id || 'noLanguage'}
                        options={languageOptions}
                        onChange={this.changeHandler('language_id')}
                    />
                )}
                {modalProperties ? (
                    <DropMediaImageZone
                        directory="templateImages"
                        onSuccess={this.handleFile}
                        isVideo={!template_image_url && (template_video && template_video.includes('.mp4'))}
                        src={template_image_url || template_video}
                    />
                ) : (
                    <DropImageZone
                        directory="templateImages"
                        onSuccess={this.handleImage}
                        src={template_image_url}
                    />
                )}

                {!modalProperties && (
                    <div style={{marginTop: '10px'}}>
                        <Option
                            label="Evolution Pro template"
                            value={evolution_template}
                            Component={BooleanInput}
                            onChange={this.changeHandler('evolution_template')}
                        />
                        <Option
                            label="Club template"
                            value={club_template}
                            Component={BooleanInput}
                            onChange={this.changeHandler('club_template')}
                        />
                        <Option
                            label="Local template"
                            value={local_template}
                            Component={BooleanInput}
                            onChange={this.changeHandler('local_template')}
                        />
                        {/*<Option*/}
                        {/*label="Preview Url"*/}
                        {/*value={preview_url}*/}
                        {/*Component={TextInput}*/}
                        {/*onChange={this.changeHandler('preview_url')}*/}
                        {/*/>*/}
                        {/*<div className="form-control">*/}
                        {/*<Option*/}
                        {/*    label="Downloadable Assets"*/}
                        {/*    value={downloadable_assets}*/}
                        {/*    Component={TextInput}*/}
                        {/*    onChange={this.changeHandler('downloadable_assets')}*/}
                        {/*/>*/}


                        {/* Uploading zip files. It may be used later on */}
                        {/*<label>Downloadable Assets</label>*/}
                        {/*<DropFileZone*/}
                        {/*directory="downloadableAssets"*/}
                        {/*uploadSuccessEndpoint={'file/upload'}*/}
                        {/*onSuccess={({src}) => this.props.update({downloadable_assets: src})}*/}
                        {/*src={downloadable_assets}*/}
                        {/*/>*/}
                        {/*</div>*/}
                    </div>
                )}
            </Section>
        </div>
    );
  }
}
