import React from 'react';
import {
  Section,
  Option,
  ColorInput,
  RangeInput,
  SelectInput
} from 'components/PropertyEditor';

export default class StyleableElementProperties extends React.Component {
  static defaultProps = {
    title: 'Appearance'
  };

  changeHandler = name => (e, val) => {
    this.props.updateElement({[name]: val});
  };

  changeBorderRadius = (e, val) => {
    const {width} = this.props.element;

    // Border radius is a percentage,
    // but if we set styles.borderRadius to a percentage
    // we end up with an oval as it's based on both width and height
    // here we approximate a pixel borderRadius

    borderRadius = width * (borderRadius / 2) / percentage;

    this.props.updateElement({
      borderRadius
    });
  };

  render() {
    const {element, title, usePadding} = this.props;
    let {stackOrder}  = this.props;

    if (!element) return null;

    const {background, borderRadius, color, paddingSides, letterSpacing, borderType, borderWidth, borderColor} = element;

    const stackOrderText = stackOrder * 5;
    const stackOrderBackground = stackOrder * 4;

    return (
      <Section title={title} icon="paint-brush">
        <Option
          label="Background Color"
          value={background}
          Component={ColorInput}
          onChange={this.changeHandler('background')}
          stackOrder={stackOrderText}
        />
        {color && (
          <Option
            label="Text Color"
            value={color}
            Component={ColorInput}
            onChange={this.changeHandler('color')}
            stackOrder={stackOrderBackground}
          />
        )}
        <Option
          label="Roundness (px)"
          value={borderRadius}
          Component={RangeInput}
          onChange={this.changeHandler('borderRadius')}
          max={100}
        />
        {/*<Option*/}
          {/*label="Border width (px)"*/}
          {/*value={borderWidth}*/}
          {/*Component={RangeInput}*/}
          {/*onChange={this.changeHandler('borderWidth')}*/}
          {/*max={100}*/}
        {/*/>*/}
        {/*<Option*/}
          {/*label="Border color"*/}
          {/*value={borderColor}*/}
          {/*Component={ColorInput}*/}
          {/*onChange={this.changeHandler('borderColor')}*/}
        {/*/>*/}
        {/*{borderWidth > 0 && <Option*/}
          {/*label="Border type"*/}
          {/*value={borderType}*/}
          {/*onChange={this.changeHandler('borderType')}*/}
          {/*Component={SelectInput}*/}
          {/*options={[*/}
            {/*{label: 'solid', value: 'solid', clearableValue: false},*/}
            {/*{label: 'dashed', value: 'dashed', clearableValue: false},*/}
            {/*{label: 'dotted', value: 'dotted', clearableValue: false}*/}
          {/*]}*/}
        {/*/>}*/}
        {usePadding &&
          <Option
            label="Padding (px)"
            value={paddingSides}
            Component={RangeInput}
            onChange={this.changeHandler('paddingSides')}
            max={40}
          />
        }
        <Option
          label="Letter Spacing (px)"
          value={letterSpacing}
          Component={RangeInput}
          onChange={this.changeHandler('letterSpacing')}
          max={10}
        />
      </Section>
    );
  }
}
