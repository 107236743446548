import {put} from 'redux-saga/effects';
import {receiveElements} from 'modules/element/element';
import {processElements} from 'modules/element/elementUtils';
import {receiveModal} from 'modules/modal/modal';

export default function* receiveModalUtil(modalData) {
  // console.log('receiveModalUtil===============');
  const [modals, elements] = processElements([modalData]);
  // console.log(modals);
  // console.log(elements);
  yield put(receiveModal(modals[0]));
  yield put(receiveElements(elements));
}
