import {take, call, put, select} from 'redux-saga/effects';
import {dragSelector} from 'modules/composer/composerSelectors';
import {NODE, findDragData} from 'modules/composer/dragging';
import {END_DRAG, DRAG, linkConnector, removeConnector} from 'modules/composer/composer';

// if only dragged this distance and not onto a node, we ignore the drag
const IGNORE_DISTANCE = 20;

export default function* drawConnector(action, {element_type, id, nodeId}) {
  let x = 0,
    y = 0;

  while (true) {
    action = yield take([DRAG, END_DRAG]);
    if (action.type === END_DRAG) {
      break;
    }

    ({x, y} = yield select(dragSelector));
  }

  const endData = findDragData(action.target);

  if (endData && endData.dragType === NODE) {
    // connect to node
    yield put(linkConnector(element_type, id, endData.id));
  } else {
    // Ignore small drags
    if (Math.abs(x) < IGNORE_DISTANCE && Math.abs(y) < IGNORE_DISTANCE) {
      return;
    }
    // reset
    // yield put(updateOutput(nodeId, idx, output));
    yield put(removeConnector(element_type, id));
  }
}
