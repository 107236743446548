import { createSelector } from 'reselect';
import { authUserSelector } from '../auth/authSelectors';
import { toJS } from 'utils/immutableUtils';

export const templatesStateSelector = state => state.templates;

export const templatesSelector = createSelector(
  templatesStateSelector,
  authUserSelector,
  (state, props) => props.table,
  (state, user, table) => {
    const languageId = state.get('languageId');
    let templates = state.get('data').toJS();
    if (table === 'projects') templates = templates.filter(template => template.language_id === languageId);

    return {
      loading: state.get('loading'),
      templates,
      user: user.toJS()
    };
  }
);

export const templateLanguagesSelector = createSelector(
  templatesStateSelector,
  state => ({
    languages: toJS(state.get('languages').toList())
  })
);

export const associatedLanguagesSelector = createSelector(
  templateLanguagesSelector,
  ({ languages }) => ({
    languages: languages.filter(language => language.templatesCount > 0)
  })
);
