import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {SAVE_MODAL} from 'modules/modal/modal';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {error} from 'utils/alert';

export default function* storeModalSaga() {
  yield takeLatest(SAVE_MODAL, saveModal);
}

function* saveModal({modalId, data}) {
  yield put(updatePageLoadingState(true));

  const res = yield fetch(`modals/${modalId}`, {
    method: 'POST',
    body: data
  });

  yield put(updatePageLoadingState(false));

  if (res.status !== 200) {
    error({text:'Unable to save node'})
  }

  // const modal = yield res.json();
}
