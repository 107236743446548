import React from 'react';
import { connect } from 'react-redux';
import ProjectCard from './ProjectCard';
import AddProjectButton from './AddProjectButton';
import Button from 'components/Buttons/Button';
import { projectsSelector } from 'modules/project/projectSelectors';
import { viewProjectsPage, updateSelectedFolder } from 'modules/project/project';
import ProjectsHeaderComponent from './ProjectsHeaderComponent';
import SubNav from 'components/SubNav';
import FoldersManagementModal from './FoldersManagementModal';
import _orderBy from 'lodash/orderBy';
import styles from './ProjectsPage.scss';
import Icon from 'components/Icon';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { Link, withRouter } from 'react-router-dom';
import FilterInput from 'components/FilterInput';
import PageBody from 'components/PageBody';
import { Option, BooleanInput, SelectInput } from 'components/PropertyEditor';
import cx from 'classnames';

@connect(projectsSelector, { viewProjectsPage, updateSelectedFolder, updatePageLoadingState })
class ProjectsPage extends React.Component {
  constructor() {
    super();

    this.state = {
      searchedProjects: [],
      searching: false,
      favouritesOnly: false,
      orderBy: 'created_at',
      sortOrder: 'desc',
      showNewUserWelcome: false,
      foldersManagementModal: false
    };
  }
  componentDidMount() {
    const { projects } = this.props;

    if (!projects) {
      this.props.viewProjectsPage();
    }
  }

  sortProjects(projects) {
    return _orderBy(projects, [this.state.orderBy], this.state.sortOrder);
  }

  canCreateProject = () => {
    const { user, projects } = this.props;

    return !(user.max_projects && projects.length >= user.max_projects);
  };

  renderNoProjects() {
    let message = 'No Projects Here!';

    return (
      <div>
        <div className={styles.noProjectWrapper}>
          <h1>
            <Icon name="exclamation-triangle" />
          </h1>
          <h2>{message}</h2>
          <AddProjectButton />
        </div>
      </div>
    );
  }

  renderProjects(sortedProjects) {
    return sortedProjects.map(project => {
      return (
        <article key={project.id} className={cx('cards_card', styles.projectCard)}>
          <ProjectCard
              project={project}  
          />
        </article>
      );
    });
  }

  renderNoSearchResult() {
    return (
      <div className={styles.noProjectWrapperSearch}>
        <h1>
          <Icon name="exclamation-triangle" />
        </h1>
        <h2>No projects found!</h2>
      </div>
    );
  }

  closeNewUserWelcome = () => {
    this.setState({ showNewUserWelcome: false });
  };

  handleFoldersManagementModal = value => {
    this.setState({
      foldersManagementModal: value
    });
  };

  get headerLeftSection() {
    let {
      projectGroups,
      folderId,
      updateSelectedFolder,
      user: { parent_user_id }
    } = this.props;
    folderId = parseInt(folderId);

    const firstNavItem = {
      id: 0,
      active: folderId === 0,
      text: 'No Folder',
      action() {
        updateSelectedFolder(0); // initial state folderId
      }
    };

    const folders = projectGroups.map(folder => {
      return {
        id: folder.id,
        active: folder.id === folderId,
        text: `${folder.title} (${folder.projectsCount})`,
        action() {
          updateSelectedFolder(folder.id);
        }
      };
    });

    return (
      !parent_user_id && (
        <div>
          <SubNav items={[firstNavItem, ...folders]} verticalFoldersScroll />
          <div className="mt-1">{this.actionsRow()}</div>
        </div>
      )
    );
  }

  handleSearch = ({ filteredData, filtering }) => {
    this.setState({ searchedProjects: filteredData, searching: filtering });
  };

  actionsRow = () => {
    const {
      user: { parent_user_id }
    } = this.props;
    return (
      <div className={cx(styles.topOptions, 'flex')}>
        <div className={cx(styles.filterRow_search)}>
          {!parent_user_id && <AddProjectButton />}
          <Button secondary onClick={() => this.handleFoldersManagementModal(true)}>
            <Icon name="bars" /> Manage Folders
          </Button>
        </div>
        <div className={cx(styles.filterRow_sortBy, 'flex-8')}>
          <FilterInput
            data={this.props.projects}
            filterKey="title"
            onFilter={this.handleSearch}
            placeholder="Filter projects..."
          />
          {/* <Option
            Component={BooleanInput}
            onChange={(e, val) => {
              this.setState({ favouritesOnly: val });
            }}
            label={'Favourites only'}
            value={this.state.favouritesOnly}
          /> */}
          <Option
            Component={SelectInput}
            value={this.state.orderBy}
            options={this.filterOptions}
            onChange={(_, value) => this.setState({ orderBy: value, sortOrder: value === 'title' ? 'asc' : 'desc' })}
            clearable={false}
            searchable={false}
            placeholder="Order By"
            className={styles.sortOptions}
          />

          {/* May add this back in later not sure it's needed */}
          {/*{*/}
          {/*(this.state.sortOrder === 'asc') ?*/}
          {/*<Icon name="sort-alpha-asc" onClick={() => this.setState({sortOrder: 'desc'})} />*/}
          {/*:*/}
          {/*<Icon name="sort-alpha-desc"  onClick={() => this.setState({sortOrder: 'asc'})} />*/}
          {/*}*/}
        </div>
      </div>
    );
  };

  filterOptions = [
    {
      label: 'Order By Favourites',
      value: 'is_favourite',
      clearableValue: false
    },
    {
      label: 'Order By Name',
      value: 'title',
      clearableValue: false
    },
    {
      label: 'Order By Created Date',
      value: 'created_at',
      clearableValue: false
    },
      // TODO
    // {
    //   label: 'Show Favourites First',
    //   value: 'title',
    //   clearableValue: false
    // },
    // {
    //   label: 'Order By Views',
    //   value: 'title',
    //   clearableValue: false
    // },
  ];

  /* any projects that fullfil internal conditions
  will get returned. use any other filters like the "favourites only" one here for performance and
  to avoid running a seperate filter down the line more than once  */
  customFilter = projects => {
    const { folderId } = this.props;
    const { favouritesOnly } = this.state;
    return projects.filter(project => {
      let passesGroupFilter;
      const projectGroupID = project.project_group_id;

      if (folderId) {
        passesGroupFilter = projectGroupID == folderId;
      } else {
        passesGroupFilter = !projectGroupID ;
      }

      const passesFavouritesFilter = favouritesOnly ? project.is_favourite : true;

      return passesGroupFilter && passesFavouritesFilter;
    });
  };


  render() {
    let {
      projects,
      updatePageLoadingState,
      user: { parent_user_id }
    } = this.props;
    const { searching, searchedProjects } = this.state;
    const isSubUser = !!parent_user_id;

    if (!projects) {
      return null;
    }


    // use projects that pass the search filter first
    projects = searchedProjects.length ? searchedProjects : projects;
    // then apply any other ones like folder or favorites-only etc ...
    /**
     * If user is subuser, no need to filter by folder/favourites etc.
     */
    if (!isSubUser) {
      projects = this.customFilter(projects);
    }

    const sortedProjects = this.sortProjects(projects);

    const noProjects = projects.length === 0 && parent_user_id === 0 && !searching;
    const noSearchResults = searching && !searchedProjects.length;

    return (
      <PageBody customHeader={<ProjectsHeaderComponent meta={this.headerLeftSection} {...this.props} />}>
        <section className={styles.wrapper}>
          {noProjects ? (
            this.renderNoProjects()
          ) : (
            <div className={cx('cards_grid')}>
              {noSearchResults ? this.renderNoSearchResult() : this.renderProjects(sortedProjects)}
            </div>
          )}
          <FoldersManagementModal
            foldersManagementModal={this.state.foldersManagementModal}
            handleFoldersManagementModal={this.handleFoldersManagementModal}
          />
        </section>
      </PageBody>
    );
  }
}

export default ProjectsPage;
