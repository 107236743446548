import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {DELETE_MODAL, modalDeleted} from 'modules/modal/modal';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {error} from 'utils/alert';
import swal from 'sweetalert2';
import history from 'browserHistory';
import {nodePath} from 'routeBuilders';

export default function* deleteModalSaga() {
  yield takeLatest(DELETE_MODAL, deleteModal);
}

function* deleteModal({modalId}) {
  const conf = yield swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to delete this modal.',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#ff6961',
    confirmButtonText: 'Yes, Delete It!',
  });

  yield put(updatePageLoadingState(true));

  const res = yield fetch(`modals/${modalId}`, {
    method: 'DELETE',
    body: {}
  });

  if (res.status !== 200) {
    yield put(updatePageLoadingState(false));
    error({text:'Unable To Delete Modal'})
  }

  yield put(modalDeleted(modalId));

  // Build a new url to take us back to the node page
  const urlSplit = window.location.href.split('/');

  // Redirect back to node
  history.push(nodePath({projectId: urlSplit[4], nodeId: urlSplit[6]}) + '?selectModal');
}
