import {createSelector} from 'reselect';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import {projectSelector, projectsSelector} from 'modules/project/projectSelectors';
import {toJS} from 'utils/immutableUtils';
import {projectIdSelector} from "../project/projectSelectors";

export const mediaStateSelector = state => state.media;

export const uploadingSelector = createSelector(mediaStateSelector, state => ({
  uploading: state.get('uploading')
}));

export const mediaSelector = createSelector(mediaStateSelector, state => ({
  media: state.get('data')
}));

export const selectedMediaSelector = createSelector(mediaStateSelector, state => ({
  selectedMedia: toJS(state.get('selectedMedia'))
}));

export const modalStateSelector = state => state.modals;

export const mediaLibrarySelector = createSelector(
  mediaSelector,
  selectedMediaSelector,
  projectsSelector,
  ({media}, {selectedMedia}, {projects}) => {
    const videos = filter(media.toList().toJS(), {hidden: 0})
    .reduce((vids, video) => {
      const videoProject = projects.find(project=> project.id === video.project_id)
      if(videoProject) {
        // append project info into video here as this can be expensive and should be memoized
        video.projectTitle = videoProject.title
      }
      return [...vids, video]
    },[]);

    return {
      media: videos,
      selectedMedia,
      projects
    }
  }
);


/**
 * This is a messy workaround. The project doens't load in on the edit node page so
 * we can't filter the media items. All we need the media items for on the edit node
 * page is the thumbnail urls so sending all the media items back works. As media library
 * is  on composer and edit node page we need to use the same selector for both (Really
 * messy but it works :) )
 */
export const mediaLibraryByProjectSelector = createSelector(
  mediaLibrarySelector,
  projectSelector,
  modalStateSelector,
  projectIdSelector,
  ({media}, {project}, modalSate, projectId) => {

    let modalList = [];

    //Super hacky way to do this but in a rush
    if (window.location.href.includes('projects')) {
      const modals = modalSate.get('modals');
      modalList = filter(modals.toList().toJS(), {project_id: parseInt(projectId)});
    }

    if (project) {
      return {
        media: orderBy(filter(media, {project_id: project.id}), 'created_at', 'desc'),
        project,
        modals: modalList,
      }
    }


    return {
      modals: modalList,
      media,
    }
  },

);
