import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {deauthenticate} from 'modules/auth/auth';
import {getToken} from 'modules/auth/authUtils';
import handleAuthenticate from './utils/handleAuthenticate';

// on first load, attempt reauth
export default function* reauthenticateSaga() {
  const token = getToken();

  if (!token) {
    yield put(deauthenticate());
    return;
  }

  const res = yield fetch('reauthenticate', {
    method: 'POST',
    body: {token}
  });

  yield handleAuthenticate(res);
}
