import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import anime from "animejs";

// components 
import HotspotElement from 'modules/element/components/Element/HotspotElement';
import ButtonElement from 'modules/element/components/Element/ButtonElement';
import ImageElement from 'modules/element/components/Element/ImageElement';
import TextElement from 'modules/element/components/Element/TextElement';
import FormElement from 'modules/element/components/Element/FormElement';
import CustomHtmlElement from 'modules/element/components/Element/CustomHtmlElement';
import {
  deleteModalElement,
} from 'modules/modal/modal';

// Stuff
import {elementSelector} from 'modules/element/elementSelectors';
import {
  updateElement,
  selectElement,
  deleteElement
} from 'modules/element/element';
import {
  HOTSPOT_ELEMENT,
  BUTTON_ELEMENT,
  IMAGE_ELEMENT,
  TEXT_ELEMENT,
  CUSTOM_HTML_ELEMENT,
  FORM_ELEMENT
} from 'shared/element';

const _props = {
  id : PropTypes.string,
  element_type : PropTypes.string,
}

// This class infers the element to render from the element_type prop
@connect(elementSelector, {updateElement, selectElement, deleteElement, deleteModalElement})
export default class ElementContainer extends React.Component {
  myRef = React.createRef();

  handler = (prop, action) => (...rest) => {
    const {element_type, id} = this.props;
    const propHandler = this.props[prop];
    const args = [element_type, id, ...rest];
    propHandler && propHandler(...args);

    if(prop==='onDelete' && this.props.modalElementSelected){
        this.props.deleteModalElement(
            this.props.selectedModalId, this.props.modalElement.id
        );

    }else {
      this.props[action](...args);
    }
  };

  handleUpdate = this.handler('onUpdate', 'updateElement');
  handleSelect = this.handler('onSelect', 'selectElement');
  handleDelete = this.handler('onDelete', 'deleteElement');

  render = () => {
    const {
      element_type,
      element,

      // don't want to pass these props through
      onSelect,
      onUpdate,
      onDelete,

      ...otherProps
    } = this.props;

    if (!element) return null;

    // Note: otherProps are second as we don't care about element.id, only interaction.id
    const props = {
      onDelete: this.handleDelete,
      onUpdate: this.handleUpdate,
      onSelect: this.handleSelect,
      ...element,
      ...otherProps
    };

    switch (element_type) {
      case HOTSPOT_ELEMENT:
        return <HotspotElement {...props} />;
      case BUTTON_ELEMENT:
        return <ButtonElement {...props} />;
      case IMAGE_ELEMENT:
        return <ImageElement {...props} />;
      case TEXT_ELEMENT:
        return <TextElement {...props} />;
      case CUSTOM_HTML_ELEMENT:
        return <CustomHtmlElement {...props} />;
      case FORM_ELEMENT:
        return <FormElement {...props} />;
    }
    return null;
  };


}
ElementContainer.propTypes = _props;