import React from 'react';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { connect } from 'react-redux';
import Button from 'components/Buttons/Button';
import styles from 'modules/project/components/ProjectSettingsPage.scss';
import { userSearch, loginAsUser, deleteUser, receiveUsers } from 'modules/user/user';
// import UserDetails from 'modules/user/components/UserDetails';
import UserManagementModal from '../modules/user/components/UserManagementModal';
import TemplateLanguages from '../modules/template/components/TemplateLanguages';
import { userSelector } from '../modules/auth/authSelectors';
import PageBody from 'components/PageBody';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Icon from './Icon';
import AdminNodeEditorComponent from './AdminNodeEditorComponent'

@connect(userSelector, { userSearch, updatePageLoadingState, loginAsUser, deleteUser, receiveUsers })
class AdminPage extends React.Component {
  constructor() {
    super();

    this.state = {
      searchFilter: '',
    };
  }

  searchForUser = async () => {
    this.props.userSearch(
      {
        searchFilter: this.state.searchFilter,
      },
      (users) => {
        this.props.receiveUsers(users);
      }
    );
  };

  _deleteUser = (id) => {
    let { users } = this.state;

    this.props.deleteUser(id, () => {
      const userIndex = users.findIndex((u) => u.id === id);
      if (userIndex !== -1) {
        users.splice(userIndex, 1);
        this.setState({ users: users });
      }
    });
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') this.searchForUser();
  };

  render() {
    const { users } = this.state;
    const { loginAsUser, user } = this.props;

    setTimeout(() => {
      this.props.updatePageLoadingState(false);
    }, 1000);

    if (!user.superuser) return 'Unauthorised';
    return (
      <PageBody heading="Admin">
        <Tabs defaultIndex={1}>
          <TabList>
            <Tab >
              <h4 className="faded-heading">
                <Icon icon="users" /> Users
              </h4>
            </Tab>
            <Tab>
              <h4 className="faded-heading">
                <Icon icon="project-diagram" /> Nodes
              </h4>
            </Tab>
          </TabList>

          <TabPanel>
           
        <div style={{ padding: '30px' }}>
          <div className="grid">
            <div className="col5">
              <div className={styles.formWrapper}>
                {/* <h2 className="form-heading">User Search</h2> */}
                <div className="form-control">
                  <label>Search</label>
                  <input
                    type="text"
                    value={this.state.searchFilter}
                    onKeyPress={this.handleKeyPress}
                    onChange={(e) => {
                      this.setState({ searchFilter: e.target.value });
                    }}
                  />
                </div>
                <div className="form-control" style={{ marginBottom: 30 }}>
                  <Button icon="search" primary onClick={() => this.searchForUser()}>
                    Search
                  </Button>
                </div>
              </div>
            </div>
            <div className="col7">
              <div className={styles.formWrapper}>
                <TemplateLanguages />
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="col12">
              <div className={styles.formWrapper}>
                <UserManagementModal adminUsers={users} isAuthSuperUser={true} forAgencyUsers={false} />
              </div>
            </div>
          </div>
        </div>
          </TabPanel>
          <TabPanel>
           <AdminNodeEditorComponent />
          </TabPanel>
        </Tabs>
      </PageBody>
    );
  }
}

export default AdminPage;
