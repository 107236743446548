import React from 'react';
import TextElement from './TextElement';
import {getStyles, BUTTON_ELEMENT} from 'shared/element';

export default class ButtonElement extends React.Component {

  render() {
    const styles = getStyles(BUTTON_ELEMENT, this.props);

    return (
      <TextElement
        {...this.props}
        style={styles}
      />
    );
  }
}
