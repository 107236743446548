import React from 'react';
import { timeForSeconds } from 'utils/timeUtils';
import styles from './VideoTimeTooltip.scss';

export default function VideoTimeTooltip({ show, value }) {
  const inlineStyles = {
    zIndex: 10,
    opacity: show ? 1 : 0,
  };

  return (
    <div className={styles.tooltip} style={inlineStyles}>
      <div className={styles.arrow_down} />
      {timeForSeconds(value, true, true)}
    </div>
  );
}
