import React from "react";
import Icon from "components/Icon";
import Button from "components/Buttons/Button";
import MediaLibrary from "../../media/components/MediaLibrary";
import style from './MediaLibraryButton.scss';
import cx from "classnames";
import styles from "./MediaLibraryButton.scss";

export default class MediaLibraryButton extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            open: true
        }
    }

    render(){
        const {open} = this.state;

        const wrapper = cx(styles.wrapper, {
            [styles.open]: open,
        });

        return (
            <span>
                <Button secondary onClick={()=>this.setState({open: true})}><Icon name="th" /> Open Media Library</Button>
                <div className={wrapper}>
                    <MediaLibrary toggleLibrary={()=>this.setState({open: false})}/>
                </div>
            </span>
        )
    }
}
