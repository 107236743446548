import {takeEvery, put, select} from 'redux-saga/effects';
import {DROP_MEDIA_ON_COMPOSER} from 'modules/media/media';
import {composerStateSelector} from 'modules/composer/composerSelectors';
import {addNode} from 'modules/node/node';
import {currentProjectIdSelector} from 'modules/node/nodeSelectors';

export default function* addNodeToComposerSaga() {
  yield takeEvery(DROP_MEDIA_ON_COMPOSER, addNodeToComposer);
}

function* addNodeToComposer({mediaId, pos}) {
  const {zoom, pan} = yield select(composerStateSelector);

  // the DROP_MEDIA_ON_COMPOSER pos is a relative mouse position
  // to the composer. We need to apply the zoom and pan to it before
  // creating a node
  pos.x /= zoom;
  pos.y /= zoom;
  pos.x += pan.x;
  pos.y += pan.y;

  const {projectId} = yield select(currentProjectIdSelector);

  const node = {
    project_id: projectId,
    media_id: mediaId,
    posX: pos.x,
    posY: pos.y
  };

  yield put(addNode(node));
}
