import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {updatePageLoadingState} from 'modules/pageLoader/pageLoader';
import {success, error} from 'utils/alert';
import {DELETE_USER, userDeleted} from "modules/user/user";
import {setToken} from 'modules/auth/authUtils';

export default function* deleteUserSaga() {
  yield takeLatest(DELETE_USER, deleteUser);
}

function* deleteUser({userId, callback}){

  yield updatePageLoadingState(true);

  const res = yield fetch(`user/${userId}`, {
    method: 'DELETE'
  });

  yield updatePageLoadingState(false);

  if (res.status !== 200) {
    error({text:'Unable to Delete User'});
  }

  yield put(userDeleted(userId));
  callback && callback();
}