import {take, put, call, fork, cancel, select} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import {
  UPDATE_ELEMENT,
  DELETE_ELEMENT,
  updateElementStateLoading
} from 'modules/element/element';
import {elementSelector} from 'modules/element/elementSelectors';
import fetch from 'utils/saga/fetch';
import {error} from 'utils/alert';

const DELAY = 1400;

// {id: Task}
let tasks = {};

// Stores elements with a debounce.
export default function* storeElementSaga() {
  while (true) {
    const action = yield take([UPDATE_ELEMENT, DELETE_ELEMENT]);
    const {id} = action;

    // cancel any current task
    const task = tasks[id];
    if (task) yield cancel(task);

    // kill task runner if element has been deleted
    if (action.type === DELETE_ELEMENT) return;

    // start new task
    tasks[id] = yield fork(storeElementTask, action);
  }
}

// we run one of these tasks for each element id
// it waits a while then stores changes so that we don't fire loads of
// requests at once.
function* storeElementTask(action) {
  yield delay(DELAY);

  yield call(storeElement, action);
}

function* storeElement({element_type, id}) {
  let {element} = yield select(elementSelector, {element_type, id});

  const res = yield fetch('elements', {
    method: 'PUT',
    body: element
  });

  yield put(updateElementStateLoading(false));
}
