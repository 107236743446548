import {takeEvery, put} from 'redux-saga/effects';
import {DELETE_COMMENT, receiveComments} from 'modules/project/project';
import fetch from 'utils/saga/fetch';
import swal from 'sweetalert2';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {error} from 'utils/alert';

export default function* deleteProjectSaga() {
  yield takeEvery(DELETE_COMMENT, deleteComment);
}

function* deleteComment({commentId}) {
  const conf = yield swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to delete this comment.',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#ff6961',
    confirmButtonText: 'Yes, Delete It!',
  });

  yield put(updatePageLoadingState(true));

  const res = yield fetch(`comments/${commentId}`, {
    method: 'DELETE'
  });


  if (res.status !== 200) {
    yield put(updatePageLoadingState(false));
    error({text:'Unable To Delete Comment'})
  }

  const data = yield res.json();
  yield put(receiveComments(data));
  yield put(updatePageLoadingState(false));
}
