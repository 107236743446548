import {takeLatest, select} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {updatePageLoadingState} from 'modules/pageLoader/pageLoader';
import {success, error} from 'utils/alert';
import {LOGIN_AS_USER} from "modules/user/user";
import {setToken} from 'modules/auth/authUtils';
import {userSelector} from "modules/auth/authSelectors";

export default function* loginAsUserSaga() {
  yield takeLatest(LOGIN_AS_USER, loginAsUser);
}

function* loginAsUser({userId, saveInStorage}){
  yield updatePageLoadingState(true);

  const res = yield fetch(`loginAsUser/${userId}`, {
    method: 'GET',
  });

  if (res.status !== 200) {
    error({text:'Invalid Response From Server'});
    yield updatePageLoadingState(false);
  }
  const json = yield res.json();
  if(json.status === 'OK') {

    // If logged in user is agency NOT and admin, save the parent user data in local storage to show in logged in sub user account
    if (saveInStorage) {
      const {user: authUser} = yield select(userSelector);
      const storageKey = 'logged_in_sub_user_'+userId;

      sessionStorage.removeItem(storageKey);
      sessionStorage.setItem(
          storageKey,
          JSON.stringify({parent: {id: authUser.id, name: authUser.name}})
      );
    }

    setToken(json.token);
    window.location.href = '/'; // reauthenticate saga triggers onload
  } else {
    yield updatePageLoadingState(false);
    error({text:'Error'});
  }
}