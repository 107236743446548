import swal from 'sweetalert2';

export function info(args){
  const {title, text} = args;

  swal({
    title,
    text,
    type: 'info',
    confirmButtonText: 'OK'
  })
}

export function confirm(args) {
  const {title, text, onConfirm} = args;
  let {confirmButtonText}  = args;

  if (! confirmButtonText) {
    confirmButtonText = 'Yes';
  }

  swal({
    title,
    text,
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#ff6961',
    confirmButtonText,
  }).then(()=>{
    onConfirm();
  }, () => {

  });

}

export function success(args) {
  const {text} = args;

  swal({
    title: 'Success!',
    text,
    type: 'success',
    confirmButtonText: 'OK'
  })
}

/** Shows an Error alert
 * @param {Object} options
 * @param {String} options.title defaults to "Error"
 * @param {String} options.text
 * 
 */
export function error(options){
  let {title, text} = options;

  if (! title) {
    title = 'Error!'
  }


  swal({
    title,
    text,
    type: 'error',
    confirmButtonText: 'OK'
  })
}