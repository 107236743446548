import {takeLatest, put} from 'redux-saga/effects';
import {DELETE_LANGUAGE, languageDeleted} from 'modules/template/template';
import fetch from 'utils/saga/fetch';

export default function* deleteLanguageSaga() {
    yield takeLatest(DELETE_LANGUAGE, deleteLanguage);
}

function* deleteLanguage({languageId}) {
    const res = yield fetch(`languages/${languageId}`, {
        method: 'DELETE'
    });

    const {success} = yield res.json();

    if (success) {
        yield put(languageDeleted(languageId));
    }
}
