import React from 'react';
import {connect} from 'react-redux';
import {embedCodeSelector} from 'modules/project/projectSelectors';
import {Option, BooleanInput, SizeInput,   RangeInput, TextInput} from 'components/PropertyEditor';
import DropImageZone from 'modules/media/components/DropImageZone';
import ColorPicker from 'components/ColorPicker';
import debounce from 'lodash/debounce';
import Button from 'components/Buttons/Button';

@connect(embedCodeSelector)
export default class EmbedSettings extends React.Component {

  changeHandler = name => (e, val) => {
    this.props.updateProject({[name]: val})
  };

  changePlayerSkinHandler = (name, options = false) => (e, val) => {
    const {project: {player_skin}} = this.props;

    if (options && options.bigPlay) {
      player_skin.bigPlay[name] = val;
    } else if (options && options.controls) {
      player_skin.controls[name] = val;
    } else {
      player_skin[name] = val;
    }

    this.props.updateProject({player_skin: {...player_skin}})
  };

  handleChangeBrandingImage = ({ src } = {}) => {
    this.props.updateProject({branding_image_src: src});
  };

  handleChangeSize = (e, [embed_width, embed_height]) => {
    this.props.updateProject({
      embed_width,
      embed_height
    });
  };

  render() {
    const {embedCode, project} = this.props;

    const isProjectLegacy = project.legacy;

    return (
      <div className="form-control" >
          <form onChange={this.handleChange} className="grid">
            <div className="col6">
              <div className="form-control">
                <Option
                  label="Autoplay"
                  Component={BooleanInput}
                  value={project.autoplay}
                  onChange={this.changeHandler('autoplay')}
                />
              </div>
            </div>
            <div className="col6">
              {!isProjectLegacy && (
                <div className="form-control">
                  {/* TODO only show this option when autoplay is set to true */}
                    <Option
                      label="Unmute Text"
                      Component={TextInput}
                      value={project.player_skin.unmute_text}
                      onChange={this.changePlayerSkinHandler('unmute_text')}
                    />
                </div>
              )}
            </div>
            <div className="col6">
                <div className="form-control">
                  <Option
                    label="Show Player Seek Bar"
                    Component={BooleanInput}
                    value={project.show_controls}
                    onChange={this.changeHandler('show_controls')}
                  />
                </div>
            </div>
            <div className="col6">
              <div className="form-control">
                <Option
                    label="Enable Chapters"
                    Component={BooleanInput}
                    value={project.chapters}
                    onChange={this.changeHandler('chapters')}
                />
              </div>
            </div>
            <div className="col6">
              {!isProjectLegacy && (
                <Option
                    label="Big Play Icon Color"
                    value={project.player_skin.bigPlay.color}
                    Component={ColorPicker}
                    stackOrder={5}
                    onChange={this.changePlayerSkinHandler('color', {bigPlay: true})}
                    style={{ marginTop: 20 }}
                />
              )}
            </div>
            <div className="col6">
              {!isProjectLegacy && (
                <Option
                  label="Big Play Icon Size"
                  value={parseInt(project.player_skin.bigPlay.size)}
                  Component={RangeInput}
                  style={{ marginTop: 20 }}
                  onChange={this.changePlayerSkinHandler('size', {bigPlay: true})}
                  max={10}
                  min={4}
                />
              )}
            </div>
            <div className="col6">
              {!isProjectLegacy && (
                <Option
                  label="Controls Icon Color"
                  value={project.player_skin.controls.color}
                  Component={ColorPicker}
                  stackOrder={3}
                  onChange={this.changePlayerSkinHandler('color', {controls: true})}
                  style={{ marginTop: 20 }}
                />
              )}
            </div>
            <div className="col6">
              {!isProjectLegacy && (
                <Option
                  label="Controls Background Color"
                  value={project.player_skin.controls.background}
                  Component={ColorPicker}
                  stackOrder={2}
                  onChange={this.changePlayerSkinHandler('background', {controls: true})}
                  style={{ marginTop: 20 }}
                />
              )}
            </div>
            <div className="col12">
                <div className="form-control">
                  <Option
                    style={{ marginTop: 20 }}
                    Component={SizeInput}
                    value={[project.embed_width, project.embed_height]}
                    onChange={this.handleChangeSize}
                    ratio={project.base_width / project.base_height}
                  />
                </div>
            </div>
            <div className="col10">
                <div className="form-control">
                  <label>Branding Image</label>
                  <DropImageZone
                      onSuccess={this.handleChangeBrandingImage}
                      src={project.branding_image_src}
                      directory="brandingImages"
                  />
                  {!!project.branding_image_src && <Button onClick={this.handleChangeBrandingImage} style={{marginTop: '10px'}}>Clear Image</Button>}
                </div>
            </div>
          </form>
      </div>
    );
  }
}
