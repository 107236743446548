import {fromJS} from 'immutable';
import {ourDomains} from 'config';


const initialState = fromJS({
  pageLoading: false,
  whitelabelPageLoader: (! ourDomains.includes(window.location.hostname)),
  // gets updated with agency whitelabel data if relevant
  // null means we don't know yet , This is done so we don't render our 
  // branding while waiting for the fetch for Whitelabel data .
  whitelabel: (ourDomains.includes(window.location.hostname) ? false : null)
});

function pageLoaderReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PAGE_LOADING_STATE:
      return state.set('pageLoading', action.value);
    case UPDATE_WHITELABEL_LOADING_STATE:
      return state.set('whitelabelPageLoader', action.value);
    case RECEIVE_WHITELABEL:
      return state.set('whitelabel', fromJS(action.data));
  }

  return state;
}

export const reducers = {
  pageLoader: pageLoaderReducer
};

export const UPDATE_PAGE_LOADING_STATE = 'pageLoader:UPDATE_PAGE_LOADING_STATE';
export function updatePageLoadingState(value) {
  return {
    type: UPDATE_PAGE_LOADING_STATE,
    value
  };
}

export const UPDATE_WHITELABEL_LOADING_STATE = 'pageLoader:UPDATE_WHITELABEL_LOADING_STATE';
export function updateWhitelabelLoadingState(value){
  return {
    type: UPDATE_WHITELABEL_LOADING_STATE,
    value
  };
}

export const FETCH_WHITELABEL = 'pageLoader:FETCH_WHITELABEL';
export function fetchWhitelabel(){
  return{
    type: FETCH_WHITELABEL
  }
}

export const RECEIVE_WHITELABEL = 'agency:RECEIVE_WHITELABEL';
export function receiveWhitelabel(data) {
  return {
    type: RECEIVE_WHITELABEL,
    data
  };
}

