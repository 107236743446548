import React from 'react';
import { connect } from 'react-redux';

import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { projectSelector } from 'modules/project/projectSelectors';
import { viewProjectSettingsPage, updateProject } from 'modules/project/project';

import styles from './ProjectSettingsPage.scss';
import FoldersManagementModal from './FoldersManagementModal';
import ProjectPage from './ProjectPage';
// import ProjectPreview from './ProjectPreview';
import ProjectSettingsForm from './ProjectSettingsForm';

// import Notification from 'components/Notification';
import Link from 'components/Link'

@connect(
  projectSelector,
  {
    viewProjectSettingsPage,
    updateProject,
    updatePageLoadingState
  }
)
export default class ProjectSettingsPage extends React.Component {
  state = {
    choosingThumbnail: false,
    dirty: false,
    foldersManagementModal: false
  };

  componentWillMount() {
    const { updatePageLoadingState, project } = this.props;
    if (!project) {
      updatePageLoadingState(true);
    }
  }

  componentDidMount() {
    const { viewProjectSettingsPage, match } = this.props;
    viewProjectSettingsPage(match.params.projectId);
  }

  handleSubmit = (e, data) => {
    e.preventDefault();
    this.props.updateProject(data);
    this.setState({ dirty: false });
  };

  handleTrigger = () => {
    this.setState({ choosingThumbnail: true });
  };

  handleFormChange = () => {
    this.setState({ dirty: true });
  };

  timeGetter = getter => {
    this.getCurrentTime = getter;
  };

  handleFoldersManagementModal = value => {
    this.setState({
      foldersManagementModal: value
    });
  };

  render() {
    const { project, projectGroups, languageOptions, updatePageLoadingState } = this.props;

    if (!project) {
      return null;
    }

    const { choosingThumbnail, dirty } = this.state;

    return (
      <ProjectPage header="Settings" headerInfo={<Link>watch the training</Link>} project={project} projectGroups={projectGroups} active="settings">
        <section className={styles.settings_wrapper}>
          <ProjectSettingsForm
            choosingThumbnail={choosingThumbnail}
            onTriggerChooseThumbnail={this.handleTrigger}
            onChange={this.handleFormChange}
            onSubmit={this.handleSubmit}
            handleFoldersManagementModal={this.handleFoldersManagementModal}
            project={project}
            projectGroups={projectGroups}
            languageOptions={languageOptions}
            className={styles.settings_form}
          />
        </section>
        <FoldersManagementModal
          foldersManagementModal={this.state.foldersManagementModal}
          handleFoldersManagementModal={this.handleFoldersManagementModal}
        />
      </ProjectPage>
    );
  }
}
