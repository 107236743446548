import React from 'react';
import { logout } from 'utils/logout';
import styles from './DashboardSidebar.scss';
import MenuItem from "../MenuItem";
import Icon from 'components/Icon';

export default class ParentUserMenu extends React.Component {
    render(){
        const {setClasses, user} = this.props;

        return(
            <ul className={styles.menu_section}>
                <MenuItem icon="tachometer" className={setClasses('dashboard')} to={'/dashboard'}>
                    <span className={styles.menu_item_text}>Dashboard</span>
                </MenuItem>

                <MenuItem icon="project-diagram" className={setClasses('projects')} to={'/projects'}>
                    <span className={styles.menu_item_text}>Projects</span>
                </MenuItem>

                <MenuItem icon="photo-video" className={setClasses('videos')} to={'/videos'}>
                    <span className={styles.menu_item_text}>Media Library</span>
                </MenuItem>

                <MenuItem icon="users-cog" className={setClasses('agency')} to={'/agency/dashboard'}>
                    <span className={styles.menu_item_text}>Agency</span>
                </MenuItem>

                <MenuItem icon="clipboard-list" className={setClasses('surveys')} to={'/surveys'}>
                    <span className={styles.menu_item_text}>Surveys</span>
                </MenuItem>

                <MenuItem icon="mail-bulk" className={setClasses('customlists')} to={'/customlists'}>
                    <span className={styles.menu_item_text}>Custom Lists</span>
                </MenuItem>


                <MenuItem icon="user-graduate" className={setClasses('training')} to={'/training'}>
                    <span className={styles.menu_item_text}>Help & Training</span>
                </MenuItem>

                {/*<MenuItem icon="users-class" className={this.setClasses('masterclass')} to={'/masterclass'}>*/}
                {/*  <span className={styles.menu_item_text}>Masterclass</span>*/}
                {/*</MenuItem>*/}

                <MenuItem icon="user" className={setClasses('account')} to={'/account/preferences'}>
                    <span className={styles.menu_item_text}>My Account</span>
                </MenuItem>

                {
                    (user.superuser === 1) &&
                    <MenuItem icon="lock" className={setClasses('admin')} to={'/admin'}>
                        <span className={styles.menu_item_text}>Admin</span>
                    </MenuItem>
                }

                <li>
                    <a onClick={()=>logout()}>
                        <Icon name="sign-out-alt"  /> <span className={styles.menu_item_text}>Logout</span>
                    </a>
                </li>
            </ul>
        )
    }
}