import {put, takeLatest} from 'redux-saga/effects';
import {SAVE_USER, receiveUser} from 'modules/user/user';
import fetch from 'utils/saga/fetch';
import {success, error} from 'utils/alert';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";

export default function* saveUserSaga() {
    yield takeLatest([SAVE_USER], saveUser);
}

function* saveUser(action) {
    yield put(updatePageLoadingState(true));
    const {id, data, callback} = action;
    const url = 'user/' + id;

    const res = yield fetch(url, {
        method: 'put',
        body: {id, ...data}
    });

    yield put(updatePageLoadingState(false));

    if (res.status !== 200) {
      error({text:'Unable to update user.'});
    }

    const newUser = yield res.json();
    yield put(receiveUser(newUser));

  callback && callback();
}
