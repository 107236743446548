import {takeLatest, put } from 'redux-saga/effects'
import { FETCH_CUSTOM_LISTS, receiveCustomLists } from 'modules/customLists/customList';
import {error} from 'utils/alert';
import fetch from 'utils/saga/fetch'


export default function* getCustomLists() {
    yield takeLatest([FETCH_CUSTOM_LISTS], fetchCustomLists)
}

function* fetchCustomLists(){
    const res = yield fetch(`customlists`);
    const {success, customLists} = yield res.json();
    if (!success) {
        // error({text:'Unable To Get Lists'});
        console.warn('failed to fetch customlists')
        return;
    }
    yield put(receiveCustomLists(customLists))
}