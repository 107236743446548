/* Use these are references for updates for usage on LocalStorage or whatever and can be handy for
 * tracing updates/changelog
 * format goes 'update-key': 'upd-ddmmyy-DD' where middle section is date and DD digits of updates
 * multiple can happen in same date
 */
export default {
  'qencode-down-01': {
    title: 'Transcoding service',
    key: 'upd-200620-01',
    text: 'Our Transcoding service is down , We&apos;re on it and will resolve this soon',
  },
  'qencode-up-01': {
    title: 'Transcoding service',
    key: 'upd-200620-02',
    text: `Transcoding service is now back up again. Click on retry to reupload again`,
  },
  'embed-code-01': {
    title: 'Embedded projects',
    key: 'upd-200718-01',
    text: `There's been an update that will affect projects already embedded on your sites. If you have any, please republish and use the new embed script on your site.
    This update should not affect projects on the share page.`,
  },
};
