import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';
import {authenticatedSelector, authenticatingSelector} from 'modules/auth/authSelectors';
import PageLoader from 'components/PageLoader';

export default connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // Determine if the user is authenticated or not
  authenticatedSelector,
  // A nice display name for this check
  wrapperDisplayName: 'Authenticated',

  authenticatingSelector,

  // Render this component when the authenticatingSelector returns true
  AuthenticatingComponent: PageLoader
});
