import { fromJS } from 'immutable';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import { keyBy } from 'utils/immutableUtils';
import { SELECT_INTERACTION } from 'modules/interaction/interaction';
import { VIEW_NODE_PAGE } from 'modules/node/node';
import { VIEW_MODAL_PAGE } from 'modules/modal/modal';

const initialState = fromJS({
  // When populated looks something like
  /*
  {
    "App//HotspotElement": {
      4: {
        id: 4,
        ...
      }
    }
  }
  */
  data: {},
  /* This will be used for element loading feature. */
  loading: false,

  // Used by the element editor
  selected: {
    element_type: null,
    id: null
  }
});

function elementReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ELEMENT:
      return state.mergeIn(['data', action.element_type, action.id.toString()], fromJS(action.data));

    case UPDATE_ELEMENT_STATE_LOADING:
      return state.set('loading', action.value);

    case RECEIVE_ELEMENTS:
      return state.mergeDeepIn(
        ['data'],
        mapValues(groupBy(action.elements, 'element_type'), el => keyBy(el))
      );

    case SELECT_INTERACTION:
      if (!action.interaction) return state.set('selected', initialState.get('selected'));

      return state.mergeIn(['selected'], {
        element_type: action.interaction.element_type,
        id: action.interaction.element_id
      });

    case ELEMENT_DELETED:
      return state.deleteIn(['data', String(action.element_type), String(action.id)])
      .setIn(['selected'], initialState.get('selected'));

    case SELECT_ELEMENT:
      return state.mergeIn(['selected'], {
        element_type: action.element_type,
        id: action.id
      });

    case VIEW_MODAL_PAGE:
    case VIEW_NODE_PAGE:
    case DESELECT_ELEMENT:
      return state.setIn(['selected'], initialState.get('selected'));
  }
  return state;
}

export const reducers = {
  elements: elementReducer
};

export const RECEIVE_ELEMENTS = 'element:RECEIVE_ELEMENTS';
export function receiveElements(elements = []) {
  if (!elements.every(element => !!element.element_type && !!element.id)) {
    throw 'elements must have an element_type and id';
  }

  return {
    type: RECEIVE_ELEMENTS,
    elements
  };
}

export function receiveElement(element) {
  return receiveElements([element]);
}

export const UPDATE_ELEMENT = 'element:UPDATE_ELEMENT';
export function updateElement(element_type, id, data) {
  return {
    type: UPDATE_ELEMENT,
    element_type,
    id,
    data
  };
}

export const UPDATE_ELEMENT_STATE_LOADING = 'element:UPDATE_ELEMENT_STATE_LOADING';
export function updateElementStateLoading(value) {
  return {
    type: UPDATE_ELEMENT_STATE_LOADING,
    value
  };
}

export const DELETE_ELEMENT = 'element:DELETE_ELEMENT';
export function deleteElement(element_type, id) {
  return {
    type: DELETE_ELEMENT,
    element_type,
    id
  };
}

export const ELEMENT_DELETED = 'element:ELEMENT_DELETED';
export function elementDeleted(element_type, id) {
  return {
    type: ELEMENT_DELETED,
    element_type,
    id
  };
}

export const SELECT_ELEMENT = 'element:SELECT_ELEMENT';
export function selectElement(element_type, id) {
  return {
    type: SELECT_ELEMENT,
    element_type,
    id,
  };
}
export const MODAL_ELEMENT_SELECTED = 'element:MODAL_ELEMENT_SELECTED';
export function selectModalElement(element_type, id) {
  return {
    type: MODAL_ELEMENT_SELECTED,
    element_type,
    id,
  };
}

export const DESELECT_ELEMENT = 'element:DESELECT_ELEMENT';
export function deselectElement() {
  return {
    type: DESELECT_ELEMENT
  };
}

export const APPLY_ELEMENT_TEMPLATE = 'element:APPLY_ELEMENT_TEMPLATE';
export function applyElementTemplate(element_type, templateId, id) {
  return {
    type: APPLY_ELEMENT_TEMPLATE,
    element_type,
    templateId,
    id
  };
}
