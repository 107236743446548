import React, {Component} from 'react';
import {connect} from 'react-redux';
import Rodal from "rodal";
import Icon from "components/Icon";
import {addProjectGroup} from 'modules/project/project';
import Button from 'components/Buttons/Button';
import {error} from 'utils/alert';
import {Option, TextInput} from 'components/PropertyEditor';

@connect(null, { addProjectGroup })
export default class AddProjectGroupModal extends Component {
    state = {
        title: '',
    };

    clearAndResetFields = () => {
        this.props.handleAddProjectGroupModal(false);
        this.setState({title: ''})
    };

    handleAddFolder = () => {
        const { title } = this.state;

        if (!title) {
            return error({text: 'Folder has no title'});
        }

        this.props.addProjectGroup(title);

        this.clearAndResetFields();
    };

    closeModal = () => {
        this.props.handleAddProjectGroupModal(false);
    };

    render() {
        return (
            <Rodal
                visible={this.props.addProjectGroupModal}
                onClose={this.clearAndResetFields}
                height={250}
                width={400}
            >
                <div className="modal-heading">
                    <Icon name="plus" /> Add New Folder
                </div>

                <div className="modal-body">
                    <div className="grid">
                        <div className="col12" style={{paddingTop: '20px'}}>
                            <Option
                                label="Enter a name for the folder"
                                value={this.state.title}
                                Component={TextInput}
                                onChange={(e, value) => {this.setState({title: value})}}
                            />
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <Button primary onClick={this.handleAddFolder} small>
                        <Icon icon="plus" /> Create
                    </Button>
                    <Button onClick={this.closeModal} small>
                        <Icon icon="times" /> Cancel
                    </Button>
                </div>
            </Rodal>
        );
    }
}