import React from 'react';
import { connect } from 'react-redux';
import { projectSelector } from 'modules/project/projectSelectors';
import {
  homePath,
  projectPath,
  projectSettingsPath,
  publishProjectPath,
  projectStatsPath,
  projectCanvasPath
} from 'routeBuilders';
import SubNav from '../../../components/SubNav';

export default class AccountNav extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { active, isSubUser } = this.props;

    let tabs = [
      {
        active: active === 'preferences',
        text: 'Preferences',
        route: '/account/preferences'
      },

      {
        active: active === 'integrations',
        text: 'Integrations ',
        route: '/account/integrations'
      },
      // {
      //     active: (active === 'affiliates'),
      //     text: 'Affiliates',
      //     route: '/account/affiliate'
      // },
      // {
      //   active: active === 'video',
      //   text: 'Video Settings',
      //   route: '/account/videosettings'
      // },
      // {
      //   active: active === 'customlists',
      //   text: 'Custom Lists',
      //   route: '/account/customlists'
      // },
      {
        active: active === 'api',
        text: 'Api',
        route: '/account/credentials'
      }
    ];

    if (isSubUser) {
      tabs = [ tabs[0], tabs[1] ]; // only account settings visible to sub users
    }

    return <SubNav items={tabs} />;
  }
}
