import {fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';

const initialState = fromJS({
  loading: false,
  data: [],
  languages: {},
  languageId: null
});

function templatesReducer(state = initialState, action) {
  switch (action.type) {
    case VIEW_TEMPLATE_LIST:
      return state.set('loading', true);

    case RECEIVE_TEMPLATES:
      return state.set('loading', false).set('data', fromJS(action.templates));

    case RECEIVE_LANGUAGES:
        return state.set('languages', keyBy(action.languages));

    case RECEIVE_LANGUAGE:
        return state.mergeIn(
            ['languages', action.language.id.toString()],
            fromJS(action.language)
        );

    case VIEW_TEMPLATE_LIST_BY_LANGUAGE:
        return state.set('languageId', action.languageId);

    case LANGUAGE_DELETED:
        return state.deleteIn(['languages', action.languageId.toString()]);
  }
  return state;
}

export const reducers = {
  templates: templatesReducer
};

export const VIEW_TEMPLATE_LIST = 'template:VIEW_TEMPLATE_LIST';
export function viewTemplateList(table, languageId = '') {
  return {
    type: VIEW_TEMPLATE_LIST,
    table,
    languageId
  };
}

export const VIEW_TEMPLATE_LIST_BY_LANGUAGE = 'template:VIEW_TEMPLATE_LIST_BY_LANGUAGE';
export function viewTemplateListByLanguage(languageId) {
    return {
        type: VIEW_TEMPLATE_LIST_BY_LANGUAGE,
        languageId
    };
}

export const RECEIVE_TEMPLATES = 'template:RECEIVE_TEMPLATES';
export function receiveTemplates(templates) {
  return {
    type: RECEIVE_TEMPLATES,
    templates
  };
}

export const RECEIVE_LANGUAGES = 'template:RECEIVE_LANGUAGES';
export function receiveLanguages(languages) {
    return {
        type: RECEIVE_LANGUAGES,
        languages
    };
}

export const RECEIVE_LANGUAGE = 'template:RECEIVE_LANGUAGE';
export function receiveLanguage(language) {
    return {
        type: RECEIVE_LANGUAGE,
        language
    };
}

export const CREATE_LANGUAGE = 'template:CREATE_LANGUAGE';
export function createLanguage(form){
    return{
        type: CREATE_LANGUAGE,
        form
    }
}

export const DELETE_LANGUAGE = 'template:DELETE_LANGUAGE';
export function deleteLanguage(languageId){
    return {
        type: DELETE_LANGUAGE,
        languageId
    }
}

export const LANGUAGE_DELETED = 'template:LANGUAGE_DELETED';
export function languageDeleted(languageId){
    return {
        type: LANGUAGE_DELETED,
        languageId
    }
}

