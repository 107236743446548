import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import map from 'lodash/map';
import PropTypes from 'prop-types'; 

// stuff
import styles from './ElementToolbar.scss';

import { ELEMENT_EDITOR_DOM_ID } from 'modules/interaction';
import { getRelativeDrop } from 'modules/composer/dragging';
import { userSelector } from 'modules/auth/authSelectors';

// components
import ElementItem from 'modules/node/components/ElementItem';

@connect(userSelector)
export default class ElementToolbarItem extends React.Component {
  handleSelectElement = posObject => {
    // Add Element with given position
    const { element, onSelect } = this.props;
    onSelect(element.type, posObject);
    // addInteraction(nodeId, elementType, posObject, { dynamic });
  };

  handleDragStop = e => {
    const acceptedDrop = getRelativeDrop(ELEMENT_EDITOR_DOM_ID, e);
    if (!acceptedDrop) return;

    this.handleSelectElement(acceptedDrop);
  };

  render() {
    const { element, user } = this.props;

    if (!element.icon) {
      console.error(`Error in Elements Toolbar , no icon is set for ${element.name} element`);
      return null;
    }

    let shouldLock;
    if (user && element.exclusiveTo) {
      shouldLock = true;
      // handle exclusive to multiple type of users
      if (Array.isArray(element.exclusiveTo)) {
        element.exclusiveTo.forEach(userFeature => {
          if (user[userFeature]) shouldLock = false;
        });
      } else if (typeof element.exclusiveTo === 'string') {
        if (user[element.exclusiveTo]) shouldLock = false;
      }
    }

    if (shouldLock) {
      return (
        <div className={styles.ElementToolbarItem}>
          <ElementItem element={element} locked={shouldLock} />
        </div>
      );
    }

    return (
      <div className={styles.ElementToolbarItem}>
        <ElementItem
          element={element}
          onSelectElement={() => this.handleSelectElement({ x: 50, y: 50 })}
          onDragged={this.handleDragStop}
        />
      </div>
    );
  }
}
