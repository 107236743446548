import { fromJS } from 'immutable';

const initialState = fromJS({
    customLists: []
});

function customListsReducer (state = initialState, action) {
    switch (action.type) {
        case RECEIVE_CUSTOM_LISTS:
            return state.set('customLists', fromJS(action.customLists));

        case RECEIVE_CUSTOM_LIST:
            return state.update(
                'customLists',
                customLists => customLists.push(fromJS(action.customList))
            );

        case DELETED_CUSTOM_LIST:
            return state.set(
                'customLists',
                state.get('customLists').filter(item => item.get('id') !== action.customListId)
            );
    }
    return state;
}

export const reducers = {
    customLists : customListsReducer
};


export const FETCH_CUSTOM_LISTS = 'account:FETCH_CUSTOM_LISTS';
export function fetchCustomLists() {
    return {type: FETCH_CUSTOM_LISTS};
}

export const RECEIVE_CUSTOM_LISTS = 'account:RECEIVE_CUSTOM_LISTS';
export function receiveCustomLists(customLists) {
    return {
        type: RECEIVE_CUSTOM_LISTS,
        customLists
    };
}

export const ADD_CUSTOM_LIST = 'account:ADD_CUSTOM_LIST';
export function addCustomList(customList, callback) {
    return {
        type: ADD_CUSTOM_LIST,
        customList,
        callback
    }
}

export const RECEIVE_CUSTOM_LIST = 'account:RECEIVE_CUSTOM_LIST';
export function receiveCustomList(customList) {
    return {
        type: RECEIVE_CUSTOM_LIST,
        customList
    }
}

export const DELETE_CUSTOM_LIST = 'account:DELETE_CUSTOM_LIST';
export function deleteCustomList(customListId) {
    return {
        type: DELETE_CUSTOM_LIST,
        customListId
    }
}
export const DELETED_CUSTOM_LIST = 'account:DELETED_CUSTOM_LIST';
export function deletedCustomList(customListId) {
    return {
        type: DELETED_CUSTOM_LIST,
        customListId
    }
}