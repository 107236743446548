function getReducers(r) {
  return r.keys().reduce((memo, key) => {
    const {reducers} = r(key);

    // Only add if function.
    if (!reducers) {
      return memo;
    }

    return {...memo, ...reducers};
  }, {});
}

const modules = require.context('./', true, /\.\/\w+\/index.js/);
export default getReducers(modules);
