import React from 'react';
import {
  Section,
  Option,
  SizeInput,
  PositionInput,
  RangeInput,
  IntegerInput,
  SelectInput
} from 'components/PropertyEditor';
import {TextInput} from "components/PropertyEditor/PropertyEditor";
import Button from 'components/Buttons/Button';
import reduce from 'lodash/reduce';

export default class PositionableElementProperties extends React.Component {
  static defaultProps = {
    title: 'Element Properties'
  };

  handleChangePosVertical = (e, posY) => {
    this.props.updateElement({
      posY
    });
  };

  handleChangePosHorizontal = (e, posX) =>  {
    this.props.updateElement({
      posX
    });
  };

  handleChangeHeight = (e, height) => {
    this.props.updateElement({
      height
    });
  };


  handleChangeWidth = (e, width) => {
    this.props.updateElement({
      width,
    });
  };

  handleNameChange = (e) => {
    this.props.updateElement({
      name: e.target.value
    })
  };

  handleIndexChange = (e, index) => {
    this.props.updateElement({
      zIndex: index
    })
  };

  updateInteractionElementGroup = (e, elementGroupId) => {

      const {
          interaction: { id: interactionId },
          updateInteraction
      } = this.props;

      elementGroupId = "0" === elementGroupId ? null : elementGroupId;

      updateInteraction(interactionId, {element_group_id: elementGroupId});
  };

  get getElementGroups() {
    if(typeof this.props.elementGroups === 'undefined' ) return false;

    return reduce(this.props.elementGroups, (memo, value) => {
                    memo[value.id] = value.name;
                    return memo;
                }, { 0: 'No Groups' });
  }

  render() {
    const {element, title, interaction, interactionLayer} = this.props;

    if (!element) return null;

    const {posX, posY, width, height, name, zIndex} = element;

    return (
      <Section title={title} icon="sliders-v">
        <Option
          label="Name"
          value={name}
          Component={TextInput}
          onChange={this.handleNameChange}
        />
          {
              (this.getElementGroups && ! interactionLayer) ?
                  <div className="grid">
                      <div className="col10">
                          <Option
                              label="Grouping"
                              Component={SelectInput}
                              options={this.getElementGroups}
                              value={interaction.element_group_id}
                              onChange={this.updateInteractionElementGroup}
                          />
                      </div>
                      <div className="col2" style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                          <Button
                              primary
                              style={{margin: '10px 0 0 20px'}}
                              onClick={this.props.handleElementGroupModal}
                          >Add</Button>
                      </div>
                  </div>
                  : null
          }
        <Option
          label="Vertical Position (px)"
          value={posY}
          Component={RangeInput}
          onChange={this.handleChangePosVertical}
          max={405}
        />
        <Option
          label="Horizontal Position (px)"
          value={posX}
          Component={RangeInput}
          onChange={this.handleChangePosHorizontal}
          max={720}
        />

        <Option
          label="Height (px)"
          value={height}
          Component={RangeInput}
          onChange={this.handleChangeHeight}
          max={405}
        />

        <Option
          label="Width (px)"
          value={width}
          Component={RangeInput}
          onChange={this.handleChangeWidth}
          max={720}
        />
        <Option
          label="Stack Order"
          value={zIndex}
          Component={RangeInput}
          min={1}
          max={10}
          onChange={this.handleIndexChange}
          helpText="The element with the highest number will appear ontop of elements with lower numbers"
        />
      </Section>
    );
  }
}
