import {takeLatest, put} from 'redux-saga/effects';
import {
  GENERATE_PIXEL_CODE,
  saveGeneratedPixelCode
} from 'modules/stat/stat';
import fetch from 'utils/saga/fetch';

export default function* fetchProjectsSaga() {
  yield takeLatest(
    [GENERATE_PIXEL_CODE],
    generatePixelCode
  );
}

function* generatePixelCode({projectId, price}) {
  const res = yield fetch(`analytics/${projectId}/pixel/${encodeURIComponent(price)}`, {
    method: 'POST'
  });
  const pixelCode = yield res.json();
  yield put(saveGeneratedPixelCode(pixelCode && pixelCode.code));
}
