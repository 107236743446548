import {put, select, takeLatest} from 'redux-saga/effects';
import {SAVE_AGENCY, receiveAgency} from 'modules/agency/agency';
import fetch from 'utils/saga/fetch';
import {error} from 'utils/alert';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {agencySelector} from "modules/agency/agencySelectors";

export default function* saveAgencySaga() {
  yield takeLatest([SAVE_AGENCY], saveAgency);
}

function* saveAgency(action) {
  yield put(updatePageLoadingState(true));

  let $agency = yield select(agencySelector);
  const url = 'agency/' + $agency.agency.id;
  const res = yield fetch(url, {
    method: 'put',
    body: $agency.agency
  });

  yield put(updatePageLoadingState(false));

  if (res.status !== 200) {
    error({text:'Unable To Save'});
    return ;
  }

  const agency = yield res.json();

  yield put(receiveAgency(agency));
}
