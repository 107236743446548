import {take, put, call, fork, cancel, select} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import {receiveAgency, UPDATE_AGENCY} from 'modules/agency/agency';
import {agencySelector} from 'modules/agency/agencySelectors';
import fetch from 'utils/saga/fetch';
import {whiteLabelSelector} from "../../pageLoader/pageLoaderSelectors";
import {receiveWhitelabel} from "../../pageLoader";

const DELAY = 1400;

// {id: Task}
let tasks = {};

// Stores  with a debounce.
export default function* updateAgencySaga() {
  while (true) {
    const action = yield take([UPDATE_AGENCY]);
    const {id} = action;

    // cancel any current task
    const task = tasks[id];
    if (task) yield cancel(task);

    // start new task
    tasks[id] = yield fork(updateAgencyTask, action);
  }
}

// we run one of these tasks for each element id
// it waits a while then stores changes so that we don't fire loads of
// requests at once.
function* updateAgencyTask(action) {
  yield delay(DELAY);

  yield call(storeAgency, action);
}

function* storeAgency() {
  const { agency } = yield select(agencySelector);
  const { whitelabel } = yield select(whiteLabelSelector);
  const url = 'agency/' + agency.id;

  const res = yield fetch(url, {
    method: 'PUT',
    body: agency
  });

  const data = yield res.json();

  yield put(receiveAgency(data));

  /**
   * Update Whitelabel data if the agency is linked
   */
  if (whitelabel && agency.id === whitelabel.id) {
    yield put(receiveWhitelabel(data));
  }


  // backend currently returns boolean uncomment to store res element
  // if (element_type === "App\\ImageElement") {
  //   const data = yield res.json();
  //   yield put(updateElement(element_type, data.id, data));
  // }
}
