export const homePath = () => '/';
export const accountPath = () => '/account';
export const statsListPath = () => '/stats';
export const dashboardPath = () => '/dashboard';
export const projectsPath = () => '/projects';
export const accountDetailsPath = () => '/account/details';
export const accountIntegrationsPath = () => '/account/integrations';
export const accountCustomListsPath = () => '/customlists';
export const projectPath = ({projectId}) => `/projects/${projectId}`;
export const addProjectPath = ({templateId} = {}) => {
  const path = `/projects/add`;
  if (templateId) {
    return path + `/${templateId}`;
  }
  return path;
};
export function nodePath({projectId, nodeId}) {
  return projectPath(...arguments) + `/nodes/${nodeId}`;
}

export function modalPath({modalId}) {
  return nodePath(...arguments) + `/modals/${modalId}`;
}

export const projectStatsPath = (...args) => {
  return projectPath(...args) + '/analytics';
};

export const projectCanvasPath = (...args) => {
    return projectPath(...args) + '/canvas';
};

export const projectSharingPage = (...args) => {
  return projectPath(...args) + '/sharing';
};

export const projectChaptersPage = (...args) => {
  return projectPath(...args) + '/chapters';
};

export const projectSettingsPath = (...args) =>
  projectPath(...args) + '/settings';

export const publishProjectPath = (...args) =>
  projectPath(...args) + '/publish';

// generates a Route path e.g. projects/:projectId/nodes/:nodeId
export function toRoutePath(pathBuilder, params = []) {
  const routeParams = params.reduce((routeParams, param) => {
    return {...routeParams, [param]: `:${param}`};
  }, {});

  return pathBuilder(routeParams);
}
