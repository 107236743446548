import React from 'react';
import SubNav from "../../../components/SubNav";

export default class AgencySubNav extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const  items = [
            {
                active: (window.location.href.includes('agency/dashboard')),
                text: 'Dashboard',
                route: '/agency/dashboard'
            },
            {
                active: (window.location.href.includes('agency/consulting-kit')),
                text: 'Consultant Kit',
                route: '/agency/consulting-kit'
            },
        ];

        return <SubNav items={items} />
    }
}
