import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import { previewUrl } from 'modules/project/projectUtils';
import { projectSelector } from 'modules/project/projectSelectors';
import styles from './ProjectPreview.scss';
import Spinner from 'components/Spinner';
import VideoPlayer from '../../../components/VideoPlayer';
import PropTypes from 'prop-types';
import Icon from "components/Icon";

const _props = {
  responsive: PropTypes.bool,
  forceControls: PropTypes.bool,
  preventAutoPlay: PropTypes.bool,
  show: PropTypes.bool,
  project: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
  }),
  height: PropTypes.number,
  width: PropTypes.number,
};

// TODO: Clean up this mess

@connect(
  projectSelector,
  {}
)
class ProjectPreview extends React.Component {
  state = {
    cacheBuster: randomNumberStr(),
  };

  componentWillReceiveProps(nextProps) {
    // reload iframe if any prop changes
    if (
      !isEqual(nextProps.project, this.props.project) ||
      !isEqual(nextProps.forceControls, this.props.forceControls) ||
      !isEqual(nextProps.reloader, this.props.reloader)
    ) {
      this.setState({
        cacheBuster: randomNumberStr()
      });
    }
  }

  // public method used for thumbnail screenshot
  getCurrentTime = async () => {
    this.iframe.contentWindow.postMessage('player_time_query', '*');

    return new Promise(resolve => {
      window.addEventListener(
        'message',
        ({ data }) => {
          if (data.player_time) {
            resolve(data.player_time);
          }
        },
        { once: true }
      );

      // if we don't get the time for a while, assume 0
      setTimeout(() => resolve(0), 1500);
    });
  };

  generateEmbedCode = previewUrl => {
    const { preventAutoPlay, height, width } = this.props;
    if (preventAutoPlay) {
      previewUrl = previewUrl + '?autoplay=0';
    }
    return `<iframe src="${previewUrl}" class="embed-responsive-item" width="${width ||
      720}" height="${height || 405}" frameborder="0" allow="autoplay *" scrolling="no" />`;
  };

  render(...props) {
    let { project, timeGetter, forceControls,  startNodeId, projectTemplate } = this.props;


    if (!project && !projectTemplate) {
      return null;
    }

    // if previewing a template preview that otherwise pick active project from strore if any
    project = projectTemplate ? projectTemplate : project ;

    // expose time getter to parent
    timeGetter && timeGetter(this.getCurrentTime);

    let url = previewUrl(project.id) + `?cacheBuster=${this.state.cacheBuster}`;

    if (startNodeId) {
      url = url + '&startNodeId=' + startNodeId;
    }

    if (forceControls !== undefined) {
      url += `&forceControls=${forceControls}`;
    }

    const html = this.generateEmbedCode(url);
    return (
      <div className={styles.wrapper}>
        {project.start_node_id === 0 && (
          <div className="embed-responsive">
            <div className="embed-responsive-item">
              <div className={styles.noVideo}>
                <h3>Unable To Show Preview As Project Has No Start Node</h3>
              </div>
            </div>
          </div>
        )}
        {project.start_node_id > 0 && (
          <div>
            {project.preview_url ? (
              // Previews used for templates for example not an interactr player
              <div className="embed-responsive">
                <div className="embed-responsive-item">
                  <VideoPlayer url={project.preview_url} videoId={project.id} className="embed-responsive-item" />
                </div>
              </div>
            ) :  (
                <div>
                  <div
                    style={{
                        position: 'absolute',
                        zIndex: '10',
                        marginTop: '160px',
                        marginLeft: '300px'
                    }}>
                    <Spinner />
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: html }}
                    className="embed-responsive"
                    style={{ zIndex: 20 }}
                  />
                </div>
              )
            }
          </div>
        )}
      </div>
    );
  }
}

function randomNumberStr() {
  return Math.random()
    .toString()
    .replace('.', '');
}

ProjectPreview.propTypes = _props;
export default ProjectPreview;
