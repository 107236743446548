const TOKEN = 'auth-token';

export function getToken() {
  return window.localStorage.getItem(TOKEN);
}

export function setToken(token) {
  if (token === null) {
    window.localStorage.removeItem(TOKEN);
    return;
  }
  window.localStorage.setItem(TOKEN, token);
}
