import React from 'react';
import styles from './Label.scss';
import cx from "classnames";

export default class Label extends React.Component{
    render(){
        const {primary, danger, children} = this.props;

        const classList = cx(styles.label, {
            [styles.primary]: primary,
            [styles.danger]: danger,
        });

       return  <span className={classList}>{children}</span>
    }
}
