import React from 'react';
import DraggableResizable from 'components/DraggableResizable';
import Element from './Element';

export default class PositionableElement extends React.Component {
  handleChangeDimensions = ({x: posX, y: posY}, {w: width, h: height}) => {
    // TODO: check if size/pos has changed before triggering
    // console.log('dimensions change from positionable Element \n' , {posX, posY, width, height})
    this.props.onUpdate({posX, posY, width, height});
  };

  render() {
    const {
      posX: x,
      posY: y,
      width: w,
      height: h,
      disabled,
      children,
      style,
      zIndex,
      ...props
    } = this.props;

    let styles = {...style, cursor: 'move', width: '100%', height: '100%'};

    return (
      <DraggableResizable
        bounds="parent"
        pos={{x, y}}
        size={{w, h}}
        onChange={this.handleChangeDimensions}
        disabled={disabled}
        minWidth={20}
        zIndex={zIndex}
      >
        <Element
          {...props}
          style={styles}
        >
          {children}
        </Element>
      </DraggableResizable>
    );
  }
}
