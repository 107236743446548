import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {updatePageLoadingState} from 'modules/pageLoader/pageLoader';
import {success, error} from 'utils/alert';
import {USER_SEARCH} from "modules/user/user";

export default function* userSearchSaga() {
  yield takeLatest(USER_SEARCH, userSearch);
}

function* userSearch({data, callback}){
  yield updatePageLoadingState(true);

  const res = yield fetch(`searchForUser`, {
    method: 'POST',
    body: {searchFilter: data.searchFilter}
  });

  yield updatePageLoadingState(false);

  if (res.status !== 200) {
    error({text:'Invalid Response From Server'})
  }

  const userLookup = yield res.json();
  if (!userLookup || userLookup.length === 0) {
    error({text:'No User Found With Them Details'})
  } else {
    callback(userLookup);
  }
}