import {takeLatest, put} from 'redux-saga/effects';
import {CREATE_LANGUAGE, receiveLanguage} from 'modules/template/template';
import fetch from 'utils/saga/fetch';

export default function* createLanguageSaga() {
    yield takeLatest(CREATE_LANGUAGE, createLanguage);
}

function* createLanguage({form}) {
    const res = yield fetch(`languages`, {
        method: 'POST',
        body: form
    });

    const {data} = yield res.json();

    yield put(receiveLanguage(data));
}
