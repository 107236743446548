import React from 'react';
import PositionableElement from './PositionableElement';
import {CUSTOM_HTML_ELEMENT} from 'shared/element'

export default class CustomHtmlElement extends React.Component {
  render() {
    const {html} = this.props;

    return (
      <PositionableElement {...this.props} style={{overflow: 'hidden'}}>
        <div dangerouslySetInnerHTML={{__html: html}}/>
      </PositionableElement>
    );
  }
}
