import React from 'react';
import { connect } from 'react-redux';
import TemplateList from 'modules/template/components/TemplateList';
import { withRouter } from 'react-router-dom';
import { projectPath, addProjectPath } from 'routeBuilders';
import { createProject } from 'modules/project/project';
import { projectFoldersSelector } from 'modules/project/projectSelectors';
import Rodal from 'components/Rodal';
import Icon from 'components/Icon';
import Button from 'components/Buttons/Button';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import PageBody from 'components/PageBody';
import styles from './AddProjectPage.scss';
import cx from 'classnames';
import { Option, BooleanInput, SelectInput } from 'components/PropertyEditor';
import { whiteLabelSelector } from 'modules/pageLoader/pageLoaderSelectors';
import { recreateSelectOptions } from '../../../utils/domUtils';
import { viewTemplateListByLanguage } from 'modules/template/template';
import ProjectsHeaderComponent from './ProjectsHeaderComponent';
import { userSelector } from 'modules/auth/authSelectors';
import Spinner from 'components/Spinner';
import {error} from 'utils/alert';

@connect(whiteLabelSelector, { createProject, updatePageLoadingState, viewTemplateListByLanguage })
@connect(projectFoldersSelector, {})
@connect(userSelector, {})
class AddProjectPage extends React.Component {
  // Form data
  state = {
    addingProject: false,
    title: '',
    description: '',
    showAddProjectModal: false,
    project_group_id: 'noFolder',
    templateId: 0,
    languageId: 'noLanguage',
    showClub: true,
    legacy: 1,
    hideLegacyToggle: false,
    currentTab: 'evolution'
  };

  componentWillMount() {
    if (this.props.user.evolution) {
      this.setState({ legacy: 0 });
    }

    if (!this.props.user.evolution) {
      if (this.props.user.is_local) {
        this.setState({ currentTab: 'local' });
      } else {
        this.setState({ currentTab: 'generic' });
      }
    }
  }

  handleSubmit = async e => {
    e.preventDefault();

    if (!this.state.title.trim()) {
      error({text: 'The project name is required.'});
      return;
    }

    this.setState({ addingProject: true });

    this.props.createProject(
      {
        title: this.state.title,
        description: this.state.description,
        templateId: this.state.templateId,
        project_group_id: this.state.project_group_id,
        legacy: this.state.legacy
      },
      (project) => {
        // if project exists it resolved , if not it failed either way set the loading state to false
        this.setState({ addingProject: false });
        this.clearAndResetFields()
      }
    );
  };

  clearAndResetFields = () => {
    this.setState({
      templateId: false,
      showAddProjectModal: false,
      title: '',
      description: '',
      legacy: 1
    });
  };

  handleSelectTemplate = (templateId, legacy) => {
    // if the project is being created from template, hide legacy toggle and set
    const selectedTemplate = 0 !== templateId;
    const userIsEvolution = 1 === this.props.user.evolution;
    const hideLegacyToggle = selectedTemplate || !userIsEvolution;

    this.setState({
      templateId: templateId,
      legacy: legacy ? legacy : this.state.legacy,
      hideLegacyToggle,
      showAddProjectModal: true
    });
  };

  handleTemplateListByLanguage = (e, languageId) => {
    this.setState({ languageId }, () => {
      if (languageId === 'noLanguage') languageId = null;
      this.props.viewTemplateListByLanguage(languageId);
    });
  };

  get groupSelectOptions() {
    return recreateSelectOptions(
      this.props.projectGroups,
      { key: 'id', label: 'title' },
      { key: 'noFolder', value: 'No Folder' }
    );
  }

  renderAddProjectModal = () => {
    return (
      <Rodal visible={this.state.showAddProjectModal} onClose={this.clearAndResetFields} height={550}>
        <form onSubmit={this.handleSubmit}>
          <div className="modal-heading">
            <Icon name="plus" /> Create New Project
          </div>

          <div className="modal-body">
            {this.state.addingProject ? (
              <Spinner style={{marginTop: 40}}/>
            ) : (
              <React.Fragment>
                <div className="form-control" style={{ marginTop: '20px' }}>
                  <label>Enter a name for your project</label>
                  <input
                    placeholder="Your project name"
                    type="text"
                    autoFocus="true"
                    required
                    onChange={e => this.setState({ title: e.target.value })}
                  />
                </div>
                <div className="form-control">
                  <label>Add a Description</label>
                  <textarea
                    rows={3}
                    name="description"
                    placeholder="Add a description if you like"
                    onChange={e => this.setState({ description: e.target.value })}
                  />
                </div>
                <div className="form-control">
                  <Option
                    label="Add to Folder"
                    name="folder"
                    value={this.state.project_group_id}
                    Component={SelectInput}
                    options={this.groupSelectOptions}
                    onChange={(e, value) => {
                      this.setState({ project_group_id: value });
                    }}
                  />
                </div>
                {!this.state.hideLegacyToggle && (
                  <div className="form-control" style={{ marginTop: '25px' }}>
                    <Option
                      label="Legacy Project"
                      name="folder"
                      value={this.state.legacy}
                      Component={BooleanInput}
                      onChange={(e, value) => {
                        this.setState({ legacy: value });
                      }}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </div>

          <div className="modal-footer">
            <Button primary type="submit" icon="plus">
              Create
            </Button>
            <Button onClick={this.cancelCreate} icon="times">
              Cancel
            </Button>
          </div>
        </form>
      </Rodal>
    );
  };

  cancelCreate(e) {
    e.preventDefault();
    this.clearAndResetFields()
  }

  setStyles = tab => {
    const { whitelabel } = this.props;
    const inlineStyles = {};
    if (whitelabel && tab === this.state.currentTab) {
      inlineStyles.borderColor = whitelabel.primary_color;
    }
    return inlineStyles;
  };

  setActiveTab = tab => () => {
    this.setState({ currentTab: tab });
  };

  render() {
    setTimeout(() => {
      this.props.updatePageLoadingState(false);
    }, 1000);

    const { showClub, currentTab } = this.state;

    const localHeadlineStyle = cx(styles.heading, {
      [styles.active]: currentTab === 'local'
    });

    const genericHeadlineStyle = cx(styles.heading, {
      [styles.active]: currentTab === 'generic'
    });

    const evolutionHeadlineStyle = cx(styles.heading, {
      [styles.active]: currentTab === 'evolution'
    });

    const evoclubHeadlineStyle = cx(styles.heading, {
      [styles.active]: currentTab === 'evoclub'
    });

    return (
      <PageBody
        heading="Create New Project"
        customHeader={
          <ProjectsHeaderComponent
            meta={this.headerLeftSection(
              localHeadlineStyle,
              genericHeadlineStyle,
              evolutionHeadlineStyle,
              evoclubHeadlineStyle
            )}
          />
        }
      >
        <div className="grid" style={{ marginTop: '-150px' }}>
          {this.renderAddProjectModal()}

          {/*<div className="col2">*/}
          {/*    <Option*/}
          {/*        Component={BooleanInput}*/}
          {/*        onChange={(e, val) => {*/}
          {/*            this.setState({ showClub: val });*/}
          {/*        }}*/}
          {/*        label={'Show Club Templates'}*/}
          {/*        value={showClub}*/}
          {/*    />*/}
          {/*</div>*/}

          {/*{(currentTab === 'local') && (*/}
          {/*<div className="col2" style={{position: 'relative'}}>*/}
          {/*  <div className={styles.languageFilterWrapper}>*/}
          {/*    <Option*/}
          {/*      Component={SelectInput}*/}
          {/*      value={this.state.languageId}*/}
          {/*      options={this.props.languageOptions}*/}
          {/*      onChange={this.handleTemplateListByLanguage}*/}
          {/*      menuContainerStyle={{zIndex: 100}}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*)*/}
          <div className="col12">
            <TemplateList
              table="projects"
              showClubTemplates={showClub}
              showLocalTemplates={currentTab === 'local'}
              showEvolutionTemplates={currentTab === 'evolution'}
              showEvolutionClubTemplates={currentTab === 'evoclub'}
              onSelect={this.handleSelectTemplate}
            />
          </div>
        </div>
      </PageBody>
    );
  }

  headerLeftSection(localHeadlineStyle, genericHeadlineStyle, evolutionHeadlineStyle, evoclubHeadlineStyle) {
    const { evolution, is_local, evolution_pro } = this.props.user;

    return (
      <div className="grid" style={{ paddingTop: '15px' }}>
        <div className="col12">
          <Button primary large onClick={() => this.handleSelectTemplate(0)}>
            <Icon icon="plus" /> Create Blank Project
          </Button>
          <h1 className={styles.mainHeading} style={{ marginTop: '7px', fontSize: '26px', marginLeft: '10px' }}>
            Or Select A Template Below
          </h1>
          {/*<h3 style={{marginBottom: '-25px'}}>NOTE: We're currently reworking the templates in this section of the app so you may not see all the templates you previously had. This should be complete within 24 hours</h3>*/}
        </div>
        <ul className={styles.tabHeadings}>
          {evolution ? (
            <li
              className={evolutionHeadlineStyle}
              style={this.setStyles('evolution')}
              onClick={this.setActiveTab('evolution')}
            >
              Evolution Templates
            </li>
          ) : null}
          {evolution ? (
            <li
              className={evoclubHeadlineStyle}
              style={this.setStyles('evoclub')}
              onClick={this.setActiveTab('evoclub')}
            >
              Evolution Agency Club
            </li>
          ) : null}
          {is_local ? (
            <li className={localHeadlineStyle} style={this.setStyles('local')} onClick={this.setActiveTab('local')}>
              Interactr Local
            </li>
          ) : null}
          {!evolution ? (
            <li
              className={genericHeadlineStyle}
              style={this.setStyles('generic')}
              onClick={this.setActiveTab('generic')}
            >
              Legacy Templates
            </li>
          ) : null}
        </ul>
      </div>
    );
  }
}

export default withRouter(AddProjectPage);
