import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {SUBMIT_PASSWORD_RESET_REQUEST} from 'modules/auth/auth';
import history from 'browserHistory';
import {toastr} from 'react-redux-toastr';

export default function* submitPasswordResetInformationSaga() {
  yield takeLatest([SUBMIT_PASSWORD_RESET_REQUEST], submitPasswordResetInformation);
}

function* submitPasswordResetInformation({email, password, confirmPassword, token}) {
  const res = yield fetch('password/reset', {
    method: 'POST',
    body: {email, password, password_confirmation: confirmPassword, token}
  });

  if (!res.ok) {
    toastr.error('Password change failed.', 'Please try again. If the problem persists, please contact support.');
    return;
  }

  toastr.success('Password change success.');
  history.push('/login');
}
