import React from 'react';
import {connect} from 'react-redux';
import {SelectInput} from 'components/PropertyEditor';
import {nodesSelector} from 'modules/node/nodeSelectors';
// import {toJS} from 'utils/immutableUtils';

@connect(nodesSelector)
export default class SelectNode extends React.Component {
  render() {
    const {nodes, ...props} = this.props;
    if (!nodes) return null;
    const options = nodes.map(node => node.get('name')).toJS();
    return (
      <SelectInput {...props} options={{'': 'Select Node', ...options}} />
    );
  }
}
