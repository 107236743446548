import React, { Component } from 'react';
import styles from "./StockListModalStyles.scss";
import VideoPlayer from "components/VideoPlayer";
import Button from "components/Buttons/Button";
import LinkButton from "components/Buttons/LinkButton";
import config from 'config';

class StockItem extends Component {

    handleTagClick = tag => {
        const loading = true,
              filteredTag = tag.toLowerCase().trim();

        this.props.setFilterText(filteredTag, loading);
    };

    renderTags() {
        const {item} = this.props;
        const tags = item.tags.split(',');

        return tags.map(tag => (
            <span
                onClick={() => this.handleTagClick(tag)}
                key={Math.random() * item.id}
                className={styles.tag}
            >
              {tag}
            </span>
        ));
    }

    get getImageURL() {
        const { PXA: { IMAGES_ROOT, IMAGE_SIZE } } = config,
              { item: { picture_id } } = this.props;

        return IMAGES_ROOT + picture_id + '_' + IMAGE_SIZE + '.jpg';
    }

    get getVideoDuration() {
        let { duration } = this.props.item;

        if (duration < 10) {
            duration = '0' + duration;
        }

        return `00:${duration}`;
    }

    render() {
        const {item, user, showStockList, addStockItem} = this.props;

        if (! showStockList) return null;

        return (
            <div className={styles.listItem}>
                <div className={styles.listItemInner}>
                    <div className={styles.videoHolder}>
                        <VideoPlayer
                            url={item.videos.tiny.url}
                            videoId={item.id}
                            imageURL={this.getImageURL}
                            controls
                            playing
                            light={this.getImageURL || false} // stops preload and only shows thumbnail awesome for perf
                        />
                        <span className={styles.videoDuration}>{this.getVideoDuration}</span>
                    </div>
                    <div className={styles.textHolder}>
                        {this.renderTags()}
                        <div className={styles.addButton}>
                            {
                                (user.is_club || user.evolution_pro) ?
                                    <Button
                                        primary
                                        noMarginRight={true}
                                        right={true}
                                        small
                                        onClick={() => addStockItem(item.id, item.videos.tiny.url, this.getImageURL, item.tags)}
                                    >
                                        Select
                                    </Button> :
                                    <LinkButton
                                        primary
                                        noMarginRight={true}
                                        right="true"
                                        small
                                        to="/upgrade"
                                    >
                                        Upgrade
                                    </LinkButton>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StockItem;
