import React from 'react';
import {connect} from 'react-redux';
import {DraggableCore} from 'react-draggable';
import {applyZoom} from 'modules/composer/composerUtils';
import {findDragData, NODE} from 'modules/composer/dragging';
import {startPositionSelector} from 'modules/composer/composerSelectors';
import {changeStartNode} from 'modules/project/project';

@connect(startPositionSelector, {changeStartNode})
export default class StartPosition extends React.Component {
  constructor(props) {
    super(...arguments);
    this.state = {
      x: props.x,
      y: props.y
    };
  }

  static getDerivedStateFromProps({x, y}, state) {
    if(x !== state.x || y !== state.y) {
      return {x,y}
    } return null
  }

  handleDrag = (e, dragObj) => {
    const {deltaX, deltaY} = applyZoom(dragObj, this.props.zoom);

    this.setState(({x, y}) => {
      // offset from mouse so that e.target isn't this element on drop
      if (x === this.props.x && y === this.props.y) {
        x -= 30;
      }

      return {x: x + deltaX, y: y + deltaY};
    });
  };

  handleDragEnd = e => {
    const data = findDragData(e.target);

    const {changeStartNode, projectId, x, y} = this.props;

    if (!data || data.dragType !== NODE) {
      this.setState({x, y});
      return;
    }

    this.props.changeStartNode(projectId, data.id);
  };

  render() {
    const {x, y} = this.state;

    if (!this.props.projectId) return null;

    return (
      <DraggableCore onDrag={this.handleDrag} onStop={this.handleDragEnd}>
        <g data-disablecomposerdrag={true}>
          <circle fill="#00b382" stroke="#FFFFFF" strokeWidth="4" cx={x} cy={y} r="18" />
          <text x={x} y={y} textAnchor="middle" fill="#fff" stroke="#fff" dy=".3em">
           S
          </text>
        </g>
      </DraggableCore>
    );
  }
}
