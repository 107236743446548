import React from 'react';
import styles from './nodeControl.scss';
import Icon from 'components/Icon';
import {browserHistory} from 'react-router';

export default class NodeControl extends React.Component {
  /**
   * Render the HTML
   *
   * @returns {*}
   */
  render() {
    if (!this.shouldShow) return null;
    const {selected} = this.props;
    const inlineStyle = selected ? {opacity: '1', height: '30px'} : {opacity: '1', height: '0px'};

    return (
      <div className={styles.wrapper} style={inlineStyle}>
        <ul>
          {this.renderEditButton()}
          {this.renderCopyButton()}
          {this.renderDeleteButton()}
        </ul>
      </div>
    );
  }

  /**
   * renderEditButton()
   *
   * @returns {XML}
   */
  renderEditButton() {
    let clickAction = this.props.onEdit;

    return (
      <li>
        {this.renderButton(this.canEdit(), 'pen-square', clickAction)}
      </li>
    );
  }

  /**
   * renderCopyButton()
   *
   * @returns {XML}
   */
  renderCopyButton() {
    return <li>{this.renderButton(this.canCopy(), 'clone', this.handleCopy)}</li>;
  }

  // NOTE:
  // better to define action handlers as class methods as otherwise they get redefined multiple times
  // every time the render function is called
  handleDelete = () => {
    this.props.onDelete();
  };

  handleCopy = () => {
    this.props.onCopy();
  }

  /**
   * renderDeleteButton()
   *
   * @returns {XML}
   */
  renderDeleteButton() {
    // can delete is figured out by the parent which provides onDelete function.
    return <li>{this.renderButton(true, 'trash-alt', this.handleDelete)}</li>;
  }

  /**
   * Receive a bool that controls if the button
   * should be clickable or disabled.
   *
   * @param arg
   * @param icon
   * @returns {XML}
   */
  renderButton(enabled, icon, clickAction) {
    let classes = !enabled ? 'disabled' : '';

    return (
      <span className={classes} onClick={clickAction}>
        <Icon name={icon} />
      </span>
    );
  }

  /**
   * Is a video selected in the UI, path's
   * can't be copied
   *
   * @returns {boolean}
   */
  canCopy() {
    return true;
  }

  canEdit() {
    return true;
  }

  /**
   * Check if any of the button can be used (ie a node or path is highlighted).
   * No point in showing the buttons unless one of them can be used
   *
   * @returns {boolean}
   */
  shouldShow() {
    return this.props.selected;
  }
}
