import { createSelector } from 'reselect';
import { toJS } from 'utils/immutableUtils';
import { createRouteParamSelector } from 'utils/selectorUtils';
// import { elementSelectedStateSelector, getElementsAsJS } from 'modules/element/elementSelectors';
import { projectIdSelector } from 'modules/project/projectSelectors';
import { TRIGGER_ELEMENT } from 'shared/element';

export const modalStateSelector = state => state.modals;

const selectedInteraction = state => state.interactions.get('selected');

const elementsStateSelector = state => state.elements;

export const selectedModalSelector = createSelector(modalStateSelector, modalsState => {
  const selectedModal = toJS(modalsState.get('selected'));
  const selectedModalElement = toJS(modalsState.get('elementSelected'));
  if (selectedModal && selectedModalElement) {
    // Append a selectedElement property to the modal if found
    selectedModal.selectedElement = selectedModal.elements.find(
      e => e.element_id == selectedModalElement.id && e.element_type == selectedModalElement.element_type
    );
  }
  return { selectedModal };
});

export const projectModalsSelector = createSelector(projectIdSelector, modalStateSelector, (projectId, state) => {
  const modals = state.get('modals');
  return {
    modals: toJS(modals.toList().filter(modal => modal.get('project_id') == projectId))
  };
});

//@TODO: 👇 This mess is because of the interaction layer id on node not pointing to modal itself , fix this
// from BE so we can avoid these messes . Very Error prone

/** Gives you back an interaction layer Modal after you pass node as a prop to it's consuming component */
export const interactionLayerSelector = createSelector(
  (state, props) => props.node,
  state => state.interactions.get('data'),
  state => state.elements.get('data'),
  modalStateSelector,
  (node, interactionsState, elementsState, modalsState) => {
    if (node && node.interaction_layer_id) {
      const ILModalTriggerInteraction = toJS(interactionsState.get(node.interaction_layer_id.toString()));
      if (ILModalTriggerInteraction && ILModalTriggerInteraction.element_type == TRIGGER_ELEMENT) {
        const { element_id } = ILModalTriggerInteraction;
        const triggerEl = toJS(elementsState.getIn([TRIGGER_ELEMENT, element_id.toString()]));
        if (triggerEl && triggerEl.actionArg) {
          const ILModal = toJS(modalsState.getIn(['modals', triggerEl.actionArg.toString()]));
          if (ILModal && ILModal.interaction_layer) {
            return {
              interactionLayerModal: ILModal
            };
          }
        }
      }
    }
    return {};
  }
);
