import { takeLatest, put } from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import { REGENERATE_PROJECT_THUMBNAILS, updateProject } from 'modules/project/project';
import { success, error } from 'utils/alert';

export default function* regenerateProjectThumbnailsSaga() {
  yield takeLatest(REGENERATE_PROJECT_THUMBNAILS, regenerateProjectThumbnails);
}

function* regenerateProjectThumbnails({ projectId, callback }) {
  const res = yield fetch(`projects/regenerate/socialthumbs`, {
    method: 'POST',
    body: { projectId },
  });

  const { project, message, ...data } = yield res.json();

  if (data.success) yield put(updateProject(project));
  else error({ text: message });

  if (typeof callback === 'function') callback(data && data.success);
}
