import {createSelector} from 'reselect';
import {toJS} from "utils/immutableUtils";

const rawState = state => toJS(state);

export const whiteLabelSelector = createSelector(rawState, (state)=>{
  const loading = toJS(state.pageLoader);
  return {
    whitelabel: loading.whitelabel
  }
});

export const pageLoadingSelector = createSelector(rawState, (state)=>{
  const loading = toJS(state.pageLoader);
  return {
    pageLoading: loading.pageLoading,
    whitelabelPageLoader: loading.whitelabelPageLoader,
    whitelabel: loading.whitelabel
  }
});