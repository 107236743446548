import { put, takeLatest } from 'redux-saga/effects';
import { CREATE_VIDEO_VERSION } from 'modules/media/media';

import fetch from 'utils/saga/fetch';
import swal from 'sweetalert2';

export default function* createVideoVersion() {
  yield takeLatest(CREATE_VIDEO_VERSION, _createVideoVersion);
}

function* _createVideoVersion({ version, mediaId }) {
  const url = `media/${mediaId}/compression/${version}`;
  const res = yield fetch(url, { method: 'post' });

  if (res.status === 200) {
    swal({
      title: 'Success',
      text: `Video conversion started. You can continue to use the app whilst this runs in the background `,
      type: 'success'
    });
  }
}
