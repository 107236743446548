import React from 'react';
import PositionableElement from './PositionableElement';
import EditableText from 'components/EditableText';
import callsProp from 'decorators/callsProp';

export default class TextElement extends React.Component {
  static defaultProps = {
    positionable: true
  };

  state = {
    editing: false
  };



  handleClickOutside = e => {
    this.setState({editing: false});
  };

  handleChangeText = html => {
    this.props.onUpdate && this.props.onUpdate({html});
  };

  @callsProp('onStartEdit')
  handleStartEdit() {
    this.setState({editing: true});
  }

  @callsProp('onStopEdit')
  handleStopEdit() {
    this.setState({editing: false});
  }

  render() {
    const {html, projectFont, style, positionable,borderRadius, borderWidth, backgroundColour, borderType, borderColor, padding, paddingLeft, paddingRight, letterSpacing, ...props} = this.props;
    const {editing} = this.state;

    let styles = {
      ...style,
      cursor: editing ? 'auto' : 'pointer',
      width: positionable ? '100%' : props.width ,
      borderRadius,
      paddingLeft: (paddingLeft) ? paddingLeft : padding + '%',
      paddingRight: (paddingRight) ? paddingRight : padding + '%',
      letterSpacing: letterSpacing + 'px'
    };

    if(projectFont){
      styles.fontFamily = `${projectFont}`
    }

    if (borderWidth) {
      styles.borderWidth = borderWidth + "px";
      styles.borderStyle = borderType || 'solid';
    }

    if (backgroundColour) {
      styles.backgroundColor = backgroundColour;
    }

    if (borderColor) {
      styles.borderColor = borderColor;
    }

    if (positionable) {
      styles = {
        ...styles,
        position: 'absolute',
        height: positionable ? '100%' : props.height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      };
    }

    const contents = (
      <EditableText
        value={html}
        style={styles}
        className="reset_children_font"
        onStartEdit={this.handleStartEdit}
        onStopEdit={this.handleStopEdit}
        onChange={this.handleChangeText}
      />
    );

    if(props.disableResize) return contents

    return (
      <PositionableElement {...props} disabled={editing || !positionable}>
          {contents}
      </PositionableElement>
    );
  }
}
