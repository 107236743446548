import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

const ErrorMessage = ({ msg, icon, iconSize, iconStyle={}, iconColor, centered = true, style, className }) => {
  return (
    <div
      style={{ width: '100%', textAlign: centered ? 'center' : 'left', padding: 20, ...style }}
      className={className}
    >
      <Icon name={icon || "exclamation-triangle"} size={iconSize || '2x'} style={iconStyle} color={iconColor || '#ff6961'} />
      <p>{msg || 'Sorry, something went wrong'}</p>
    </div>
  );
};

ErrorMessage.propTypes = {
  /** Message that component will display */
  msg: PropTypes.string.isRequired,
  /** size of alert icon , accepts FA supported sizes */
  iconSize: PropTypes.string,
  /** Style object passed to Icon component */
  iconStyle: PropTypes.object
};

export default ErrorMessage;
