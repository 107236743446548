import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SelectInput } from 'components/PropertyEditor';
import { accountCustomListsPath } from 'routeBuilders';
import IconButton from 'components/Buttons/IconButton';
import { addCustomList } from 'modules/customLists/customList';
import Icon from "components/Icon";
import Rodal from "rodal";
import { Option, TextInput } from 'components/PropertyEditor/PropertyEditor';
import Button from 'components/Buttons/Button';

@connect(null, { addCustomList })
export default class AddCustomListModal extends Component {

    constructor() {
        super();

        this.state = {
            customList: '',
            showCreateCustomListModal: false,
        };
    }

    handleAddCustomListModal = () => {
        this.setState((state, props) => ({
            showCreateCustomListModal: !state.showCreateCustomListModal
        }))
    };

    addCustomList = () => {
        const { customList } = this.state;

        if (!customList.length) {
            return error({ text: 'Please fill the custom list name.' });
        }

        this.props.addCustomList({ customList }, customList => {
            this.setState({customList: '', showCreateCustomListModal: false});

            this.props.onSuccess(customList);
        });
    };

    renderAddCustomListModal() {
        return (
            <Rodal
                visible={this.state.showCreateCustomListModal}
                height={220}
                onClose={this.handleAddCustomListModal}
            >
                <div className="modal-heading">
                    <Icon name="plus" /> Add New Custom List
                </div>
                <div className="modal-body">
                    <div className="form-control">
                        <Option
                            label="Custom List Name"
                            value={this.state.customList}
                            Component={TextInput}
                            onChange={(e)=>this.setState({customList: e.target.value})}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <Button
                        primary
                        onClick={this.addCustomList}
                    >Create</Button>
                </div>
            </Rodal>
        )
    }

    render() {
        return (
            <div>
                <IconButton primary icon="plus" onClick={this.handleAddCustomListModal}>
                    Create
                </IconButton>

                {this.renderAddCustomListModal()}
            </div>
        )
    }
}