import fetch from 'utils/saga/fetch';
import {put} from 'redux-saga/effects';
import {receiveModal} from 'modules/modal/modal';
import {success, error} from 'utils/alert';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";

export default function* addModal(projectId) {
  yield put(updatePageLoadingState(true));

  const res = yield fetch('modals', {
    method: 'POST',
    body: {projectId}
  });

  if (res.status !== 200) {
    yield put(updatePageLoadingState(false));
    error({text:'Error creating modal'})
  }

  // TODO: needs to add modal to store.

  const modal = yield res.json();

  yield put(receiveModal(modal));

  yield put(updatePageLoadingState(false));

  return modal;
}
