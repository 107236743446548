import isUndefined from 'lodash/isUndefined';
import React from 'react';
import reduce from 'lodash/reduce';
import styles from './PropertyEditor.scss';
import ColorPicker from 'components/ColorPicker';
import ScalarInput from './ScalarInput';
import Icon from 'components/Icon';
import Toggle from 'react-toggle';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import BaseTimeInput from 'components/TimeInput';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { timeForSeconds, secondsForTime } from 'utils/timeUtils';
// import HelpText from 'components/HelpText';
import cx from 'classnames';
import { connect } from 'react-redux';
import { whiteLabelSelector } from 'modules/pageLoader/pageLoaderSelectors';
import { randomString } from 'utils/domUtils';

@connect(whiteLabelSelector, {})
export class Section extends React.Component {
  render() {
    const { title, children, icon, whitelabel, className, noHeading } = this.props;
    const classList = title && title.toLowerCase() === 'admin' ? styles.admin : className;
    const whitelabelStyles = whitelabel && whitelabel.primary_color ? { background: whitelabel.primary_color } : {};
    return (
      <section className={classList}>
        {
          (! noHeading) &&
          <div className={styles.header} style={whitelabelStyles}>
            {icon && <Icon name={icon} />}
            {title}
          </div>
        }
        <div className={styles.content}>{children}</div>
      </section>
    );
  }
}

export function Option({ label, Component, value, id, name, onChange, helpText, style, className, ...props }) {
  // make sure we have an id so label htmlFor works
  id = id || randomString(20);
  value = value || ''; // stop react warnings

  return (
    <div className={cx('form-option', className)} style={style}>
      {!!label && <label htmlFor={id}>
        {label} {!!helpText && <Icon name="question-circle"  data-tip={helpText}  />}
      </label>}
      <Component id={id} name={name || id} value={value} onChange={wrapOnChangeWithValue(onChange)} {...props} />
    </div>
  );
}

// export class Option extends React.Component{
//   render(){
//     let {label, Component, value, id, onChange, helpText} = this.props;
//     // make sure we have an id so label htmlFor works
//     id = id || label;
//     value = value || ''; // stop react warnings
//
//     return(
//       <div className="form-control">
//         <label htmlFor={id}>{label} <HelpText helpText={helpText} /></label>
//         <Component
//           id={id}
//           value={value}
//           onChange={wrapOnChangeWithValue(onChange)}
//           {...this.props}
//         />
//       </div>
//     )
//   }
// }

// If onChange signature isn't (e, val) makes 2nd argument
// e.target.value
const wrapOnChangeWithValue = onChange => (e, val, ...args) => {
  if (isUndefined(val) &&( e && e.target)) {
    const { checked, value, type } = e.target;
    val = type == 'checkbox' ? checked : value;
  }

  onChange(e, val, ...args);
};

export function PositionInput(props) {
  return <ScalarInput labels={['x', 'y']} {...props} />;
}

export function SizeInput(props) {
  return <ScalarInput labels={['Max Width (px)', 'Max Height (px)']} {...props} />;
}

export function TextInput(props) {
  return <input type="text" {...props} title={props.value} />;
}

export function IntegerInput(props) {
  const input = <input type="number" {...props} />;

  if (!props.help) return input;

  return (
    <div>
      {input}
      <small>{props.help}</small>
    </div>
  );
}

export function LargeTextInput({ value, ...props }) {
  return <textarea {...props} value={value || ''} />;
}

export const ColorInput = ColorPicker;

export function RangeInput({ value, onChange, min, max, step }) {
  min = min || 0;

  const changeHandler = e => {
    const val = typeof e.target === 'undefined' ? e : parseInt(e.target.value);

    // let value = parseInt(e.target.value);
    //value = Math.max(min, Math.min(val, max));

    // Needed to prevent errors with actions from other types
    if (!val && val !== 0) return null;
    onChange(e, val);
  };

  if(! step) step = 1;

  if (!value) {
    value = 0;
  }
  return (
    <div className="grid" style={{ margin: 0 }}>
      <div className="col9" style={{ paddingLeft: 0 }}>
        <Slider
          className={styles.slider}
          min={min}
          max={max}
          step={step}
          defaultValue={parseInt(value)}
          value={value}
          onChange={changeHandler}
        />
      </div>
      <div className="col3" style={{ paddingRight: 0 }}>
        <input type="text" value={isNaN(value) ? 0 : value.toString()} onChange={changeHandler} />
      </div>
    </div>
  );
}

export function MultiSelect({ name, value, options, onChange, multi }) {
  // If multiselect we get back an array
  const changeHandler = selected => {
    onChange(null, selected);
  };

  return (
    <Select name={name} value={value} options={options} onChange={changeHandler} clearable={false} multi={multi} />
  );
}

export function SelectInput({ name, value, onChange, options, returnValueOnly=true, className, ...props }) {
  // Uses the react select library
  // https://github.com/JedWatson/react-select
  const changeHandler = selected => {
    // @TODO: call onChange with entire selected object and fix everywhere , needing entire object is too common
    // the default behavior though should be that the formating already adds 'label' and 'value' to the selected object 
    onChange(null, (typeof selected == 'object' && selected.hasOwnProperty('value') ) ? selected.value : selected);
  };

  if (!Array.isArray(options)) {
    // Format the object to an array for the dropdown component
    options = reduce(
      options,
      (optionsAsArray, label, val) => {
        return [
          ...optionsAsArray,
          {
            label,
            value: val,
            clearableValue: false
          }
        ];
      },
      []
    );
  }

  // this only works in v2 of react-select we should get there some time
  // const customStyles = {
  //   option : (defaultStyles, {isDisabled}) => ({
  //     ...defaultStyles,
  //     cursor : isDisabled ? 'not-allowed' : 'pointer'

  //   })
  // }
  
  return (
    <Select
      name={name}
      value={value}
      options={options}
      onChange={changeHandler}
      clearable={!!props.clearable || false}
      className={className}
      // this will set high priority to dropdown menu visibility
      menuContainerStyle={{ zIndex: 500 }}
      isDisabled={props.disabled}
      // styles={customStyles}
      {...props}
    />
  );
}

export function BooleanInput({ value, onChange, id, name, ...rest }) {
  return <Toggle checked={!!value} onChange={onChange} name={name} {...rest} />;
}

export function TimeInput({ name, value, onChange, initMount }) {
  const changeHandler = timeString => {
    onChange(null, secondsForTime(timeString));
  };

  return (
    <BaseTimeInput
      name={name}
      onChange={changeHandler}
      value={timeForSeconds(value, initMount)}
    />
  );
}
