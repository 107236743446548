import { delay } from 'redux-saga';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { FETCH_DATA } from '../auth';
import { receiveProjects, receiveProjectGroups } from 'modules/project/project';
import { receiveUsers } from 'modules/user/user';
import { receiveAllNodes } from 'modules/node/node';
import { receiveMedia } from 'modules/media/media';
import { receiveCustomLists } from 'modules/customLists/customList';
import { receiveAgency } from 'modules/agency/agency';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { receiveLanguages } from 'modules/template/template';
import { receiveModals } from 'modules/modal/modal';
import { receiveElements } from 'modules/element/element';
import { processElements } from 'modules/element/elementUtils';
import fetch from 'utils/saga/fetch';
import { error } from 'utils/alert';

export default function* fetchData() {
  yield takeLatest([FETCH_DATA], _fetchData);
}

function* _fetchData({cb}) {
  yield put(updatePageLoadingState(true));
  const res = yield fetch('pageload', {timeout: 60000});

  if (res.ok) {
    const data = yield res.json();
    const { users, projects, projectGroups, nodes, media, customLists, agency, templateLanguages } = data;

    // Preprocessing for modals and elements from project fetching saga
    const _modals = projects.reduce((modals, project) => {
      return modals.concat(project.modals);
    }, []);

    const [modals, elements] = processElements(_modals);

    yield all([
      put(receiveProjects(projects)),
      put(receiveAllNodes(nodes)),
      put(receiveMedia(media)),
      put(receiveProjectGroups(projectGroups)),
      put(receiveModals(modals)),
      put(receiveElements(elements)),
      put(receiveUsers(users)),
      put(receiveCustomLists(customLists)),
      put(receiveAgency(agency)),
      put(receiveLanguages(templateLanguages)),
    ]);

    yield put(updatePageLoadingState(false));
    if (typeof cb === 'function') cb(true)
  } else {
		// this fetch util function returns a Response of type error when it's a network error like CORS misconfig or timing out
		// check https://developer.mozilla.org/en-US/docs/Web/API/Response/error
    error({ text: `We could not fetch your initial data! ${res.type == 'error' ? 'request timed out!' : ''} ` });
    yield put(updatePageLoadingState(false));
    if(typeof cb == 'function') cb(false);
  }
}
