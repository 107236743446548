import { takeEvery, put } from 'redux-saga/effects';
import { SELECT_INTERACTION } from 'modules/interaction';
import { setSelectedModal } from 'modules/modal';
import { TRIGGER_ELEMENT } from 'shared/element';
import { deselectElement} from "../../element";

export default function* selectModalSaga() {
  yield takeEvery(SELECT_INTERACTION, function*({ interaction }) {
    if(!interaction){
      // ensure we clear the selected modal element as this can mess up the UI
      yield put(deselectElement())
    }

    if (interaction && interaction.element_type === TRIGGER_ELEMENT) {
      const triggerElm = interaction.element;
      const modalId = triggerElm && triggerElm.actionArg;
      if (modalId) yield put(setSelectedModal(modalId));
    }
    // it's not a modal so deselect the modal
    else yield put(setSelectedModal(null));
  });
}
