import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {LOGIN, authError} from 'modules/auth/auth';
import handleAuthenticate from './utils/handleAuthenticate';
import {success, error} from 'utils/alert';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import React from "react";


export default function* loginSaga() {
  yield takeLatest(LOGIN, login);
}

function* login(action) {
  yield put(updatePageLoadingState(true));

  const res = yield fetch('authenticate', {
    method: 'POST',
    body: action
  });

  yield handleAuthenticate(res);
}
