import React from 'react';
import {connect} from 'react-redux';
import {SelectInput} from 'components/PropertyEditor/PropertyEditor';
import {configuredIntegrationsSelector} from 'modules/integration/integrationSelectors';
import {toJS} from 'utils/immutableUtils';
import {accountIntegrationsPath} from 'routeBuilders';
import {Link} from 'react-router';
import LinkButton from 'components/Buttons/LinkButton';

@connect(configuredIntegrationsSelector)
export default class SelectIntegration extends React.Component {
  render() {
    const {integrations, ...props} = this.props;
    if (!integrations.length) {
      return (
        <div>
          No integrations set up.{' '}
          <LinkButton to={accountIntegrationsPath()}>
            Integration Settings
          </LinkButton>
        </div>
      );
    }
    

    const options = integrations.reduce((memo, integration) => {
        return {...memo, [integration.key]: integration.name};
    }, {});
    return (
      <SelectInput
        {...props}
        options={options}
      />
    );
  }
}
