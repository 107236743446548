import React from 'react';
import { connect } from 'react-redux';
import PositionableElementProperties from './PositionableElementProperties';
import ClickableElementProperties from './ClickableElementProperties';
import StyleableElementProperties from './StyleableElementProperties';
import { getPrefixedProps, prefixProps } from 'shared/element';
import {
  Section,
  Option,
  SelectInput,
  BooleanInput,
  RangeInput,
  ColorInput,
  TextInput
} from 'components/PropertyEditor/PropertyEditor';
import SelectIntegration from 'modules/integration/components/SelectIntegration';
import SelectSubUserIntegration from 'modules/integration/components/SelectSubUserIntegration';
import SelectSubUser from 'modules/integration/components/SelectSubUser';
import SelectList from 'modules/integration/components/SelectList';
import {
  ChooseTemplateButton,
  TemplateProperties
} from 'modules/template/template';
import { applyElementTemplate } from 'modules/element/element';
import { accountDetailsSelector } from 'modules/account/account';
import { fetchCustomLists } from 'modules/customLists/customList';
import { customListsSelector } from 'modules/customLists/customListSelectors';
import SelectCustomList from 'modules/customLists/components/SelectCustomList';
import AddCustomListModal from 'modules/customLists/components/AddCustomListModal';

@connect(
  customListsSelector,
  { fetchCustomLists }
)
@connect(
  accountDetailsSelector,
  { applyElementTemplate }
)
export default class FormElementProperties extends React.Component {
  componentDidMount() {
    const { fetchCustomLists , customLists} = this.props;
    if(!customLists) fetchCustomLists();
  }

  changeHandler = name => (e, val) => {
    this.props.updateElement({ [name]: val });
  };

  changeCampaignListHandler = (e, val) => {
    if (typeof val === 'object' && val.label === 'Select List') return;

    this.changeHandler('integration_list')(e, val);
  }

  handleSubUpdate = prefix => props => {
    this.props.updateElement(prefixProps(props, prefix));
  };

  handleAddCustomList = customList => {
    this.props.updateElement({ 'custom_list_id': customList.id });
  };

  handleTemplate = templateId => {
    const {
      element: { element_type, id },
      applyElementTemplate
    } = this.props;
    applyElementTemplate(element_type, templateId, id);
  };

  listIntegrationsBySubUser = (e, id) => {
    this.props.updateElement({
        'integration': null,
        'integration_list': null,
        'sub_user': parseInt(id)
    });
  };

  componentDidUpdate() {
    const {
      element: { integration, integration_webhook },
      user: { integration_zapier: zapier },
      updateElement
    } = this.props;
    // Manually make the zapier integration webhook for this element the default one , which wouldn't happen until user
    // changes the field to trigger the changeHandler method .
    if (integration === 'integration_zapier' && integration_webhook === null) {
      updateElement({ integration_webhook: zapier.default_webhook });
    }
  }


  renderCustomListOptions = () => {
    const {element:{custom_list_id} , customLists, user} = this.props;

    return ((user.is_club || user.is_agency) ? <div className="grid">
      <div className="col10">
        <Option
          label="Custom List"
          value={custom_list_id}
          Component={SelectCustomList}
          customLists={customLists}
          onChange={this.changeHandler('custom_list_id')}
        />
      </div>
      <div className="col2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <AddCustomListModal onSuccess={this.handleAddCustomList} />
      </div>
    </div> : null )
  }

  renderAutoResponderOptions = () => {
    const { element: {integration, integration_webhook, integration_list, sub_user}, user: { is_agency } } = this.props;
    const isParentUsingSubUserAutoresponder = !!is_agency && sub_user ;

    return (
      <div>
        {!!is_agency && (
          <Option
            label="Select a User"
            value={sub_user.toString()}
            Component={SelectSubUser}
            onChange={this.listIntegrationsBySubUser}
          />
        )}

        <Option
          label="Autoresponder"
          style={{marginTop: '20px'}}
          value={integration}
          subUserId={sub_user} // gets passed down to selectSubUserIntegration if relevant
          Component={ isParentUsingSubUserAutoresponder ? SelectSubUserIntegration : SelectIntegration}
          onChange={this.changeHandler('integration')}
        />

        { integration &&
          (integration === 'integration_zapier' ? (
            <Option
              label="Zapier Webhook"
              style={{marginTop: '20px'}}
              value={integration_webhook}
              Component={TextInput}
              onChange={this.changeHandler('integration_webhook')}
              helpText="zapierWebhookProperty"
              subUserId={sub_user}
            />
          ) :
            <Option
              label="List/Campaign"
              style={{marginTop: '20px'}}
              value={integration_list}
              Component={SelectList}
              integrationType={integration}
              subUserId={isParentUsingSubUserAutoresponder ? sub_user : null }
              onChange={this.changeCampaignListHandler}
            />
          )
        }
      </div>
    )
  }

  render() {
    const {element, user} = this.props;

    const {
      data_handler,
      show_name_field,
      on_one_line,
      padding,
      borderRadius,
      backgroundColour,
      success_text,
      error_text,
      name_placeholder_text,
      email_placeholder_text,
    } = element;

    const canUseCustomLists = user.is_agency || user.is_club ;
    const useAutoresponders = data_handler === 'autoresponder' || !canUseCustomLists ;
    // data handler SelectInput options 
    const dataHandlerSelectOptions = {autoresponder: 'Autoresponders'}
    if(canUseCustomLists) dataHandlerSelectOptions.custom_list = 'Custom Lists';
    
    return (
      <div>
        <PositionableElementProperties {...this.props} />
        <ClickableElementProperties {...this.props} actionTitle="On submit" />
        <Section title="Form Behaviour" icon="envelope-open">
          <Option
            label="Email handler"
            value={data_handler}
            Component={SelectInput}
            options={dataHandlerSelectOptions}
            onChange={this.changeHandler('data_handler')}
          />
          {useAutoresponders ? 
            this.renderAutoResponderOptions() : 
            this.renderCustomListOptions()
          }
        </Section>
        <Section icon="paint-brush" title="Form Appearance">
          <div style={{ position: 'relative', zIndex: 100 }}>
            <Option
              label="Background Color"
              value={backgroundColour}
              Component={ColorInput}
              onChange={this.changeHandler('backgroundColour')}
            />
          </div>
          <Option
            label="Roundness (px)"
            value={borderRadius}
            Component={RangeInput}
            onChange={this.changeHandler('borderRadius')}
          />
          <Option
            label="Padding (px)"
            value={padding}
            Component={RangeInput}
            onChange={this.changeHandler('padding')}
          />
        </Section>
        <StyleableElementProperties
          title="Input Appearance"
          element={getPrefixedProps(element, 'input_')}
          updateElement={this.handleSubUpdate('input_')}
          stackOrder={2}
        />
        <StyleableElementProperties
          title="Button Appearance"
          element={getPrefixedProps(element, 'button_')}
          updateElement={this.handleSubUpdate('button_')}
          stackOrder={1}
          usePadding={true}
        />
        <Section title="Form Options" icon="sliders-h">
          <Option
            label="Show name field"
            value={show_name_field}
            Component={BooleanInput}
            onChange={this.changeHandler('show_name_field')}
            helpText="showNameField"
          />
          <Option
            label="Show form on one line"
            value={on_one_line}
            Component={BooleanInput}
            onChange={this.changeHandler('on_one_line')}
            helpText="formOnOneLine"
          />
          <Option
            label="Email field label"
            value={email_placeholder_text}
            Component={TextInput}
            onChange={this.changeHandler('email_placeholder_text')}
          />

          {show_name_field > 0 && (
            <Option
              label="Name field label"
              value={name_placeholder_text}
              Component={TextInput}
              onChange={this.changeHandler('name_placeholder_text')}
            />
          )}
          <Option
            label="Success Message"
            value={success_text}
            Component={TextInput}
            onChange={this.changeHandler('success_text')}
            helpText="successAutoResponderMessage"
          />
          <Option
            label="Error Message"
            value={error_text}
            Component={TextInput}
            onChange={this.changeHandler('error_text')}
            helpText="errorAutoResponderMessage"
          />
        </Section>
        {/*<TemplateProperties item={element} update={updateElement} />*/}
      </div>
    );
  }
}
