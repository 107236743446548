import React from 'react';
export default function Time({s}) {
  // TODO: support hours
  const m = Math.floor(s / 60);
  s = Math.floor(s % 60);

  return (
    <span>
      {m.toString().padStart(2, '0')}:{s.toString().padStart(2, '0')}
    </span>
  );
}
