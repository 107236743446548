import React from 'react';
import {connect} from 'react-redux';
import {SelectInput} from 'components/PropertyEditor/PropertyEditor';
import {usersSelector} from 'modules/user/userSelectors';
import LinkButton from 'components/Buttons/LinkButton';

@connect(usersSelector)
export default class SelectSubUser extends React.Component {
    render() {
        const {users, ...props} = this.props;
        if (!users.length) {
            return (
                <div>
                    No users created.{' '}
                    <LinkButton to={'/agency'}>
                        Agency Page
                    </LinkButton>
                </div>
            );
        }


        const options = users.reduce((memo, user) => {
            return {...memo, [user.id]: user.name};
        }, {0: 'My Integrations'});

        return (
            <SelectInput
                {...props}
                options={options}
            />
        );
    }
}
