import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  viewProjectSettingsPage,
  updateProject as _updateProject,
  saveProject,
  regenerateProjectThumbnails,
} from 'modules/project/project';
import { projectSelector } from 'modules/project/projectSelectors';
import PreviewPageUrl from '../PreviewPageUrl';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { BooleanInput, LargeTextInput, Option, TextInput } from 'components/PropertyEditor/PropertyEditor';
import Comment from 'components/Comment';
import Icon from 'components/Icon';
import ProjectPage from '../ProjectPage';
import Link from 'components/Link';
import Button from 'components/Buttons/Button';
import SharingOnPlayerTabs from './SharingOnPlayerTabs';
import styles from './SharingProjectPage.scss';
import SharingDataTabs from './SharingDataTabs';
import getAsset from '../../../../utils/getAsset';
import DropImageZone from '../../../media/components/DropImageZone';
import Rodal from 'rodal';
import Spinner from '../../../../components/Spinner';
import ErrorMessage from 'components/ErrorMessage';
// import { useParams } from 'react-router-dom';

export default function SharingProjectPage({ match }) {
  const [showReplaceThumbnailModal, setShowReplaceThumbnailModal] = useState(false);
  const [regeneratingThumbnails, setRegeneratingThumbnails] = useState(false);
  const [replacingProjectThumbnails, setReplacingProjectThumbnails] = useState(false);

  const { projectId } = match;

  const { project, projectGroups } = useSelector((state) => projectSelector(state, { projectId }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!project) {
      dispatch(updatePageLoadingState(true));
      dispatch(viewProjectSettingsPage(projectId));
    }
  }, [projectId, project]);

  const updateProject = (data, callback) => {
    dispatch(
      _updateProject(
        {
          id: project.id,
          ...data,
        },
        true,
        callback
      )
    );
  };

  const isPublished = project && !!project.published_at;

  const handleBoolChange = (val, key) => {
    const value = val ? 1 : 0;
    handleChange(value, key);
  };

  const handleChange = (val, key, callback = false) => {
    updateProject(
      {
        [key]: val,
        id: project.id,
      },
      callback
    );
  };

  const handleReplaceThumbnail = ({ src }) => {
    setShowReplaceThumbnailModal(false);
    setReplacingProjectThumbnails(true);
    handleChange(src, 'image_url', () => {
      setReplacingProjectThumbnails(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(saveProject(project));
  };

  const handleShareDataChange = (val, key) => {
    const value = typeof val === 'boolean' ? (val ? 1 : 0) : val;

    updateProject({
      share_data: { ...project.share_data, [key]: value },
      id: project.id,
    });
  };

  const handleRegenerateThumbnails = () => {
    setRegeneratingThumbnails(true);
    dispatch(
      regenerateProjectThumbnails(project.id, (thumbRegenerated) => {
        setRegeneratingThumbnails(false);
      })
    );
  };

  const closeReplaceModal = () => {
    setShowReplaceThumbnailModal(false);
  };

  const renderReplaceThumbnailModal = () => {
    return (
      <Rodal visible={showReplaceThumbnailModal} height={270} onClose={closeReplaceModal}>
        <div className="modal-heading">
          <Icon name="cloud-upload" /> Replace Thumbnail
        </div>

        <div className="modal-body">
          <DropImageZone onSuccess={handleReplaceThumbnail} directory="thumbnails" />
        </div>

        <div className="modal-footer">
          <Button primary onClick={closeReplaceModal}>
            Close
          </Button>
        </div>
      </Rodal>
    );
  };

  if (!project) {
    return null;
  }

  if (project) {
    //  Add a little delay here makes the UI nicer and the loade
    setTimeout(() => {
      dispatch(updatePageLoadingState(false));
    }, 1000);
  }

  const projectImageUrl = project.image_url || getAsset('/img/no-thumb.jpg');

  return (
    <ProjectPage
      header={'Project Sharing'}
      headerInfo={<Link>Watch the training</Link>}
      project={project}
      projectGroups={projectGroups}
      active="sharing"
    >
      <div className="grid" style={{ paddingLeft: '30px' }}>
        <div className="col6">
          <h3 className="form-heading" style={{ marginTop: 0 }}>
            Share Page
          </h3>
          <PreviewPageUrl project={project} updateUrl={(url) => handleShareDataChange(url, 'url')} />

          <h4 className="faded-heading" style={{ marginTop: '15px', marginBottom: '5px' }}>
            Other Videos
          </h4>
          <Option
            label="Show this video on other share pages"
            value={project.is_public}
            Component={BooleanInput}
            onChange={(e, val) => handleBoolChange(val, 'is_public')}
            helpText="When your project is inside a folder this video will only show on other projects share pages in the same folder."
          />

          <Option
            label="Show other videos on this share page"
            value={project.show_more_videos_on_share_page}
            Component={BooleanInput}
            onChange={(e, val) => handleBoolChange(val, 'show_more_videos_on_share_page')}
            helpText=" When your project is inside a folder only other projects in that folder will be shown on the share page."
          />

          <Option
            label="Allow Comments?"
            value={project.allow_comments}
            Component={BooleanInput}
            onChange={(e, val) => handleBoolChange(val, 'allow_comments')}
          />
          <div className="form-control">
            <h4 className="faded-heading" style={{ marginTop: '15px', marginBottom: '5px' }}>
              Likes
            </h4>
            <p style={{ marginTop: 0 }}>
              <Icon name="heart" /> {project.likes}
            </p>
          </div>

          <h4 className="faded-heading" style={{ marginTop: '15px', marginBottom: '5px' }}>
            Comments
          </h4>
          {project.comments && project.comments.length ? (
            project.comments.map((comment) => <Comment comment={comment} key={comment.id} />)
          ) : (
            <p>
              <em>You have no comments on your share page yet</em>
            </p>
          )}

          <h3 className="form-heading" style={{ marginTop: '45px' }}>
            Sharing on Player
          </h3>
          {project.legacy ? (
            <p>
              <em>Sharing on the player is unavailable for legacy projects.</em>
            </p>
          ) : (
            <SharingOnPlayerTabs project={project} updateProject={updateProject} handleBoolChange={handleBoolChange} />
          )}
        </div>

        <div className="col6">
          <h3 className="form-heading" style={{ marginTop: 0 }}>
            Sharing Data
          </h3>
          <Option
            label="Headline"
            Component={TextInput}
            value={project.title}
            onChange={(e, val) => handleChange(val, 'title')}
          />
          <Option
            label="Description"
            Component={LargeTextInput}
            rows={3}
            value={project.description}
            onChange={(e, val) => handleChange(val, 'description')}
          />

          <label>Thumbnail</label>
          {replacingProjectThumbnails ? (
            <div className={styles.loading}>
              <Spinner />
            </div>
          ) : (
            <img src={projectImageUrl} className={styles.thumbnail} />
          )}

          <div className="mt-1">
            <Button
              primary
              className={styles.replaceButton}
              onClick={() => setShowReplaceThumbnailModal(true)}
              icon="images"
            >
              Replace
            </Button>
            <Button
              disabled={!project.image_url}
              secondary
              className={styles.replaceButton}
              onClick={handleRegenerateThumbnails}
              loading={regeneratingThumbnails}
              icon="redo-alt"
            >
              Regenerate
            </Button>
          </div>

          <div className="mt-2">
            <label>Auto Generated Thumbnails</label>
            <p>
              The thumbnails below are automatically generated from the thumbnail above due to the fact these three
              platforms require the share images to be different sizes
            </p>
            <p>
              If you find the images don&apos;t match the main thumbnail then you can click &quot;regenerate&quot; to
              recreate the thumbnails.
            </p>
          </div>

          <div className="pb-2" style={{ minHeight: 400 }}>
            <SharingDataTabs
              projectThumbnail={project.image_url}
              facebookImageUrl={project.facebook_image_url}
              twitterImageUrl={project.twitter_image_url}
              googleImageUrl={project.google_image_url}
              loading={regeneratingThumbnails || replacingProjectThumbnails}
            />
          </div>

          {renderReplaceThumbnailModal()}

          {/*<div>*/}
          {/*    <Button primary large icon="save" loading={props.saving ? props.saving.saving : false} onClick={handleSubmit}>*/}
          {/*        Save Changes*/}
          {/*    </Button>*/}
          {/*</div>*/}
        </div>
      </div>
    </ProjectPage>
  );
}
