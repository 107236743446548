import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { publishButton } from 'modules/project/projectSelectors';
import { publishProject, unpublishProject } from 'modules/project/project';
import Button from 'components/Buttons/Button';
// import buttonStyles from 'components/Buttons/Button.scss';
import Icon from 'components/Icon';
// import IconButton from 'components/Buttons/IconButton';

// @connect(publishButton, { publishProject, unpublishProject })

PublishButton.defaultProps = {
  unpublishOption: false,
};

export default function PublishButton({
  projectId,
  isPublished,
  unpublishOption,
  text,
  size,
  color,
  children,
}) {
  const { publishing, unpublishing } = useSelector(publishButton);
  const dispatch = useDispatch();

  const shouldShowUnpublish = isPublished && unpublishOption;

  const handlePublish = (e) => {
    dispatch(publishProject(projectId));
  };

  const handleUnpublish = (e) => {
    dispatch(unpublishProject(projectId));
  };

  return (
    <div>
      {shouldShowUnpublish ? (
        <Button icon="cloud-download" red onClick={handleUnpublish} loading={unpublishing} right>
          Unpublish
        </Button>
      ) : (
        <Button primary icon="cloud-upload" onClick={handlePublish} loading={publishing} right>
          {children || text}
        </Button>
      )}
    </div>
  );
}
