import React from 'react';
import { connect } from 'react-redux';
import { SelectInput } from 'components/PropertyEditor';
import { viewSelectList } from 'modules/integration/integration';
import { listsSelector, SUB_USER_INTEGRATIONS } from 'modules/integration/integrationSelectors';
import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';

const _props = {
  /** sub user id passed down from parent component if it's an agency accessing sub users integrations */
  subUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  /** the integration type */
  integrationType: PropTypes.oneOf(SUB_USER_INTEGRATIONS.map(i => i.key)).isRequired
};

@connect(
  listsSelector,
  { viewSelectList }
)
class SelectList extends React.Component {
  state = {
    subUserLists : []
  }

  componentWillMount() {
    this._fetchIntegrationLists()
  }

  // @todo adjust this integration owner as well
  componentWillReceiveProps({ subUserId, integrationType }) {
    if (integrationType !== this.props.integrationType || subUserId !== this.props.subUserId) {
      this._fetchIntegrationLists()
    }
  }

  /** Annoying hack to make aWebber and mailchimp work as the aWebber lists come back as lists.lists[] instead of lists[] */
  normaliseLists = lists =>  (lists && lists.lists) ? lists.lists : lists
  
  _fetchIntegrationLists = () => {
    const { subUserId, integrationType, viewSelectList } = this.props;
    // fetch lists for integration for sub user and handle them on the component level
    // hack to not let sub user lists override parent user lists
    if(subUserId) viewSelectList(integrationType, subUserId, this._subUserListsHandler);
    else viewSelectList(integrationType) // this will fetch 
  }

  _subUserListsHandler = lists => {
    lists = this.normaliseLists(lists)
    // console.log(' from sub user lists handler : \n', lists);
    this.setState({ subUserLists: lists });
  };

  render() {
    let { lists, loading, subUserId, ...props } = this.props;
    const { subUserLists } = this.state;
    // use sub lists if available 
    if(subUserId && subUserLists.length) lists = subUserLists

    if (loading) return <Spinner />;

    lists = this.normaliseLists(lists)

    if (!lists || !lists.length) {
      return <div>No lists</div>;
    }

    // the backend could return normalized data but hey it's faster to just fix it here
    const options = lists.reduce((memo, { list_id, campaignId, id, list_name, name }) => {
      return { ...memo, [list_id || id || campaignId]: list_name || name };
    }, {});

    return <SelectInput {...props} options={{ '': 'Select List', ...options }} />;
  }

}

SelectList.propTypes = _props;
export default SelectList
