import React from 'react';
import { toastr } from 'react-redux-toastr';
import updatesLog from 'shared/updatesLog';
import Button from 'components/Buttons/Button';

/* Used flashing a global toast that only closes on click and never again , uses localStorage
 * @param {String} title
 * @param {String} msgLabel - the key in the updatesLog
 * @param {String} messageComponent
 * @param {type} messageComponent
 *
 *
 */
function showUpdateMessage(title, msgLabel, type = 'info') {
  if (typeof msgLabel != 'string' || typeof title != 'string') {
    // console.error('showUpdateMessage method needs a "msgLabel" argument and it must be a string.');
    throw Error('Global message util requires a title and a msgLabel to be strings');
  }
  // if (messageComponent === undefined) {
  //   throw Error('messageComponent must be valid react element');
  // }

  const update = updatesLog[msgLabel];
  if (typeof update != 'object' || update.key === undefined) {
    throw Error(
      'Error, You should add an new entry in the log in shared/updatesLog.js and give it a key, title and text properties'
    );
  }
  // return if already dismissed the update
  if (localStorage.getItem(update.key)) return;

  // ⚡️ Don't forget to update the message itself 👇
  const message = (
    <>
      <p>{update.text}</p>
      <Button right onClick={() => localStorage.setItem(update.key, true)}>
        Got it
      </Button>
    </>
  );

  const toastrOptions = {
    timeOut: 0, // by setting to 0 it will prevent the auto close
    // icon: (<myCustomIconOrAvatar />), // You can add any component you want but note that the width and height are 70px ;)
    position: 'top-center',
    // onShowComplete: () => console.log('SHOW: animation is done'),
    // onHideComplete:  () => localStorage.setItem(update, true),
    // onCloseButtonClick: () => console.log('Close button was clicked'),
    // onToastrClick: () => console.log('Toastr was clicked'),
    showCloseButton: false, // false by default
    closeOnToastrClick: true, // false by default, this will close the toastr when user clicks on it
    removeOnHover: false,
    className: 'update-message-toast',
    component: message,
    status: type,
    icon: type,
  };

  toastr.light(title, toastrOptions);
}

export default showUpdateMessage;
