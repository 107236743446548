import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';

/* ColorPicker component requires a `name` prop to work */
function ColorPicker({ name, value, onChange , ...props}) {
  const [shown, setShown] = useState(false);

  const show = () => {
    setShown(true);
  };
  // made like this to access it for HOC plugin in ,
  // using like static property causes issues when component used multiple times
  // hence making unique handler for each one 
  ColorPicker['handleClickOutside_' + name] = () => setShown(false);

  const handleInputChange = (e) => {
    onChange(e);
  };

  const handleColorChange = (data, e) => {
    // data.rgb contains a channel - Color requires it to be called
    // alpha for some backwards reason.
    const { r, g, b, a } = data.rgb;

    onChange(e, makeRgbaString(r, g, b, a));
  };

  function makeRgbaString(r, g, b, a) {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }


  return (
    <div style={{ position: 'relative', width: '100%' }} className="clearfix">
      <div style={{ float: 'left', width: '80%' }}>
        <input value={value} onFocus={show} onChange={handleInputChange} />
      </div>
      <div style={{ float: 'left', width: '15%', marginLeft: '5%', marginTop: '0', height: '42px' }}>
        {value && (
          <div
            onClick={show}
            style={{
              background: value,
              height: '100%',
              width: '100%',
              borderRadius: '10px',
              boxShadow: '0 5px 15px -5px rgba(0, 0, 0, 0.4)',
              cursor: 'pointer',
            }}
          >
            &nbsp;
          </div>
        )}
      </div>

      {shown ? (
        <div style={{ position: 'absolute', zIndex: 10 }}>
          <SketchPicker color={value} onChange={handleColorChange}  />
        </div>
      ) : null }
    </div>
  );
}
const clickOutsideConfig = {
  // check https://github.com/Pomax/react-onclickoutside/issues/329 to know more
  // about the multiple components issue
  handleClickOutside: ({props}) => ColorPicker['handleClickOutside_' + props.name],
};

ColorPicker.propTypes = {
  name : PropTypes.string.isRequired,
  value:  PropTypes.string,
}
export default onClickOutside(ColorPicker, clickOutsideConfig);
