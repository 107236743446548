import React from 'react';
import Icon from 'components/Icon';
import Rodal from 'rodal';
import Button from 'components/Buttons/Button';

export default class RenameNodeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {nodeName: props.nodeName};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.nodeName !== this.state.nodeName) {
      this.setState({nodeName: nextProps.nodeName});
    }
  }

  render(){
    const {editNodeName, updateNodeName, closeModal}= this.props;
    const {nodeName} = this.state;

    return(
      <Rodal
        visible={editNodeName}
        onClose={()=>closeModal()}
        height={200}
      >
        <div className="modal-heading">
          <Icon name="pen-square" /> Rename Node
        </div>
        <div className="modal-body">
          <div className="form-control">
            <input type="text" value={nodeName} onChange={(e)=>this.setState({nodeName: e.target.value})} />
          </div>
        </div>
        <div className="modal-footer">
          <Button primary onClick={()=>updateNodeName(nodeName)}>
            Done
          </Button>
        </div>
      </Rodal>
    )
  }
}