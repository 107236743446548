import React from 'react';
import { connect } from 'react-redux';
import Button from 'components/Buttons/Button';
import LinkButton from 'components/Buttons/LinkButton'
import { login } from 'modules/auth/auth';
import { loginPageSelector } from 'modules/auth/authSelectors';
import NotAuthenticated from 'decorators/NotAuthenticated';
import styles from './loginForm.scss';
// import { supportLink } from 'utils/externalLinks';
// import { error } from 'utils/alert';
// import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import AuthPage from './AuthPage';
import PropTypes from 'prop-types';
import cx from "classnames";

const proptypes = {
  whitelabel: PropTypes.any,
  /** error msg if any */
  error: PropTypes.string,
  login: PropTypes.func.isRequired
};

@NotAuthenticated
@connect(
  loginPageSelector,
  { login }
)
export default class LoginPage extends React.Component {
  state = {
    email: '',
    password: '',
    passwordType: 'password'
  };

  handleSubmit = e => {
    e.preventDefault();

    const { email, password } = this.state;
    this.props.login(email, password);
  };

  handleChange = e => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  get tip() {
    const { whitelabel } = this.props;
    return !whitelabel ? (
      <div style={{ marginBottom: '30px' }}>
        <h4 style={{ marginBottom: '0px' }}>New User?</h4>
        <p>
          Check your emails for an email from chris@videosuite.io with your password. You can also use the reset
          password functionality below. If you have any issues you can contact our support team by email on{' '}
          <strong>support@videosuite.io</strong>
        </p>
      </div>
    ) : null;
  }

  setPasswordType = passwordType => () => {
    this.setState({passwordType});
  }

  render() {
    const { error, whitelabel } = this.props;
    const { passwordType } = this.state;

    const whitelabelStyle = whitelabel
      ? {
          borderTopColor: whitelabel.primary_color
        }
      : {};

    if (whitelabel) {
      // Prevents notifications showing when it's a whitelabel account
      window.localStorage.removeItem('previousPage');
    }
    if (__DEV__) console.log('whitelabel ? ', whitelabel);

    return (
      <AuthPage heading="Welcome Back" tip={this.tip} whitelabel={whitelabel}>
        <form onChange={this.handleChange} onSubmit={this.handleSubmit} className={styles.form} style={whitelabelStyle}>
          <div className="form-control ">
            <label>Email Address</label>
            <input type="email" name="email" className="rounded" placeholder="Email" />
          </div>
          <div className={cx(styles.passwordField, "form-control")}>
            <label>Password</label>
            <input type={passwordType} name="password" className="rounded" placeholder="Password" />
            {passwordType === 'password' && (
                <span onClick={this.setPasswordType('text')}>Show</span>
            )}
            {passwordType === 'text' && (
                <span onClick={this.setPasswordType('password')}>Hide</span>
            )}
          </div>
          {!!error && (
            <div className={styles.error}>
              <p>{error}</p>
            </div>
          )}
          <div className={styles.footer}>
            {!whitelabel ? (
              <a href={'/forgotten-password'} className={styles.forgotPasswordButton}>
                Reset Password?
              </a>
            ) : null}
            <Button
              type="submit"
              large
              primary
              rightIcon
              icon={'sign-in-alt'}
              noMarginRight
            >
              Login
            </Button>
          </div>
        </form>
        {!whitelabel && (
            <div className=" text-center" style={{background: 'white',borderRadius: '25px', marginTop: '40px', padding: '20px', paddingBottom: '60px'}}>
              <img src="https://static.jvzoo.com/images/jvzoo_logo_plain_150.png" style={{maxWidth: '150px', margin:'0 auto'}}/>
              <h3 style={{color: '#435b71'}}>Purchased via JVZOO?</h3>
              <p style={{color: '#435b71'}}>Click the button below to create your account. You will need your <strong>JVZOO transaction ID</strong> for
                this.</p>
              <LinkButton primary to={'/register/jvzoo'} style={{marginLeft: '80px'}}><i className="fas fa-user-plus"></i> Create Your Account</LinkButton>
            </div>
        )}
      </AuthPage>
    );
  }
}

LoginPage.propTypes = proptypes;
