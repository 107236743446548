import React, { useState } from 'react';
import Button from './Buttons/Button';
import { phpApi } from 'utils/saga/apis';
import { error, success } from 'utils/alert';

async function getNode(id) {
  try {
    return await (await phpApi(`nodes/${id}`)).json();
  } catch (e) {
    console.error(e);
    error({ text: e.data ? e.data.userMessage : e.statusText });
  }
}
async function updateMedia(data) {
  try {
    await phpApi(`media/${data.id}`, { method: 'put', body: { item: data} });
    success({ text: 'Successfully updated!' });
  } catch (e) {
    console.error(e);
    error({ text: e.statusText });
  }
}
export default function AdminNodeEditorComponent() {
  const [node, setNode] = useState(null);
  const [search, setSearch] = useState('');
  const [newManifestUrl, setNewManifestUrl] = useState('');

  async function searchForNode() {
    if(!search) return
    if (isNaN(+search)) {
      error({ text: 'Invalid ID' });
      return;
    }
    const node = await getNode(search);

    setNode(node)
    // for default value without needing to track editing state
    setNewManifestUrl(node.media.manifest_url) 
  }
  async function updateManifestUrl(){
    if(!newManifestUrl.trim() ) {return}
    if(newManifestUrl.trim().search(/^http(s)*:\/\//g) != 0){
      error({text : 'Invalid URL'})
      return
    } 
    await updateMedia({id: node.media.id, manifest_url: newManifestUrl})
  }
  return (
    <div className="mt-2">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="col8">
          <div className="form-control">
            <label>Node</label>
            <input
              type="text"
              required
              placeholder="Node ID"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>

          <div className="form-control" style={{ marginBottom: 30 }}>
            <Button icon="search" primary onClick={searchForNode}>
              Search
            </Button>
          </div>
        </div>
      </form>

      {!!node && (
        <div className="col8">
          <form onSubmit={e=> e.preventDefault()}>

          <div className="form-control">
            <label>Media Manifest Url</label>
            <input
              type="text"
              required
              placeholder="Manifest_url"
              value={newManifestUrl}
              onChange={(e) => {
                setNewManifestUrl(e.target.value);
              }}
              />
          </div>

          <div className="form-control" style={{ marginBottom: 30 }}>
            <Button icon="save" primary onClick={updateManifestUrl}>
              Save
            </Button>
          </div>
              </form>
        </div>
      )}
    </div>
  );
}
