import React from 'react';
import styles from "./StockListModalStyles.scss";
import {Link} from "react-router-dom";
import Rodal from 'components/Rodal';
import Spinner from 'components/Spinner';
import Button from "components/Buttons/Button";
import Icon from 'components/Icon';
import {connect} from "react-redux";
import {userSelector} from "modules/auth/authSelectors";
import { mediaLibrarySelector } from 'modules/media/mediaSelectors';
import FilterInput from 'components/FilterInput';
import isEqual from "lodash/isEqual";
import MediaLibraryVideoItem from "./MediaLibraryVideoItem";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";

// TODO Refactor and go functional with hooks

const LEGACY_MEDIA_STRING = 'https://swiftcdn6.global.ssl.fastly.net';

@connect(mediaLibrarySelector, {})
@connect(userSelector, {}) // userselector has whitelabel as well , helps when refactoring
export default class MediaLibraryModal extends React.Component {
    constructor(props) {
        super(props);

        const filteredVideos = this.filterVideosByLegacyProject(props.media);

        this.state = {
            // searchTerm: '',
            filteredVideos,
            emptyVideosData: isEmpty(filteredVideos),
            filtering: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if ( !isEqual(nextProps.media, this.state.filteredVideos)) {
            const filteredVideos = this.filterVideosByLegacyProject(nextProps.media);

            this.setState({filteredVideos, emptyVideosData: isEmpty(filteredVideos)});
        }
    }

    filterVideosByLegacyProject = videos => {
        const { isLegacyProject } = this.props;
        let videosData = [];

        if (isLegacyProject) {
            videosData = videos.filter(video => video.url.includes(LEGACY_MEDIA_STRING));
        }

        if (!isLegacyProject) {
            videosData = videos.filter(video => video.manifest_url !== '' && video.manifest_url !== null);
        }

        return videosData;
    };

    setFilterText = searchTerm => {
        this.setState({ searchTerm });
    };

    addMediaItem = (mp4Link, thumbnail_url, name) => {
        this.props.close();
        this.props.addMediaUrl(mp4Link, thumbnail_url, name);
    };

    handleFilter = ({ filteredData, filtering }) => {
        const filteredVideos = this.filterVideosByLegacyProject(filteredData);
        this.setState({ filteredVideos, filtering, emptyVideosData: isEmpty(filteredVideos) });
    };

    renderVideosList = () => {
        const {filteredVideos} = this.state;

        return filteredVideos.map(video => (
            <MediaLibraryVideoItem
                item={video}
                key={'media_library_video_' + video.id}
                showMediaLibrary={this.props.showMediaLibrary}
                addMediaItem={this.addMediaItem}
                user={this.props.user}
            />
        ));
    };

    render() {
        const { loading, emptyVideosData } = this.state;
        const { close, showMediaLibrary, user, isLegacyProject , whitelabel } = this.props;

        // includes whitelabel being null in that condition which we want to treat as whitelabel too 
        const isAgencySubUser = typeof whitelabel === 'object' && !!user.parent_user_id ;  

        return (
            <Rodal
                visible={showMediaLibrary}
                onClose={close}
                height={emptyVideosData ? 200 : 800}
                width={1000}
            >
                <div>
                    <div className="modal-heading">
                        <Icon name="list" /> Video Library
                    </div>

                    <div className="modal-body">
                        {!isAgencySubUser && !isLegacyProject && (
                            <div style={{textAlign: 'center', display:'block', marginTop:'5px', marginBottom: '5px', width: '100%', backgroundColor: '#f3f3f3', paddingTop: '5px', paddingBottom: '5px'}}>
                                <p>Only videos uploaded to evolution projects will be shown here</p>
                            </div>
                        )}
                        {!isAgencySubUser && user.evolution && isLegacyProject ? (
                            <div style={{textAlign: 'center', display:'block', marginTop:'5px', marginBottom: '5px', width: '100%', backgroundColor: '#f3f3f3', paddingTop: '5px', paddingBottom: '5px'}}>
                                <p>Only videos uploaded to non-evolution projects will be shown here</p>
                            </div>
                        ) : null}

                        {emptyVideosData ? (
                            <h3 className="text-center">
                                No available media found in library
                            </h3>
                        ) : (
                            <div className={styles.fromLibraryModalWrapper}>
                                <div className={styles.filterWrapper}>
                                    <div className={styles.filterLeftWrapper}>
                                        <FilterInput
                                            data={this.props.media}
                                            filterKey="name"
                                            onFilter={this.handleFilter}
                                            placeholder="Search"
                                        />
                                    </div>
                                </div>
                                <div>
                                    {loading ?
                                        (<div className={styles.spinnerWrapper}>
                                            <Spinner/>
                                        </div>)
                                        :
                                        <div>
                                            <div className={styles.mediaLibraryListWrapper}>
                                                {this.renderVideosList()}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={cx(styles.fromLibraryModalFooter, 'modal-footer')}>
                        <Button secondary onClick={close} className={styles.fromLibraryModalCloseButton}>
                            Back
                        </Button>
                    </div>
                </div>
            </Rodal>
        );
    }
}