// TODO: this code is repeated in client and player
// get all interactions that should be shown at the current played time
export function getShownInteractions(interactions, elementGroups, played) {
  // console.log('interactions passed to showing them util function ', {interactions, elementGroups})
  return interactions.reduce((interactionsToShow, interaction) => {
    let { timeIn, timeOut, element_group_id: elementGroupId } = interaction;
    let elementGroup = elementGroups[elementGroupId];

    // If the interaction exists in element group.
    if (elementGroup) {
      timeIn = elementGroup.timeIn;
      timeOut = elementGroup.timeOut;
    }

    // set little duration for timeIn.
    const updatedPlayed = played + 0.1;

    if ((parseFloat(timeIn)) <= updatedPlayed && (parseFloat(timeOut) + 0.1) >= played) {
      interactionsToShow.push(interaction);
    }

    return interactionsToShow;
  }, []);
}

export function shouldShowInteraction(interaction, played) {
  const {timeIn, timeOut} = interaction;
  const passedTimeIn = parseFloat(timeIn) <= played + 0.1
  let result = passedTimeIn
  if(timeOut) {
    const beforeTimeOut =  parseFloat(timeOut) + 0.1 >= played  
    result = passedTimeIn && beforeTimeOut
  }
  return result
}