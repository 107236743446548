import React from 'react';
import InteractiveSvg from './InteractiveSvg';
import Node from './Node';
import {connect} from 'react-redux';
import {
  findDragData
} from 'modules/composer/dragging';
import {
  beginDrag,
  drag,
  endDrag,
  zoom,
  COMPOSER_DOM_ID
} from 'modules/composer/composer';
import {applyZoom} from 'modules/composer/composerUtils';
import {composerSelector} from 'modules/composer/composerSelectors';
import Connectors from './Connectors';
import StartPosition from './StartPosition';
import {nodeAndParents} from 'utils/domUtils';
import Legend from './Legend';
import ZoomControl from './ZoomControl';
import {updateNode} from 'modules/node/node';
import RenameNodeModal from 'modules/node/components/RenameNodeModal';

@connect(composerSelector, {beginDrag, drag, endDrag, changeZoom: zoom, updateNode})
class Composer extends React.Component {
  state = {
    editNodeName: false,
    nodeName: '',
    nodeId: 0
  };

  isDragDisabled({target}) {
    return !!nodeAndParents(target).find(
      node => node.dataset.disablecomposerdrag
    );
  }

  // apply zoom to drag values as mouse px !== zoomed svg px
  applyZoom(dragObj) {
    const {zoom} = this.props;
    return applyZoom(dragObj, zoom);
  }

  handleDragStart = e => {
    const {target} = e;
    // If the thing being dragged has a data.disablecomposerdrag attr
    // disable dragging (it likely takes care of it itself)

    if (this.isDragDisabled(e)) return false;

    const {dragType, ...data} = findDragData(target) || {};
    this.props.beginDrag(dragType, target, data);
  };

  handleDragMove = (e, data) => {
    if (this.isDragDisabled(e)) return false;
    delete data.node;
    this.props.drag(this.applyZoom(data));
  };

  handleDragEnd = e => {
    this.props.endDrag(e.target);
  };

  handleWheel = e => {
    const delta = Math.max(-1, Math.min(1, e.deltaY / 1000));
    this.props.changeZoom(delta);
  };


  editNodeName = (name, id) => {
    this.setState({editNodeName: true, nodeName: name,nodeId: id});
  };

  renderNodes() {
    // the nodes passed are Immutable Maps , not arrays
    return this.props.nodes
      .map((node, id) => <Node id={id} key={id} editNodeName={this.editNodeName} />)
      .toList()
      .toJS();
  }

  renderConnectors() {
    // the nodes passed are Immutable Maps , not arrays
    return this.props.nodes
      .map((node, id) => <Connectors key={id} nodeId={id} />)
      .toList()
      .toJS();
  }

  renderContent() {
    if (!this.props.nodes.size) {
      return (
        <text
          x="40%"
          y="50%"
          textAnchor="middle"
          stroke="#002033"
          fontSize={20}
        >
          Drag Media From Your Media Library To Get Started
        </text>
      );
    }

    return (
      <g>
        {/* for some reason, everything renders providing we have this circle */}
        <circle r="50" fill="none" />
        {this.renderConnectors()}
        {this.renderNodes()}
        <StartPosition />
      </g>
    );
  }

  renderDefs() {
    {
      /* The right arrow we define here so we can use later with <use> */
    }
    return (
      <defs>
        <polygon
          id="connector-arrow"
          fill="#00b382"
          points={this.scaleThePolygon(10)}
        />
      </defs>
    );
  }

  scaleThePolygon(scaleBy) {
    let val1 = 159.33 / scaleBy;
    let val2 = 85.33 / scaleBy;
    let val3 = 128 / scaleBy;
    let val4 = 11.33 / scaleBy;
    let val5 = 170.66 / scaleBy;
    let val6 = 60 / scaleBy;
    let val7 = 85.33 / scaleBy;
    let val8 = 11.33 / scaleBy;
    let val9 = '0';
    let val10 = 85.33 / scaleBy;
    let val11 = 42.66 / scaleBy;

    return `${val1},${val2} ${val2},${val3} ${val4},${val5} ${val6},${val7} ${
      val8
    },${val9} ${val10},${val11}`;
  }

  updateNodeName = (value)=>{
    this.props.updateNode(this.state.nodeId, {name: value});
    this.closeModal();
  };

  closeModal = ()=> {
    this.setState({editNodeName: false});
    this.setState({nodeName: ''});
    this.setState({nodeId: 0});
  };

  render() {
    const {zoom, pan, style} = this.props;

    return (
      <div
        style={{
          // height: '100%',
          position: 'relative',
          ...style
        }}
      >
        <RenameNodeModal {...this.state} updateNodeName={this.updateNodeName} closeModal={this.closeModal}/>
         <Legend />
        <ZoomControl zoom={this.props.changeZoom} />
        <InteractiveSvg
          id={COMPOSER_DOM_ID} // used by media item onDrop
          onDragStart={this.handleDragStart}
          onDragMove={this.handleDragMove}
          onDragEnd={this.handleDragEnd}
          pan={pan}
          zoom={zoom}
          onWheel={this.handleWheel}
        >
          {this.renderDefs()}
          {this.renderContent()}
        </InteractiveSvg>
      </div>
    );
  }
}

export default Composer;
