import React from 'react';
import PositionableElementProperties from './PositionableElementProperties';
import ClickableElementProperties from './ClickableElementProperties';

export default class HotspotElementProperties extends React.Component {
  render() {
    return (
      <div>
        <ClickableElementProperties {...this.props} />
        <PositionableElementProperties {...this.props} />
      </div>
    );
  }
}
