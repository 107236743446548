import React from 'react';
import {connect} from 'react-redux';
import {zoom} from 'modules/composer/composer';
import styles from './zoomControl.scss';
import IconButton from 'components/Buttons/IconButton';

export default function ZoomControl({zoom}) {
  return (
    <div className={styles.wrapper}>
      <IconButton  white grouped icon="minus" onClick={() => zoom(0.1)}  />
      <IconButton  white grouped icon="plus" onClick={() => zoom(-0.1)} />
      <IconButton  white grouped icon="undo" onClick={() => zoom('reset')} />
    </div>
  );
}
