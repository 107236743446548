import {takeLatest, put} from 'redux-saga/effects';
import {FETCH_USERS, receiveUsers} from 'modules/user/user';
import fetch from 'utils/saga/fetch';
import {error} from 'utils/alert';

export default function* fetchAgencySaga() {
    yield takeLatest(
        [FETCH_USERS], fetchUsers
    );
}

function* fetchUsers() {
    const res = yield fetch('user');

    if (res.status !== 200) {
        error({text:'Unable To Get Users'})
    }

    const data = yield res.json();
    yield put(receiveUsers(data));
}
