import React from 'react';
import {
  updateAccountPassword,
  updateAccountName,
  updateAccountEmail,
  updateAccountCompanyName,
  updateAccountCompanyLogo,
  updateHideLogo
} from 'modules/auth/auth';
import { connect } from 'react-redux';
import { updateAccountDetails, accountDetailsSelector } from 'modules/account/account';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import Button from 'components/Buttons/Button';
import DropImageZone from 'modules/media/components/DropImageZone';
import styles from './AccountPage.scss';
import Icon from 'components/Icon';
import AccountNav from './AccountNav';
import PageBody from 'components/PageBody';
import { Option, BooleanInput } from 'components/PropertyEditor';

@connect(
  accountDetailsSelector,
  {
    updateAccountDetails,
    updatePageLoadingState,
    updateAccountPassword,
    updateAccountName,
    updateAccountEmail,
    updateAccountCompanyName,
    updateAccountCompanyLogo,
    updateHideLogo
  }
)
export default class AccountDetailsPage extends React.Component {
  constructor() {
    super();

    this.state = {
      password: ''
    };
  }

  handleSave = () => {
    const { name, email, company_name, logo, hide_logo_on_share_page } = this.props.user;
    this.props.updateAccountDetails(
      this.props.user.id,
      { name, email, password: this.state.password, company_name, logo, hide_logo_on_share_page },
      true
    );
    this.setState({ password: '' });
  };

  handleImageUpload = ({ src }) => {
    this.props.updateAccountCompanyLogo(src);
  };

  render() {
    const {
      user: {
        id,
        name,
        email,
        password,
        company_name,
        logo,
        is_club,
        is_agency,
        parent_user_id,
        hide_logo_on_share_page,
        evolution_club,
       evolution_pro
      }
    } = this.props;

    return (
      <PageBody heading="Preferences" right={<AccountNav active="Preferences" isSubUser={!!parent_user_id} />}>
          <div style={{ paddingLeft: '30px', marginBottom: '50px' }}>
            <div className="grid">
              <div className="col8">
                {/* <div className="form-control">
                  <label>Account Id</label>
                  <p>{id}</p>
                </div> */}
                <h3 className="form-heading">Your Details</h3>
                <div className="form-control">
                  <label>Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={e => {
                      this.props.updateAccountName(e.target.value);
                    }}
                  />
                </div>
                <div className="form-control">
                  <label>Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={e => {
                      this.props.updateAccountEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="form-control">
                  <label>New Password</label>
                  <input type="password" value={password} onChange={e => this.setState({ password: e.target.value })} />
                </div>
                {parent_user_id === 0 && ( // hide these settings from sub users
                  <div>
                    <div className="form-control">
                      <label>Company Name (This is shown on the share page)</label>
                      <input
                        type="text"
                        value={company_name || ''}
                        onChange={e => {
                          this.props.updateAccountCompanyName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-control">
                      <label>Company Icon for share page (50px x 50px)</label>
                      <DropImageZone directory="companyLogos" onSuccess={this.handleImageUpload} src={logo} />
                    </div>
                    {is_club || evolution_club || evolution_pro ? ( // show only to club users
                      <div className="form-control" style={{ marginTop: '30px' }}>
                        <Option
                          label="Hide Interactr branding on share page"
                          Component={BooleanInput}
                          value={hide_logo_on_share_page}
                          onChange={(e, val) => {
                            this.props.updateHideLogo(val);
                          }}
                        />
                      </div>
                    ) : (
                      <p style={{ marginTop: '30px' }}>
                        <strong>Hide Interactr branding on share page</strong>
                        <br />
                        <em>Only available to Club Users.</em>
                      </p>
                    )}
                  </div>
                )}

                <div className="form-control" style={{ marginTop: '30px' }}>
                  <Button primary large icon="save" onClick={() => this.handleSave()}>
                    Save Changes
                  </Button>
                </div>
              </div>
              <div className="col4" style={{ paddingLeft: '2vw' }}>
                <h2 className="form-heading">Avatar</h2>
                <p>
                  We use the Gravatar service to provide user avatars. Gravatar allows users to register in one place
                  and have the same avatar image used across any website that supports it.
                </p>
                <p>
                  To set up your gravatar go to{' '}
                  <a href="http://en.gravatar.com/" className="linkColor" target="_blank">
                    www.gravatar.com
                  </a>{' '}
                  and register with the same email address that you use for your account.
                </p>
              </div>
            </div>
          </div>
      </PageBody>
    );
  }
}
