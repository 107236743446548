import React from 'react';
import {Tooltip} from 'react-lightweight-tooltip';

export default class TimelineBarTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {disabled: false};
  }

  disable() {
    this.setState({disabled: true});
  }

  enable() {
    this.setState({disabled: false});
  }

  render() {
    const {name} = this.props;

    if (this.state.disabled) {
      return null;
    }

    const tooltipStyle = {
      wrapper: {
        position: 'relative',
        display: 'inherit',
        cursor: 'inherit',
        height: '100%',
        zIndex: '50'
      }
    };

    // temp disable tooltip think text looks better
    return <div />

    return (
      <Tooltip content={name} styles={tooltipStyle}>
        <div style={{position: 'absolute', top: 0, bottom: 0, padding: '5px 0'}}> </div>
      </Tooltip>
    );
  }
}
