import {takeLatest, put} from 'redux-saga/effects';
import {APPLY_ELEMENT_TEMPLATE, receiveElement} from 'modules/element/element';
import fetch from 'utils/saga/fetch';

export default function* applyElementTemplateSaga() {
  yield takeLatest(APPLY_ELEMENT_TEMPLATE, applyElementTemplate);
}

function* applyElementTemplate({element_type, id, templateId, callback}) {
  const res = yield fetch(`elements/applyTemplate`, {
    method: 'POST',
    body: {element_type, templateId, id,}
  });

  const element = yield res.json();

  yield put(receiveElement({...element, element_type}));
  if(typeof callback == 'function') yield callback()
}
