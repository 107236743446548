import {takeEvery, put} from 'redux-saga/effects';
import {COPY_PROJECT, receiveProject} from 'modules/project/project';
import fetch from 'utils/saga/fetch';
import swal from 'sweetalert2';
import {loadMedia} from 'modules/media/media';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {success, error} from 'utils/alert';

export default function* deleteProjectSaga() {
  yield takeEvery(COPY_PROJECT, copyProject);
}

function* copyProject({projectId}) {
  const conf = yield swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to copy this project.',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#00b382',
    confirmButtonText: 'Yes, Please!',
  });

  yield put(updatePageLoadingState(true));

  const res = yield fetch(`projects/copy/${projectId}`, {
    method: 'POST'
  });

  // Clear the loader
  yield put(updatePageLoadingState(false));

  if (res.status !== 200) {
    error({text:'Unable To Copy Project'})
  }

  // Receive the project
  const project = yield res.json();
  yield put(receiveProject(project));
  yield put(loadMedia()); // load media for new project 


}
