import {put,takeEvery} from 'redux-saga/effects';
import {SAVE_INTEGRATION, savedIntegration} from 'modules/integration/integration';
import fetch from 'utils/saga/fetch';
import {success, error} from 'utils/alert';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";

export default function* saveIntegrationSaga() {
  yield takeEvery(SAVE_INTEGRATION, saveIntegration);
}

function* saveIntegration({integrationType, data}) {
  yield put(updatePageLoadingState(true));

  const res = yield fetch(`account/integrations/${integrationType}`, {
    method: 'POST',
    body: data
  });

  if (!res.ok) {
    yield put(updatePageLoadingState(false));
    error({text: 'Could not save settings. Please try again later.'});
    return;
  }

  const response = yield res.json();

  if (!response.success) {
    yield put(updatePageLoadingState(false));
    error({title: "Authentication Failed", text: 'Please check your api key and url.'});
    return;
  }

  yield put(updatePageLoadingState(false));

  yield put(savedIntegration(response.user));

  success({text:'Integration settings saved'});
}
