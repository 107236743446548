import React from 'react';
import {BarChart} from '@toast-ui/react-chart'
import analytics from 'shared/analytics';

export default class ProjectViewsByDeviceChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupBy:'day'
        }
    }

    render(){
        const {data} = this.props;

        const mobile = analytics.getSeriesFromQuery(data.mobile);
        const desktop = analytics.getSeriesFromQuery(data.desktop);

        var d = {
            categories: analytics.getCategoriesFromQuery(data.mobile, this.state.groupBy),
            series:[
                {
                    name: 'Desktop',
                    data: desktop
                },
                {
                    name: 'Mobile',
                    data: mobile
                }
            ]
        };

        var options = {
            chart: {
                width: 1160,
                height: 650,
                title: 'By Device',
            },
            yAxis: {
                title: 'Date'
            },
            xAxis: {
                title: 'Views',

            },
            series: {
                stackType: 'normal'
            },
            theme: 'myTheme'
        };

        return(
            <BarChart
                data={d}
                options={options}
            />
        );
    }
}