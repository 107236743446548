import React, {Component} from 'react';
import cx from 'classnames';
import { keyBy } from 'utils/immutableUtils';
import {connect} from 'react-redux';
import {addProjectGroup, updateProjectGroup, deleteProjectGroup} from 'modules/project/project';
import {error} from 'utils/alert';
import {Option, TextInput} from 'components/PropertyEditor';
import Icon from 'components/Icon';
import EditableLabel from 'components/EditableLabel';
import styles from './ProjectGroupItem.scss'

@connect(null, { updateProjectGroup, deleteProjectGroup })
export default class ProjectGroupItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.folder.title,
            isEditing: false
        }
    }

    handleFocusOut = title => {
      const {folder, updateProjectGroup} = this.props;

      this.setState(
        { title },
        () => updateProjectGroup(folder.id, title)
      );
    };

    handleDelete = () => {
        const {folder, deleteProjectGroup} = this.props;
        deleteProjectGroup(folder.id);
    };

    render() {
        return (
            <div className="grid">
                <div className={cx(styles.folderItem, 'col12')}>
                    <Icon name="bars" className={styles.folderItemIcon}/>
                    <EditableLabel
                        text={this.state.title}
                        labelClassName={styles.folderItemLabel}
                        inputClassName={styles.folderItemInput}
                        wrapperClassName={styles.folderItemWrapper}
                        onFocus={this.handleFocus}
                        onFocusOut={this.handleFocusOut}
                    />
                    <Icon name="trash-alt" className={styles.folderItemDelete} onClick={this.handleDelete}/>
                </div>
            </div>
        );
    }
}