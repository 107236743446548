import React from 'react';
import {Timeline, TimelineBar} from 'components/Timeline';
import {connect} from 'react-redux';
import {
  updateInteraction,
  selectInteraction,
  deleteInteraction
} from 'modules/interaction/interaction';
import {updateElementGroup} from 'modules/node/node';
import {nodeSelector} from 'modules/node/nodeSelectors';
import {interactionTimelineSelector} from 'modules/interaction/interactionSelectors';
import {TRIGGER_ELEMENT} from 'shared/element';
import styles from './InteractionTimeline.scss';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';

@connect(interactionTimelineSelector, {
  updateInteraction,
  selectInteraction,
  deleteInteraction,
  updateElementGroup
})
@connect(nodeSelector, {})
export default class InteractionTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showTimeline: true, resizeTime: null};
    this.debounceResizeWindow = debounce(this.resizeWindow, 100);
    this.timeout = null;
  }

  changeHandler = (id, elementGroupId) => ({timeIn, timeOut}) => {
    const {updateInteraction, nodeId, updateElementGroup} = this.props;

    if (!!elementGroupId) {
      updateElementGroup({timeIn, timeOut, id : elementGroupId})
    }
    // only update the interaction's element group if present
    else updateInteraction(id, {timeIn, timeOut});

  };

  clickHandler = (interaction) => {
    this.props.selectInteraction(interaction);
    this.props.jumpToTime(interaction.timeIn + 0.05);
  };

  deleteHandler = id => () => {
    this.props.deleteInteraction(id);
  };

  componentDidMount() {
    window.addEventListener('resize', this.debounceResizeWindow);
  }

  resizeWindow = () => {
    this.setState({showTimeline: false});

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({showTimeline: true});
    }, 100);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceResizeWindow);
    clearTimeout(this.timeout);
  }

  renderTimeLineBar = (interaction) => {
    const {selected, duration, elementGroups, nodeId} = this.props;
    let {timeIn, timeOut, element_group_id: elementGroupId} = interaction,
        elementGroup;

    if (elementGroupId) elementGroup = elementGroups[elementGroupId];

    // If the interaction exists in element group.
    if (elementGroup) {
      timeIn = elementGroup.timeIn;
      timeOut = elementGroup.timeOut;
    }

    return (
      <TimelineBar
        key={interaction.id}
        from={timeIn}
        to={timeOut}
        interactionId={interaction.id}
        disableResize={interaction.element_type === TRIGGER_ELEMENT}
        onChange={this.changeHandler(interaction.id, interaction.element_group_id)}
        onClick={()=>this.clickHandler(interaction)}
        onDelete={this.deleteHandler(interaction.id)}
        selected={selected && selected.id == interaction.id}
        className={
            interaction.element_type === TRIGGER_ELEMENT &&
            styles.TriggerElement
        }
        timelineDuration={duration}
      >
        {getInteractionTitle(interaction)}
      </TimelineBar>
    );
  };

  render() {
    const {interactions, node} = this.props;
    const {showTimeline} = this.state;

    const filteredInteractions = filter(interactions, (interaction)=>{
      return (interaction.id !== node.interaction_layer_id);
    });

    return (
      <Timeline {...this.props}>
        {!filteredInteractions.length && (
          <div><p style={{textAlign:'center'}}>Get started by dragging an element in from the elements bar next to the video.</p></div>
        )}
        {showTimeline && filteredInteractions.map(interaction => this.renderTimeLineBar(interaction))}
      </Timeline>
    );
  }
}

function getInteractionTitle(interaction) {
  let title = interaction.element.name,
      {element_group: elementGroup} = interaction;

  if (elementGroup) {
    title += ` (${elementGroup.name})`
  }

  return title;
}

function htmlToText(html) {
  const el = document.createElement('div');
  el.innerHTML = html;
  return el.textContent || el.innerText || '';
}
