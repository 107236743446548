import {put, select, takeEvery} from 'redux-saga/effects';
import {ADD_INTERACTION, receiveInteraction, selectInteraction} from 'modules/interaction/interaction';
import fetch from 'utils/saga/fetch';
import {receiveElement} from 'modules/element/element';
import {updateNode} from 'modules/node';
import processAndReceiveModal from 'modules/modal/sagas/utils/receiveModal';

import {splitElementFromObj} from 'modules/element/elementUtils';
import {nodeDurationSelector} from 'modules/node/nodeSelectors';

export default function* addInteractionSaga() {
  yield takeEvery(ADD_INTERACTION, addInteraction);
}

function* addInteraction({projectId, nodeId, callback, ...data}) {
  const duration = yield select(nodeDurationSelector);

  const res = yield fetch('interactions', {
    method: 'POST',
    body: {
      nodeId,
      projectId,
      ...data ,
      timeIn: data.timeIn || window.currentVideoPlayedSeconds || 0,
      timeOut: data.timeOut || duration || 60
    }
  });
  // the creating of interaction endpoint returns the modal it's a modal trigger
  const {interaction: interactionWithElement , modal, node} = yield res.json();
  
  const [interaction, element] = splitElementFromObj(interactionWithElement);
  
  yield put(receiveElement(element));
  yield put(receiveInteraction(interaction));

  if(modal) {
    yield processAndReceiveModal(modal);
  }

  //  update the node in Redux 
  if(node) yield put(updateNode(node.id , {interaction_layer_id : interaction.id}, true))
  if(callback) callback(interaction, element)
  
  yield put(selectInteraction(interactionWithElement));
}
