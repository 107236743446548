import React from 'react';
import {connect} from 'react-redux';
import videos from 'utils/trainingVideos';
import TrainingVideo from 'components/TrainingVideo';
import { withRouter } from 'react-router-dom';
// import AccountNav from "../modules/account/components/AccountNav";
import PageBody from 'components/PageBody';
import SubNav from "components/SubNav";
import {whiteLabelSelector} from "modules/pageLoader/pageLoaderSelectors";
import {userSelector} from "modules/auth/authSelectors";
import { projectsPath } from 'routeBuilders';

const meta = (
  <>
    <strong>Submitting a Support Ticket</strong>
    <p>
      You can submit a support request by going to Support Desk using the link on the top right of this page.<br />
      If you prefer to email us directly you can send an email to  {' '}
      <a href="mailto:support@videosuite.io" target="_blank" style={{ color: '#ffc180' }}>
          support@videosuite.io
      </a>
    </p>
  </>
);

@connect(userSelector, {})
@connect(whiteLabelSelector, {})
class TrainingPage extends React.Component  {

    render() {
        const {user, whitelabel, history} = this.props;
        const isSubUser = !!user.parent_user_id;

        if (isSubUser && whitelabel) {
            history.push(projectsPath());
            return null;
        }

        return (
            <PageBody heading="Training Videos" meta={meta} right={<TrainingSubNav />}>
                <div style={{ padding: '0px' }}>
                    {videos.map(video => (
                        <TrainingVideo video={video} key={video.name} />
                    ))}
                </div>
            </PageBody>
        );
    }
}

function TrainingSubNav(){
    const  items = [
        {
            active: true,
            text: 'Training Videos',
            route: '/training'
        },
        {
            active: false,
            text: 'Support Desk',
            external: true,
            action: ()=>window.location.href = 'https://support.videosuite.io/'
        },
        {
            active: false,
            external: true,
            text: 'Facebook Group',
            // action: ()=>window.location.href = 'https://www.facebook.com/groups/championentrepreneurs/?fref=nf'
            action: ()=>window.location.href = 'https://www.facebook.com/groups/interactrusers/?fref=nf'
        },
    ];

    return <SubNav items={items} />
}

export default withRouter(TrainingPage);