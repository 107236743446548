import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {
    VIEW_PROJECT_GROUPS,
    receiveProjectGroups,
} from 'modules/project/project';

export default function* fetchProjectGroupsSaga() {
    yield takeLatest(
        [VIEW_PROJECT_GROUPS],
        fetchProjectGroups
    );
}

function* fetchProjectGroups() {
    const res = yield fetch('projectGroups'),
        { data } = yield res.json();

    yield put(receiveProjectGroups(data));
}