import { put, select, takeLatest } from 'redux-saga/effects';
import { nodesStateSelector } from 'modules/node/nodeSelectors';
import { VIEW_NODE_PAGE, receiveProjectNodes, FETCH_NODES, receiveElementGroup } from 'modules/node/node';
import { VIEW_MODAL_PAGE } from 'modules/modal/modal';
import { VIEW_PROJECT_PAGE, VIEW_PROJECT_SETTINGS_PAGE, VIEW_PROJECT_STATS_PAGE } from 'modules/project/project';
import { projectIdSelector } from 'modules/project/projectSelectors';
import fetch from 'utils/saga/fetch';
import { receiveElements } from 'modules/element/element';
import { processElements } from 'modules/element/elementUtils';
import { error } from 'utils/alert';

export default function* fetchProjectNodesSaga() {
  yield takeLatest([VIEW_PROJECT_PAGE, VIEW_NODE_PAGE, VIEW_PROJECT_SETTINGS_PAGE], _fetchProjectNodes);
}

function* _fetchProjectNodes({ projectId, overwrite }) {
  // check if we've already fetched for this project
  const state = yield select(nodesStateSelector);
  // this condition is currently only working because of the bug from node reducer
  // take in consideration after redoing Node state management
  if (overwrite === undefined && state.getIn(['data', 'nodes']).size) {
    return;
  }

  let newProjectId = projectId ? projectId : yield select(projectIdSelector);

  const res = yield fetch(`projects/${newProjectId}/nodes`);
  if (res.ok) {
    let data = yield res.json();

    let [nodes, elements] = processElements(data, 'interactions');

    yield put(receiveElements(elements));
    yield put(receiveProjectNodes(nodes));
  }
}
