import {takeLatest} from 'redux-saga/effects';
import {take, put, select} from 'redux-saga/effects';
import {nodesSelector} from 'modules/node/nodeSelectors';
import {RECEIVE_NODE, RECEIVE_ACTIVE_PROJECT_NODES} from 'modules/node/node';
import {changeStartNode, RECEIVE_PROJECTS, VIEW_PROJECT_PAGE, MARK_START_NODE} from 'modules/project/project';
import {projectSelector} from 'modules/project/projectSelectors';

export default function* markStartNodeSaga() {
  yield takeLatest([VIEW_PROJECT_PAGE, MARK_START_NODE], markStartNode);
}

function* markStartNode({projectId}) {

  let project;
  while (!project) {
    ({project} = yield select(projectSelector, {projectId}));
    if (!project) {
      yield take(RECEIVE_PROJECTS);
    }
  }

  if (project.start_node_id != 0) {
    return;
  }

  let nodes = {};
  while (!nodes.size) {
    ({nodes} = yield select(nodesSelector));
    if (!nodes.size) {
      yield take([RECEIVE_NODE, RECEIVE_ACTIVE_PROJECT_NODES]);
    }
  }

  yield put(changeStartNode(projectId, nodes.first().get('id')));
}
