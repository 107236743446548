import React from 'react';
import CopyToClipboard from 'components/CopyToClipboard';
import { userSelector } from 'modules/auth/authSelectors';
import { connectorsSelector } from '../../composer/composerSelectors';
import { connect } from 'react-redux';
import { updateAccountDetails } from 'modules/account/account';
import AccountNav from './AccountNav';
import PageBody from 'components/PageBody';

@connect(
  userSelector,
  { updateAccountDetails }
)
export default class AccountDetailsAffiliate extends React.Component {
  componentWillMount() {
    const {
      user: { id, api_key },
      updateAccountDetails
    } = this.props;
    if (!api_key) {
      updateAccountDetails(id, { api_key: this.generateKey() }, true);
    }
  }
  render() {
    const { api_key } = this.props.user;
    return (
      <PageBody heading="Your Details" right={<AccountNav active="api" />}>
        <div style={{ paddingLeft: '30px' }}>
          <h2 className="form-heading">API Keys</h2>
          <div className="grid">
            {api_key ? (
              <div style={{ width: '100%', maxWidth: 800 }}>
                <h4>Your API Key</h4>
                <CopyToClipboard rows={1} value={this.props.user.api_key} />
              </div>
            ) : (
              <h3>
                <strong>Generating You a Key...</strong>
              </h3>
            )}
          </div>
        </div>
      </PageBody>
    );
  }

  generateKey() {
    const range = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let key = '';
    for (let i = 0; i < range.length; i++) {
      key += range[Math.floor(Math.random() * range.length)];
    }
    return key;
  }
}
