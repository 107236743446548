import {takeEvery, select, put} from 'redux-saga/effects';
import {ADD_MODAL_TO_ELEMENT} from 'modules/modal/modal';
import {updateElement} from 'modules/element/element';
import addModal from './utils/addModal';
import history from 'browserHistory';
import {modalPath} from 'routeBuilders';
import {success, error} from 'utils/alert';

export default function* addModalToElementSaga() {
  yield takeEvery(ADD_MODAL_TO_ELEMENT, addModalToElement);
}

function* addModalToElement({
  projectId,
  nodeId,
  element_type,
  element_id,
  actionKey
}) {
  // add the modal
  const modal = yield addModal(projectId);

  // update the elements action to trigger our new modal
  if (element_id) {
    yield put(
      updateElement(element_type, element_id, {
        [actionKey]: 'openModal',
        [actionKey + 'Arg']: modal.id
      })
    );
  }

  if(!nodeId) {
    // Need to grab it from the url
    const url = window.location.href;
    if (url.includes('nodes/')) {

      let urlSplit = url.split('/');
      nodeId = urlSplit[6];

    } else {
      error({text:'Error creating modal'})
    }
  }

  const newNodeId = nodeId.replace("?selectModal", "");
  history.push(modalPath({projectId, nodeId: newNodeId, modalId: modal.id}));
}
