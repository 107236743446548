import {createSelector} from 'reselect';
import {authUserSelector} from "../auth/authSelectors";

const usersStateSelector = state => state.users;

export const selectedUserIdSelector = createSelector(usersStateSelector, (state)=>{
    return {
        id: state.get('selected').toJS()
    }
});

export const usersSelector = createSelector(authUserSelector, usersStateSelector, (user, state)=>{
    const users = state.get('data').toList().toJS();
    return {
        users,
        masterUser: user
    }
});

export const userSelector = createSelector(
    usersStateSelector,
    selectedUserIdSelector,
    (state, id) => {
        return {
            user: (id) ? state.getIn(['data', id]).toJS() : {},
        }
    }
);