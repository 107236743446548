import {takeLatest, put} from 'redux-saga/effects';
import {receiveSearchProjects} from 'modules/project/project';
import {receiveModals} from 'modules/modal/modal';
import fetch from 'utils/saga/fetch';
import {receiveElements} from 'modules/element/element';
import {processElements} from 'modules/element/elementUtils';
import {SEARCH_PROJECTS} from "../project";
import {error} from 'utils/alert';

export default function* searchProjectsSaga() {
    yield takeLatest(
        [SEARCH_PROJECTS],
        searchProjects
    );
}

function* searchProjects({term}) {
    const res = yield fetch(`searchProjects?term=${term}`);
    const data = yield res.json();

    if (!data.success) {
        return error({text: 'Something went wrong'});
    }

    //const [modals, elements] = processElements(data.projects);

    yield put(receiveSearchProjects(data.projects));
    //yield put(receiveElements(elements));
    //yield put(receiveModals(modals));
}
