import { takeLatest, put } from 'redux-saga/effects';
import { CREATE_PROJECT, receiveProject, receiveProjectGroups} from 'modules/project/project';
import fetch from 'utils/saga/fetch';
import history from 'browserHistory';
import { projectPath } from 'routeBuilders';
import { loadMedia } from 'modules/media/media';
import { error} from 'utils/alert';

export default function* createProjectSaga() {
    yield takeLatest([CREATE_PROJECT], createProject);
}

function* createProject({project: projectData, callback, redirectAfterSave}) {
    if (projectData.project_group_id === 'noFolder') {
        projectData.project_group_id = null;
    }

    const res = yield fetch('projects', {
        method: 'POST',
        body: projectData
    });

    if (res.status !== 200) {
        error({text:'Unable To Save Project'})
        if(callback) callback()
        return ;
    }

    const {project, projectGroups} = yield res.json();

    yield put(loadMedia()); // load media for new project
    yield put(receiveProject(project));

    /**
     * Update Project Groups only if project.project_group_id has been updated
     */
    if (projectGroups) {
        yield put(receiveProjectGroups(projectGroups));
    }

    if(callback) callback(project);

    if (redirectAfterSave) {
        history.push(projectPath({projectId: project.id}));
    }
}
