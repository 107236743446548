import {takeLatest, put} from 'redux-saga/effects';
import {UPDATE_PROJECT_GROUP_SORTING, receiveProjectGroups} from 'modules/project/project';
import fetch from 'utils/saga/fetch'
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";

export default function* updateProjectGroupSortingSaga() {
    yield takeLatest([UPDATE_PROJECT_GROUP_SORTING], updateProjectGroupSorting);
}

function* updateProjectGroupSorting({folders}) {
    const res = yield fetch('projectGroups/sorting', {
        method: 'PUT',
        body: folders
    });

    const {data} = yield res.json();

    yield put(receiveProjectGroups(data));
}
