import React from 'react';
import Integration from './Integration';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { userSelector } from 'modules/auth/authSelectors';
import AccountNav from '../../account/components/AccountNav';
import PageBody from 'components/PageBody';
import getAsset from "../../../utils/getAsset";

@connect(
  userSelector,
  { updatePageLoadingState }
)
class SubUserIntegrationsPage extends React.Component {
  render() {
    const {
      user: { is_club, parent_user_id }
    } = this.props;
    setTimeout(() => {
      this.props.updatePageLoadingState(false);
    }, 1000);

    return (
      <PageBody heading="Your Details" right={<AccountNav active="integrations" isSubUser={!!parent_user_id} />}>
        <div style={{ padding: '30px' }}>
          <Integration
            imageSrc={getAsset("/img/mailchimp.png")}
            name="Mailchimp"
            fields={{ key: 'Key' }}
            integrationType="integration_mailchimp"
            helpText={
              <div
                style={{
                  textAlign: 'left',
                  display: 'block',
                  marginTop: '-10px',
                  marginBottom: '15px',
                  width: '100%',
                  padding: '15px'
                }}
              >
                <p>
                  Details on how to access your API key can be found here:{' '}
                  <a href="https://mailchimp.com/help/about-api-keys/" target="_blank">
                    https://mailchimp.com/help/about-api-keys/
                  </a>
                </p>
              </div>
            }
          />
          <Integration
            imageSrc={getAsset("/img/sendlane.jpg")}
            name="Sendlane"
            fields={{ key: 'API Key', hash: 'Hash Key', domain: 'Domain' }}
            integrationType="integration_sendlane"
            helpText={
              <div
                style={{
                  textAlign: 'left',
                  display: 'block',
                  marginTop: '-10px',
                  marginBottom: '15px',
                  width: '100%',
                  padding: '15px'
                }}
              >
                <p>
                  Details on how to access your API keys can be found here:{' '}
                  <a href="http://help.sendlane.com/knowledgebase/api-key/" target="_blank">
                    http://help.sendlane.com/knowledgebase/api-key/
                  </a>
                </p>
              </div>
            }
          />
          <Integration
            imageSrc={getAsset("/img/activecampaign.png")}
            name="Active Campaign"
            fields={{ key: 'Key', url: 'URL' }}
            integrationType="integration_activecampaign"
            helpText={
              <div
                style={{
                  textAlign: 'left',
                  display: 'block',
                  marginTop: '-10px',
                  marginBottom: '15px',
                  width: '100%',
                  padding: '15px'
                }}
              >
                <p>
                  Details on how to access your API keys can be found here:{' '}
                  <a
                    href="https://help.activecampaign.com/hc/en-us/articles/207317590-Getting-started-with-the-API"
                    target="_blank"
                  >
                    https://help.activecampaign.com/hc/en-us/articles/207317590-Getting-started-with-the-API
                  </a>
                </p>
              </div>
            }
          />
          <Integration
            imageSrc={getAsset("/img/getresponse.png")}
            name="Get Response"
            fields={{ key: 'Key' }}
            integrationType="integration_getresponse"
            helpText={
              <div
                style={{
                  textAlign: 'left',
                  display: 'block',
                  marginTop: '-10px',
                  marginBottom: '15px',
                  width: '100%',
                  padding: '15px'
                }}
              >
                <p>
                  Details on how to access your API key can be found here:{' '}
                  <a href="https://www.getresponse.com/help/glossary/api-key.html" target="_blank">
                    https://www.getresponse.com/help/glossary/api-key.html
                  </a>
                </p>
              </div>
            }
          />
        </div>
      </PageBody>
    );
  }
}

export default withRouter(SubUserIntegrationsPage);
