import React from 'react';
import { connect } from 'react-redux';
import styles from './SubNav.scss';
import cx from 'classnames';
import {withRouter} from 'react-router-dom';
import { injectStyles } from 'utils/domUtils';
import {whiteLabelSelector} from "modules/pageLoader/pageLoaderSelectors";

@connect(whiteLabelSelector, {})
class SubNav extends React.Component {
    
    setWhiteLabelCss = () => {
        const {whitelabel} = this.props
        if(whitelabel){
            const _css = `
                .sn_whitelabel.sn_active button {
                    color: ${whitelabel.primary_color};
                }
                .sn_whitelabel button:hover  {
                    color: ${whitelabel.primary_color};
                    // filter : brightness(.9);
                    opacity: 0.9;
                }
                
            `;

            injectStyles('whitelabel_sn', _css)
        }
    }

    renderItem = (item) => {
        const {whitelabel, verticalFoldersScroll} = this.props
        const wrapperClasses = cx(styles.item, {
            [styles.active]: (item.active > 0),
            sn_whitelabel: whitelabel && whitelabel.primary_color,
            sn_active:  !!item.active,
            [styles.noWrap]: verticalFoldersScroll
        });


        return (
            <li className={wrapperClasses} key={Math.ceil(Math.random()*10000)} onClick={()=>{this.handleClick(item)}}>
                    <div className={styles.text}>
                        <h4>{item.text}</h4>
                        <p><small>{item.summary}</small></p>
                    </div>
            </li>
        )
    }

    handleClick = (item) => {
        const {action} = this.props;

        // First try and fire a global click action passed in
        if (action) {
            action();
            return;
        }

        // Next check the array item for a local click action
        if (item.action) {
            item.action();
            return;
        }

        // If no click action try to do page nav
        if(item.route) {
            this.props.history.push(item.route);
            return;
        }
    };

    // horizontalScroll = e => {
    //     e.stopPropagation();
    //     let container = document.getElementById('folders-container');
    //     let containerScrollPosition = document.getElementById('folders-container').scrollLeft;
    //     container.scrollTo({
    //         top: 0,
    //         left: containerScrollPosition + e.deltaY,
    //         behaviour: 'smooth' //if you want smooth scrolling
    //     })
    // };

    render() {
        const {items, verticalFoldersScroll} = this.props;

        const wrapperClasses = cx(styles.wrapper, {
            [styles.verticalFoldersScroll]: verticalFoldersScroll
        });

        return (
            <ul className={wrapperClasses}>
                {this.setWhiteLabelCss()}
                {items.map(this.renderItem)}
            </ul>
        );
    }
}

export default withRouter(SubNav);
