import {fromJS, setIn} from 'immutable';
import {SAVED_INTEGRATION} from 'modules/integration/integration';
import {UPDATE_ACCOUNT_DETAILS} from 'modules/account/account';
const initialState = fromJS({
  user: null,
  error: null,
  emailOK: null,
  authenticating: true // reauthenticate gets triggered on load anyway
});

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return state.set('authenticating', true).set('error', null);

    case AUTH_ERROR:
      return state.set('error', action.message);

    case EMAIL_OK:
      return state.set('emailOK', action.value);

    case SAVED_INTEGRATION:
      return state.set('user', fromJS(action.user));

    case AUTHENTICATE:
      return initialState
        .set('authenticating', false)
        .set('user', fromJS(action.user));

    case DEAUTHENTICATE:
      return initialState
        .set('authenticating', false)
        .set('error', action.error);

    case UPDATE_AUTHENTICATED_USER:
      return state.set('user', fromJS(action.user));

    case UPDATE_ACCOUNT_PASSWORD:
      return state.setIn(
        ['user', 'password'], fromJS(action.pasword)
      );
    case UPDATE_ACCOUNT_NAME:
      return state.setIn(
        ['user', 'name'], fromJS(action.name)
      );
    case UPDATE_ACCOUNT_EMAIL:
      return state.setIn(
        ['user', 'email'], fromJS(action.email)
      );

    case UPDATE_ACCOUNT_COMPANY_NAME:
      return state.setIn(
        ['user', 'company_name'], fromJS(action.name)
      );

    case UPDATE_ACCOUNT_COMPANY_LOGO:
      return state.setIn(
        ['user', 'logo'], fromJS(action.src)
      );

      case UPDATE_HIDE_LOGO:
          return state.setIn(
              ['user', 'hide_logo_on_share_page'], fromJS(action.val)
          );

    case LOGOUT:
      return state.set('authenticating', true).set('error', null);
  }
  return state;
}

export const reducers = {
  auth: authReducer
};

export const UPDATE_ACCOUNT_PASSWORD = 'auth:UPDATE_ACCOUNT_PASSWORD';
export function updateAccountPassword( password) {
  return {
    type: UPDATE_ACCOUNT_PASSWORD,
    password
  };
}

export const UPDATE_ACCOUNT_NAME = 'auth:UPDATE_ACCOUNT_NAME';
export function updateAccountName( name) {
  return {
    type: UPDATE_ACCOUNT_NAME,
    name
  };
}

export const UPDATE_ACCOUNT_EMAIL = 'auth:UPDATE_ACCOUNT_EMAIL';
export function updateAccountEmail( email) {
  return {
    type: UPDATE_ACCOUNT_EMAIL,
    email
  };
}

export const UPDATE_ACCOUNT_COMPANY_NAME = 'auth:UPDATE_ACCOUNT_COMPANY_NAME';
export function updateAccountCompanyName(name){
  return {
    type: UPDATE_ACCOUNT_COMPANY_NAME,
    name
  }
}

export const UPDATE_ACCOUNT_COMPANY_LOGO = 'auth:UPDATE_ACCOUNT_COMPANY_LOGO';
export function updateAccountCompanyLogo(src){
  return {
    type: UPDATE_ACCOUNT_COMPANY_LOGO,
    src
  }
}

export const UPDATE_HIDE_LOGO = 'auth:UPDATE_HIDE_LOGO';
export function updateHideLogo(val){
    return {
        type: UPDATE_HIDE_LOGO,
        val : Number(val)
    }
}

export const LOGIN = 'auth:LOGIN';
export function login(email, password) {
  return {
    type: LOGIN,
    email,
    password
  };
}

export const AUTH_ERROR = 'auth:AUTH_ERROR';
export function authError(message) {
    return {
        type: AUTH_ERROR,
        message
    };
}

export const EMAIL_OK  = 'auth:EMAIL_OK';
export function emailOK(value){
  return{
    type: EMAIL_OK,
    value
  }
}


export const LOGOUT = 'auth:LOGOUT';
export function logout() {
  return {
    type: LOGOUT,
  };
}

export const REAUTHENTICATE = 'auth:REAUTHENTICATE';
export function reauthenticate() {
  return {
    type: REAUTHENTICATE
  };
}

export const AUTHENTICATE = 'auth:AUTHENTICATE';
export function authenticate(token, user) {
  return {
    type: AUTHENTICATE,
    token,
    user
  };
}

export const DEAUTHENTICATE = 'auth:DEAUTHENTICATE';
export function deauthenticate(error = null) {
  return {
    type: DEAUTHENTICATE,
    error
  };
}

export const UPDATE_AUTHENTICATED_USER = 'auth:UPDATE_AUTHENTICATED_USER';
export function updateAuthenticatedUser(user) {
  return {
    type: UPDATE_AUTHENTICATED_USER,
    user
  }
}

export const SEND_PASSWORD_RESET_EMAIL = 'auth:SEND_PASSWORD_RESET_EMAIL';
export function sendPasswordResetEmail(email) {
  return {
    type: SEND_PASSWORD_RESET_EMAIL,
    email
  }
}

export const SUBMIT_PASSWORD_RESET_REQUEST = 'auth:SUBMIT_PASSWORD_RESET_REQUEST';
export function submitPasswordResetRequest(email, password, confirmPassword, token) {
  return {
    type: SUBMIT_PASSWORD_RESET_REQUEST,
    email,
    password,
    confirmPassword,
    token
  }
}

export const FETCH_DATA = 'auth:FETCH_DATA';
export function fetchData(cb) {
  return {type: FETCH_DATA, cb}
}
