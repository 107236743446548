import React from 'react';
import ProjectsViewsByAll from './ProjectsViewsByAll';
import analytics from 'shared/analytics';
import styles from './ProjectsViewsChart.scss';
import Icon from 'components/Icon';
import map from 'lodash/map';

export default class ProjectsViewsChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            gotData: false,
            data: {},
            groupBy:'day'
        }
    }

    async componentDidMount() {
        const { projects, startDate, endDate } = this.props;
        if (projects) {
            await this.fetchStats(projects, startDate, endDate );
        }
    }

    async shouldComponentUpdate(nextProps, nextState){
        const { projectIds, startDate, endDate } = this.props;
        if(startDate !== nextProps.startDate || endDate !== nextProps.endDate) {
            await this.fetchStats(projectIds, nextProps.startDate, nextProps.endDate );
        }
    }

    get projectIds(){
        return  map(this.props.projects, project=>project.id);
    }

    async fetchStats(projects, startDate, endDate ) {
        // 👇 must be an array of queries
        //const projectIds =  map(projects, project=>(project.id));

        const queries = [
            {
                name: 'impressions',
                collection: 'Impression',
                api: 'Interactr',
                filters: {
                    project_id: this.projectIds
                },
                start_date: startDate,
                end_date: endDate,
                group_by: 'project_id'
            },
            {
                name: 'plays',
                collection: 'ProjectView',
                api: 'Interactr',
                filters: {
                    project_id: this.projectIds
                },
                start_date: startDate,
                end_date: endDate,
                group_by: 'project_id'
            }
        ];

        try {
            const { data } = await analytics.queries(queries);
            this.setState({ data, gotData: true });

        } catch (error) {
            this.setState({ fetchingStats: false, statsErrors: { views: true } });
        }
    }

    render(){
        const {startDate, endDate, projects} = this.props;

        const {gotData} = this.state;

        if(! gotData){
            return(
                <div className={styles.loader}>
                    <h2 style={{ marginTop: '80px', lineHeight: '40px'}}>
                        <Icon icon="spinner-third" spin={true} /> <br/> Getting Chart Data...
                    </h2>
                </div>
            );
        }

        const {data} = this.state;

        analytics.registerTheme();

        return(
            <div>
                <ProjectsViewsByAll
                    startDate={startDate}
                    endDate={endDate}
                    data={data}
                    projects={projects}
                />
            </div>
        );
    }
}