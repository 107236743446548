import {fromJS} from 'immutable';

const initialState = fromJS({
  data: false,
  errors:{}
});

function agencyReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AGENCY:
      return state.mergeIn(['data'], fromJS(action.data));
    case RECEIVE_AGENCY:
      return state.set('data', fromJS(action.agency))
      .set('errors', fromJS(action.errors))
  }

  return state;
}

export const reducers = {
  agency: agencyReducer
};


export const UPDATE_AGENCY = 'agency:UPDATE_AGENCY';
export function updateAgency(data) {
  return {
    type: UPDATE_AGENCY,
    data
  };
}

export const FETCH_AGENCY = 'agency:FETCH_AGENCY';
export function fetchAgency() {
  return {type: FETCH_AGENCY};
}

export const SAVE_AGENCY = 'agency:SAVE_AGENCY';
export function saveAgency() {
  return {type: SAVE_AGENCY};
}

export const RECEIVE_AGENCY = 'agency:RECEIVE_AGENCY';
export function receiveAgency(agency, errors={}) {
  return {
    type: RECEIVE_AGENCY,
    agency,
    errors
  };
}
export const VERIFY_DOMAIN = 'agency:VERIFY_DOMAIN';
export function verifyDomain(domain) {
  return {
    type: VERIFY_DOMAIN,
    domain,
  };
}
