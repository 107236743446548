import React, {useState} from 'react';
import {connect} from 'react-redux';
import PageBody from 'components/PageBody';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';
import cardStyles from 'components/Card.scss';
import styles from './DashboardPage.scss';
import { userSelector } from 'modules/auth/authSelectors';
import cx from 'classnames';
import DashboardCard from "./DashboardCard";
import moment from "moment";
import ProjectsViewsChart from "./Charts/ProjectsViewsChart";
import { projectsSelector } from 'modules/project/projectSelectors';
import FeatureRequestCard from './FeatureRequestCard';
import { pageLoadingSelector } from 'modules/pageLoader/pageLoaderSelectors';
import find from 'lodash/find';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';


export default function DashboardPage () {
    /** Get Redux Data */
    const { user } = useSelector(userSelector);
    const { projects } = useSelector(projectsSelector);

    /** Prevents the user getting a flash of the interactr dashboard before the user is loaded in */
    if(! user) return null;

    /** Dashboard page is not accessible for sub users */
    if(user.parent_user_id){
        window.location.href = '/projects';
    }

    return(
      <PageBody heading={`Welcome Back, ${user.name}`} >
          <div style={{marginLeft: '20px'}} className="mb-2">
              <div className="grid">
                  <DashboardCard
                    bgColor="green"
                    icon="rocket-launch"
                    heading="Quick Start"
                    text="Watch our quick start training video."
                    href="https://support.videosuite.io/article/23-quick-start-an-introduction-to-interactr-video"
                  />
                  <DashboardCard
                    bgColor="blue"
                    icon="book"
                    heading="User Guide"
                    text="Get more in depth help in our User Guide"
                    href="https://support.videosuite.io/"
                  />
                  <DashboardCard
                    bgColor="green"
                    icon="user-graduate"
                    heading="Training Videos"
                    text="Watch our training videos"
                    to="/training"
                  />
                  <DashboardCard
                    bgColor="blue"
                    icon="question-circle"
                    heading="Need Help?"
                    text="Submit a new request here"
                    href="https://support.videosuite.io/"
                  />
              </div>

              <div className="mt-2 mb-2">
                  <DashboardChart projects={projects} />
                  <LegacyProjectsNotification projects={projects} />
              </div>

              <FeatureRequestCard />
          </div>
      </PageBody>
    )
}

function DashboardChart({projects}){
    /** Init Local State */
    const [startDate, setStateDate] = useState(moment().subtract(365, 'day'));
    const [endDate, setEndDate] = useState(moment());

    /** Redux Data */
    const { pageLoading } = useSelector(pageLoadingSelector);
    if(pageLoading) {
        return <EmptyChart><Icon icon="spinner-third" spin={true} /> <br/> Loading...</EmptyChart>
    }

    if(! isEmpty(projects)) {
        return <ProjectsViewsChart projects={projects} startDate={startDate} endDate={endDate} />;
    }

    return <EmptyChart><Icon icon="project-diagram" /> <br/>  Not Enough Data For Project Views Chart</EmptyChart>;
}

/** If we have legacy projects the charts wont show impressions so need to show user message */
function LegacyProjectsNotification({projects}){
    const hasLegacyProject = ( find(projects, {legacy: 1}) );

    if(hasLegacyProject) {
        return(
          <div className="mt-2 mb-2 pl-1 pr-1">
              <p style={{marginTop: 0}}><em><small>* Legacy projects don't have an impressions stat so this will be 0 for all legacy projects.</small></em></p>
          </div>
        );
    }

    return null;
}

/** Util component for showing chart status (loading, no projects etc) */
function EmptyChart({children}){
    return(
      <div className="grid">
          <div className="col12">
              <div style={{border: '1px solid #ccc', height: '540px', width: '100%', textAlign: 'center', borderRadius: '20px'}}>
                  <h2 style={{ marginTop: '235px', lineHeight: '40px'}}>{children}</h2>
              </div>
          </div>
      </div>
    );
}
