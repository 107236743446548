import pickBy from 'lodash/pickBy';
import reduce from 'lodash/reduce';
import mapKeys from 'lodash/mapKeys';

export const HOTSPOT_ELEMENT = 'App\\HotspotElement';
export const BUTTON_ELEMENT = 'App\\ButtonElement';
export const TEXT_ELEMENT = 'App\\TextElement';
export const QUESTION_ELEMENT = 'App\\QuestionElement';
export const CUSTOM_HTML_ELEMENT = 'App\\CustomHtmlElement';
export const IMAGE_ELEMENT = 'App\\ImageElement';
export const TRIGGER_ELEMENT = 'App\\TriggerElement';
export const FORM_ELEMENT = 'App\\FormElement';
export const COUNTDOWN_ELEMENT = 'App\\CountdownElement';
export const DYNAMIC_TEXT_ELEMENT = 'App\\DynamicTextElement';

export const clickableElementTypes = [BUTTON_ELEMENT, IMAGE_ELEMENT, HOTSPOT_ELEMENT];

// types of elements used in the app
export const elements = {
  [HOTSPOT_ELEMENT]: {
    // name shown to user
    name: 'Hotspot',
    icon: 'hotspot.png',
    description: 'Make an area of the video clickable whilst adding no visual styles'
  },

  [BUTTON_ELEMENT]: {
    name: 'Button',
    icon: 'button.png',
    styles: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
    description: 'Add a clickable button to the video'
  },

  [TEXT_ELEMENT]: {
    name: 'Text',
    icon: 'text.png',
    styles: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    description: 'Add plain text to the video'
  },

  [DYNAMIC_TEXT_ELEMENT]: {
    name: 'Dynamic Text',
    icon: 'dynamic-text.png',
    styles: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    description: `Add text to the video that can dynamically pull values from the URL`
  },
  [FORM_ELEMENT]: {
    name: 'Email',
    icon: 'email.png',
    description: 'Capture users emails with a form element',
    buttonHeight: 40,
    buttonPadding: 15,
    inputStyles: {
      height: 40,
      flex: 1
    },
    elementSpacing: 10
  },

  // [QUESTION_ELEMENT]: {
  //   name: 'Question',
  //   icon: 'question-circle-o',
  //   description:
  //     'Question elements allow you to add multiple choice questions to your video.'
  // },

  [CUSTOM_HTML_ELEMENT]: {
    name: 'Custom HTML',
    icon: 'html.png',
    styles: {
      overflow: 'hiddenx'
    },
    description: 'Add html markup to the video .'
  },

  [IMAGE_ELEMENT]: {
    name: 'Image',
    icon: 'image.png',
    description: 'Add an image to the video'
  },

  // [COUNTDOWN_ELEMENT]: {
  //   name: 'Image',
  //   icon: 'image',
  //   description: 'Image elements allow you to add an image to the video.'
  // },

  [TRIGGER_ELEMENT]: {
    name: 'Popup',
    icon: 'popup-trigger.png',
    description: 'Add a modal to your video'
  }
};

export function getElementMeta(element_type) {
  const meta = elements[element_type];
  if (!meta) throw new Error('invalid element_type ' + element_type);
  return meta;
}

export function getStyles(element_type, { borderRadius, letter_spacing, ...element } = {}) {
  const meta = getElementMeta(element_type);
  const baseStyles = meta.styles || {};

  // we use the whole element as the style prop. This is slightly hacky but works for now.
  // we could have a whitelist of style props.

  // convert borderRadius to percentage
  // borderRadius = (borderRadius  / 2)+ '%';

  // Add PX to letter spacing
  //const letterSpacing = letter_spacing + 'px';

  return { ...baseStyles, ...element, borderRadius };
}

export function someElements(...element_types) {
  return pickBy(elements, (element, element_type) => element_types.includes(element_type));
}

// used for pulling out subprops i.e. for prefix 'button':
// {button_html, button_blah, something} => {html, blah}
export function getPrefixedProps(props, prefix) {
  return reduce(
    props,
    (subProps, val, key) => {
      if (key.startsWith(prefix)) {
        subProps[key.substr(prefix.length)] = val;
      }
      return subProps;
    },
    {}
  );
}

export function prefixProps(props, prefix) {
  return mapKeys(props, (val, key) => prefix + key);
}

/** Formats the element type name to readable one  */
export function formatElementTypeName(typeName) {
  typeName = typeof typeName == 'string' ? typeName.replace(/(app\\)|(element)/gi, '') : 'Element';

  // Handle Edge cases 
  if (typeName === 'Trigger') typeName = 'Modal';
  if (typeName === 'CustomHtml') typeName = 'Custom Html';

  return typeName;
}
