import React from 'react';
// import cx from 'classnames';
import styles from './AuthPage.scss';
// import { supportLink, docsLink } from 'utils/externalLinks';
import AppLogo from 'components/AppLogo';
import Icon from 'components/Icon';
import { error } from 'utils/alert';
import { isBrowserSupported } from 'utils/domUtils';
// import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import LinkButton from 'components/Buttons/LinkButton';

export default class AuthPage extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    isBrowserSupported() ||
      error({ text: `Your browser is not supported , we recommend latest chrome for better experience` });
    window.localStorage.setItem('previousPage', 'login');
  }

  render() {
    const { children, tip, heading, whitelabel } = this.props;

    const leftWhitelabelStyles = {};

    if (whitelabel) {
      leftWhitelabelStyles.background = whitelabel.background_colour;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.left} style={leftWhitelabelStyles}>
          <div className={styles.logo}>
            <AppLogo />
          </div>
          <div className={styles.box}>
            <div className={styles.boxForm}>
              {heading && <h1>{heading}</h1>}
              {/* {tip} */}
              {children}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          {whitelabel === false && (
            <div style={{ position: 'absolute', top: '20px', right: '15px' }}>
              <LinkButton primary large href="https://support.videosuite.io/" target="_blank">
                <Icon name="life-ring" /> Support
              </LinkButton>
            </div>
          )}
          &nbsp;
        </div>
      </div>
    );
  }
}
