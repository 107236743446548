import {call, put, takeLatest} from 'redux-saga/effects';
import {UPDATE_MEDIA_ITEM, receiveMediaItem} from 'modules/media/media';
import fetch from 'utils/saga/fetch';
import swal from 'sweetalert2';

export default function* updateMediaSaga() {
  yield takeLatest(UPDATE_MEDIA_ITEM, updateMedia);
}

function* updateMedia({id, item, shouldUpdateDB, callback}) {
  /* This action is triggered to update DB and also to update the store (only)
  in use cases like updating thumnail triggered by Pusher , hence wanting to only
  update the store without updating the DB since it already is 
  saved before being triggered by Pusher */
  if (!shouldUpdateDB) {
    return;
  }

  const res = yield fetch('media/'+id, {
    method: 'PUT',
    body: {
      item
    }
  });

  if (!res.ok) {
    swal({
    title: 'Error',
      text: 'There was a problem updating this media item.',
      type: 'warning',
      confirmButtonColor: '#ff6961',
      confirmButtonText: 'Got it!',
    });
    return ;
  }

  const media = yield res.json();
  if(media) {
    yield put(receiveMediaItem(media, media.project_id))
    if(callback) callback();
  }

}
