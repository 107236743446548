import {put, select, takeEvery} from 'redux-saga/effects';
import {ADD_ELEMENT_GROUP, receiveElementGroup} from 'modules/node/node';
import {nodesStateSelector} from 'modules/node/nodeSelectors';
import fetch from 'utils/saga/fetch';
import { error } from 'utils/alert'
import { updatePageLoadingState } from 'modules/pageLoader';

export default function* addElementGroupSaga() {
    yield takeEvery(ADD_ELEMENT_GROUP, addElementGroup);
}

function* addElementGroup({nodeId, name, callback}) {
    yield put(updatePageLoadingState(true));
    const state = yield select(nodesStateSelector);
    const timeOut = state.get('duration');

    const res = yield fetch(`nodes/${nodeId}/elementGroup`, {
        method: 'POST',
        body: {name, timeOut, timeIn: 0}
    });

    const data = yield res.json();

    if (!data.success) {
        error({text: 'Error creating new element group.'});
        yield put(updatePageLoadingState(false));
        return;
    }

    yield put(updatePageLoadingState(false));
    yield put(receiveElementGroup(data.elementGroup));
    if(callback) callback()
}
