import React from "react";
import LinkButton from "components/Buttons/LinkButton";
import { addProjectPath } from "routeBuilders";

export default function AddProjectButton({ children }) {
  return (
    <LinkButton
      to={addProjectPath()}
      primary
      icon={'plus'}
      right
    >
      {children || "New Project"}
      </LinkButton>
  );
}
