import {nodeAndParents} from 'utils/domUtils';

// TODO: should this be moved to src/utils?

// Creates a util for reading and writing node drag data
const dragData = (dragType, requiredProps = []) => ({
  // Adds props & dragType to node dataset
  set(node, props) {
    requiredProps.forEach(key => {
      const prop = props[key];
      if (typeof prop == 'undefined' && __DEV__) {
        console.warn(`Missing prop ${key}`);
      }
      node.dataset[key] = prop || '';
    });

    node.dataset.dragType = dragType;
  },

  // Gets drag props from node
  get(node) {
    if (node.dataset.dragType !== dragType) {
      throw new Error('Incorrect dragType');
    }

    return [...requiredProps, 'dragType'].reduce((data, key) => {
      const value = node.dataset[key];
      if (typeof value === 'undefined') {
        throw new Error(`Node is missing required data prop ${key}`);
      }
      return {...data, [key]: value};
    }, {});
  }
});

// dragTypes
export const CONNECTOR = 'CONNECTOR';
export const NODE = 'NODE';

export const DRAG_DATA_HELPERS = {
  [CONNECTOR]: dragData(CONNECTOR, ['nodeId', 'element_type', 'id']),
  [NODE]: dragData(NODE, ['id'])
};

export function findDraggableNode(node) {
  return nodeAndParents(node).find(n => n.dataset.dragType);
}

export function getDragHelper(draggableNode) {
  if (!draggableNode) {
    return null;
  }

  return DRAG_DATA_HELPERS[draggableNode.dataset.dragType];
}

export function getDragData(draggableNode) {
  return getDragHelper(draggableNode).get(draggableNode);
}

export function findDragData(node) {
  const draggableNode = findDraggableNode(node);
  return draggableNode && getDragData(draggableNode);
}

// looks through node and parents for a drag type
export function findDragType(node) {
  const data = findDragData(node);
  return data && data.dragType;
}

export const dragDataSetter = dragType => {
  const helper = DRAG_DATA_HELPERS[dragType];
  if (!helper) throw new Error(`invalid dragType ${dragType}`);

  return function(node) {
    if (!node) return;
    helper.set(node, this.props);
  };
};

// Gets info on drop event relative to the specified domId
// i.e. returns relative position if the domId was the drop target/an ancestor
// else returns false
export function getRelativeDrop(domId, e) {
  // Make sure it's a drop on the composer
  const matchingNode = nodeAndParents(e.target).find(el => el.id === domId);

  if (!matchingNode) {
    return false;
  }

  const {clientX, clientY} = e;
  const {left, top} = matchingNode.getBoundingClientRect();

  // relative to node
  const x = clientX - left;
  const y = clientY - top;

  return {x, y};
}
