import React, {Component} from 'react';
import {connect} from 'react-redux';
import Rodal from "rodal";
import Icon from "components/Icon";
import {updateProjectGroup} from 'modules/project/project';
import {projectFoldersSelector} from 'modules/project/projectSelectors';
import Button from 'components/Buttons/Button';
import {success, error} from 'utils/alert';
import {Option, TextInput} from 'components/PropertyEditor';
import styles from './FoldersManagementModel.scss';
import AddProjectGroupModal from './AddProjectGroupModal';
import FoldersList from './FoldersList';

@connect(projectFoldersSelector, {updateProjectGroup})
export default class FoldersManagementModal extends Component {
    state = {
        folders: {},
        addProjectGroupModal: false
    };

    clearAndResetFields = () => {
        this.props.handleFoldersManagementModal(false);
    };

    handleAddProjectGroupModal = value => {
        this.setState({addProjectGroupModal: value});
    };

    closeModal = () => {
        this.props.handleFoldersManagementModal(false);
    };

    emptyFolders = () => {
        return !this.props.projectGroups.length;
    };

    renderModalBody = () => {
        return (
            <div className="modal-body">
                <div className="grid">
                    <div className="col12" style={{paddingTop: '15px'}}>
                        <p style={{color: '#113348', fontWeight: '600', fontSize: '20px', display: 'block', float :'left', marginTop: 0}}>Your Folders</p>
                        <Button primary onClick={() => this.handleAddProjectGroupModal(true)} style={{marginLeft: '10px'}} small>
                           <Icon icon="plus" /> Add New
                        </Button>
                    </div>
                </div>
                {this.emptyFolders()
                    ?   <div className="grid">
                        <div className="col12">
                            <p>You haven't created any folders yet. Click <strong>Add New</strong> to create a new folder so you can organise your interactive videos.</p>
                        </div>
                    </div>
                    :   <div className={styles.foldersRow}>
                            <p style={{marginTop: 0}}><small>Click and drag a folder re order them or click on a folder name to edit the name.</small></p>

                            <FoldersList folders={this.props.projectGroups} />
                        </div>
                }
            </div>
        );
    };

    render() {
        return (
            <div>
                <Rodal
                    visible={this.props.foldersManagementModal}
                    onClose={this.clearAndResetFields}
                    customStyles={{overflow: 'inherit'}}
                    height={420}
                    width={460}
                >
                    <div className="modal-heading">
                        <Icon name="plus" /> Manage Folders
                    </div>

                    {/* Modal Body */}
                    {this.renderModalBody()}

                    <div className="modal-footer">
                        <Button onClick={this.closeModal}>
                           <Icon icon="times" /> Cancel
                        </Button>
                    </div>
                </Rodal>

                <AddProjectGroupModal
                    addProjectGroupModal={this.state.addProjectGroupModal}
                    handleAddProjectGroupModal={this.handleAddProjectGroupModal}
                />
            </div>
        );
    }
}