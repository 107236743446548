import {takeEvery, put, select} from 'redux-saga/effects';
import {ADD_CHAPTER, receiveNode} from 'modules/node/node';
import {nodeSelector} from 'modules/node/nodeSelectors';
import fetch from 'utils/saga/fetch';

export default function* addChapterSaga() {
  yield takeEvery(ADD_CHAPTER, addChapter);
}

function* addChapter({nodeId, chapter}) {
  const res = yield fetch(`nodes/${nodeId}/chapters`, {
    method: 'PUT',
    body: chapter
  });

  const chapters = yield res.json();
  // update node
  let {node} = yield select(nodeSelector, {nodeId});
  node.chapters = chapters;
  yield put(receiveNode(node));
}
