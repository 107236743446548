import React from 'react';
import {connect} from 'react-redux';
import Button from 'components/Buttons/Button';
import SimpleForm from 'components/SimpleForm';
import styles from './Integration.scss';
import {saveIntegration} from 'modules/integration/integration';
import {integrationSelector} from 'modules/integration/integrationSelectors';
import {toastr} from 'react-redux-toastr';
import { cloneableGenerator } from '../../../../node_modules/redux-saga/utils';

@connect(integrationSelector, {saveIntegration})
export default class Integration extends React.Component {
  constructor(props) {
    // console.log('props integration 7777777:', props)
    super(props);
    this.state = {showButton: props.showButton || false, errors:{}};
  }

  handleSubmit = (e, data) => {
    e.preventDefault();
    const errors = this.validate(data);
    const errorsKeys = Object.keys(errors);

    if(errorsKeys.length) {
      toastr.error('Error', errors[errorsKeys[0]]);
      return;      
    }

    const {saveIntegration, integrationType} = this.props;
    saveIntegration(integrationType, data);
  };

  generateForm = () => {
    const {fields, data} = this.props;
    return (
      <SimpleForm
        className={styles.form}
        fields={fields}
        data={data}
        onSubmit={this.handleSubmit}
      />
    );
  };

  validate(data) {
    const errors = {}
    const fields = Object.getOwnPropertyNames(data);
    fields.forEach(field => {
      if(!data[field]) errors[field]= `${field} field is required`;
    })
    return errors;
  }

  buttonClick = () => {
    const {buttonClickURL} = this.props;
    buttonClickURL && window.open(buttonClickURL,'_blank');

    this.setState({showButton: false});
  };

  generateButton = () => {
    const {buttonText, data} = this.props;
    let isAuthed = false;
    if (data && Object.keys(data).length) {
      isAuthed = true;
    }

    return (
      <span style={{marginLeft: '30px'}}>
        {isAuthed && <p>You are already authenticated with AWeber</p>}
        <Button onClick={this.buttonClick}>{buttonText}</Button>
      </span>
    );
  };

  render() {
    const {name, imageSrc, videoId,helpText} = this.props;
    const {showButton} = this.state;

    return (
      <div className={styles.Integration}>
        <div className="grid">
          <div className="col6">
            <img src={imageSrc} className={styles.image} />
            <h2 className="form-heading" style={{marginBottom: '30px', marginLeft: '50px', marginTop: '-7px'}}>
              {name}
            </h2>
            <div className={styles.content}>
              {showButton && this.generateButton()}
              {!showButton && this.generateForm()}
            </div>
          </div>
          <div className="col6">
            <h4 className="faded-heading" style={{marginBottom: '10px'}}>Help</h4>
            {helpText}
          </div>
        </div>
      </div>
    );
  }
}
