import React from 'react';
import Draggable from 'react-draggable';
import {Resizable} from 're-resizable';
import ReactDOM from 'react-dom';
import isNumber from 'lodash/isNumber';

export default class DraggableResizable extends React.Component {
  state = {
    resizing: false,
    lockRatio: false
  };

  static defaultProps = {
    bounds: 'parent'
  };

  getResizeEnabled() {
    if (this.props.disableResize) {
      return false;
    }

    if (this.props.axis === 'x') {
      return {
        left: true,
        right: true
      };
    }
  }

  toggleKeyDown = (e)=>{
    if(e.shiftKey){
      this.setState({lockRatio:  true});
    }
  };

  toggleKeyUp = (e)=>{
    this.setState({lockRatio: false});
  };

  componentDidMount(){
    document.addEventListener('keydown', this.toggleKeyDown);

    document.addEventListener('keyup', this.toggleKeyUp);
  }

  componentWillUnmount(){
    document.removeEventListener('keydown', this.toggleKeyDown);
    document.removeEventListener('keyup', this.toggleKeyUp);
  }

  componentDidUpdate() {
    if (this.draggableRef) {
      this.setZIndex();
    }
  }

  setZIndex = () => {
    const draggableElem = ReactDOM.findDOMNode(this.draggableRef);
    if (draggableElem) {
      let zIndex = parseInt(this.props.zIndex);
      if (isNumber(zIndex) && !isNaN(zIndex)) {
        draggableElem.style.zIndex = this.props.zIndex;
      }
    }
  };



  handleResizeStart = e => {
    this.setState({resizing: true});

    // we have to store this, in case
    // props.size changes while mid resize
    // re-resizable calculates delta
    // from the size when resize began
    this.resizeStartSize = this.props.size;
  };

  handleResize = (e, dragHandleName, target, sizeDelta) => {
    const {pos: {x, y}, size: {w, h}, onChange} = this.props;

    const parentBounding = target.parentElement.getBoundingClientRect();
    dragHandleName = dragHandleName.toLowerCase();

    let newPos = {x, y};

    // re-resizable calculates delta from when drag began
    // -- account for the fact that this is a "controlled" component
    // and props.size will have changed
    const widthChange = this.resizeStartSize.w - w + sizeDelta.width;
    const heightChange = this.resizeStartSize.h - h + sizeDelta.height;

    let newSize = {w: w + widthChange, h: h + heightChange};

    // If we're dragging from top/left, we need to adjust position as well.
    if (dragHandleName.includes('left')) {
      newPos.x -= widthChange;

      if (newPos.x < 0) {
        newSize.w += newPos.x;
        newPos.x = 0;
        this.resizeable.updateSize({width: newSize.w, height: h, x: 0, y: y});
      }
    }

    if (dragHandleName.includes('top')) {
      newPos.y -= heightChange;

      if (newPos.y < 0) {
        newSize.h += newPos.y;
        newPos.y = 0;
        this.resizeable.updateSize({width: w, height: newSize.h, x: x, y: 0});
      }
    }


    if (dragHandleName.includes('bottom')) {
      if ((newPos.y + newSize.h) > parentBounding.height) {
        newSize.h -= (newPos.y + newSize.h) - parentBounding.height;
        this.resizeable.updateSize({width: w, height: newSize.h, x: x, y: y});
      }
    }

    if (dragHandleName.includes('right')) {
      if ((newPos.x + newSize.w) > parentBounding.width) {
        newSize.w -= (newPos.x + newSize.w) - parentBounding.width;
        this.resizeable.updateSize({width: newSize.w, height: h, x: x, y: y});
      }
    }

    // We have to call this on every resize
    // as pos may need updating
    onChange(newPos, newSize);
  };

  handleResizeStop = (_, __, ___, {width, height}) => {
    this.setState({resizing: false});
  };

  handleDragStart = e => {
    if (this.isDisabled()) return false;
  };

  handleDrag = e => {
    if (this.isDisabled()) return false;
  };

  handleDragStop = (e, {x, y}) => {
    if (this.isDisabled()) return false;

    this.props.onChange({x, y}, this.props.size);
  };

  isDisabled = () => this.state.resizing || this.props.disabled;

  render() {
    const {
      axis,
      bounds,
      pos,
      size: {w, h},
      children,
      className,
      disabled,
      disableResize,
      zIndex,
      ...props
    } = this.props;

    const {resizing, lockRatio} = this.state;


    return (
      <Draggable
        {...props}
        disabled={this.isDisabled()}
        enableUserSelectHack={false}
        axis={axis}
        bounds={bounds}
        position={pos}
        onStart={this.handleDragStart}
        onDrag={this.handleDrag}
        onStop={this.handleDragStop}
        ref={(me) => this.draggableRef = me}
      >
        <Resizable
          {...props}
          ref={(re) => this.resizeable = re}
          enable={this.getResizeEnabled()}
          // width={w}
          size={{width : w, height:h}}
          style={{position: 'absolute'}}
          // height={h}
          lockAspectRatio={lockRatio}
          className={className}
          onResizeStart={this.handleResizeStart}
          onResize={this.handleResize}
          onResizeStop={this.handleResizeStop}
        >
          {children}
        </Resizable>
      </Draggable>
    );
  }
}