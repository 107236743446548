import {takeLatest} from 'redux-saga/effects';
import {put} from 'redux-saga/effects';
import {FETCH_AGENCY, receiveAgency} from 'modules/agency/agency';
import {receiveUsers} from 'modules/user/user';
import fetch from 'utils/saga/fetch';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {error} from 'utils/alert';

export default function* fetchAgencySaga() {
  yield takeLatest(
    [FETCH_AGENCY], _fetchAgency
  );
}

function* _fetchAgency() {
  const res = yield fetch('agency/create');

  yield put(updatePageLoadingState(false));

  if (res.status === 500) {
    error({text:'Unable To Get Agency App'})
    return ;
  }

  const data = yield res.json();
  let errors = {} ;

  if (res.status === 403 ) {
    errors.unauthorized = data.message ;
    yield put(receiveAgency(false, errors));
  } else {
    
    yield put(receiveAgency(data));
  }

}
