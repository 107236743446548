import {fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';
import isString from 'lodash/isString';

const initialState = fromJS({
  projectId: null,
  projectsCount: 0,
  folderId: 0,
  loading: false,
  saving: false,
  publishing: false,
  unpublishing: false,
  data: {
    projects: {},
    projectGroups: {}
  }
});

function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case VIEW_PROJECTS_PAGE :
      return state.set('projectId', null)
      
    case VIEW_PROJECT_PAGE :
    case VIEW_PROJECT_SETTINGS_PAGE:
    case VIEW_PROJECT_STATS_PAGE:
      return state.set('projectId', action.projectId);

    case RECEIVE_PROJECTS:
        return state.setIn(['data', 'projects'], keyBy(action.projects))
            .set('projectsCount', action.projects.length);


    case RECEIVE_PROJECT:
      return state.setIn(
        ['data', 'projects', action.project.id.toString()],
        fromJS(action.project)
      );

    case RECEIVE_PROJECT_GROUP:
      return state.mergeIn(
          ['data', 'projectGroups', action.projectGroup.id.toString()],
          fromJS(action.projectGroup)
      );

    case RECEIVE_PROJECT_GROUPS:
        return state.setIn(['data', 'projectGroups'], keyBy(action.projectGroups));

    case RECEIVE_SEARCH_PROJECTS:
      return state.setIn(['data', 'projects'], keyBy(action.projects))
        .set('searching', true);


    case UPDATE_PROJECT:
      return state.mergeIn(
        ['data', 'projects', action.project.id.toString()],
        fromJS(action.project)
      );

    case UPDATE_SELECTED_FOLDER:
      return state.set('folderId', action.folderId);

    case UPDATE_PROJECT_THUMBNAIL:
      let projectId = action.projectId;
      if (!isString(projectId)) {
        projectId = projectId.toString();
      }

      return state.setIn(
        ['data', 'projects', projectId, 'image_url'],
        action.thumbnail
      );

    case CHANGE_START_NODE:
      return state.setIn(
        ['data', 'projects', action.projectId.toString(), 'start_node_id'],
        action.startNodeId
      );

    case PROJECT_DELETED:
      return state.deleteIn(['data', 'projects', action.projectId.toString()])
                  .set('projectsCount', action.projectsCount);

    case PROJECT_GROUP_DELETED:
        return state.deleteIn(['data', 'projectGroups', action.folderId.toString()]);

    case UPDATE_PROJECT_SAVING :
      return state.set('saving', action.saving);

    case UPDATE_PROJECT_PUBLISHING :
      return state.set('publishing', action.val);

    case UPDATE_PROJECT_UNPUBLISHING :
      return state.set('unpublishing', action.val);

    case RECEIVE_COMMENTS :
      return state.setIn(
        ['data', 'projects', action.projectId.toString(), 'comments'],
        fromJS(action.comments)
      );
  }


  return state;
}

export const reducers = {
  projects: projectsReducer
};

export const VIEW_PROJECTS_PAGE = 'project:VIEW_PROJECTS_PAGE';
export function viewProjectsPage() {
  return {
    type: VIEW_PROJECTS_PAGE,
  };
}

export const VIEW_PROJECT_PAGE = 'project:VIEW_PROJECT_PAGE';
export function viewProjectPage(projectId) {
  return {type: VIEW_PROJECT_PAGE, projectId};
}

export const VIEW_PROJECT_GROUPS = 'project:VIEW_PROJECT_GROUPS';
export function viewProjectGroups() {
    return {type: VIEW_PROJECT_GROUPS};
}

export const VIEW_PROJECT_SETTINGS_PAGE = 'project:VIEW_PROJECT_SETTINGS_PAGE';
export function viewProjectSettingsPage(projectId) {
  return {
    type: VIEW_PROJECT_SETTINGS_PAGE,
    projectId
  };
}

export const VIEW_PROJECT_STATS_PAGE = 'project:VIEW_PROJECT_STATS_PAGE';
export function viewProjectStatsPage(projectId) {
  return {
    type: VIEW_PROJECT_STATS_PAGE,
    projectId
  };
}

export const RECEIVE_PROJECTS = 'project:RECEIVE_PROJECTS';
export function receiveProjects(projects) {
  return {
    type: RECEIVE_PROJECTS,
    projects
  };
}

export const RECEIVE_PROJECT = 'project:RECEIVE_PROJECT';
export function receiveProject(project) {
  return {
    type: RECEIVE_PROJECT,
    project
  };
}

export const RECEIVE_PROJECT_GROUP = 'project:RECEIVE_PROJECT_GROUP';
export function receiveProjectGroup(projectGroup) {
    return {
        type: RECEIVE_PROJECT_GROUP,
        projectGroup
    };
}

export const RECEIVE_PROJECT_GROUPS = 'project:RECEIVE_PROJECT_GROUPS';
export function receiveProjectGroups(projectGroups) {
    return {
        type: RECEIVE_PROJECT_GROUPS,
        projectGroups
    };
}

export const CREATE_PROJECT = 'project:CREATE_PROJECT';
export function createProject(project, callback) {
  return {
    type: CREATE_PROJECT,
    project,
    redirectAfterSave: true,
    callback
  };
}

export const ADD_PROJECT_GROUP = 'project:ADD_PROJECT_GROUP';
export function addProjectGroup(title) {
    return {
      type: ADD_PROJECT_GROUP,
      title
    };
}

export const UPDATE_PROJECT_GROUP_SORTING = 'project:UPDATE_PROJECT_GROUP_SORTING';
export function updateProjectGroupSorting(folders) {
    return {
        type: UPDATE_PROJECT_GROUP_SORTING,
        folders
    }
}

export const UPDATE_PROJECT_GROUP = 'project:UPDATE_PROJECT_GROUP';
export function updateProjectGroup(folderId, title) {
    return {
        type: UPDATE_PROJECT_GROUP,
        folderId,
        title
    };
}

export const UPDATE_PROJECT = 'project:UPDATE_PROJECT';
export function updateProject(project, showLoading=true, callback = false) {
  return {
    type: UPDATE_PROJECT,
    project,
    showLoading,
    callback,
  };
}

export const SAVE_PROJECT = `project:SAVE_PROJECT`;
export function saveProject(project, showLoading= true, callback = false) {
    return {
        type: SAVE_PROJECT,
        project,
        showLoading,
        callback
    };
}

export const UPDATE_PROJECT_SAVING = 'project:UPDATE_PROJECT_SAVING';
export function updateProjectSaving(saving) {
  return {
    type: UPDATE_PROJECT_SAVING,
    saving
  };
}

export const UPDATE_PROJECT_PUBLISHING = 'project:UPDATE_PROJECT_PUBLISHING';
export function updateProjectPublishing(val) {
  return {
    type: UPDATE_PROJECT_PUBLISHING,
    val
  };
}

export const UPDATE_PROJECT_UNPUBLISHING = 'project:UPDATE_PROJECT_UNPUBLISHING';
export function updateProjectUnPublishing(val) {
  return {
    type: UPDATE_PROJECT_UNPUBLISHING,
    val
  };
}

export const CHANGE_START_NODE = 'project:CHANGE_START_NODE';
export function changeStartNode(projectId, startNodeId) {
  return {
    type: CHANGE_START_NODE,
    projectId,
    startNodeId
  };
}

export const PUBLISH_PROJECT = 'project:PUBLISH_PROJECT';
export function publishProject(projectId) {
  return {
    type: PUBLISH_PROJECT,
    projectId
  };
}

export const MARK_START_NODE = 'project:MARK_START_NODE';
export function markStartNode(projectId) {
  return {
    type: MARK_START_NODE,
    projectId
  };
}

export const UNPUBLISH_PROJECT = 'project:UNPUBLISH_PROJECT';
export function unpublishProject(projectId) {
  return {
    type: UNPUBLISH_PROJECT,
    projectId
  };
}

export const DELETE_PROJECT = 'project:DELETE_PROJECT';
export function deleteProject(projectId) {
  return {
    type: DELETE_PROJECT,
    projectId
  };
}

export const PROJECT_DELETED = 'project:PROJECT_DELETED';
export function projectDeleted(projectId, projectsCount) {
    return {
        type: PROJECT_DELETED,
        projectId,
        projectsCount
    };
}

export const DELETE_PROJECT_GROUP = 'project:DELETE_PROJECT_GROUP';
export function deleteProjectGroup(folderId) {
    return {
        type: DELETE_PROJECT_GROUP,
        folderId
    };
}

export const PROJECT_GROUP_DELETED = 'project:PROJECT_GROUP_DELETED';
export function projectGroupDeleted(folderId) {
    return {
        type: PROJECT_GROUP_DELETED,
        folderId
    };
}

export const GENERATE_PROJECT_THUMBNAIL = 'project:GENERATE_PROJECT_THUMBNAIL';
export function generateProjectThumbnail(projectId, time, url, onComplete) {
  return {
    type: GENERATE_PROJECT_THUMBNAIL,
    projectId,
    time,
    url,
    onComplete
  };
}

export const REGENERATE_PROJECT_THUMBNAILS = 'project:REGENERATE_PROJECT_THUMBNAILS';
export function regenerateProjectThumbnails(projectId, callback) {
  return {
    type: REGENERATE_PROJECT_THUMBNAILS,
    projectId,
    callback
  }
}

export const UPDATE_PROJECT_THUMBNAIL = 'media:UPDATE_PROJECT_THUMBNAIL';
export function updateProjectThumbnail(projectId, thumbnail) {
  return {
    type: UPDATE_PROJECT_THUMBNAIL,
    projectId,
    thumbnail
  }
}

export const UPDATE_SELECTED_FOLDER = 'project:UPDATE_SELECTED_FOLDER';
export function updateSelectedFolder(folderId) {
    return {
        type: UPDATE_SELECTED_FOLDER,
        folderId
    }
}

export const COPY_PROJECT = 'project:COPY_PROJECT';
export function copyProject(projectId) {
  return {
    type: COPY_PROJECT,
    projectId
  };
}

export const DELETE_COMMENT = 'project:DELETE_COMMENT';
export function deleteComment(commentId) {
  return {
    type: DELETE_COMMENT,
    commentId
  };
}

export const RECEIVE_COMMENTS = 'project:RECEIVE_COMMENTS';
export function receiveComments(data){
  return {
    type: RECEIVE_COMMENTS,
    comments: data.comments,
    projectId: data.projectId
  }
}

export const SEARCH_PROJECTS = 'project:SEARCH_PROJECTS';
export function searchProjects(term){
    return {
        type: SEARCH_PROJECTS,
        term
    }
}

export const RECEIVE_SEARCH_PROJECTS = 'project:RECEIVE_SEARCH_PROJECTS';
export function receiveSearchProjects(projects){
    return {
        type: RECEIVE_SEARCH_PROJECTS,
        projects
    }
}
