import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export default function TimeInput({ value, name, onChange }) {
  const [_value, _setValue] = useState(value);
  const debouncedUpdate = debounce(onChange, 800);
  
  useEffect(() => {
    if (value !== _value) _setValue(value);
  }, [value]);

  const handleChange = (e) => {
    e.preventDefault();

    let eventValue = e.target.value;

    const colonOccurences = (eventValue.match(/:/g) || []).length;
    if (colonOccurences !== 2) {
      return;
    }

    const [m, s, ms] = eventValue.split(':').map((str) => parseInt(str, 10));
    const [mString, sString, msString] = eventValue.split(':');
    if (m < 0 || m > 60) {
      return;
    }

    if (s < 0 || s > 60) {
      return;
    }

    if (mString.length > 2 || sString.length > 2 || (msString && msString.length > 3)) {
      return;
    }

    _setValue(eventValue);
    debouncedUpdate(eventValue);
  };

  return <input name={name} type="text" value={_value} onChange={handleChange} />;
}
