import {takeEvery,put } from 'redux-saga/effects';
import {DELETE_CUSTOM_LIST , deletedCustomList} from 'modules/customLists/customList'
import fetch from 'utils/saga/fetch'
import { error , success} from 'utils/alert'
import { updatePageLoadingState } from 'modules/pageLoader';


export default function* deleteCustomList(){
    yield takeEvery([DELETE_CUSTOM_LIST], _deleteCustomList)
}

function* _deleteCustomList({customListId}){
    const res = yield fetch('customlists', {
        method: 'DELETE',
        body: {customListId}
    });

    const data = yield res.json();

    if (!data.success) {
        error({text: data.message});
        return;
    }

    yield put(deletedCustomList(customListId));
}