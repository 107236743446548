import { put,  takeLatest } from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import { error, success } from 'utils/alert';
import { VERIFY_DOMAIN, receiveAgency } from '../agency';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';

export default function* verifyAgencyDomain() {
  yield takeLatest([VERIFY_DOMAIN], verifyDomain);
}

function* verifyDomain({ domain }) {
  yield put(updatePageLoadingState(true));

  const url = 'domains/verify';
  const res = yield fetch(url, {
    method: 'post',
    body: { domain }
  });

  yield put(updatePageLoadingState(false));

  if (res.status !== 200) {
    const {message} = yield res.json()
    error({ text: message });
    return;
  }

  success({ text: 'Domain name is valid!' });
  const { agency } = yield res.json();
  yield put(receiveAgency(agency));
}
