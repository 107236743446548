import {createSelector} from 'reselect';
import {authUserSelector} from 'modules/auth/authSelectors';
import {toJS} from 'utils/immutableUtils';



export const UPDATE_ACCOUNT_DETAILS = 'auth:UPDATE_ACCOUNT_DETAILS';
export function updateAccountDetails(userId, newFieldsAndValues, isCurrentUser) {
  return {
    type: UPDATE_ACCOUNT_DETAILS,
    userId,
    newFieldsAndValues,
    isCurrentUser,
  };
}
export const accountDetailsSelector = createSelector(
    authUserSelector,
  (user) => ({
      user: toJS(user)
  })
);
