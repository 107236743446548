import { fromJS } from 'immutable';
import { keyBy } from 'utils/immutableUtils';
import toString from 'lodash/toString';
import { SELECT_INTERACTION } from 'modules/interaction';
import { MODAL_ELEMENT_SELECTED, DESELECT_ELEMENT } from 'modules/element';

const initialState = fromJS({ modals: {}, selected: null, elementSelected: null });

function modalReducer(state = initialState, action) {
  if (action.modalId) {
    action.modalId = action.modalId.toString();
  }

  switch (action.type) {
    case SET_SELECTED_MODAL:
      if (!action.modalId) return state.set('selected', null);
      let selectedModal = state.getIn(['modals', action.modalId]);
      return state.set('selected', selectedModal);

    case RECEIVE_MODALS:
      return state.set('modals', keyBy(action.modals));

    case UPDATE_MODAL:
      return state.mergeIn(['selected'], action.data).mergeIn(['modals', action.modalId], action.data);

    case RECEIVE_MODAL:
      return state
        .setIn(['modals', toString(action.modal.id)], fromJS(action.modal))
        .set('selected', fromJS(action.modal));

    case DELETE_MODAL_ELEMENT:
      return state
          .updateIn(['modals', action.modalId, 'elements'], elements =>
              elements.filter(element => element.get('id') != action.id))
          .updateIn(['selected', 'elements'], elements => elements.filter(e => {
            if(e.id){
              return (e.id !==  parseInt(action.id));
            }else {
              const obj = e.toJS();
              return (obj.id !==  parseInt(action.id));
            }
          }));

    case RECEIVE_MODAL_ELEMENT:
      return state
        .updateIn(['modals', action.modalId, 'elements'], elements => elements.push(fromJS(action.modalElement)))
        .updateIn(['selected', 'elements'], elements => elements.push(action.modalElement))
        .set('elementSelected', {
          element_type: action.modalElement.element_type,
          id: action.modalElement.element_id
        });

    case MODAL_ELEMENT_SELECTED:
      return state.set('elementSelected', {
        element_type: action.element_type,
        id: action.id
      });
    case DESELECT_ELEMENT:
      return state.set('elementSelected', null);

    case MODAL_DELETED:
      return state.deleteIn(['modals', action.modalId.toString()]).set('selected', null);
  }
  return state;
}

export const reducers = {
  modals: modalReducer
};

export const FETCH_MODAL = 'modal:FETCH_MODAL';
export function fetchModal(modalId) {
  return {
    type: FETCH_MODAL,
    modalId
  };
}

export const ADD_MODAL_ELEMENT = 'modal:ADD_MODAL_ELEMENT';
export function addModalElement(modalId, element_type, pos) {
  return {
    type: ADD_MODAL_ELEMENT,
    modalId,
    element_type,
    pos
  };
}

// TODO: implement saga
export const DELETE_MODAL_ELEMENT = 'modal:DELETE_MODAL_ELEMENT';
export function deleteModalElement(modalId, id) {
  return {
    type: DELETE_MODAL_ELEMENT,
    modalId,
    id
  };
}

export const DUPLICATE_MODAL_ELEMENT = 'modal:DUPLICATE_MODAL_ELEMENT';
export function duplicateModalElement(id) {
  return {
    type: DUPLICATE_MODAL_ELEMENT,
    id
  };
}

export const RECEIVE_MODAL_ELEMENT = 'modal:RECEIVE_MODAL_ELEMENT';
export function receiveModalElement(modalElement) {
  return {
    type: RECEIVE_MODAL_ELEMENT,
    modalId: modalElement.modal_id,
    modalElement
  };
}

export const VIEW_MODAL_PAGE = 'modal:VIEW_MODAL_PAGE';
export function viewModalPage(projectId) {
  return {
    type: VIEW_MODAL_PAGE,
    projectId
  };
}

export const UPDATE_MODAL = 'modal:UPDATE_MODAL';
export function updateModal(modalId, data) {
  return {
    type: UPDATE_MODAL,
    modalId,
    data
  };
}

export const SAVE_MODAL = 'modal:SAVE_MODAL';
export function saveModal(modalId, data) {
  return {
    type: SAVE_MODAL,
    modalId,
    data
  };
}

export const ADD_MODAL_TO_ELEMENT = 'modal:ADD_MODAL_TO_ELEMENT';
export function addModalToElement(projectId, nodeId, element_type, element_id, actionKey = 'action') {
  return {
    type: ADD_MODAL_TO_ELEMENT,
    projectId,
    nodeId,
    element_type,
    element_id,
    actionKey
  };
}

export const RECEIVE_MODAL = 'modal:RECEIVE_MODAL';
export function receiveModal(modal) {
  return {
    type: RECEIVE_MODAL,
    modal
  };
}

export const RECEIVE_MODALS = 'modal:RECEIVE_MODALS';
export function receiveModals(modals) {
  return {
    type: RECEIVE_MODALS,
    modals
  };
}
export const APPLY_MODAL_TEMPLATE = 'modal:APPLY_MODAL_TEMPLATE';
/** Applies template modal to modal if modal id provided and creates a new one if not,
 *  projectIid is required if modal id is not provided  */
export function applyModalTemplate(templateId, { modalId, projectId }, callback) {
  return {
    type: APPLY_MODAL_TEMPLATE,
    modalId,
    templateId,
    projectId,
    callback
  };
}

export const COPY_MODAL = 'modal:COPY_MODAL';
export function copyModal(modalId) {
  return {
    type: COPY_MODAL,
    modalId
  };
}

export const DELETE_MODAL = 'modal:DELETE_MODAL';
export function deleteModal(modalId) {
  return {
    type: DELETE_MODAL,
    modalId
  };
}

export const MODAL_DELETED = 'modal:MODAL_DELETED';
export function modalDeleted(modalId) {
  return {
    type: MODAL_DELETED,
    modalId
  };
}
export const SET_SELECTED_MODAL = 'modal:SET_SELECTED_MODAL';
export function setSelectedModal(modalId) {
  return {
    type: SET_SELECTED_MODAL,
    modalId
  };
}
