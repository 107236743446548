import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {REGISTER, registrationFailed} from 'modules/registration/registration';
// TODO: bit weird crossing module boundary
import handleAuthenticate from 'modules/auth/sagas/utils/handleAuthenticate';

export default function* registerSaga() {
  yield takeLatest(REGISTER, register);
}

function* register({user}) {
  const res = yield fetch('register', {
    method: 'POST',
    body: user
  });

  if (res.ok) {
    return yield handleAuthenticate(res);
  }

  // handle failure
  const {message} = yield res.json();
  yield put(registrationFailed(message));
}
