import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {PUBLISH_PROJECT, UNPUBLISH_PROJECT, receiveProject, updateProjectPublishing, updateProjectUnPublishing} from 'modules/project/project';
import {error} from 'utils/alert';

export default function* publishProjectSaga() {
  yield takeLatest([PUBLISH_PROJECT, UNPUBLISH_PROJECT], publishProject);
}

function* publishProject({projectId, type: actionType}) {
  const endpoint = actionType === PUBLISH_PROJECT ? 'publish' : 'unpublish';

  if (endpoint === 'publish') {
    yield put(updateProjectPublishing(true));
  } else {
    yield put(updateProjectUnPublishing(true));
  }


  const res = yield fetch(`projects/${projectId}/${endpoint}`, {
    method: 'POST'
  });
  const project = yield res.json();

  if (project.error) {
    yield put(updateProjectPublishing(false));
    error({
      text: project.errorMessage,
      title: project.errorTitle
    });
    return;
  }

  yield put(receiveProject(project));

  if (endpoint === 'publish') {
    yield put(updateProjectPublishing(false));
  } else {
    yield put(updateProjectUnPublishing(false));
  }
}
