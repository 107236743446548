import {takeEvery} from 'redux-saga/effects';
import {DELETE_MODAL_ELEMENT} from 'modules/modal/modal';
import fetch from 'utils/saga/fetch';

export default function* deleteModalElementSaga() {
  yield takeEvery(DELETE_MODAL_ELEMENT, deleteModalElement);
}

function* deleteModalElement({id}) {
  const res = yield fetch(`modals/elements/${id}`, {
    method: 'DELETE'
  });
}
