import {createSelector} from 'reselect';
import {toJS} from 'utils/immutableUtils';
import {subUserSelector} from 'modules/integration/integrationSelectors';

const elementsStateSelector = state => state.elements;

export const elementsSelector = createSelector(elementsStateSelector, state => {
  return {
    elements: state.get('data')
  };
});

export const elementSelectedStateSelector = createSelector(
  elementsStateSelector,
  state => {
    return {selected: state.get('selected').toJS()};
  }
);

export const elementSelector = createSelector(
    elementsSelector,
    (state, props) => props,
    elementSelectedStateSelector,
    state => state.modals,
    ({ elements }, { id, element_type }, { selected: selection }, modalState) => {
        const selected = element_type === selection.element_type && id == selection.id;
        const selectedModal = toJS(modalState.get('selected'));
        const modalElementSelected = toJS(modalState.get('elementSelected'));
        const modalElement = (selectedModal && modalElementSelected) ? selectedModal.elements.find(
            e => e.element_id == modalElementSelected.id && e.element_type == modalElementSelected.element_type
        ): null;

        return {
            element: toJS(getElement(elements, element_type, id)),
            selected,
            selectedModalId: !!selectedModal && selectedModal.id.toString(),
            modalElementSelected,
            modalElement
        };
    }
);

export const getElementsAsJS = createSelector(
  elementsSelector,
  ({elements})=>{
    return {
      elements: toJS(elements)
    }
  }
);

export const selectedElementSelector = createSelector(
  elementsStateSelector,
  elementSelectedStateSelector,
  elementsSelector,
  (state, {selected}, {elements}) => {
    const {element_type, id, isModalElement} = selected;
    return {
      loading: state.get('loading'),
      element: !!element_type && toJS(getElement(elements, element_type, id)),
      isModalElement 
    };
  }
);

function getElement(elements, element_type, id) {
  return elements.getIn([element_type, id.toString()]);
}
