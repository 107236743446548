import React from 'react';
import onClickOutside from 'react-onclickoutside';
import filterInvalidDomProps from 'filter-invalid-dom-props';
import { Editor } from '@tinymce/tinymce-react';
import config from 'config'
import NativeListener from 'react-native-listener';
import { nodeAndParents } from 'utils/domUtils';
// import './EditableText.css';
// import styles from './EditableText.scss';

@onClickOutside
export default class EditableText extends React.Component {
  state = {
    editing: false,
  };


  handleClickOutside = (e) => {
    // that the editor shows up as a modal , think it uses portals anyway we need to stop editing if we click outside of those modals
    // ignore the click otherwise
    if (!!nodeAndParents(e.target).find((node) => node.classList.contains('tox'))) {
      return;
    }

    this.setState({ editing: false });
    const { onStopEdit } = this.props;
    onStopEdit && onStopEdit();
  };

  edit = () => {
    this.setState({ editing: true });
    const { onStartEdit } = this.props;
    onStartEdit && onStartEdit();
  };

  handleKeyDown = (e) => {
    e.stopPropagation();
  };

  handleChange = (content, editor) => {
    // this.props.onChange(e.target.getContent());
    this.props.onChange(content)
  };

  renderContent() {
    let { value, onChange, className, ...props } = this.props;

    props = filterInvalidDomProps(props);

    if (this.state.editing) {
      return (
        // we need to stop keyboard propagation
        <NativeListener onKeyDown={this.handleKeyDown}>
          <div className={`${className} editable-text_editing"`} {...props}>
            <Editor
              onEditorChange={this.handleChange}
              apiKey={config.TINYMCE_KEY} 
              value={value || '<span>Click to add text</span>'}
              init={{
                inline: true,
                menubar: false,
                toolbar:
                  'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright |',
              }}
            />
          </div>
        </NativeListener>
      );
    }

    //value = '<span>' + value + '</span>';
    return <div className="editable-text" dangerouslySetInnerHTML={{ __html: value }} {...props} />;
  }

  render() {
    return <div onDoubleClick={this.edit}>{this.renderContent()}</div>;
  }
}
