import React from 'react';
import {connect} from 'react-redux';
import {SelectInput} from 'components/PropertyEditor/PropertyEditor';
import {configuredSubUserIntegrationsSelector} from 'modules/integration/integrationSelectors';
import PropTypes from 'prop-types';

const _props = {
    /** sub user id passed down from parent component */
    subUserId : PropTypes.number.isRequired,
    /** sub user object agency user, incomming from the selector after filtering 
     * using the subUserId
    */
    subUser: PropTypes.object,
    /** Array of constants, All possible sub user integrations incomming from the store */
    integrations : PropTypes.array,
};

@connect(configuredSubUserIntegrationsSelector)
class SelectSubUserIntegration extends React.Component {

    integrationOptions = () => {
        const {subUser, integrations } = this.props;
        if(subUser) {
            return integrations.reduce((result, integration)=>{
                if(subUser[integration.key]) return { ...result, [integration.key]: integration.name }
            }, {})
        } else return null ;
        
    };
    
    render() {
        const integrations = this.integrationOptions();

        if (!integrations) {
            return (
                <div>
                    This user has no integrations set up, you will need to login as this user to setup on their behalf or get the user to login and set this up.
                </div>
            );
        }

        return (
            <SelectInput
                {...this.props}
                options={ integrations }
            />
        );
    }
}

SelectSubUserIntegration.propTypes = _props
export default SelectSubUserIntegration