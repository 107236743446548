import React from 'react';
import PositionableElementProperties from './PositionableElementProperties';
import {Section, Option, TextInput, BooleanInput, RangeInput, ColorInput, SelectInput} from 'components/PropertyEditor';
import DynamicTextElementProperties from "./DynamicTextElementProperties";
import AnimationElementProperties from "./AnimationElementProperties";
import {userSelector} from "modules/auth/authSelectors";
import {connect} from "react-redux";

@connect(userSelector, {})
export default class TextElementProperties extends React.Component {
  changeHandler = name => (e, val) => {
    this.props.updateElement({[name]: val});
  };


  updateDefaultValues = (data) => {
    this.props.updateElementStateLoading(true);
    this.props.updateElement({default_values: data});
  };


  render() {
    const {element} = this.props;
    const {
      padding,
      borderRadius,
      borderColor,
      borderWidth,
      borderType,
      backgroundColour,
      letterSpacing,
      dynamic,
      default_values
    } = element;

    return (
      <div>
        {(this.props.interactionLayer) && <AnimationElementProperties {...this.props} />}
        <PositionableElementProperties {...this.props} />
        {dynamic && (
            <Section icon="cog" title="Dynamic Text">
              <DynamicTextElementProperties
                  defaultValues={default_values ? default_values : [] }
                  updateDefaultValues={this.updateDefaultValues}
                  loading={this.props.loading}
              />
            </Section>
        )}
        <Section icon="paint-brush" title="Appearance">
          <div style={{position: 'relative', zIndex: 100}}>
            <Option
              label="Background Color"
              value={backgroundColour}
              Component={ColorInput}
              onChange={this.changeHandler('backgroundColour')}
            />
          </div>
          <Option
            label="Padding (%)"
            value={padding}
            Component={RangeInput}
            onChange={this.changeHandler('padding')}
          />
          <Option
            label="Roundness (%)"
            value={borderRadius}
            Component={RangeInput}
            onChange={this.changeHandler('borderRadius')}
          />
          {/*<Option*/}
            {/*label="Border width (px)"*/}
            {/*value={borderWidth}*/}
            {/*Component={RangeInput}*/}
            {/*onChange={this.changeHandler('borderWidth')}*/}
            {/*max={100}*/}
          {/*/>*/}
          {/*<Option*/}
            {/*label="Border color"*/}
            {/*value={borderColor}*/}
            {/*Component={ColorInput}*/}
            {/*onChange={this.changeHandler('borderColor')}*/}
          {/*/>*/}
          {/*{borderWidth > 0 && <Option*/}
            {/*label="Border type"*/}
            {/*value={borderType}*/}
            {/*onChange={this.changeHandler('borderType')}*/}
            {/*Component={SelectInput}*/}
            {/*options={[*/}
              {/*{label: 'solid', value: 'solid', clearableValue: false},*/}
              {/*{label: 'dashed', value: 'dashed', clearableValue: false},*/}
              {/*{label: 'dotted', value: 'dotted', clearableValue: false}*/}
            {/*]}*/}
          {/*/>}*/}
          <Option
            label="Letter Spacing (px)"
            value={letterSpacing}
            Component={RangeInput}
            onChange={this.changeHandler('letterSpacing')}
            max={10}
          />
        </Section>
      </div>
    );
  }
}