import React from 'react';
import styles from "./StockListModalStyles.scss";
import LinkButton from "components/Buttons/LinkButton";
import Button from "components/Buttons/Button";
import VideoPlayer from "components/VideoPlayer";
import getAsset from 'utils/getAsset';

export default class MediaLibraryVideoItem extends React.Component {
    constructor(props) {
        super(props);
    }

    get getThumbnailURL() {
        return this.props.item.thumbnail_url || getAsset('/img/no-thumbnail.png');
    }

    get getVideoURL() {
        return this.props.item.url || this.props.item.manifest_url || '';
    }

    render() {
        const {item, user, showMediaLibrary, addMediaItem} = this.props;

        if (! showMediaLibrary) return null;

        return (
            <div className={styles.listItem}>
                <div className={styles.listItemInner}>
                    <div className={styles.videoHolder}>
                        <VideoPlayer
                            url={this.getVideoURL}
                            videoId={item.id}
                            imageURL={this.getThumbnailURL}
                            controls
                            light={this.getThumbnailURL} // stops preload and only shows thumbnail awesome for perf
                        />
                        <div className={styles.addButton}>
                            <Button
                                primary
                                noMarginRight={true}
                                right={true}
                                small
                                onClick={() => addMediaItem(this.getVideoURL, item.thumbnail_url || null, item.name)}
                            >
                                Select
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}