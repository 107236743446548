import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {SEND_PASSWORD_RESET_EMAIL, authError, emailOK} from 'modules/auth/auth';

export default function* sendPasswordResetEmailSaga() {
  yield takeLatest([SEND_PASSWORD_RESET_EMAIL], sendPasswordResetEmail);
}

function* sendPasswordResetEmail({email}) {
  const res = yield fetch('password/email', {
    method: 'POST',
    body: {email}
  });


    const data = yield res.json();

  if(! data.success){
    yield put( authError(data.message) );
    yield put( emailOK(false) );

  } else {
    yield put( authError(null) );
    yield put( emailOK(true) );
  }
}
