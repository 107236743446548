import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';

import { applyModalTemplate } from 'modules/modal';
import { selectInteraction, addInteraction } from 'modules/interaction';
import { deselectElement } from 'modules/element';
import { TRIGGER_ELEMENT } from 'shared/element';
import { phpApi } from 'utils/saga/apis';
import { error } from 'utils/alert';
import { saveNodeSelector } from '../nodeSelectors';
// import styles from './NodeActionsComponent.scss';

// components
import TemplateListComponent from 'modules/template/components/TemplateListComponent';
import PreviewProjectButton from 'modules/project/components/PreviewProjectButton';
// import PublishButton from 'modules/project/components/PublishButton';
import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';

const props = {
  project: PropTypes.any,
  node: PropTypes.object,
  selectedInteraction: PropTypes.any,
  interactions: PropTypes.any,
  onScrub: PropTypes.func,
};

// @TODO: Move the interaction layer stuff into it's own capsulated button component
export default function NodeActionsComponent({
  project,
  node,
  interactions,
  onScrub,
  selectedInteraction,
}) {
  const [saving, setSaving] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [showInteractionLayerTemplates, setShowInteractionLayersTemplates] = useState(false);

  const dispatch = useDispatch();

  const { elementsToSave, interactionsToSave } = useSelector(saveNodeSelector);

  const nodeData = {
    node,
    elements: elementsToSave,
    interactions: interactionsToSave,
  };

  const interactionLayer =
    !!node && !!node.interaction_layer_id && find(interactions, { id: node.interaction_layer_id });

  const goToInteractionLayer = () => {
    // if interaction layer is already selected and we also have selected elements, on click interaction layer deselect elements
    if (selectedInteraction && interactionLayer && selectedInteraction.id == interactionLayer.id) {
      dispatch(deselectElement());
      return;
    }

    if (interactionLayer) {
      // Set the active interaction layer
      setSelectedInteraction(interactionLayer);
      onScrub(0.99);
    } else {
      // Open the new interaction layer Templates
      setShowInteractionLayersTemplates(true);
    }
  };

  function handleSelectTemplate(templateId) {
    // We must create the interaction 1st before applying the template to it's element
    // creating a trigger element auto creates a blank modal then if user chooses a template that'll be
    // applied through the callback , if not the blank modal is used . during which operation the node interaction_layer_id
    // is also appended in the backend
    dispatch(
      addInteraction(
        {
          projectId: project.id,
          nodeId: node.id,
          element_type: TRIGGER_ELEMENT,
          interaction_layer: 1,
        },
        addInteractionCallback
      )
    );

    // callback that fires after interaction holding interaction_layer modal
    function addInteractionCallback(interaction, element) {
      if (templateId) {
        // might be null if used blank template
        const { action, actionArg } = element;
        const modalTemplateData = {};
        if (action == 'openModal' && !!actionArg) {
          modalTemplateData.modalId = actionArg;
          dispatch(applyModalTemplate(templateId, { modalId: actionArg }), function applyModalCallback() {
            // this happens after the modal is created and template applied to it
            setSelectedInteraction(interaction);
          });
        }
      } else setSelectedInteraction(interaction); // used a blank interaction layer
      setShowInteractionLayersTemplates(false);
    }
  }

  function setSelectedInteraction(interaction) {
    dispatch(selectInteraction(interaction));
  }

  async function handlePublish() {
    try {
      setPublishing(true);
      await handleSaveNode();
      await phpApi(`projects/${node.project_id}/publish`, { method: 'post' });
    } catch (error) {
      error({ text: 'Error while publishing' });
    } finally {
      setPublishing(false);
    }
  }

  async function handleSaveNode() {
    try {
      setSaving(true);
      await phpApi(`nodes`, { method: 'put', body: nodeData });
    } catch (e) {
      error({ text: 'Error saving node' });
    } finally {
      setSaving(false);
    }
  }

  return (
    <div className="pr-4">
      <div className="d-flex justify-end align-center">
        {!!project.legacy && (
          <Button secondary disabled>
            <span data-tip={'Only available on new projects'}>
              <Icon name="bullseye-pointer" /> Interaction Layer
            </span>
          </Button>
        )}
        {!project.legacy && (
          <div className="mr">
            {/*<BackButton link={`/projects/${projectId}`}  />*/}
            <Button secondary onClick={goToInteractionLayer}>
              <Icon name="bullseye-pointer" /> Interaction Layer
            </Button>
            {showInteractionLayerTemplates && (
              <TemplateListComponent
                resource="modals"
                filter="interaction_layer"
                title="Select Interaction Layer Preset"
                interactionLayerPopup
                show={showInteractionLayerTemplates}
                offerBlankOption={!node.interaction_layer_id}
                height={650}
                onClose={() => {
                  setShowInteractionLayersTemplates(false);
                }}
                onSelectTemplate={handleSelectTemplate}
              />
            )}
          </div>
        )}

        <div className="mr">
          <PreviewProjectButton startNodeId={node.id} projectId={project.id}>
            Preview Node
          </PreviewProjectButton>
        </div>
        <div className="mr">
          {/* <PublishButton projectId={project.id} text="Save & Publish Changes" style={{ marginBottom: 0 }} /> */}
          <Button
            primary
            icon="save"
            onClick={handleSaveNode}
            text="Save"
            style={{ marginBottom: 0 }}
            loading={saving}
          />
        </div>
        <div >
          {/* <PublishButton projectId={project.id} text="Save & Publish Changes" style={{ marginBottom: 0 }} /> */}
          <Button
            primary
            icon="cloud-upload"
            onClick={handlePublish}
            text="Publish"
            style={{ marginBottom: 0 }}
            loading={publishing}
          />
        </div>
      </div>
    </div>
  );
}
NodeActionsComponent.propTypes = props;
