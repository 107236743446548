import React, { Component } from 'react';
import {createPortal} from "react-dom";
import {connect} from 'react-redux';
import {updateProjectGroupSorting} from 'modules/project/project';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import isEqual from 'lodash/isEqual';
import ProjectGroupItem from "./ProjectGroupItem";
import {browser} from "../../../utils/domUtils";

@connect(null, {updateProjectGroupSorting})
export default class FoldersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folders: props.folders
        }
    }

    static getDerivedStateFromProps(props, state) {
        return !isEqual(props.folders, state.folders) ? { folders:  props.folders} : null
    }

    sortBySortOrderNumber(folders) {
        return folders.reduce((result, folder, index) => {
            result.push({
                id: folder.id,
                sortOrderNumber: ++index
            });
            return result;
        }, []);
    }

    reorder = result => {
        const folders = [...this.state.folders],
              startIndex = result.source.index,
              endIndex = result.destination.index;

        const [removed] = folders.splice(startIndex, 1);
        folders.splice(endIndex, 0, removed);

        const sortedFoldersData = this.sortBySortOrderNumber(folders);

        this.setState(
            { folders },
            () => this.props.updateProjectGroupSorting(sortedFoldersData)
        );
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        this.reorder(result);
    };

    get getPortal () {
        // use this in case of browser support!
        const portal = document.createElement("div");
        document.getElementById("appRoot").appendChild(portal);

        return portal;
    }

    render () {
        const { folders } = this.state;

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <DraggableContent
                            folders={folders}
                            provided={provided}
                            snapshot={snapshot}
                            portal={this.getPortal}
                        />
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

function DraggableContent({folders, provided, portal}) {

    const getItemStyles = (isDragging, draggableStyle) => ({
        // change background colour if dragging
        background: isDragging ? '#f1f1f1' : '',
        cursor: 'inherit',
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const generateFolderKey = folderId => ['folder', folderId].join('_');

    return (
        <div
            ref={provided.innerRef}
        >
            {folders.map((folder, index) => {
                return (
                    <Draggable key={folder.id} draggableId={folder.id.toString()} index={index}>
                        {(provided, snapshot) => {
                            const child = (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyles(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <ProjectGroupItem
                                        key={generateFolderKey(folder.id)}
                                        folder={folder}
                                    />
                                </div>
                            );

                            if (!snapshot.isDragging || browser.name === 'chrome') {
                                return child;
                            }

                            // if dragging - put the item in a portal
                            return createPortal(child, portal);
                        }}
                    </Draggable>
                )
            })}
            {provided.placeholder}
        </div>
    );
}