import React from 'react';
import styles from './Page.scss';
import { userSelector } from 'modules/auth/authSelectors';
import {connect} from 'react-redux';

@connect(userSelector)
class Page extends React.Component {
  constructor({user}) {
    super(...arguments);
  }


  render() {
    const {user, children} = this.props;

    return (
      <div className={styles.page_wrapper}>
        {children}
      </div>
    );
  }
}

export default Page;
