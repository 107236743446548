import {createSelector} from 'reselect';
import {projectsSelector, projectSelector} from 'modules/project/projectSelectors';
import {nodesAsArraySelector} from 'modules/node/nodeSelectors';
import {userSelector} from "../auth/authSelectors";

const statsStateSelector = state => state.stats;

export const statsInfoSelector = createSelector(
  statsStateSelector, state => ({
    generatedPixelCode: state.generatedPixelCode,
    pixelLoading: state.pixelLoading
  })
);

export const statsListPageSelector = createSelector(
  projectsSelector,
  ({projects}) => ({
    projects
  })
);

export const projectStatsSelector = createSelector(
  projectSelector,
  statsStateSelector,
  nodesAsArraySelector,
  userSelector,
  ({project, projectGroups}, statState, {nodes}, user) => ({
    project,
    projectGroups,
    statInfo: statState.toJS(),
    nodes: nodes,
    user
  })
);