import React, {Component} from 'react';
import {connect} from 'react-redux';
import Rodal from "rodal";
import Icon from "components/Icon";
import {updateProjectGroup} from 'modules/project/project';
import {projectFoldersSelector} from 'modules/project/projectSelectors';
import Button from 'components/Buttons/Button';
import {Option, SelectInput} from 'components/PropertyEditor';
import {recreateSelectOptions} from "../../../utils/domUtils";

@connect(projectFoldersSelector, {updateProjectGroup})
export default class MoveToFolderModal extends Component {
    constructor(props) {
        super(props);
        const project_group_id = props.projectGroupID ? props.projectGroupID : 'noFolder';

        this.state = {
            folders: {},
            addProjectGroupModal: false,
            project_group_id: project_group_id
        };
    }

    closeModal = () => {
        this.props.handleMoveToFolderModal(false);
    };

    changeHandler = name => (_, val) => {
        this.setState({[name]: val });
    };

    handleSave = () => {
        let {project_group_id} = this.state;

        if (project_group_id === "noFolder")
            project_group_id = null;

        this.props.updateProject({ project_group_id });
        this.closeModal();
    }

    get filteredGroups() {
        return recreateSelectOptions(
            this.props.projectGroups,
            { key: 'id', label: 'title' },
            { key: 'noFolder', value: 'No Folder' }
        );
    }

    renderModalBody = () => {
        const {project_group_id} = this.state;

        return (
            <div className="modal-body">
                <div style={{marginTop: '10px'}}>
                    <Option
                        label="Folders"
                        Component={SelectInput}
                        value={project_group_id}
                        options={this.filteredGroups}
                        onChange={this.changeHandler('project_group_id')}
                    />
                </div>
            </div>
        );
    };

    render() {
        return (
            <div>
                <Rodal
                    visible={this.props.moveToFolderModal}
                    onClose={this.closeModal}
                    customStyles={{overflow: 'inherit'}}
                    height={230}
                    width={460}
                >
                    <div className="modal-heading">
                        <Icon name="plus" /> Move to Folder
                    </div>

                    {/* Modal Body */}
                    {this.renderModalBody()}

                    <div className="modal-footer">
                        <Button primary onClick={this.handleSave} >
                            Save
                        </Button>
                        <Button onClick={this.closeModal}>
                            <Icon icon="times" /> Cancel
                        </Button>
                    </div>
                </Rodal>
            </div>
        );
    }
}