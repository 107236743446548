
const zoomValue = window.localStorage.getItem('interactr_canvas_zoom');

const initialState = {
  dragging: false,
  dragType: null,
  // Stores the delta of the drag from drag start to current position
  drag: {
    x: 0,
    y: 0
  },
  zoom: zoomValue || 1,
  pan: {
    x: 0,
    y: 0
  },
  dragData: null
};

const MIN_ZOOM = 0.2;
const MAX_ZOOM = 2;

function inBounds(min, max, val) {
  return Math.max(Math.min(val, max), min);
}

// The point of this reducer is to store basic state about the composer
export function composerReducer(state = initialState, action) {
  switch (action.type) {
    case BEGIN_DRAG:
      return {
        ...state,
        dragging: true,
        dragType: action.dragType,
        dragData: action.data
      };

    case ZOOM:
      const newZoom = (action.delta === 'reset') ? 1 : inBounds(MIN_ZOOM, MAX_ZOOM, state.zoom - action.delta);
      const parsedNewZoom = parseFloat(newZoom).toFixed(1)
      window.localStorage.setItem('interactr_canvas_zoom', parsedNewZoom);
      // const width = 1005;
      // const height = 798;
      // console.log(Math.pow(newZoom, 2), width / Math.pow(newZoom, 2));
      // const newPan = {
      //   x: state.pan.x + 1 / width * newZoom,
      //   y: state.pan.y + 1 / height * newZoom
      // };
      // console.log(newPan);
      return {...state, zoom: parsedNewZoom};

    case DRAG:
      // If no drag type pan the composer
      const {deltaX: dx, deltaY: dy} = action.info;
      if (!state.dragType) {
        return {
          ...state,
          pan: {
            // TODO: really need a util for this
            x: state.pan.x - dx,
            y: state.pan.y - dy
          }
        };
      }
      if (dx || dy) {
        return {...state, drag: {x: state.drag.x + dx, y: state.drag.y + dy}};
      }

      return state;
    case END_DRAG:
      return {
        ...state,
        dragging: false,
        drag: initialState.drag,
        dragData: initialState.dragData
      };
  }
  return state;
}

export const reducers = {
  composer: composerReducer
};

export const BEGIN_DRAG = 'composer:BEGIN_DRAG';
export function beginDrag(dragType, target, data) {
  return {
    type: BEGIN_DRAG,
    dragType,
    // Used by saga
    target,
    data
  };
}

export const DRAG = 'composer:DRAG';
export function drag(info) {
  return {
    type: DRAG,
    info
  };
}

export const END_DRAG = 'composer:END_DRAG';
export function endDrag(target) {
  return {
    type: END_DRAG,
    target
  };
}

export const ZOOM = 'composer:ZOOM';
export function zoom(delta) {
  return {
    type: ZOOM,
    delta
  };
}

export const COMPOSER_DOM_ID = 'interactr-composer';

export const LINK_CONNECTOR = 'composer:LINK_CONNECTOR';
export function linkConnector(element_type, id, toNodeId = '') {
  return {
    type: LINK_CONNECTOR,
    id,
    element_type,
    toNodeId
  };
}

export const REMOVE_CONNECTOR = 'composer:REMOVE_CONNECTOR';
export function removeConnector(element_type, id) {
  return {
    type: REMOVE_CONNECTOR,
    id,
    element_type,
    toNodeId: ''
  };
}
