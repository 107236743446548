import React from 'react';
import { logout } from 'utils/logout';
import styles from './DashboardSidebar.scss';
import MenuItem from "../MenuItem";
import Icon from 'components/Icon';

export default class SubUserMenu extends React.Component {
    render(){
        const {setClasses} = this.props;

        return(
            <ul className={styles.menu_section}>
                <MenuItem icon="project-diagram" className={setClasses('projects')} to={'/projects'}>
                    <span className={styles.menu_item_text}>Projects</span>
                </MenuItem>

                <MenuItem icon="photo-video" className={setClasses('videos')} to={'/videos'}>
                    <span className={styles.menu_item_text}>Media Library</span>
                </MenuItem>

                <MenuItem icon="user" className={setClasses('account')} to={'/account/preferences'}>
                    <span className={styles.menu_item_text}>My Account</span>
                </MenuItem>

                <li>
                    <a onClick={()=>logout()}>
                        <Icon name="sign-out-alt"  /> <span className={styles.menu_item_text}>Logout</span>
                    </a>
                </li>
            </ul>
        )
    }
}