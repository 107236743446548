import React from 'react';
import PositionableElement from './PositionableElement';

export default class HotspotElement extends React.Component {
  render() {
    return (
      <PositionableElement
        {...this.props}
        style={{
          border: '1px dashed #eee',
          boxShadow:
            '1px 1px 10px rgba(255,255,255, 0.2), 1px 1px 10px rgba(0,0,0,0.2)'
        }}
      />
    );
  }
}
