import React from 'react';
import { SelectInput } from 'components/PropertyEditor';

export default function SelectCustomList({ customLists , ...props }) {
  if (!customLists.length) {
    return (
      <div>
        <div style={{ marginBottom: 5 }}>No custom Lists ! &nbsp; </div>
      </div>
    );
  }

  const parsedCustomLists = customLists.reduce(
    (result, list) => ({
      ...result,
      [list.id]: list.custom_list_name,
    }),
    {}
  );
  return <SelectInput {...props} options={{ '': '--', ...parsedCustomLists }} />;
}
