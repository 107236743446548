import {put,  takeEvery} from 'redux-saga/effects';
import {UPDATE_ELEMENT_GROUP, receiveElementGroup} from 'modules/node/node';
import fetch from 'utils/saga/fetch';
import { error } from 'utils/alert'
import { toFloat } from 'utils/numberUtils';

export default function* updateElementGroupSaga() {
    yield takeEvery(UPDATE_ELEMENT_GROUP, updateElementGroup);
}

function* updateElementGroup({ data : {id, timeOut, timeIn}, cb}) {
    const floatTimeIn = toFloat(timeIn)
    const floatTimeOut = toFloat(timeOut)
    
    if(floatTimeIn > floatTimeOut) {
        error({title: 'Timeline Error' , text:'The time-in of the element group must be less than the time-out'});
        return;
    }
    
    const res = yield fetch(`nodes/elementGroup/`, {
        method: 'PUT',
        body: {id, timeOut : floatTimeOut, timeIn : floatTimeIn}
    });

    const data = yield res.json();

    if (!data.success) {
        error({text: 'Error updating the timeline for this elment group.'});
        return;
    }

    yield put(receiveElementGroup(data.elementGroup));
    if(cb) cb()
}
