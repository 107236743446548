import React from 'react';
import styles from './App.scss';
import { Router, Switch } from 'react-router-dom';

import history from './browserHistory';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'modules/sagas';
import reducers from 'modules/reducers';
import RootPage from 'RootPage';
// Add icons library
import './iconsLibrary';
// Add styles
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-dropzone-uploader/dist/styles.css';
import 'rodal/lib/rodal.css';
import 'chartist/dist/chartist.min.css';
// Add styles.scss after all vendor styling as it includes some overwrites for those
import 'sass/styles.scss';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  combineReducers(reducers),
  {}, // initial state
  compose(
    applyMiddleware(sagaMiddleware),
    // If you are using the devToolsExtension, you can add it here also
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__({
          serialize: {
            replacer: (key, value) => (value && value.nodeName ? `Element(${value.nodeName})` : value)
          },
          trace: __DEV__ ? true : false,
          traceLimit: 15
        })
      : f => f
  )
);

sagaMiddleware.run(rootSaga);

export default function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <div className={styles.App}>
          <RootPage />
        </div>
      </Router>
    </Provider>
  );
}
