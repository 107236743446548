import get from 'lodash/get';
import { createSelector } from 'reselect';
import { toJS } from 'utils/immutableUtils';
import { generateEmbedCode } from './projectUtils';
import { authUserSelector } from 'modules/auth/authSelectors';
import { templateLanguagesSelector, associatedLanguagesSelector } from 'modules/template/templateSelectors';
import { recreateSelectOptions } from 'utils/domUtils';
import { List } from 'immutable';
import getAsset from 'utils/getAsset';


const projectsStateSelector = state => state.projects;
const nodesStateSelector = state => state.nodes;

// So fucked off with this state shit get the raw state when needed
const rawState = state => toJS(state);

// TODO: move to utils
const routeParamSelector = paramName => (state, props) =>
  ((props && props[paramName]) || get(props, ['match', 'params', paramName], '')).toString();


// Expects a project from the components own props as a parameter
const getProjectMedias = (state, {project}) => {
  return {
      projectMedias: state.media.get('data').filter(media => media.get('project_id') == project.id),
      project
  };
};

const getProjectNodes = (state, { project }) => {
  return state.nodes.get('all').filter(node => node.get('project_id') == project.id);
};


export const projectSaving = createSelector(
  projectsStateSelector,
  state => ({saving: state.get('saving')})
);

export const projectIdSelector = createSelector(
  projectsStateSelector,
  nodesStateSelector,
  routeParamSelector('projectId'),
  (nodesState, projectsState, routeProjectId) => {
      if (nodesState.get('projectId')) return nodesState.get('projectId');
      if (projectsState.get('projectId')) return projectsState.get('projectId');
      if (routeProjectId) return routeProjectId;
  }
);

export const projectsFolderIdSelector = createSelector(
  projectsStateSelector,
  state => state.get('folderId')
);

export const projectFoldersSelector = createSelector(
  projectsStateSelector,
  associatedLanguagesSelector,
  (state, { languages }) => ({
    projectGroups: state
      .getIn(['data', 'projectGroups'])
      .sortBy(folder => folder.get('sortOrderNumber'))
      .toList()
      .toJS(),
    languageOptions: recreateSelectOptions(
      languages,
      { key: 'id', label: 'nativeName' },
      { key: 'noLanguage', value: 'English' }
    )
  })
);

export const projectsSelector = createSelector(
  projectsStateSelector,
  authUserSelector,
  projectFoldersSelector,
  projectsFolderIdSelector,
  (state, user, { projectGroups }, folderId) => ({
    user: toJS(user),
    projects: state
      .getIn(['data', 'projects'])
      .toList()
      .toJS(),
    projectGroups,
    searching: state.get('searching'),
    folderId
  })
);

export const projectSelector = createSelector(
  projectsStateSelector,
  projectIdSelector,
  authUserSelector,
  projectSaving,
  templateLanguagesSelector,
  (state, projectId, user, saving , { languages }) => {
    const theUser = toJS(user);
    const project = toJS(state.getIn(['data', 'projects', projectId]));
    const projectGroups = state
      .getIn(['data', 'projectGroups'])
      .toList()
      .toJS();
    const languageOptions = recreateSelectOptions(
      languages,
      { key: 'id', label: 'englishName' },
      { key: 'noLanguages', value: 'English' }
    );

    return typeof project !== 'undefined'
      ? { project, user: theUser, projectGroups, languageOptions, saving }
      : { user: theUser };
  }
);

export const embedCodeSelector = createSelector(
  projectSelector,
  ({ project }) => ({
    embedCode: generateEmbedCode(project)
  })
);

export const fontSelector = createSelector(
  projectSelector,
  ({ project }) => {
    if (project) {
      return { font: project.font };
    }
    return {};
  }
);

export const videoThumbnailSelector = createSelector(
  rawState,
  projectIdSelector,
  (state, projectId) => {
    return {
      projectVideos: state.media
        .get('data')
        .toList()
        .toJS()
        .filter(media => media.project_id == projectId)
    };
  }
);


export const publishButton = createSelector(
  projectsStateSelector,
  projectState => {
    return {
      publishing: projectState.get('publishing'),
      unpublishing: projectState.get('unpublishing')
    };
  }
);

export const BreadcrumbSelector = createSelector(
  rawState,
  projectsFolderIdSelector,
  (state, folderId) => ({
    nodes: state.nodes.get('data').toJS(),
    projects: state.projects.get('data').toJS(),
    folderId
  })
);



/** creates an exclusive selector that won't care about other components it's passed to,
 * it's memoization would always work, just use this factory while connecting it. 👉 https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
 * @return {function} Selector
 */
export const createProjectCardSelector = () => createSelector(
  [rawState, getProjectMedias, getProjectNodes, authUserSelector],
  (state, {projectMedias, project}, projectNodes, user) => {
    /**
    * Make first thumbnail to be project.image_url if it's valid
    *
    */
    let thumbnailsDefaultList = List();
    const projectThumb = project.image_url;

  if (projectThumb) {
          thumbnailsDefaultList = thumbnailsDefaultList.push(projectThumb);
    }

    // extract thumbnails from media objects
    const projectThumbnails = projectMedias.reduce((acc, next) => {
      let thumb = next.get('thumbnail_url').toString().trim();
      thumb = thumb ? thumb : getAsset('/img/no-thumb.jpg');
      return acc.size < 4  ? acc.push(thumb) : acc;
    }, thumbnailsDefaultList);

    return {
      user: user.toJS(),
      projectThumbnails: projectThumbnails.toJS(),
      nodesCount: projectNodes.size,
      whitelabel: state.pageLoader.toJS().whitelabel
    };
  }
);
