import {takeEvery, put} from 'redux-saga/effects';
import {DELETE_NODE, nodeDeleted, fetchProjectNodes} from 'modules/node/node';
import fetch from 'utils/saga/fetch';
import {confirm} from 'utils/alert';
import swal from 'sweetalert2';
import {changeStartNode} from 'modules/project/project';

export default function* deleteNodeSaga() {
  yield takeEvery(DELETE_NODE, deleteNode);
}

function* deleteNode({id}) {
  const conf = yield swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to delete this node.',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#ff6961',
    confirmButtonText: 'Yes, Delete It!',
  });

  const res = yield fetch(`nodes/${id}`, {
    method: 'DELETE'
  });

  const response = yield res.json();

  if (response && response.newStartNodeId !== false) {
    yield put(changeStartNode(response.projectId, response.newStartNodeId));
  }

  if (response && response.projectId) {
    yield put(fetchProjectNodes(response.projectId, true));
  }

  yield put(nodeDeleted(id));

}
