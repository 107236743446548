import {put, takeEvery, select} from 'redux-saga/effects';
import {UPDATE_INTERACTION_ELEMENT_GROUP, receiveInteraction} from 'modules/interaction/interaction';
import {nodeDurationSelector} from 'modules/node/nodeSelectors';
import {receiveElement} from 'modules/element/element';
import {splitElementFromObj} from 'modules/element/elementUtils';
import { error } from 'utils/alert'
import fetch from 'utils/saga/fetch';

export default function* updateInteractionElementGroupSaga() {
    yield takeEvery(UPDATE_INTERACTION_ELEMENT_GROUP, updateInteractionElementGroup);
}

function* updateInteractionElementGroup({interactionId, elementGroupId}) {
    const timeOut = yield select(nodeDurationSelector);
    const res = yield fetch(`interactions/${interactionId}/elementGroup`, {
        method: 'POST',
        body: {
            elementGroupId,
            timeOut
        }
    });

    const data = yield res.json();

    if (!data.success) {
        error({text: 'Error updating element group.'});
        return;
    }

    const [interaction, element] = splitElementFromObj(data.interaction);
    yield put(receiveElement(element));
    yield put(receiveInteraction(interaction));
}
