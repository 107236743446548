import React from 'react';
import PropTypes from 'prop-types';
import {
  Section,
  Option,
  SelectInput,
  TextInput,
  TimeInput,
  BooleanInput
} from 'components/PropertyEditor/PropertyEditor';
import SelectNode from 'modules/node/components/SelectNode';
import SelectModal from 'modules/modal/components/SelectModal';
import { modalPath } from 'routeBuilders';
import { withRouter, Link } from 'react-router-dom';
import LinkButton from 'components/Buttons/LinkButton';
import { BUTTON_ELEMENT, TRIGGER_ELEMENT } from 'shared/element';
import ReactTooltip from 'react-tooltip';
import Icon from 'components/Icon';
import cx from 'classnames';
import Button from 'components/Buttons/Button';

const _props = {
  actionTitle: PropTypes.string.isRequired,
  actionType: PropTypes.oneOf(['element_click', 'node_complete']),
  updateElement: PropTypes.func.isRequired,
  /** callback on interaction data to change property that updates redux */
  interactionPropertyChangeHandler: PropTypes.func,
  /** callback on interaction data to change */
  updateInteraction: PropTypes.func,
  /** Only relevant for using elements vs modal editing not node */
  editingContext: PropTypes.string,
  interaction: PropTypes.shape({
    element_type: PropTypes.string,
    timeIn: PropTypes.number
  }),
  element: PropTypes.shape({
    action: PropTypes.string,
    actionArg: PropTypes.any
    // ... etc
  })
};
class ClickableElementProperties extends React.Component {
  static defaultProps = {
    actionTitle: 'On click',
    actionType: 'element_click'
  };

  elementChangeHandler = name => (e, val) => {
    if (val === 'null') val = null;

    // This is a little hacky, see line 174 of PropertyEditor to see why we do it
    if (typeof val.value !== 'undefined' && val.value === '') {
      val = '';
    }

    // When action is changed, reset the actionArg
    if (name === 'action') {
      return this.props.updateElement({
        action: val,
        actionArg: null
      });
    }

    this.props.updateElement({ [name]: val });
  };

  /** Handles Interaction change handler */
  handleInteractionChange = property => {
    const { interactionPropertyChangeHandler, updateInteraction } = this.props;
    if (interactionPropertyChangeHandler) return interactionPropertyChangeHandler(property);

    return (e, val) => {
      updateInteraction({ [property]: val });
    };
  };

  getActions() {
    const {
      editingContext,
      actionTitle,
      element: { element_type }
    } = this.props;

    let options = {
      '': 'Do nothing',
      playNode: actionTitle === 'On Video End' ? 'Play Menu Node' : 'Play Node',
      openUrl: 'Open Url',
      skipToTime: 'Skip To Time'
    };

    // =========== Problem with modals being linked exclusively to triggers ===========
    // if (editingContext === 'modal') {
    //   options.closeModal = 'Close Popup';
    // }

    // should we hide this until we fix the modal triggering only through trigger element ??
    // if (editingContext !== 'modal') {
    //   options.openModal = 'Open Popup';
    // }
    // =========== ====================================== ================= ===========


    if (element_type === BUTTON_ELEMENT && editingContext !== 'modal') {
      options.play = 'Unpause Video';
    }

    return options;
  }

  renderPauseVideoWhenShown() {
    const { element, interaction, editingContext } = this.props;

    if (!element.id || interaction.element_type === TRIGGER_ELEMENT) return null;

    return (
      <Option
        label="Pause Video When Shown"
        value={interaction.pause_when_shown}
        onChange={this.handleInteractionChange('pause_when_shown')}
        Component={BooleanInput}
      />
    );
  }

  render() {
    const {
      element: { action, actionArg, open_in_new_tab, element_type, id },
      actionTitle,
      actionType,
      disabled,
      className
    } = this.props;

    const option = (
      <div>
        {this.renderPauseVideoWhenShown()}
        <Option
          label={actionTitle}
          value={action}
          onChange={this.elementChangeHandler('action')}
          Component={SelectInput}
          options={this.getActions()}
          disabled={disabled}
        />
      </div>
    );

    return (
      // <React.Fragment title="Behaviour" icon="bolt">
      <div className={cx(className)} style={{ maxWidth: '500px' }}>
        <ReactTooltip />
        {/*  Wrap the element (on click) part of this in a section */}
        {actionTitle === 'On click' && <Section noHeading>{option}</Section>}
        {actionTitle === 'On submit' && <Section title="On Submit">{option}</Section>}
        {actionTitle === 'On Video End' && option}
        
        {!disabled && (
          <div style={{ marginTop: '-20px', marginBottom: '20px' }}>
            {action === 'playNode' && (
              <Option
                label="Node"
                value={actionArg}
                Component={SelectNode}
                onChange={this.elementChangeHandler('actionArg')}
                className="w-100"
              />
            )}

            {action === 'openUrl' && (
              <React.Fragment>
                <Option
                  label="Url"
                  value={actionArg}
                  Component={TextInput}
                  onChange={this.elementChangeHandler('actionArg')}
                  placeholder="ex. http://google.com"
                  className="rounded-3"
                />
                {actionType !== 'node_complete' && (
                  <Option
                    Component={BooleanInput}
                    onChange={this.elementChangeHandler('open_in_new_tab')}
                    label={'Open the url in a new tab'}
                    value={open_in_new_tab}
                  />
                )}
              </React.Fragment>
            )}

            {action === 'openModal' && (
              <div className="clearfix mt-3">
                {/*<Option*/}
                {/*  label="Template"*/}
                {/*  value={actionArg}*/}
                {/*  Component={SelectModal}*/}
                {/*  element_type={element_type}*/}
                {/*  element_id={id}*/}
                {/*  onChange={this.elementChangeHandler('actionArg')}*/}
                {/*/>*/}
                {/*{actionArg && (*/}
                {/*  <LinkButton*/}
                {/*    to={modalPath({ projectId, nodeId, modalId: actionArg })}*/}
                {/*    icon="pen-square"*/}
                {/*    style={{*/}
                {/*      position: 'relative',*/}
                {/*      top: '-52px',*/}
                {/*      marginBottom: '-47px'*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Change*/}
                {/*  </LinkButton>*/}
                {/*)}*/}
              </div>
            )}

            {action === 'skipToTime' && (
              <Option
                label="Time"
                value={actionArg}
                Component={TimeInput}
                onChange={this.elementChangeHandler('actionArg')}
                className="w-100"
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
ClickableElementProperties.propTypes = _props;
export default ClickableElementProperties;
