import React from 'react';
import PositionableElementProperties from './PositionableElementProperties';
import StyleableElementProperties from './StyleableElementProperties';
import ClickableElementProperties from './ClickableElementProperties';
import AnimationElementProperties from './AnimationElementProperties';
import {userSelector} from "modules/auth/authSelectors";
import {connect} from "react-redux";

@connect(userSelector, {})
export default class ButtonElementProperties extends React.Component {
  render() {
      return (
      <div>
        <ClickableElementProperties {...this.props} />
          {(this.props.interactionLayer) && <AnimationElementProperties {...this.props} />}
        <PositionableElementProperties {...this.props} />
        <StyleableElementProperties {...this.props} stackOrder={100} />
      </div>
    );
  }
}
