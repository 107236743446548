import {UPDATE_ACCOUNT_DETAILS} from 'modules/account/account';
import {updateAuthenticatedUser} from 'modules/auth/auth';
import fetch from 'utils/saga/fetch';
import {put, takeEvery} from 'redux-saga/effects';
import {success, error} from 'utils/alert';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";

export default function* updateAccountDetailsSaga() {
  yield takeEvery(UPDATE_ACCOUNT_DETAILS, updateAccountDetails);
}

function* updateAccountDetails({userId, newFieldsAndValues, isCurrentUser}) {
  yield put(updatePageLoadingState(true));

  const res = yield fetch(`account/${userId}/details`, {
    method: 'PUT',
    body: newFieldsAndValues
  });

  if (!res.ok) {
    yield put(updatePageLoadingState(false));
    error({text: 'Could not update details. Please try again later.'});
    return;
  }

  if (isCurrentUser) {
    const {user} = yield res.json();
    yield put(updateAuthenticatedUser(user)); // update the auth user
  }

  yield put(updatePageLoadingState(false));
  success({text: 'Account Details Updated!'})
}

