import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// import { interactionsForNodeSelector, selectedInteractionSelector } from 'modules/interaction/interactionSelectors';
import { selectInteraction, deleteInteraction } from 'modules/interaction/interaction';
import ElementEditor from 'modules/element/components/ElementEditor';
import EditorModal from 'modules/modal/components/EditorModal';
import { getShownInteractions, shouldShowInteraction } from 'shared/interactionUtils';
import InteractionLayer from './InteractionLayer';

export default function InteractionEditor({
  nodeId,
  interactions,
  className,
  played,
  selectedInteraction,
  elementGroups,
  selectedModal,
  grid,
  ...props
}) {
  const dispatch = useDispatch();

  function getInteractionByElement(element_type, element_id) {
    return interactions.find(
      (interaction) => interaction.element_id == element_id && interaction.element_type == element_type
    );
  }

  useEffect(() => {
    handleSelectEditor();
  }, [nodeId]);

  // we call selectInteraction etc here so we can highlight
  // the interaction in the timeline.
  function handleSelectEditor() {
    dispatch(selectInteraction(null));
  }

  function handleSelectElement(element_type, element_id) {
    const interaction = getInteractionByElement(element_type, element_id);
    dispatch(selectInteraction(interaction));

    // TODO: bit of a hack - youtube annoyingly shows a play button
    // overlay when the video is first loaded,
    // calling play manually here makes it seem like the button works.
    // we may need to add some state to only allow this click once
    // if (this.props.played === 0) this.props.play();
  }

  function handleDeleteElement(element_type, element_id) {
    const { id } = getInteractionByElement(element_type, element_id);
    dispatch(deleteInteraction(id));
  }

  function hasPlayedPastModal() {
    // selected interaction in this if selected modal exists is modalss own tirrger interaction
    return selectedModal && selectedInteraction && shouldShowInteraction(selectedInteraction, played);
  }
  const shownInteractions = getShownInteractions(interactions, elementGroups, played);

  return selectedModal && selectedModal.interaction_layer ? (
    <InteractionLayer modal={selectedModal} grid={grid} />
  ) : hasPlayedPastModal() ? (
    <EditorModal modal={selectedModal} grid={grid} />
  ) : (
    <ElementEditor
      grid={grid}
      className={className}
      elementContainers={shownInteractions}
      onSelectEditor={handleSelectEditor}
      onSelectElement={handleSelectElement}
      onDeleteElement={handleDeleteElement}
    />
  );
}
