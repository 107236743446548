import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {COPY_MODAL, receiveModal} from 'modules/modal/modal';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {error} from 'utils/alert';
import swal from 'sweetalert2';
import history from 'browserHistory';
import {modalPath} from 'routeBuilders';

export default function* copyModalSaga() {
  yield takeLatest(COPY_MODAL, copyModal);
}

function* copyModal({modalId}) {
  const conf = yield swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to copy this modal.',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#00b382',
    confirmButtonText: 'Yes, Copy It!',
  });

  yield put(updatePageLoadingState(true));

  const res = yield fetch(`modals/copy/${modalId}`, {
    method: 'POST',
    body: {}
  });

  if (res.status !== 200) {
    yield put(updatePageLoadingState(false));
    error({text:'Unable To Copy Modal'})
  }

  const modal = yield res.json();

  yield put(receiveModal(modal));

  // Build a new url to take us back to the node page
  const urlSplit = window.location.href.split('/');

  // Redirect to new node
  history.push(modalPath({projectId: urlSplit[4], nodeId: urlSplit[6], modalId: modal.id}) + '?selectModal');
}
