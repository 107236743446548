import React from 'react';
import Card from 'components/Card';
// import { addProjectPath } from 'routeBuilders';
import PreviewProjectButton from 'modules/project/components/PreviewProjectButton';

export default function TemplateCard({
  template,
  onSelect,
  showPreviewButton,
  locked,
  userIsClub,
  large = false,
  userIsLocal,
  ...props
}) {
  // Allows us to show the preview project button on the templates
  const previewButton = showPreviewButton ? (
    <PreviewProjectButton projectId={template.id} projectTemplate={template} color="#556aff" style={{width:'100%'}}>
      Preview
    </PreviewProjectButton>
  ) : (
    false
  );

  const primaryButton = locked
    ? {
        text: 'Upgrade',
        icon: 'arrow-up',
        action: () => (window.location.href = '/upgrade')
      }
    : {
        text: 'Select',
        icon: 'plus',
        action: () => {
          onSelect && onSelect(template.id);
        }
      };

  let image = '';
  let video = false;

  if(template.template_video){
        video = template.template_video
  }else {
        image = template.template_image_url ? template.template_image_url : 'http://via.placeholder.com/380x214';
  }

  return (
    <Card
      large={large}
      primaryButton={primaryButton}
      title={template.template_name}
      description={template.description}
      images={image}
      layerPreview={video}
      previewButton={previewButton}
      downloadUrl={template.downloadable_assets}
      locked={locked}
      // userIsClub={userIsClub}
      // templateIsClub={template.club_template}
      // userIsLocal={userIsLocal}
      // templateIsLocal={template.local_template}
      {...props}
    />
  );
}
