import axios from 'axios';
import config from 'config';

export function logout() {
  // Fuck knows why I gota do this here!!
  axios.post(config.API.ROOT + 'logout', {}, {
    headers: {
      'Authorization': 'Bearer ' + window.localStorage.getItem('auth-token')
    }
  })
    .then(function (response) {
      window.localStorage.removeItem('auth-token');
      window.location.href = '/login';
    })
    .catch(function (error) {
      error({text: 'Error logging you out of the system'})
    });
}