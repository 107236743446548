import {call, put, takeLatest} from 'redux-saga/effects';
import {COPY_VIDEO_TO_PROJECT} from 'modules/media/media';
import fetch from 'utils/saga/fetch';
import swal from 'sweetalert2';
import {updatePageLoadingState} from "../../pageLoader";
import {receiveMediaItem} from "../media";

export default function* copyVideoToProjectSaga() {
    yield takeLatest(COPY_VIDEO_TO_PROJECT, copyVideoToProject);
}

function* copyVideoToProject({projectId, mediaId}) {
    yield put(updatePageLoadingState(true));

    const res = yield fetch(`media/copyToProject/${mediaId}/${projectId}`, {
        method: 'POST',
        body:{}
    });

    if (!res.ok) {
        swal({
            title: 'Error',
            text: 'There was a problem copying this media item.',
            type: 'warning',
            confirmButtonColor: '#ff6961',
            confirmButtonText: 'Got it!',
        });
    }

    const media = yield res.json();
    yield put(receiveMediaItem(media, media.project_id));

    yield put(updatePageLoadingState(false));
}
