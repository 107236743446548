import { put } from 'redux-saga/effects';
import { authenticate, deauthenticate, authError } from 'modules/auth/auth';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { setToken } from '../../authUtils';
import config from 'config';
import {error} from "../../../../utils/alert";

export default function* handleAuthenticate(res) {
  try {

    if (!res.ok || res.status !== 200) {

      let errorText = 'There has been an error, please contact support';

      /** If server is down status will == 0 and res.json() will fail without */
      /** being able to set a proper error for the user **/
      if(res.status) {
        const { userMessage, message } = yield res.json();
        if(message) errorText = message;
        if(userMessage) errorText = userMessage;
      }

      yield put(updatePageLoadingState(false));
      yield authError(errorText);
      throw new Error(errorText);
    }

    const { token, user } = yield res.json();

    if (typeof user.parent_user_id === 'undefined') {
      window.location.reload();
    }

    setToken(token);
    yield put(authenticate(token, user));
  } catch (e) {
    if (config.ENV !== 'production') console.error('Authentication Error\n,', e.message);
    yield put(updatePageLoadingState(false));
    setToken(null);
    return yield put(deauthenticate(e.message));
  }
}
