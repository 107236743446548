import {select, put, takeEvery} from 'redux-saga/effects';
import {LINK_CONNECTOR, REMOVE_CONNECTOR} from 'modules/composer/composer';
import {updateElement} from 'modules/element/element';
import {updateNode} from 'modules/node/node';

export default function* updateConnectorSaga() {
  yield takeEvery([LINK_CONNECTOR, REMOVE_CONNECTOR], updateConnector);
}

function* updateConnector({element_type, id, toNodeId}) {
  const action = 'playNode';

  if (element_type) {
    yield put(
      updateElement(element_type, id, {
        action: action,
        actionArg: toNodeId
      })
    );
    return;
  }

  yield put(
    updateNode(id, {
      completeAction: action,
      completeActionArg: toNodeId
    })
  );
}
