import React from 'react';
import 'components/Table.scss';
import { connect } from 'react-redux';
import {
    accountDetailsSelector,
//   updateAccountDetails
} from 'modules/account/account';
import {  addCustomList, deleteCustomList } from 'modules/customLists/customList';
import { customListsSelector } from 'modules/customLists/customListSelectors';
import Button from 'components/Buttons/Button';
// import IconButton from 'components/Buttons/IconButton';
import { Option, TextInput, BooleanInput } from 'components/PropertyEditor';
import { CSVLink } from 'react-csv';
import Icon from 'components/Icon';
import { error } from 'utils/alert';
import AccountNav from "./AccountNav";
import PageBody from 'components/PageBody'
import { confirm } from 'utils/alert';

@connect(accountDetailsSelector, {})
@connect(customListsSelector,{ addCustomList, deleteCustomList })
export default class AccountCustomEmailLists extends React.Component {
    constructor() {
        super();

        this.state = {
            customListName: '',
        };
    }

    changeListName = (e, val) => {
        this.setState({customListName: val})
    };

    enterListName = e => {
        e.key === 'Enter' && this.createCustomList();
    };

    createCustomList = () => {
        const { customListName } = this.state;

        if (!customListName.length) {
            return error({ text: 'Please fill the custom list name.' });
        }

        this.props.addCustomList({ customList: customListName });
        this.setState({customListName:''})
    };

    filterEmails = emails => {
        return emails.map((item, key) => {
            const fields = {
                ID: ++key,
                Email: item.email,
                'Created At': item.created_at
            };
            if (item.name) fields.Name = item.name;
            return fields;
        });
    };

    renderUpgradeMsg() {
        return(
            <div style={{paddingTop: '10px'}}>
                <h3><strong><i className={'fa fa-lock'}></i>Exclusive Feature</strong></h3>
                <div>
                    <p style={{marginTop: '10px'}}>
                        Custom Email lists are a feature exclusively available for club users and agencies. You can upgrade your account <a href="http://special.interactr.io/interactr-club/a.html" target="_blank">here</a> or head over the docs to read more about what you can do with custom lists.
                    </p>
                </div>
                <div>
                    <p>
                        <Button primary onClick={()=>{
                            window.open('http://special.interactr.io/interactr-club/a.html', '_blank');
                        }}>Upgrade Now</Button>
                    </p>
                </div>

            </div>
        )
    }

    onDeleteList = (listId) => () => {
        confirm({
            title: 'Are You Sure!',
            text: 'Are You Sure You Want To Delete This List?',
            confirmButtonText: 'Yes, Delete It!',
            onConfirm:  () => {
                this.props.deleteCustomList(listId)
            }
        })

    }

    renderCustomListSection() {
        const { customListName } = this.state;
        const { customLists } = this.props;

        return(

            <div style={{paddingRight: '50px'}}>
                <div className="form-control">
                    <div className="grid" style={{alignItems: 'center', paddingTop: '0px'}}>
                        <div className="col7" >
                            <Option
                                label="Create new list"
                                Component={TextInput}
                                name="customListName"
                                value={customListName}
                                onChange={this.changeListName}
                                onKeyPress={this.enterListName}
                                placeholder={'List Name'}
                            />
                        </div>
                        <div className="col3" style={{marginTop : '-5px'}}>
                            <Button primary  onClick={this.createCustomList} >
                                <Icon name="plus" /> Create
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="form-control">
                    <table>
                        <thead>
                        <tr>
                            <th>List Name</th>
                            <th>Email Count</th>
                            <th>Download</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            customLists.map(list => (
                                <tr key={list.id}>
                                    <td>{list.custom_list_name}</td>
                                    <td>{list.email_count}</td>
                                    <td>
                                        <CSVLink
                                            data={this.filterEmails(list.emails)}
                                            filename={'user-email-lists.csv'}
                                        >
                                            <Icon name="download" />
                                        </CSVLink>
                                        <a onClick={this.onDeleteList(list.id)} style={{marginLeft: '30px'}}>
                                            <Icon name='trash-alt'/>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    render() {
        const { user } = this.props ;
        return (
            <PageBody
                heading="Your Surveys"
            >
                <div style={{marginLeft: '30px'}}>
                    <h2>Coming Soon</h2>
                    <h4>This feature is not quite ready yet. We're going through some final testing and should have it ready in the next couple of weeks</h4>
                </div>
            </PageBody>
        );
    }
}
