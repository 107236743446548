import flatten from 'lodash/flatten';
import { fromJS, Map } from 'immutable';
import { RECEIVE_ACTIVE_PROJECT_NODES, VIEW_NODE_PAGE } from 'modules/node/node';
import { keyBy } from 'utils/immutableUtils';

const initialState = fromJS({
  selected: null, // null == the node itself
  data: {},
  wizard: {
    open: false,
    elementType: '',
    elementPosition: { x: 50, y: 50 },
  },
});

function interactionsReducer(state = initialState, action) {
  if (action.id) {
    action.id = action.id.toString();
  }

  switch (action.type) {
    case VIEW_NODE_PAGE:
      return state.set('selected', null);

    case TOGGLE_ELEMENT_WIZARD:
      return state.updateIn(['wizard'], () => {
        const { type: unneeded, ...data } = action;
        return action.open ? data : initialState.get('wizard');
      });

    case RECEIVE_ACTIVE_PROJECT_NODES:
      // Pull the interactions out of the nodes so that we can store them here.
      return state.set('data', keyBy(flatten(action.nodes.map((node) => node.interactions))));

    case RECEIVE_INTERACTION:
      return state.setIn(['data', action.id], fromJS(action.data));

    case UPDATE_INTERACTION:
      return state
        .mergeIn(['data', action.id], fromJS(action.data))
        .set('selected', state.get('selected') ? { ...state.get('selected'), ...action.data } : action.data);

    case SELECT_INTERACTION:
      // accept either the interaction itself or
      if (typeof action.interaction === 'string') {
        return state.set('selected', state.getIn(['data', action.interaction]));
      }
      return state.set('selected', action.interaction);

    case INTERACTION_DELETED:
      return state.deleteIn(['data', action.id]).set('selected', null);
  }

  return state;
}

export const reducers = {
  interactions: interactionsReducer,
};

export const SELECT_INTERACTION = 'interaction:SELECT_INTERACTION';
export function selectInteraction(interaction) {
  return {
    type: SELECT_INTERACTION,
    interaction,
  };
}

export const UPDATE_INTERACTION = 'interaction:UPDATE_INTERACTION';
export function updateInteraction(id, data) {
  return {
    type: UPDATE_INTERACTION,
    id,
    data,
  };
}

export const RECEIVE_INTERACTION = 'interaction:RECEIVE_INTERACTION';
export function receiveInteraction(data) {
  return {
    type: RECEIVE_INTERACTION,
    id: data.id, // just so we can reuse the UPDATE_INTERACTION reducer
    data,
  };
}

export const ADD_INTERACTION = 'interaction:ADD_INTERACTION';
export function addInteraction({ projectId, nodeId, ...data }, callback) {
  return {
    type: ADD_INTERACTION,
    projectId,
    nodeId,
    ...data,
    callback,
  };
}

export const UPDATE_INTERACTION_ELEMENT_GROUP = 'interaction:UPDATE_INTERACTION_ELEMENT_GROUP';
export function updateInteractionElementGroup(interactionId, elementGroupId) {
  return {
    type: UPDATE_INTERACTION_ELEMENT_GROUP,
    interactionId,
    elementGroupId,
  };
}

export const DELETE_INTERACTION = 'interaction:DELETE_INTERACTION';
export function deleteInteraction(id) {
  return {
    type: DELETE_INTERACTION,
    id,
  };
}

export const INTERACTION_DELETED = 'interaction:INTERACTION_DELETED';
export function interactionDeleted(id) {
  return {
    type: INTERACTION_DELETED,
    id,
  };
}

export const DUPLICATE_INTERACTION = 'interaction:DUPLICATE_INTERACTION';
export function duplicateInteraction(id, callback) {
  return {
    type: DUPLICATE_INTERACTION,
    id,
    callback,
  };
}

export const TOGGLE_ELEMENT_WIZARD = 'interaction:TOGGLE_ELEMENT_WIZARD';
export function toggleElementWizard({ open, elementType, elementPosition }) {
  return { type: TOGGLE_ELEMENT_WIZARD, open, elementType, elementPosition };
}
