import { createSelector } from 'reselect';
import { nodeIdSelector, currentProjectIdSelector, nodeElementGroupSelector } from 'modules/node/nodeSelectors';
import { toJS } from 'utils/immutableUtils';
import { idSelector } from 'utils/selectorUtils';
import { selectedElementSelector } from 'modules/element/elementSelectors';
import { joinWithElement } from 'modules/element/elementUtils';
import { projectSelector } from 'modules/project/projectSelectors';
import { TRIGGER_ELEMENT } from 'shared/element';

const interactionsStateSelector = state => state.interactions;

export const interactionsSelector = createSelector(
  interactionsStateSelector,
  state => state.elements.get('data'),
  // elementsSelector,
  (state, elements) => {
    let interactions = state.get('data');

    return {
      interactions: interactions.map(interaction => {
        // We join interactions with their elements here.
        return joinWithElement(interaction, elements);
      })
    };
  }
);

const modalsStateSelector = state => state.modals;

export const selectedInteractionSelector = createSelector(
  interactionsStateSelector,
  nodeElementGroupSelector,
  modalsStateSelector,
  // selectedElementSelector,
  (state, { elementGroups }, modalsState) => {
    const selectedInteraction = toJS(state.get('selected'));

    let interactionElementGroup = null;
    // let selectedModal = null;
    const selectedModal = toJS(modalsState.get('selected'));
    const selectedModalElement = toJS(modalsState.get('elementSelected'));
    if (selectedInteraction) {
      if (selectedModal && selectedModalElement ) {
        // Append a selectedElement property to the modal if found
        selectedModal.selectedElement = selectedModal.elements.find(
          e => e.element_id == selectedModalElement.id && e.element_type == selectedModalElement.element_type
        ) ;
      }

      const elementGroupId = selectedInteraction.element_group_id;
      if (elementGroupId) {
        interactionElementGroup = toJS(elementGroups.get(elementGroupId.toString()));
      }
    }

    if (!elementGroups.isEmpty()) {
      elementGroups = toJS(elementGroups.valueSeq());
    }

    return {
      elementGroups,
      interactionElementGroup,
      selectedInteraction,
      selectedModal
    };
  }
);

export const selectedInteractionElementGroupSelector = createSelector(
  selectedInteractionSelector,
  ({ interactionElementGroup }) => interactionElementGroup
);

/**returns interactions for node if passed , and defaults to node id from
 * router or props . default will work only on Class components that 
 * either have a router wrapper or a nodeId prop
 * the selector creator works for useSelector hook where u pass nodeId directly
 * in HOF
*/
export const getInteractionsForNodeSelector = (nodeId) => createSelector(
  interactionsSelector,
  interactionsStateSelector,
  nodeIdSelector,
  nodeElementGroupSelector,
  ({ interactions }, state, nodeIdFromRouterOrProps, { elementGroups }) => ({
    selected: state.get('selected'),
    interactions: interactions
      .filter(el => el.get('node_id') == (nodeId || nodeIdFromRouterOrProps) )
      .toList()
      .sortBy(el => el.get('id'))
      .toJS(),
    elementGroups: toJS(elementGroups)
  })

)
export const interactionsForNodeSelector = createSelector(
  interactionsSelector,
  interactionsStateSelector,
  nodeIdSelector,
  nodeElementGroupSelector,
  ({ interactions }, state, nodeId, { elementGroups }) => ({
    selected: state.get('selected'),
    interactions: interactions
      .filter(el => el.get('node_id') == nodeId) 
      .toList()
      .sortBy(el => el.get('id'))
      .toJS(),
    elementGroups: toJS(elementGroups)
  })
);

export const interactionTimelineSelector = interactionsForNodeSelector;
export const interactionEditorSelector = interactionsForNodeSelector;

export const interactionSelector = createSelector(interactionsSelector, idSelector, ({ interactions }, id) =>
  toJS(interactions.get(id))
);

/** returns a selector for all interactions that have given element type
 * along with the element attached
 * @param {String} type the element type ie: App\ButtonElement
 */
export const getInteractionsWithTypeSelector = type => {
  return createSelector(
    interactionsSelector,
    // state => state.elements,
    ({ interactions }) => {
      return {
        interactions: interactions
          .filter(i => i.get('element_type') == type)
          .toList()
          .toJS()
        // elements: elements.getIn(['data', type]).toJS()
      };
    }
  );
};
