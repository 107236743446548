import { createSelector } from 'reselect';
import { toJS } from 'utils/immutableUtils';
import { whiteLabelSelector } from 'modules/pageLoader/pageLoaderSelectors';

const rawState = state => state;
const authStateSelector = state => state.auth;

export const authUserSelector = createSelector(
	authStateSelector,
	state => state.get('user')
);

export const authenticatedSelector = createSelector(
	authUserSelector,
	user => user !== null
);

export const notAuthenticatedSelector = createSelector(
	authenticatedSelector,
	authenticated => !authenticated
);

export const authenticatingSelector = createSelector(
	authStateSelector,
	state => state.get('authenticating')
);

export const loginPageSelector = createSelector(
	authStateSelector,
	whiteLabelSelector,
	(state, whitelabel) => ({
		error: state.get('error'),
		emailOK: state.get('emailOK'),
		whitelabel: whitelabel.whitelabel
	})
);

export const topNavSelector = createSelector(
	authUserSelector,
	whiteLabelSelector,
	(user, whitelabel) => ({
		whitelabel: whitelabel.whitelabel,
		user: toJS(user)
	})
);

export const userSelector = createSelector(
	authUserSelector,
	whiteLabelSelector,
	(state, {whitelabel}) => {
		const user = toJS(state);
		return { user , whitelabel };
	}
);

export const isSuperuserSelector = createSelector(
	authUserSelector,
	state => {
		const user = toJS(state);
		return {
			superuser: user.superuser === 1
		};
	}
);

export const globalSearchSelector = createSelector(
	rawState,
	rawState => {
		const projects = rawState.projects
			.getIn(['data', 'projects'])
			.toList()
			.toJS()
			.map(el => ({ ...el, type: 'project', searchable: _strNormalize(el.title) }));
		const videos = rawState.media
			.get('data')
			.toList()
			.toJS()
			.map(el => ({ ...el, type: 'media', searchable: _strNormalize(el.name) }));
		const subUsers = rawState.users
			.get('data')
			.toList()
			.toJS()
			.map(el => ({ ...el, type: 'subUser', searchable: _strNormalize((el.name +' '+ el.email)) }));
		const nodes = rawState.nodes
			.get('all')
			.toList()
			.toJS()
            .map(el => {
                let name = el.name;
                // If node name is not null convert to lower case.
                if (name) {
                    name = _strNormalize(el.name);
                }

                return ({ ...el, type: 'node', searchable: name });
            });

		let searchables = projects.concat(subUsers, nodes, videos) // will be same order with which results show up on the search component
		return { searchables };
	}
);

/** trims lowercase string to normalize the search string for filtering */
function _strNormalize(str){ return str.toLowerCase().trim()}
