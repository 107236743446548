import React from 'react';
import styles from './Button.scss';
import Icon from 'components/Icon';
import cx from 'classnames';
import {connect} from 'react-redux';
import {whiteLabelSelector} from "modules/pageLoader/pageLoaderSelectors";

@connect(whiteLabelSelector, {})
export default class Button extends React.Component{
  static defaultProps = {
    rightIcon : false,
  }
  render(){
    const {
      className,
      primary,
      secondary,
      color,
      large,
      red,
      right,
      left,
      grouped,
      white,
      loading,
      noFloat,
      noMarginRight,
      greyDark,
      icon,
      rightIcon,
      children,
      text,
      whitelabel,
      xxs,
      small,
      disabled,
      ...myProps
    }= this.props;

    const classes = cx(className, styles.Button, {
      [styles.primary]: primary,
      [styles.secondary]: secondary,
      [styles.large]: large,
      [styles.red]: red,
      [styles.right]: right,
      [styles.left]: left,
      [styles.grouped] : grouped,
      [styles.white] : white,
      [styles.greyDark] : greyDark,
      [styles.noFloat]: noFloat,
      [styles.noMarginRight]: noMarginRight,
      [styles.rightIcon] : rightIcon,
      [styles.xxs] : xxs,
      [styles.small] : small,
      [styles.disabled] : disabled,
    });

    let {style} = this.props;
    let inlineStyles = {};

    if (whitelabel){
      //inlineStyles.backgroundImage = 'none';
      if (primary){
        inlineStyles.background =  whitelabel.primary_color;
      } else if (secondary){
        inlineStyles.background =  whitelabel.secondary_color;
      }
    }
    if(color){
      inlineStyles.background = color;
    }

    const newStyles = {...style, ...inlineStyles};


    const theIcon = (loading) ? 'spinner-third' : icon;

    return (
      <button {...myProps} disabled={disabled} className={classes} style={newStyles}>
        {icon && !rightIcon && <Icon name={theIcon} spin={loading} className={styles.icon} />}
        {children || text}
        {icon && rightIcon && <Icon name={theIcon} spin={loading} className={styles.icon} />}
      </button>
    );
  }
}
