import React from 'react';
import {connect} from 'react-redux';
// import Select from 'react-select';

import {viewProjectStatsPage, updateProject} from 'modules/project/project';
import {updatePageLoadingState} from 'modules/pageLoader/pageLoader';
import {projectStatsSelector} from 'modules/stat/statSelectors';
// import ConversionPixelBuilder from 'modules/stat/components/conversionPixelBuilder';

// import Icon from 'components/Icon';
import Link from 'components/Link';
import PageStyles from 'components/Page.scss';

import Icon from 'components/Icon';

// https://airbnb.io/react-dates/?path=/story/daterangepicker-drp--default
import { DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';

// import Styles from './ProjectStats.scss';
import ProjectStatsMetrics from "./ProjectStatsMetrics";
import ProjectPage from './ProjectPage';
import ImpressionsChart from "./Charts/ImpressionsChart";
import ProjectViewsChart from "./Charts/ProjectViewsChart";
import EngagementChart from "./Charts/EngagementChart";
import 'tui-chart/dist/tui-chart.css';
import TuiChart from 'tui-chart';

// const ProjectStatsMetrics = React.lazy(() => import(/* webpackChunkName: 'ProjectStatsMetrics' */ './ProjectStatsMetrics'))

@connect(projectStatsSelector, { viewProjectStatsPage, updateProject, updatePageLoadingState })
export default class ProjectStatsPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      startDate: moment().subtract(30, 'day'),
      endDate: moment(),
      focusedInput: false,
      active: 'impressions'
    }
  }

    async componentDidMount() {
      const { viewProjectStatsPage, match } = this.props;
    viewProjectStatsPage(match.params.projectId);
  }

  updateCurrency = value => {
    const { project } = this.props;

    project.currency = value;
    this.props.updateProject(project);
  };

  render() {
    const { project, stats, statInfo, updatePageLoadingState, nodes, user, projectGroups } = this.props;
    const {active} = this.state;

    if (project === undefined) {
      return null;
    }

    if (project) {
      //  Add a little delay here makes the UI nicer and the loade
      setTimeout(() => {
        updatePageLoadingState(false);
      }, 1000);
    }

    if(project.legacy){
      return (
          <ProjectPage
              header="Analytics"
              headerInfo={<Link>watch the training</Link>}
              project={project}
              projectGroups={projectGroups}
              active="analytics"
          >
            <div className={PageStyles.container}>
              <ProjectStatsMetrics project={project} />
            </div>
          </ProjectPage>
      );
    }

    var theme = {
      series: {
        colors: [
          '#366fe0', '#41c186', '#ff6961', '#0eb6ac', '#556aff',
          '#7b8bff', '#71a0ee', '#f7882f', '#fcd534', '#6e3667'
        ]
      }
    };

    TuiChart.registerTheme('myTheme', theme);


    return (
        <ProjectPage
            header={
              <ul className={PageStyles.subPagesTabs}>
                <li className={(active==='impressions') ? PageStyles.subPagesTabs_active : null} onClick={()=>this.setState({active: 'impressions'})}>
                  <Icon name="eye" /> Impressions
                </li>
                <li className={(active==='views') ? PageStyles.subPagesTabs_active : null} onClick={()=>this.setState({active: 'views'})}>
                  <Icon name="play" /> Views
                </li>
                <li className={(active==='engagement') ? PageStyles.subPagesTabs_active : null} onClick={()=>this.setState({active: 'engagement'})}>
                  <Icon name="mouse-pointer" /> Engagement
                </li>
              </ul>
            }
            project={project}
            projectGroups={projectGroups}
            active="analytics"
            buttons={
              <div>
                <DateRangePicker
                    anchorDirection="right"
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                    isOutsideRange={() => false}
                    maxDate={moment()}
                />
              </div>
            }
        >
        <div className={PageStyles.container} style={{marginLeft: '30px'}}>
          { (active === 'impressions') && <ImpressionsChart project={project} startDate={this.state.startDate} endDate={this.state.endDate} /> }
          { (active === 'views') && <ProjectViewsChart project={project} startDate={this.state.startDate} endDate={this.state.endDate} /> }
          { (active === 'engagement') && <EngagementChart project={project} startDate={this.state.startDate} endDate={this.state.endDate} /> }
        </div>
      </ProjectPage>
    );
  }
}
