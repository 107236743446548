import {select, takeLatest, put} from 'redux-saga/effects';
import {CREATE_USER, receiveUser} from 'modules/user/user';
import fetch from 'utils/saga/fetch';
import {success, error} from 'utils/alert';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {topNavSelector} from "modules/auth/authSelectors";

export default function* createUserSaga() {
    yield takeLatest([CREATE_USER], createUser);
}

function* createUser(action) {
    yield put(updatePageLoadingState(true));
    let user = action.user;

    const res = yield fetch('user', {
        method: 'post',
        body: user
    });

    yield put(updatePageLoadingState(false));

    const data = yield res.json();

    if(!data.success) {
        error({text: data.message});
    }

    yield put(receiveUser(data.user));
    action.callback && action.callback();
}
