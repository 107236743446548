import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import styles from './DashboardSidebar.scss';
import Icon from 'components/Icon';

// import { injectStyles } from 'utils/domUtils';
import cx from 'classnames';
import AccountAvatar from '../AccountAvatar';
import MenuItem from '../MenuItem';
import {whiteLabelSelector} from "modules/pageLoader/pageLoaderSelectors";
import getAsset from 'utils/getAsset';
import SubUserMenu from "./SubUserMenu";
import ParentUserMenu from "./ParentUserMenu";

const _proptypes = {
  user: PropTypes.object.isRequired,
  projects: PropTypes.array,
  projectGroups: PropTypes.array.isRequired,
  updateSelectedFolder: PropTypes.func.isRequired,
  whitelabel: PropTypes.any
};

@connect(whiteLabelSelector, {})
class Sidebar extends React.Component {
  constructor({ match }) {
    super(...arguments);
    this.state = {
      activeTab: match.url.split('/')[1],
      parentData: null
    };
  }

  componentDidMount() {
    const {user} = this.props;
    const isSubUser = !!user.parent_user_id;

    if (isSubUser) {
      this.fetchParentUserFromStorage()
    }
  }

  fetchParentUserFromStorage = () => {
    const {user} = this.props;
    const storageKey = 'logged_in_sub_user_' + user.id;

    const parent = sessionStorage.getItem(storageKey);
    const parseData = JSON.parse(parent);
    console.log('parentData::', parseData);
    if (parseData && typeof parseData === 'object' && parseData.hasOwnProperty('parent')) {
      const {parent} = parseData;
      this.setState({parentData: parent});
    }
  };

  // ======= Crappy and Hacky as hell way to style active tab
  // while considering whitelabel custom colors ==========
  setClasses = tab => {
    return cx([styles.menu_item], {
      [styles.active]: tab === this.state.activeTab
      // active: whitelabel && tab === this.state.activeTab,
      // whitelabel: (whitelabel && whitelabel.primary_color) || null
      // pile classes here as per 'classnames' rules
    });
  };

  //  @todo Get back to this and figure a better way to handle this
  // removing this in interactr 3 design for now til we know we'll need it

  // renderWhitelabelStyles = () => {
  //   const { whitelabel } = this.props;
  //   if (whitelabel) {
  //     const styles = `
  //       .active.whitelabel {
  //         color: ${whitelabel.primary_color}
  //         background: rgba(2, 132, 97, 0.32);
  //       }
  //       .active.whitelabel:after {
  //         background:${whitelabel.primary_color};
  //         border-radius: 10px;
  //         content: " ";
  //         height: 85%;
  //         left: 0;
  //         position: absolute;
  //         top: 50%;
  //         transform: translateY(-50%);
  //         width: 6px; }
  //         .whitelabel {position:relative} ;
  //       `;

  //     injectStyles('whitelabel_sb', styles);
  //   }

  //   return null;
  // };
  handleFolderClick = folderId => e => {
    e.preventDefault();
    this.props.updateSelectedFolder(folderId);
    this.props.history.push('/projects');
  };
  render() {
    const { user, projectGroups, updateSelectedFolder, whitelabel } = this.props;
    const isNotWhitelabel = whitelabel === false;
    const isSubUser = !!user.parent_user_id;
    const sidebarWhitelabelStyles = {};

    if (whitelabel) {
      sidebarWhitelabelStyles.background = whitelabel.background_colour;
    }

    return (
      <aside className={styles.sidebar_wrapper} style={sidebarWhitelabelStyles}>
        <AccountAvatar user={user} parentData={this.state.parentData} />

        { (isSubUser) ?
            <SubUserMenu setClasses={this.setClasses}  /> :
            <ParentUserMenu user={user} setClasses={this.setClasses} />
        }

        {!whitelabel ? (
            <React.Fragment>
              {(user.evolution===1) && <img src="https://convertri.imgix.net/e0567d6a-ed3f-11e6-829d-066a9bd5fb79/e27a6c52d4c57749b546e9b39f878c8bb96c819b/interactr%20evolution%20logo%20-%20green%20blue.png" className={styles.sidebar_logo}/>}
              {(user.evolution===0) && <img src="https://s3.us-east-2.amazonaws.com/interactrapp.com/img/logo-new.png" className={styles.sidebar_logo} />}
              {(user.evolution===1) && <img src={getAsset('/img/evolution-logo.png')} className={styles.sidebar_logo_small} />}
              {(user.evolution===0) && <img src="https://s3.us-east-2.amazonaws.com/interactrapp.com/interactr.png" className={styles.sidebar_logo_small} />}
            </React.Fragment>
        ): (
            <>
              <img src={whitelabel.logo} className={styles.sidebar_logo} />
              <img src={whitelabel.icon} className={styles.sidebar_logo_small} />
            </>
          )}

      </aside>
    );
  }
}

Sidebar.propTypes = _proptypes;
export default Sidebar;
