import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {GENERATE_PROJECT_THUMBNAIL} from 'modules/project/project';
import {timeForSeconds} from 'utils/timeUtils';
import {updatePageLoadingState} from 'modules/pageLoader/pageLoader';
import {success, error} from 'utils/alert';

export default function* generateProjectThumbnailSaga() {
  yield takeLatest(GENERATE_PROJECT_THUMBNAIL, generateProjectThumbnail);
}

function* generateProjectThumbnail({projectId, time, url, onComplete}) {
  yield put(updatePageLoadingState(true));

  const res = yield fetch(`projects/${projectId}/generateThumbnail`, {
    method: 'POST',
    timeout: 100000,
    body: {time, url}
  });

  if (res.status !== 200) {
    error({text:'Unable To Save Project'})

  }
  yield put(updatePageLoadingState(false));
  onComplete()
}
