import {takeLatest} from 'redux-saga/effects';
import {AUTHENTICATE, DEAUTHENTICATE} from 'modules/auth/auth';
import {setToken} from 'modules/auth/authUtils';

export default function* storeTokenSaga() {
  yield takeLatest([AUTHENTICATE, DEAUTHENTICATE], storeToken);
}

function* storeToken({type, token}) {
  if (type === DEAUTHENTICATE) {
    setToken(null);
    return;
  }

  setToken(token);
}
