import React from 'react';
import ManageUserProjects from './ManageUserProjects';
import { TextInput, Option, BooleanInput } from '../../../components/PropertyEditor/PropertyEditor';
import swal from 'sweetalert2';
import Button from 'components/Buttons/Button';
import IconButton from 'components/Buttons/IconButton';
import { deleteUser, updateUser, createUser, fetchUsers, saveUser } from 'modules/user/user';
import { connect } from 'react-redux';
import { usersSelector } from '../userSelectors';
import { toJS } from 'utils/immutableUtils';
import PropTypes from 'prop-types'

const _props = {
  isAuthSuperUser : PropTypes.bool.isRequired,
  /** set if we're an agency editing sub user as opposed to admin editing any user */
  forAgencyUsers : PropTypes.bool.isRequired,
  /** id of selected user  */
  selectedUser: PropTypes.oneOfType([
    PropTypes.string,PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.func,
}

@connect(
  usersSelector,
  { deleteUser, updateUser, createUser, saveUser }
)
class UserForm extends React.Component {
  initialUserData = {
    name: '',
    email: '',
    projects: [],
    password: ''
  };
  
  state = {
    newUser: this.initialUserData
  };

  getUser = () => {
    const { selectedUser, users } = this.props;

    if (selectedUser) {
      // Get user from users
      return users.find(user => user.id === selectedUser);
    }

    return this.state.newUser;
  };

  handleSave = () => {
    const { createUser, saveUser, selectedUser, masterUser, isAuthSuperUser } = this.props;
    let user = this.getUser();

    if (selectedUser) {
      // Update User
      saveUser(user.id, user, this.resetSelected);
    } else {
      //Create New
      if (isAuthSuperUser) {
        if (!user.advanced_analytics) {
          user.max_projects = 3;
          user.advanced_analytics = 0;
        }
      } else {
        user.parent_user_id = toJS(masterUser).id;
      }
      createUser(user, this.resetSelected);
    }
  };

  changeHandler = (key, delay = true) => (e, value) => {
    const { updateUser, selectedUser } = this.props;

    if (selectedUser) {
      updateUser(selectedUser, { [key]: value }, delay);
    } else {
      this.updateInLocalState(key, value);
    }
  };

  updateInLocalState = (key, val) => {
    let user = { ...this.state.newUser };
    user[key] = val;
    this.setState({ newUser: user });
  };

  deleteConfirm = () => {
    const { deleteUser } = this.props;
    const user = this.getUser();

    swal({
      title: 'Delete user',
      text: 'Are you sure you wish to delete this user?',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#ff6961',
      confirmButtonText: 'Delete user'
    }).then(() => {
      deleteUser(user.id, this.resetSelected);
    });
  };

  resetSelected = () => {
    this.props.reset();
    this.setState({ newUser: this.initialUserData });
    //window.location.reload();
  };

  render() {
    const { isAuthSuperUser, forAgencyUsers } = this.props;
    const user = this.getUser();
    if(! user) return null;
    const {
      name,
      email,
      projects,
      password,
      is_club,
      is_agency,
      evolution,
      evolution_pro,
      evolution_club,
      masterclass,
      is_local,
      advanced_analytics,
      read_only
    } = user;

    return (
      <div>
        <div className="modal-heading">User Management</div>
        <div className="modal-body">
          <div className="grid">
            <div className="col6">
              <Option
                label="Name"
                value={name}
                Component={TextInput}
                onChange={this.changeHandler('name')}
              />
              <Option
                label="Email"
                value={email}
                Component={TextInput}
                placeholder="me@myemail.com"
                onChange={this.changeHandler('email')}
              />
              <Option
                label="Password"
                value={password}
                Component={TextInput}
                onChange={this.changeHandler('password')}
              />
              {forAgencyUsers && (
                <Option
                  label="Read Only"
                  value={read_only}
                  Component={BooleanInput}
                  onChange={this.changeHandler('read_only', false)}
                />
              )}
            </div>
            <div className="col6">
              {forAgencyUsers && (
                <ManageUserProjects
                  selectedProjects={projects}
                  onChange={this.changeHandler}
                />
              )}
              {isAuthSuperUser && (
                  <div className="grid">
                    <div className="col6">
                      <Option
                          label="Commercial"
                          value={advanced_analytics}
                          Component={BooleanInput}
                          onChange={this.changeHandler('advanced_analytics', false)}
                      />
                      <Option
                          label="Club"
                          value={is_club}
                          Component={BooleanInput}
                          onChange={this.changeHandler('is_club', false)}
                      />
                      <Option
                          label="Local"
                          value={is_local}
                          Component={BooleanInput}
                          onChange={this.changeHandler('is_local', false)}
                      />
                      <Option
                          label="Agency"
                          value={is_agency}
                          Component={BooleanInput}
                          onChange={this.changeHandler('is_agency', false)}
                      />
                    </div>
                    <div className="col6">
                      <Option
                          label="Evolution"
                          value={evolution}
                          Component={BooleanInput}
                          onChange={this.changeHandler('evolution', false)}
                      />
                      <Option
                          label="Evolution Pro"
                          value={evolution_pro}
                          Component={BooleanInput}
                          onChange={this.changeHandler('evolution_pro', false)}
                      />
                      <Option
                          label="Evolution Club"
                          value={evolution_club}
                          Component={BooleanInput}
                          onChange={this.changeHandler('evolution_club', false)}
                      />
                      <Option
                          label="Masterclass"
                          value={masterclass}
                          Component={BooleanInput}
                          onChange={this.changeHandler('masterclass', false)}
                      />
                    </div>
                  </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {user.id ? (
            <IconButton red onClick={this.deleteConfirm} style={{ float: 'left' }} icon="trash-alt">
              Delete User
            </IconButton>
          ) : null}
          <Button primary onClick={this.handleSave}>
            Save
          </Button>
          <Button onClick={this.resetSelected}>Back</Button>
        </div>
      </div>
    );
  }
}

UserForm.propTypes = _props ;
export default UserForm;