import {put, takeEvery} from 'redux-saga/effects';
import {DELETE_INTERACTION, interactionDeleted} from 'modules/interaction/interaction';
import {elementDeleted} from 'modules/element';
import fetch from 'utils/saga/fetch';
import swal from 'sweetalert2';

export default function* deleteInteractionSaga() {
  yield takeEvery(DELETE_INTERACTION, deleteInteraction);
}

function* deleteInteraction({id}) {
  const conf = yield swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to delete this element.',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#ff6961',
    confirmButtonText: 'Yes, Delete It!',
  });


  const res = yield fetch(`interactions/${id}`, {
    method: 'DELETE'
  });

  const {success, interaction} = yield res.json();
  if(success){ 
    yield put(interactionDeleted(id));
    if(interaction) yield put(elementDeleted(interaction.element_type , interaction.element_id))
  }
}
