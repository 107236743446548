import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { viewProjectPage } from 'modules/project/project';
import { projectSelector } from 'modules/project/projectSelectors';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';

import Composer from 'modules/composer/components/Composer';
import MediaLibrary from 'modules/media/components/MediaLibrary';

import PreviewProjectButton from './PreviewProjectButton';
import ProjectPage from './ProjectPage';
import Link from 'components/Link';

import styles from './ProjectCanvasPage.scss';
import MediaLibraryButton from './MediaLibraryButton';

@connect(
  projectSelector,
  { viewProjectPage, updatePageLoadingState }
)
class ProjectCanvasPage extends React.Component {
  componentDidMount() {
    this.props.viewProjectPage(this.props.match.params.projectId);
  }

  render() {
    const { loading, project, updatePageLoadingState, projectGroups } = this.props;

    if (loading || !project) {
      return null;
    }

    if (project) {
      //  Add a little delay here makes the UI nicer and the loade
      setTimeout(() => {
        updatePageLoadingState(false);
      }, 500);
    }

    return (
      <ProjectPage
        header="Project Canvas"
        headerInfo={<Link>watch the training</Link>}
        project={project}
        projectGroups={projectGroups}
        active="canvas"
        style={{ padding: 0 }}
      >
        <div className={styles.canvas_body}>
          <Composer style={{ height: '100%' }} projectId={project.projectId} />
          <div className={styles.media_library}>
            <MediaLibrary toggleLibrary={() => this.setState({ open: false })} project={project} />
          </div>
        </div>
      </ProjectPage>
    );
  }
}

export default withRouter(ProjectCanvasPage);
