import {createSelector} from 'reselect';
import {nodesSelector, nodeSelector} from 'modules/node/nodeSelectors';
import {projectSelector} from 'modules/project/projectSelectors';
import {interactionsForNodeSelector} from 'modules/interaction/interactionSelectors';
import {projectModalsSelector} from "../modal/modalSelectors";
import {getElementsAsJS} from 'modules/element/elementSelectors';
import find from 'lodash/find';
import forEach from 'lodash/forEach';

export const composerStateSelector = state => state.composer;

export const zoomSelector = createSelector(composerStateSelector, ({zoom}) => ({
  zoom
}));

export const dragSelector = createSelector(
  composerStateSelector,
  ({drag}) => drag
);

export const composerSelector = createSelector(
  composerStateSelector,
  nodesSelector,
  (composer, {nodes}) => ({
    ...composer,
    nodes
  })
);

export const startPositionSelector = createSelector(
  nodesSelector,
  projectSelector,
  zoomSelector,
  ({nodes}, {project}, {zoom}) => {
    if (!project || !nodes) return {};
    const node = nodes.get(project.start_node_id.toString());
    if (!node) return {};

    const {posX: x, posY: y} = node.toJS();
    return {x: x + 5, y: y + 96, projectId: project.id, zoom};
  }
);

// connectors for a node
export const connectorsSelector = createSelector(
  nodeSelector,
  interactionsForNodeSelector,
    projectModalsSelector,
    getElementsAsJS,
  ({node}, {interactions}, {modals}, {elements}) => {

    let connectors = [node, ...interactions].reduce((memo, item) => {

        if(item.element_type==="App\\TriggerElement"){
            // generate connectors for modal elements
            const modal = find(modals, {id: parseInt(item.element.actionArg)});
            if(modal && modal.elements && modal.elements.length){
                forEach(modal.elements, el=>{
                    const element = find(elements[el.element_type], {id: el.element_id});
                    if(element && element.actionArg) {
                        const connector = generateConnector({
                            element,
                            element_type: el.element_type,
                            id: element.id,
                        });
                        if (connector) {
                            memo.push(connector);
                        }
                    }
                })
            }

        } else {
            const connector = generateConnector(item);
            if (connector) {
                memo.push(connector);
            }
        }

      return memo;
    }, []);

    if (!connectors.length) {
      // we always want at least 1 connector so we can connect nodes.
      connectors = [generateConnector({...node, completeAction: 'playNode'})];
    }

    return {
      connectors,
      x: node.posX,
      y: node.posY
    };
  }
);

function generateConnector({
  element,
  completeAction,
  completeActionArg,
  element_type,
  name,
  id,
  element_id
}) {
  let action = element ? element.action : completeAction;
  let actionArg = element ? element.actionArg : completeActionArg;
  if (action !== 'playNode') {
    return;
  }

  return {
    element_type,
    // element_id over interaction.id. fallback to node.id
    id: element_id || id,
    to: {
      nodeId: actionArg
    },
    name
  };
}
