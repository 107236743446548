import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PositionableElement from './PositionableElement';
import ButtonElement from './ButtonElement';
import { getStyles, getElementMeta, FORM_ELEMENT, getPrefixedProps, prefixProps } from 'shared/element';
import { appendPlaceholderColorToDom } from 'utils/domUtils';

const _props = {
  name_placeholder_text: PropTypes.string,
  positionable: PropTypes.bool.isRequired,
  show_name_field: PropTypes.bool,
  on_one_line: PropTypes.bool,
  input_color: PropTypes.string,
  onUpdate: PropTypes.func.isRequired
};

const randomString = length => {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export default function FormElement(props) {
  const [editing, setEditing] = useState(false);
  const className = randomString(6);
  const {
    show_name_field,
    on_one_line,
    padding,
    backgroundColour,
    borderRadius,
    button_paddingSides,
    name_placeholder_text,
    email_placeholder_text,
    positionable,
    
  } = props;

  const toggleEditState = state => () => {
    setEditing(state);
  };

  const changeHandler = prefix => (changedData) => {
  // console.log('props.......', changedData)
   const buttonProps = prefixProps(changedData, prefix);
  //  console.log('prefix props', buttonProps)
   if(typeof props.onUpdate == 'function') props.onUpdate(buttonProps);
  };

  function renderInput({ placeholder, className }) {
    const { on_one_line, input_color } = props;
    let styles = { ...getElementMeta(FORM_ELEMENT).inputStyles, ...getPrefixedProps(props, 'input_') };

    appendPlaceholderColorToDom(input_color, className);

    if (on_one_line) {
      styles.marginRight = getElementMeta(FORM_ELEMENT).elementSpacing + 'px';
      styles.marginBottom = 0;
      styles.flexGrow = 1;
    } else {
      styles.marginBottom = getElementMeta(FORM_ELEMENT).elementSpacing + 'px';
      styles.flexGrow = 0;
    }

    return <input type="text" style={styles} placeholder={placeholder} className={className} />;
  }

  const wrapperStyles = {
    flexDirection: on_one_line ? 'row' : 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingLeft: padding + 'px',
    paddingRight: padding + 'px',
    backgroundColor: backgroundColour,
    borderRadius: borderRadius
  };

  const renderForm = () => (
    <div style={wrapperStyles}>
      {!!show_name_field && renderInput({ placeholder: name_placeholder_text, className })}
      {renderInput({ placeholder: email_placeholder_text, className })}
      <div style={{ flex: 1, flexGrow: 0 }}>
        <ButtonElement
          onStartEdit={toggleEditState(true)}
          onStopEdit={toggleEditState(false)}
          onUpdate={changeHandler('button_')}
          height={getElementMeta(FORM_ELEMENT).buttonHeight}
          paddingLeft={button_paddingSides + 'px'}
          paddingRight={button_paddingSides + 'px'}
          positionable={false}
          disableResize // disables resizing of the button
          {...getPrefixedProps(props, 'button_')}
        />
      </div>
    </div>
  );


  return (
    <PositionableElement disabled={editing || !positionable} {...props}>
      {renderForm()}
    </PositionableElement>
  );
}
// FormElement.propTypes = _props;
FormElement.defaultProps = {
  positionable : true,
}