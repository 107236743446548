export function secondsForTime(timeString) {
  let [m, s, ms] = timeString.split(':').map(str => parseInt(str, 10));
  let [mString, sString, msString] = timeString.split(':');

  if (isNaN(ms)) {
    ms = 0;
  }

  if (isNaN(s)) {
    s = 0;
  }

  if (isNaN(m)) {
    m = 0;
  }

  ms = parseFloat("0."+msString);

  return ((m * 60) + s) + ms;
}

export function timeForSeconds(seconds, initMount = false, ignoreMs = false) {
  if (!seconds) {
    if (ignoreMs) {
      return "00:00";
    }
    return "00:00:000";
  }

  const minutesBeforeFormat = Math.floor(seconds / 60); // 2
  const m = minutesBeforeFormat.toString().padStart(2, 0);

  const leftOverSeconds = seconds - (minutesBeforeFormat * 60); // 50.2

  const secondsBeforeFormat = Math.floor(leftOverSeconds); // 50
  const s = secondsBeforeFormat.toString().padStart(2, 0);

  const secondSplit = seconds.toString().split(".");
  let ms = "";
  if (secondSplit[1] === undefined) {
      if (initMount) {
          ms = "000";
      }
  } else {
      ms = secondSplit[1].substr(0, 3);
  }
  // ignoreMs
  let response = `${m}:${s}`;

  if (!ignoreMs) {
    response += `:${ms}`;
  }
  return response;
}