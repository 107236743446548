import {takeLatest, put} from 'redux-saga/effects';
import {ADD_PROJECT_GROUP, UPDATE_PROJECT_GROUP, receiveProjectGroup} from 'modules/project/project';
import fetch from 'utils/saga/fetch'
import {isMethod} from 'utils/domUtils';
import history from 'browserHistory';
import {projectPath} from 'routeBuilders';
import {success, error} from 'utils/alert';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";

export default function* saveProjectGroupSaga() {
    yield takeLatest([ADD_PROJECT_GROUP, UPDATE_PROJECT_GROUP], saveProjectGroup);
}

function* saveProjectGroup(action) {
    const dataBody = {title: action.title};
    const method = (action.type === ADD_PROJECT_GROUP) ? 'POST' : 'PUT';

    if (method === 'PUT') {
        dataBody.folderId = action.folderId
    }

    const res = yield fetch('projectGroup', {
        method,
        body: dataBody
    });

    const {data} = yield res.json();

    yield put(receiveProjectGroup(data));
}
