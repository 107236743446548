import React from 'react';
import { connect } from 'react-redux';
import { fetchAgency, updateAgency, saveAgency, verifyDomain } from 'modules/agency/agency';
import { agencySelector } from 'modules/agency/agencySelectors';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import styles from 'modules/project/components/ProjectSettingsPage.scss';
import { TextInput, Option } from 'components/PropertyEditor';
import Button from 'components/Buttons/Button';
import ColorPicker from 'components/ColorPicker';
import DropImageZone from 'modules/media/components/DropImageZone';
import InputImageExample from 'components/InputImageExample';
import Label from 'components/Label';
import UserManagementModal from 'modules/user/components/UserManagementModal';
import PageBody from 'components/PageBody';
import Upgrade from 'components/Upgrade';
import AgencySubNav from './AgencySubNav';
import { userSelector } from '../../auth/authSelectors';
import getAsset from 'utils/getAsset';
import swal from 'sweetalert2'

@connect(
  userSelector,
  {}
)
@connect(
  agencySelector,
  { fetchAgency, updatePageLoadingState, updateAgency, saveAgency, verifyDomain }
)
export default class AgencyPage extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    if (!this.props.agency) this.props.fetchAgency();
  }

  changeHandler = name => (e, val) => {
    const { updateAgency } = this.props;
    updateAgency({ [name]: val });
  };

  alertSuccess = () => {
    swal({
      title: 'Success',
      text: 'Upload successful',
      type: 'success',
      confirmButtonText: 'Got it!',
    });
  }

  handleLogoSuccess = image => {
    const { updateAgency } = this.props;
    updateAgency({ logo: image.src });
    this.alertSuccess()
  };
  
  handleIconSuccess = image => {
    const { updateAgency } = this.props;
    updateAgency({ icon: image.src });
    this.alertSuccess()
  };

  handleDomainChange = (e, val) => {
    const { updateAgency } = this.props;
    updateAgency({ domain : val, domain_verified: false})
  }
  renderUpgrade = () => {
    const { errors } = this.props;
    if (errors && errors.unauthorized) {
      return (
        <Upgrade
          text="To use the Agency feature you must upgrade!"
          url="http://special.interactr.io/agency-academy/a.html"
        />
      );
    }
  };

  verifyDomain = () => {
    const { agency, verifyDomain } = this.props;
    verifyDomain(agency.domain)
  };
  render() {
    const { agency, errors, saveAgency, users, user } = this.props;

    const { name, domain, page_title, primary_color, domain_verified, secondary_color, background_colour, icon, logo } = agency;
    return (
      <PageBody heading="Agency Settings" right={<AgencySubNav />}>
          <div style={{ padding: '0px 30px' }}>
            {user.is_agency ? (
              <div className="grid" style={{ paddingBottom: '50px' }}>
                <div className="col6">
                  <div className={styles.formWrapper}>
                    <h3 className="form-heading">Agency App Details</h3>
                    <Option
                      label="Name"
                      value={name}
                      Component={TextInput}
                      placeholder="My Awesome App"
                      onChange={this.changeHandler('name')}
                    />
                    
                    <div className="d-flex justify-between align-center mt-1 mb-2"> 
                      <div className="flex-1">
                      <Option
                        label={<span>Domain </span> }
                        value={domain}
                        Component={TextInput}
                        placeholder="www.mydomainname.com"
                        onChange={this.handleDomainChange}
                      />
                      </div>
                      {!domain_verified ? (
                          <Button className="ml-1" secondary onClick={this.verifyDomain} style={{marginTop: '0px'}}>
                            Verify
                          </Button>
                      ) : (
                          <Button icon="check" className="ml-1" greyDark style={{marginTop: '0px'}}>
                            Verified
                          </Button>
                      )}
                    </div>

                    <div
                      style={{
                        textAlign: 'left',
                        display: 'block',
                        marginTop: '-10px',
                        marginBottom: '15px',
                        width: '100%',
                        backgroundColor: '#f3f3f3',
                        padding: '15px'
                      }}
                    >
                      <p>
                        <strong>Setting up your custom domain</strong>
                      </p>
                      <p>
                        <strong>Step 1</strong> Create a CNAME record on your webhost from your custom subdomain to
                        custom.interactrapp.com
                      </p>
                      <p>
                        <strong>Step 2</strong> Enter your subdomain name in the field above. Do not include http or /. Then hit the 
                        verify button , it'll let you know if you successfully set up the subdomain.
                      </p>
                      <p>
                        <em>
                          <u>Note: </u> If your getting an unable to load app error this means that the domain name in
                          the above field does not match the domain name in the URL bar these need to be the same for it
                          to work.
                        </em>
                      </p>
                    </div>

                    <div className="form-control">
                      <label>Logo</label>
                      <DropImageZone directory="logos" onSuccess={this.handleLogoSuccess} src={logo} />
                    </div>

                    <Option
                      label="Primary Color"
                      value={primary_color}
                      Component={ColorPicker}
                      stackOrder={2}
                      onChange={this.changeHandler('primary_color')}
                      style={{ marginTop: 20 }}
                    />
                    <Option
                      label="Secondary Color"
                      value={secondary_color}
                      Component={ColorPicker}
                      stackOrder={1}
                      onChange={this.changeHandler('secondary_color')}
                    />
                    <Option
                        label="Background Colour"
                        value={background_colour}
                        Component={ColorPicker}
                        stackOrder={3}
                        onChange={this.changeHandler('background_colour')}
                    />
                    <Option
                      label="Page Title"
                      value={page_title}
                      Component={TextInput}
                      placeholder="My App - My Strapline"
                      onChange={this.changeHandler('page_title')}
                    />
                    <InputImageExample
                      image={getAsset("/img/pageTitleExample.png")}
                      title="Example Page Title"
                      height={213}
                      width={692}
                    />
                    <div className="form-control">
                      <label>Page Icon</label>
                      <DropImageZone directory="icons" onSuccess={this.handleIconSuccess} src={icon} />
                    </div>
                    <InputImageExample
                      image={getAsset("/img/pageIconExample.png")}
                      title="Example Page Icon"
                      height={213}
                      width={692}
                    />

                    <div className="form-control">
                      <Button icon="save" primary large onClick={() => saveAgency()}>
                        Save Changes
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col6">
                  <div className={styles.formWrapper}>
                    <UserManagementModal users={users} isAuthSuperUser={false} forAgencyUsers={true} width={700} height={600} />
                  </div>
                </div>
              </div>
            ) : (
              <Upgrade
                text="To use the Agency feature you must upgrade!"
                url="http://special.interactr.io/agency-academy/a.html"
              />
            )}
          </div>
      </PageBody>
    );
  }
}
