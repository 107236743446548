import React from 'react';
import Select from 'react-select';

export default class StockListCategoryFilter extends React.Component {
  state = {
    selectedCategory: ''
  };

  changeSelectedCategories = ({value}) => {
    this.setState({selectedCategory: value});
    this.props.onChange && this.props.onChange(value);
  };

  getOptionsForCategories = () => {
    const {availableCategories} = this.props;

    return availableCategories.map(item => {
      return {
        value: item,
        label: item
      };
    });
  };

  render() {
    return (
      <Select
        name="stock-list-select-tag"
        value={this.state.selectedCategory}
        onChange={this.changeSelectedCategories}
        clearable={false}
        placeholder="Search by Categories"
        options={this.getOptionsForCategories()}
        menuContainerStyle={{'zIndex': 100}}
      />
    );
  }
}
