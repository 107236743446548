import React from 'react';
import PositionableElement from './PositionableElement';

export default class ImageElement extends React.Component {
  state = {
    aspectRatio: 1
  }

  // we have to constrain image aspect ratio else dragging experience
  // is weird
  handleLoad = e => {
    const {naturalWidth, naturalHeight} = e.target;
    this.setState({
      aspectRatio: naturalWidth / naturalHeight
    });
  };

  handlePosUpdate = ({ width, height, ...pos}) => {
    // TODO: really this should live closer to the resizable code

    // ASPECT RATIO CODE
    // const {aspectRatio} = this.state;
    // if (width / height !== aspectRatio) {
    //   console.log(width, this.props.width)
    //   if (width !== this.props.width) {
    //     height = width / aspectRatio;
    //   } else if(height !== this.props.height) {
    //     width = height * aspectRatio;
    //   }
    // }
    
    this.props.onUpdate({...pos, width, height})
  }

  render() {
    const {src} = this.props;

    return (
      <PositionableElement
        {...this.props}
        onUpdate={this.handlePosUpdate}
        style={{
          border: !src && '1px dashed #eee'
        }}
      >
        {src && (
          <img
            src={src}
            style={{width: '100%', height: '100%'}}
            draggable={false}
            onLoad={this.handleLoad}
          />
        )}
      </PositionableElement>
    );
  }
}
