import React from 'react';
import {LineChart} from '@toast-ui/react-chart';
import map from 'lodash/map';
import find from 'lodash/find';
import {allNodesSelector} from 'modules/node/nodeSelectors'
import {connect} from "react-redux";

@connect(allNodesSelector, {})
export default class DropOffChartNode extends React.Component {
    constructor(props) {
        super(props);
    }

    getNodeName(nodeId){
        const node = find(this.props.nodes, {id: parseInt(nodeId)});
         
        return (node) ? node.name : nodeId;
    }

    getViewCountAsPercentage(node){
        const res = node.map(n => {
            return 10;
        })

        // Make sure it starts at 100%;
        return [100].concat(res);
    }

    render(){
        const {data} = this.props;

        if(! data) return null;

        var d = {
            categories: ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
            series: map(data.nodes, (node, id)=>{
                delete node.node_id
                return {
                    name: this.getNodeName(id),
                    data: map(node, v=>(v))
                }
            })
        };

        var options = {
            chart: {
                width: 1160,
                height: 540,
                title: 'Node Drop Off'
            },
            yAxis: {
                title: 'Total Viewers',
            },
            xAxis: {
                title: '% Of Node Viewed',
                pointOnColumn: true,
                tickInterval: 'auto'
            },
            series: {
                showDot: false,
                zoomable: true
            },
            tooltip: {
                suffix: 'Viewers'
            },
        };

        return(
            <LineChart
                data={d}
                options={options}
            />
        );
    }
}