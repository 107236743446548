import {Map, fromJS} from 'immutable';

export function alphabeticalSorter(field) {
  return (a, b) => {
    return a.get(field).localeCompare(b.get(field));
  };
}

// Checks if an Immutable Map matches the passed props
export function mapContains(map, props = {}) {
  return Object.keys(props).every(key => {
    return map.get(key) === props[key];
  });
}

// Predicate version of mapContains.
// i.e. blah.find(whereContains({a: 1, b: 2}))
export function whereContains(props) {
  return map => mapContains(map, props);
}

export function toJS(obj) {
  if (obj && obj.toJS) {
    return obj.toJS();
  }
  return obj;
}

// Convert an array into a Map
export function keyBy(arr, keyProp = 'id', initialValue = Map()) {
  return arr.reduce((result, obj) => {
    const key = obj[keyProp];
    return result.set(key.toString(), fromJS(obj));
  }, initialValue);
}

export function swap(list, idxA, idxB) {
  const a = list.get(idxA);
  const b = list.get(idxB);

  return list.set(idxA, b).set(idxB, a);
}
