import {fromJS} from 'immutable';
import {keyBy} from 'utils/immutableUtils';
import {VIEW_PROJECT_STATS_PAGE} from 'modules/project/project';

const initialState = fromJS({
  generatedPixelCode: '',
  pixelLoading: false
});

function statsReducer(state = initialState, action) {
  switch (action.type) {
    case GENERATE_PIXEL_CODE:
      return state.set('pixelLoading', true);
    case SAVE_GENERATED_PIXEL_CODE:
      return state.set('generatedPixelCode',action.code).set('pixelLoading', false);
    case VIEW_PROJECT_STATS_PAGE:
      return state.set('generatedPixelCode', '').set('pixelLoading', false);
  }
  return state;
}

export const reducers = {
  stats: statsReducer
};

export const VIEW_STATS_LIST_PAGE = 'stat::VIEW_STATS_LIST_PAGE';
export function viewStatsListPage() {
  return {
    type: VIEW_STATS_LIST_PAGE
  };
}

export const GENERATE_PIXEL_CODE = 'stat::GENERATE_PIXEL_CODE';
export function generatePixelCode(projectId, price) {
  return {
    type: GENERATE_PIXEL_CODE,
    projectId,
    price
  }
}

export const SAVE_GENERATED_PIXEL_CODE = 'stat::SAVE_GENERATED_PIXEL_CODE';
export function saveGeneratedPixelCode(code) {
  return {
    type: SAVE_GENERATED_PIXEL_CODE,
    code
  }
}