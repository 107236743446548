function listsReducer(state = {}, action) {
  switch (action.type) {
    case VIEW_SELECT_LIST:
      // When we improve VIEW_SELECT_LIST to not always fetch, the loading logic will need updating
      return { ...state, loading: true };
    case RECEIVE_LISTS:
      if (action.integrationType && action.lists)
        return { ...state, [action.integrationType]: action.lists, loading: false };
      else return { ...state, loading: false };
    case SAVED_INTEGRATION:
      return { ...state };
  }

  return state;
}

export const reducers = {
  lists: listsReducer
};

export const SAVE_INTEGRATION = 'integration:SAVE_INTEGRATION';
export function saveIntegration(integrationType, data) {
  return {
    type: SAVE_INTEGRATION,
    integrationType,
    data
  };
}

export const SAVED_INTEGRATION = 'integration:SAVED_INTEGRATION';
export function savedIntegration(user) {
  return {
    type: SAVED_INTEGRATION,
    user
  };
}

export const VIEW_SELECT_LIST = 'integration:VIEW_SELECT_LIST';
export function viewSelectList(integrationType, subUserId, callback) {
  return {
    type: VIEW_SELECT_LIST,
    integrationType,
    subUserId,
    callback
  };
}

export const RECEIVE_LISTS = 'integration:RECEIVE_LISTS';
export function receiveLists(integrationType, lists) {
  return {
    type: RECEIVE_LISTS,
    integrationType,
    lists
  };
}