import isomorphicFetch from 'isomorphic-fetch';
import config from 'config';
import {delay} from 'redux-saga';
import {select, call, race, put} from 'redux-saga/effects';
import {getToken} from 'modules/auth/authUtils';
import {deauthenticate} from 'modules/auth/auth';

// Generator wrapping fetch api and adding auth headers + api root to url
export default function* FETCH(url, options = {}, ...args) {
  url = config.API.ROOT + url;

  options.headers = options.headers || {};

  if (options.body && !(options.body instanceof FormData)) {
    options.body = JSON.stringify(options.body);
    options.headers['Content-Type'] = 'application/json';
    options.headers['Accept'] = 'application/json';
  }

  // Add auth token
  const token = getToken();
  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`;
  }

  try {
    const {timedOut, response} = yield race({
      timedOut: delay(options.timeout || config.API.REQUEST_TIME_OUT),
      response: call(isomorphicFetch, url, options, ...args)
    });

    if (timedOut) {
      throw 'timed out';
    }

    // clone to be able to use the response stream again down the line as it's
    // meant to only be "consumed" once hence the clone usage
    const responseClone =  response.clone();
    
    // make simple deauthenticate way if token is not provided
    if (responseClone.status === 400) {
        const res = yield responseClone.json();

        if (res.hasOwnProperty('error') && res.error === "token_not_provided") {
            yield put(deauthenticate());
        }
    }

    //deauth if necessary
    if (response.status === 401) {
      yield put(deauthenticate());
    }

    return response;
  } catch (err) {
    console.error(`Failed to fetch ${url}\nerror: ${err}\noptions : `, options);
    return Response.error();
  }
}
