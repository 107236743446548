import React from 'react';
import Button from "components/Buttons/Button";
import IconButton from "components/Buttons/IconButton";
import Rodal from 'components/Rodal';
import Icon from 'components/Icon';
import StockVideos, { Categories } from 'utils/stockMediaVideos';
import styles from './StockListModalStyles.scss';
import debounce from 'lodash/debounce';
import StockListCategoryFilter from "./StockListCategoryFilter";
import StockItem from "./StockItem";
import Spinner from 'components/Spinner';
import {userSelector} from "modules/auth/authSelectors";
import { addMediaUrl } from 'modules/media/media';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {error} from 'utils/alert';
import ReactPaginate from 'react-paginate';
import cx from "classnames";

@connect(userSelector, { addMediaUrl })
class StockListModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            availableCategories: [],
            searchTerm: '',
            page: 1,
            selectedCategory: '',
            adding: false,
            videos: [],
            fetchAgain: false,
            showLoadMoreButton: true,
            loading: true,
            totalHits: 1,
            perPage: 20
        };

        this.debounceSetFilterText = debounce(this.setFilterText, 300);
    }


  shouldComponentUpdate(nextProps, nextState){
    // Define what props should force update
    if (nextProps.showStockList !== this.props.showStockList){
      return true;
    }
    if (nextProps.user !== this.props.user){
      return true;
    }

    // Update if local state changes
    return (! isEqual(this.state, nextState));
  };

  componentDidMount() {
    this.loadAvailableTags();
  }

  loadAvailableTags() {
    this.setState({availableCategories: ['No Category', ...Categories]});
  }

  addStockItem = (stockVideoID, mp4Link, thumbnail_url, name) => {
    const {projectId, close, addMediaUrl} = this.props;
    // close modal
    close();

    addMediaUrl(mp4Link, { stockVideoID, thumbnail_url, projectId, name, stockVideo: true });
  };

  fetchVideos = async () => {
    const { videos, page, selectedCategory, searchTerm, fetchAgain } = this.state;


    if (!videos.length || fetchAgain) {
      const params = { page };

      if (selectedCategory) {
          params.category = selectedCategory;
      }

      if (searchTerm) {
          params.q = encodeURIComponent(searchTerm);
      }

      return StockVideos(params)
            .then(videos => videos);
    }
    return videos;
  };

  filterFetchedData = (state, totalHits, videos) => {
      const { searchTerm, selectedCategory, fetchAgain } = state;
      const collect = {};
      // if selected category clean and draw again.
      if (fetchAgain) {
          collect.fetchAgain = !fetchAgain;
      }

      collect.videos = [...videos];
      collect.totalHits = totalHits;
      collect.loading = false;

      return collect;
  };

  fetchStockLists = () => {
    const { fetchAgain, videos } = this.state;
    if (!videos.length && this.props.showStockList || fetchAgain) {
      this.fetchVideos()
          .then(data => {
              this.setState(state => this.filterFetchedData(state, data.totalHits, data.hits) );
              console.log(data);
          }).catch(text => {
              error({text});
              this.setState({loading: false})
          })
    }

    return videos;
  };

  showPagination = () => {
      const {videos} = this.state;
      const videosAreNotEmpty = videos.length;

      return videosAreNotEmpty && this.getMaxPageCount() > 1;
  }

  renderStockList = () => {
    const {videos} = this.state;

    if (videos.length) {
        return videos.map(videoItem => (
            <StockItem
                item={videoItem}
                key={"stock_item_" + videoItem.id}
                showStockList={this.props.showStockList}
                addStockItem={this.addStockItem}
                user={this.props.user}
                setFilterText={this.setFilterText}
            />
        ));
    }

    return (
      <div className={cx(styles.noVideosContainer, 'grid')}>
          <label>
              <h3>
                  No Results
              </h3>
          </label>
      </div>
    );
  }

  loadMoreStockListItems = data => {
      this.setStateAndFetch({
        page: ++data.selected,
        loading: true
    });
  };

  setFilterText = (value, tagClick = false) => {
    if (value) {
        return this.setStateAndFetch({
            searchTerm: value,
            loading: tagClick,
            page: 1
        });
    }

    return this.setStateAndFetch({
        cleanSearchFilter: true
    });
  };

  changeCategories = (category) => {
      if (category !== 'No Category') {
          return this.setStateAndFetch({
              selectedCategory: category,
              page: 1
          });
      }

      this.setStateAndFetch({
          cleanCategoryFilter: true
      });
  };

  setStateAndFetch = (options = {}) => {
    this.setState(state => {
        let defaultOption = { fetchAgain: !state.fetchAgain },
            collect = {};

        if (state.searchTerm) defaultOption.searchTerm = state.searchTerm;
        if (state.selectedCategory) defaultOption.selectedCategory = state.selectedCategory;

        if (!isEmpty(options)) {
            if (options.hasOwnProperty('selectedCategory')) options.loading = true;
            if (options.hasOwnProperty('cleanSearchFilter')) options.searchTerm = '';
            if (options.hasOwnProperty('cleanCategoryFilter')) options.selectedCategory = '';

            collect = Object.assign(defaultOption, options);
        } else {
            collect = defaultOption;
        }

        return collect;
    });
  };

  getMaxPageCount = () => {
      const {totalHits, perPage} = this.state;
      return totalHits / perPage;
  }

  render() {
      const { loading } = this.state;
      const { close, showStockList, user } = this.props;

      this.fetchStockLists();

      return (
          <Rodal
              visible={showStockList}
              onClose={close}
              height={675}
              width={1000}
          >
              <div>
                  <div className="modal-heading">
                      <Icon name="list" /> Video Library
                  </div>

                  <div className="modal-body">
                    <div className={styles.filterWrapper}>
                      <div className={styles.filterLeftWrapper}>
                          Search: <input name="filter" className={styles.filterInput} onChange={(e) => this.debounceSetFilterText(e.target.value)} placeholder="Filter by tags" />
                      </div>
                      <div className={styles.filterRightWrapper}>
                          Categories:
                          <StockListCategoryFilter
                              availableCategories={this.state.availableCategories}
                              onChange={this.changeCategories}
                          />
                      </div>
                    </div>
                      <div>
                        {
                        ( user.is_club || user.evolution_pro) ? null :
                          <div style={{textAlign: 'center', display:'block', marginTop:'5px', marginBottom: '5px', width: '100%', backgroundColor: '#f3f3f3', paddingTop: '5px', paddingBottom: '5px'}}>
                            <p>Our Video Library is only available to Evolution Pro Members. <Link to="/upgrade">Upgrade Here</Link></p>
                          </div>
                        }
                        {loading ?
                          (<div className={styles.spinnerWrapper}>
                              <Spinner/>
                          </div>)
                          :
                          <div className={styles.listWrapper}>
                              {this.renderStockList()}
                            {/*{*/}
                            {/*    (isMore && showLoadMoreButton) &&*/}
                            {/*<div style={{*/}
                            {/*  textAlign: 'center',*/}
                            {/*  display: 'block',*/}
                            {/*  marginTop: '20px',*/}
                            {/*  marginBottom: '20px',*/}
                            {/*  width: '100%'*/}
                            {/*}}>*/}
                            {/*    <Button onClick={this.loadMoreStockListItems} noFloat={true}>*/}
                            {/*      Load More*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                            {/*}*/}
                          </div>
                        }
                      </div>
                    </div>

                  <div className="modal-footer">
                      { this.showPagination() ? (
                        <div className={styles.paginationContainer}>
                          <ReactPaginate
                              previousLabel={'Previous'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={this.getMaxPageCount()}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={data => this.loadMoreStockListItems(data)}
                              containerClassName={styles.pagination}
                              subContainerClassName={'pages pagination'}
                              activeClassName={'active'}
                          />
                        </div>
                      ) : null}
                      <Button  onClick={close} style={{float:'left', marginLeft: '10px'}}>
                          Close
                      </Button>
                  </div>
              </div>
          </Rodal>
      );
  }
}

export default StockListModal;
