import {take, put, call, fork, cancel, select} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import {
  UPDATE_INTERACTION,
  DELETE_INTERACTION,
  receiveInteraction
} from 'modules/interaction/interaction';
import {interactionSelector} from 'modules/interaction/interactionSelectors';
import {receiveElement} from 'modules/element/element';
import {splitElementFromObj} from 'modules/element/elementUtils';
import fetch from 'utils/saga/fetch';
import { error } from 'utils/alert';

const DELAY = 1400;

// {id: Task}
let tasks = {};

// Stores interactions with a debounce.
export default function* storeInteractionSaga() {
  while (true) {
    const action = yield take([UPDATE_INTERACTION, DELETE_INTERACTION]);
    const {id} = action;

    // cancel any current task
    const task = tasks[id];
    if (task) yield cancel(task);

    // kill task runner if interaction has been deleted
    if (action.type === DELETE_INTERACTION) return;

    // start new task
    tasks[id] = yield fork(storeInteractionTask, action);
  }
}

// we run one of these tasks for each interaction id
// it waits a while then stores changes so that we don't fire loads of
// requests at once.
function* storeInteractionTask(action) {
  yield delay(DELAY);

  yield call(storeInteraction, action.id);
}

function* storeInteraction(id) {
  const {element: unneeded, element_group, ...interaction} = yield select(
    interactionSelector,
    {id}
  );

  if(interaction.timeIn > interaction.timeOut) {
    return error({title: 'Timeline Error' , text:'The time-in of the element must be less than the time-out'});
  }

  const res = yield fetch('interactions', {
    method: 'PUT',
    body: interaction
  });

  const data = yield res.json();
  const [savedInteraction, element] = splitElementFromObj(data.interaction);
  yield put(receiveElement(element));
  yield put(receiveInteraction(savedInteraction));

}
