import {createSelector} from 'reselect';
import {toJS} from 'utils/immutableUtils';
import {authUserSelector} from 'modules/auth/authSelectors';
import {usersSelector} from 'modules/user/userSelectors';
import {selectedElementSelector} from 'modules/element/elementSelectors';

const INTEGRATIONS = [
  {name: 'aWeber', key: 'integration_aweber'},
  {name: 'Zapier', key: 'integration_zapier'},
  {name: 'Sendlane', key: 'integration_sendlane'},
  {name: 'Active Campaign', key: 'integration_activecampaign'},
  {name: 'Mailchimp', key: 'integration_mailchimp'},
  {name: 'Get Response', key: 'integration_getresponse'}
];

export const SUB_USER_INTEGRATIONS = [
    {name: 'Sendlane', key: 'integration_sendlane'},
    {name: 'Active Campaign', key: 'integration_activecampaign'},
    {name: 'Get Response', key: 'integration_getresponse'},
    {name: 'Mailchimp', key: 'integration_mailchimp'}
];

export const listsStateSelector = state => state.lists;

export const integrationsSelector = createSelector(authUserSelector, user => {
  return {
    integrations: INTEGRATIONS.map(integration => {
      return {...integration, config: toJS(user.get(integration.key))};
    })
  };
});

export const configuredIntegrationsSelector = createSelector(
  integrationsSelector,
  ({integrations}) => {
    return {
      integrations: integrations.filter(integration => integration.config)
    };
  }
);

export const configuredSubUserIntegrationsSelector = createSelector(
    (state, props) => props.subUserId,
    usersSelector,
    (subUserId, {users}) => ({
        integrations: SUB_USER_INTEGRATIONS,
        subUser : users.find(user => user.id === subUserId)
    })
);

export const integrationSelector = createSelector(
  (state, props) => props.integrationType,
  authUserSelector,
  (integrationType, user) => {
    return {
      data: toJS(user.get(integrationType))
    }
  }
);

export const listsSelector = createSelector(
  state => state.lists,
  (state, props) => props.integrationType,
  (lists, integrationType) => {
    return {
      lists: lists[integrationType],
      loading: lists.loading
    };
  }
);
