import React from 'react';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import Button from 'components/Buttons/Button';

export default class SimpleForm extends React.Component {
  static defaultProps = {
    data: {},
    button: <Button type="submit" primary>Save</Button>
  };

  constructor(props) {
    super(props);
    this.state = props.data || {};
  }

  handleChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value});
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(e, this.state);
  };

  componentWillReceiveProps({data}) {
    if (!isEqual(data, this.props.data)) {
      this.setState(data);
    }
  }

  renderField = (label, name) => {
    const {data} = this.props;

    let type = 'text';
    if (name === "password") {
      type = 'password';
    }

    return (
      <div key={name} className="form-control">
        <label>{label}</label>
        <input
          name={name}
          type={type}
          value={this.state[name] || ''}
          onChange={this.handleChange}
        />
      </div>
    );
  };

  render() {
    const {fields, button, ...props} = this.props;
    return (
      <form {...props} onSubmit={this.handleSubmit}>
        {map(fields, this.renderField)}
        {button}
      </form>
    );
  }
}
