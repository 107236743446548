import {takeLatest, put} from 'redux-saga/effects';
import {UPDATE_NODE,SAVE_NODE, receiveNode} from 'modules/node/node';
import fetch from 'utils/saga/fetch';
import {splitElementsFromContainers} from 'modules/element/elementUtils';
import {receiveElements} from 'modules/element/element';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {success, error} from 'utils/alert';

export default function* updateNodeSaga() {
  yield takeLatest([UPDATE_NODE, SAVE_NODE], saveNode);
}

function* saveNode({id, data, pageLoader , localStateOnly}) {
  // Ugly hack for updating only the local redux store which gets done in the 
  // reducer , this flag prevents us from doing an api call to DB
  if(localStateOnly) return ;
  if(pageLoader) {
    yield put(updatePageLoadingState(true));
  }

  const res = yield fetch('nodes', {
    method: 'PUT',
    body: {id, ...data}
  });

  // TODO: increases performance - uncomment if we need to re store backend data
  if(pageLoader) {
    yield put(updatePageLoadingState(false));

    if (res.status !== 200) {
      error({text:'Unable to save node'})
    }
  }

  return;

  // const node = yield res.json();

  // const [interactions, elements] = splitElementsFromContainers(
  //   node.interactions
  // );

  // yield put(receiveElements(elements));
  // yield put(receiveNode({...node, interactions}));

  // return (res.status === 200);
}
