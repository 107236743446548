import {takeLatest, put} from 'redux-saga/effects';
import {
    VIEW_TEMPLATE_LIST,
    receiveTemplates
} from 'modules/template/template';
import fetch from 'utils/saga/fetch';

export default function* fetchTemplatesSaga() {
  yield takeLatest([
      VIEW_TEMPLATE_LIST,
  ], fetchTemplates);
}

function* fetchTemplates({table}) {
    const res = yield fetch(`templates/${table}`);
    const templates = yield res.json();
    yield put(receiveTemplates(templates));
}
