import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

// stuff
import {
  selectInteraction,
  updateInteraction,
  deleteInteraction,
  duplicateInteraction,
} from 'modules/interaction/interaction';
import { deleteModalElement, duplicateModalElement } from 'modules/modal/modal';
import { updateElementGroup as _updateElementGroup } from 'modules/node';
import { deselectElement, selectElement } from 'modules/element/element';
import { elements, TEXT_ELEMENT, DYNAMIC_TEXT_ELEMENT, TRIGGER_ELEMENT } from 'shared/element';
import styles from './InteractionProperties.scss';

// components
import { Section, Option, BooleanInput, TimeInput } from 'components/PropertyEditor';
import ElementContainerActions from 'modules/element/components/ElementContainerActions';
import ElementProperties from 'modules/element/components/Properties/ElementProperties';
import ModalProperties from 'modules/modal/components/ModalProperties';
import ModalElementsToolbar from 'modules/node/components/ModalElements';
import Icon from 'components/Icon';
import Button from 'components/Buttons/Button';
import ModalElementList from 'modules/modal/components/ModalElementList';

export default function InteractionProperties({ interaction, modal, interactionElementGroup, elementGroups, node }) {
  // const [initMount] = useState(false);

  const [activeTab, setActiveTab] = useState('interaction');

  const dispatch = useDispatch();

  
  const { id, element_type } = interaction;
  let { timeIn, timeOut } = interaction;

  if (interactionElementGroup) {
    timeIn = interactionElementGroup.timeIn;
    timeOut = interactionElementGroup.timeOut;
  }

  const modalElementSelected = modal && modal.selectedElement;

  const interactionLayer = node.interaction_layer_id === interaction.id;

  // go back to interaction tab when an element is selected
  useEffect(() => {
    modalElementSelected && setActiveTab('interaction');
  }, [modalElementSelected]);

  function handleSelectVideo() {
    dispatch(selectInteraction(null));
  }

  function handleCopy() {
    dispatch(duplicateInteraction(interaction.id));
  }

  function handleDelete() {
    if (modal && modal.selectedElement) {
      // Delete modal element
      dispatch(deselectElement(modal.selectedElement.id));
      dispatch(deleteModalElement(modal.id, modal.selectedElement.id));
    } else {
      dispatch(deleteInteraction(interaction.id));
    }
  }

  function handleSelectElement(type, id) {
    dispatch(selectElement(type, id));
  }

  const interactionPropertyChangeHandler = (name) => {
    return function (e, val)  {
      // const interactionId = interaction.id;
      const data = { [name]: val };
      if (name === 'timeIn' || name === 'timeOut') {
        window.forceUpdateTimelineBar = true; // horrible hack
  
        if (interactionElementGroup) {
          dispatch(_updateElementGroup({ id: interactionElementGroup.id, ...data }));
          // exit here so interaction doesn't get updated after this.
          return;
        }
      }
      dispatch(updateInteraction(interaction.id, data));
    };
  }

  function renderInteractionTab(interactionLayer, modalElementSelected) {
    return (
      <div>
        <div className="clearfix"></div>
        <div style={{ marginTop: '20px' }}>
          <ElementProperties
            node={node}
            editingContext="node"
            interactionPropertyChangeHandler={interactionPropertyChangeHandler}
            interaction={interaction}
            elementGroups={elementGroups}
          />
        </div>

        {/* We need to edit the modal itself as well here along with it's triggering interaction */}
        {modal && !modalElementSelected && (
          <ModalProperties
            modal={modal}
            selectedElement={modal.selectedElement}
            elements={modal.elements}
            selectElement={handleSelectElement}
            node={node}
          />
        )}

        {!interactionLayer && !modalElementSelected && (
          <Section icon="stopwatch" title="Timeline">
            <Option
              label="Time in (m:s::ms)"
              value={timeIn}
              onChange={interactionPropertyChangeHandler('timeIn', interaction)}
              Component={TimeInput}
            />
            {element_type !== TRIGGER_ELEMENT && (
              <Option
                label="Time out (m:s::ms)"
                value={timeOut}
                onChange={interactionPropertyChangeHandler('timeOut', interaction)}
                Component={TimeInput}
              />
            )}
          </Section>
        )}

        <ElementContainerActions
          onCopy={handleCopy}
          onDelete={handleDelete}
          interactionLayer={interactionLayer}
          modalElementSelected={modalElementSelected}
        />
      </div>
    );
  }

  const isActiveTab = (tabName) => activeTab === tabName;
  const buttonColor = isActiveTab('modal_elements') && !modalElementSelected ? 'secondary' : 'primary';
  const buttonText = isActiveTab('modal_elements') && !modalElementSelected ? 'Cancel' : 'Add New Element';
  const buttonIcon = isActiveTab('modal_elements') && !modalElementSelected ? 'times' : 'plus';
  const buttonClick = isActiveTab('modal_elements')
    ? () => {
        setActiveTab('interaction');
      }
    : () => {
        dispatch(deselectElement());
        setActiveTab('modal_elements');
      };

  return (
    <div>
      <Button onClick={handleSelectVideo} small secondary>
        <Icon name="angle-left" />
        Back
      </Button>

      <span className={styles.elementIcon}>{renderElementTypeIcon(element_type, interactionLayer)}</span>

      {modal && (
        <div className={styles.tab_headers_wrapper}>
          <h3
            style={{ opacity: '0.8' }}
            className={cx(styles.tab_header, { [styles.active]: isActiveTab('interaction') })}
            onClick={() => {
              setActiveTab('interaction');
            }}
          >
            <Icon name="sliders-v" /> Settings
          </h3>
          <h3 className={cx(styles.tab_header)} style={{ paddingBottom: '1px' }}>
            <Button
              small
              onClick={buttonClick}
              primary={buttonColor === 'primary'}
              secondary={buttonColor === 'secondary'}
            >
              <Icon name={buttonIcon} /> {buttonText}
            </Button>
          </h3>
        </div>
      )}

      {(activeTab === 'interaction' || modalElementSelected) && (
        <div>
          {renderInteractionTab(interactionLayer, modalElementSelected)}
          {interaction.element_type === 'App\\TriggerElement' && (
            <ModalElementList modal={modal} selectElement={handleSelectElement} interactionLayer={interactionLayer} />
          )}
        </div>
      )}
      {activeTab === 'modal_elements' && !modalElementSelected ? <ModalElementsToolbar modal={modal} /> : null}
    </div>
  );
}

function renderElementTypeIcon(element_type, interactionLayer) {
  switch (element_type) {
    case 'App\\ButtonElement':
      return <h4>Button</h4>;
    case 'App\\TextElement':
      return <h4>Text</h4>;
    case 'App\\FormElement':
      return <h4>Form</h4>;
    case 'App\\ImageElement':
      return <h4>Image</h4>;
    case 'App\\CustomHtmlElement':
      return <h4>Custom HTML</h4>;
    case 'App\\HotspotElement':
      return <h4>Hotspot</h4>;
    case 'App\\TriggerElement':
      return <h4>{interactionLayer ? 'Interaction Layer' : 'Popup'} </h4>;
  }
}
