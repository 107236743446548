import React from 'react';
import RelativeDate from "./date/RelativeDate";
import Button from 'components/Buttons/Button'
import styles from 'components/Comment.scss';
import {connect} from 'react-redux';
import {deleteComment} from "modules/project/project";

@connect(null, {deleteComment})
export default class Comment extends React.Component {
  deleteComment = (id) => {
    this.props.deleteComment(id);
  };

  render(){
    const {comment} = this.props;

    return(
      <div className={styles.wrapper}>

        <div>
          <p>
            <strong>Name</strong><br />
            {comment.name}
          </p>
          <p>
            <strong>Email</strong><br />
            {comment.email}
          </p>
          <p className={styles.deleteIcon}>
            <Button red icon="trash-alt" onClick={()=>this.deleteComment(comment.id)}>Delete</Button>
          </p>
        </div>

        <div>
          <p>
            <strong>Submitted On</strong><br />
            <RelativeDate date={comment.created_at}/>
          </p>
          <p>
            <strong>Comment</strong><br />
            {comment.text}
          </p>
        </div>
      </div>
    )
  }
}