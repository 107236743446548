import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import DashboardSidebar from 'components/Sidebar/DashboardSidebar';

// import TopNav from 'components/TopNav';
import HeaderRow from './HeaderRow';
import { updateSelectedFolder } from 'modules/project/project';
import { projectsSelector } from 'modules/project/projectSelectors';
// import { whiteLabelSelector } from 'modules/pageLoader/pageLoaderSelectors';
import styles from './PageBody.scss';

const _propTypes = {
  // Custom header component, overwrites default header comopnent
  customHeader: PropTypes.node,
  //  Main title of default header component
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** */
  meta: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

@connect(projectsSelector, { updateSelectedFolder })
class PageBody extends React.Component {
  render() {
    const { children, customHeader, hasAccess,  ...props } = this.props;

    return (
      <div className={styles.wrapper}>
        {/* <TopNav {...this.props}/> */}
        <DashboardSidebar {...props} />
        <main className={styles.main}>
          {/* 👇 Default Header  */}
          {customHeader ? customHeader : <HeaderRow {...props} />}
          <section className={styles.main_body}>
            {hasAccess ? children : <Redirect to="/" />}
            {/* {children} */}
            </section>
        </main>
      </div>
    );
  }
}
PageBody.defaultProps = {
  hasAccess : true
}
PageBody.propTypes = _propTypes;

export default withRouter(PageBody);
