import {takeEvery, select, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {splitElementFromObj} from 'modules/element/elementUtils';
import {receiveElement, selectElement} from 'modules/element/element';
import {receiveModalElement, ADD_MODAL_ELEMENT} from 'modules/modal/modal';
import { error } from 'utils/alert';

export default function* addModalElementSaga() {
  yield takeEvery(ADD_MODAL_ELEMENT, addModalElement);
}

function* addModalElement({modalId, element_type, pos}) {
  const res = yield fetch(`modals/${modalId}/elements`, {
    method: 'POST',
    body: {
      element_type,
      pos
    }
  });
  if(res.ok) {
    const data = yield res.json();
    if(data) yield handleModalElement(modalId, data, element_type);

  }
  else error({text : "Couldn't add the element to modal!"})
}

function* handleModalElement(modalId, data, element_type) {
  const [modalElement, element] = splitElementFromObj(data);

  yield put(receiveElement(element));
  yield put(receiveModalElement(modalElement));
  yield put(selectElement(
      element_type, element.id
  ))
}
