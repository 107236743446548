import React from 'react';
import {createPortal} from 'react-dom';
import {DraggableCore} from 'react-draggable';
import callsProp from 'decorators/callsProp';

const initialState = {
  dragging: false
};

export default class CloneOnDrag extends React.Component {
  state = initialState;

  static defaultProps = {
    offset: {
      x: 50,
      y: 0
    }
  };

  constructor(props) {
    super(...arguments);
    this.state = this.getInitialState(props);
    this.el = document.createElement('div');
  }

  getInitialState(props) {
    const {offset} = props;
    return {
      ...initialState,
      ...offset
    };
  }

  resetState() {
    this.setState(this.getInitialState(this.props));
  }

  @callsProp('onStart')
  handleDragStart(e) {
    e.preventDefault();
    // e.dataTransfer.dropEffect = 'copy';
    this.setState({dragging: true});
  }

  @callsProp('onDrag')
  handleDrag(e, {x, y}) {
    // this.setState(({x, y}) => ({x: x + deltaX, y: y + deltaY}));
    this.setState({x: x + this.props.offset.x, y: y + this.props.offset.y});
    this.props.onDragging && this.props.onDragging(true);
  }

  @callsProp('onStop')
  handleDragStop() {
    this.resetState();
    this.props.onDragging && this.props.onDragging(false);
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    const {children, className} = this.props;
    const {dragging, x, y} = this.state;

    return (
      <DraggableCore
        onStart={this.handleDragStart}
        onDrag={this.handleDrag}
        onStop={this.handleDragStop}
        offsetParent={document.body}
      >
        <div style={{position: 'relative'}} className={className}>
          {children}
          {dragging &&
            createPortal(
              <div style={{position: 'absolute', left: x, top: y, zIndex: 9999}}>
                <div style={{maxWidth: 1, left: '50%', position: 'absolute'}}>
                  {children}
                </div>
              </div>,
              this.el
            )}
        </div>
      </DraggableCore>
    );
  }
}
