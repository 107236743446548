import React from 'react';
import {connect} from 'react-redux';
import {pageLoadingSelector} from 'modules/pageLoader/pageLoaderSelectors';
import styles from './PageLoader.scss';
import cx from 'classnames';
import {fetchWhitelabel} from "modules/pageLoader/pageLoader";
import Helmet from 'react-helmet';
import {userSelector} from 'modules/auth/authSelectors'
import Icon from 'components/Icon';

@connect(pageLoadingSelector, {fetchWhitelabel})
export default class PageLoader extends React.Component {
  componentWillMount(){
    if(this.props.whitelabelPageLoader) {
      this.props.fetchWhitelabel();
    }
  }

  render(){
    const {whitelabel, whitelabelPageLoader } = this.props;

    const classes = cx({
      [styles.wrapper]: true,
      [styles.in] : (whitelabelPageLoader) ? !!whitelabelPageLoader : this.props.pageLoading,
      [styles.notOpaque]: whitelabelPageLoader,
    });

    return(
      <div className={classes}>
        {
          // ugliness 👇 intentional , we gotta make sure it's not an agency before
          // displaying interactr logo , whitelabel can take null value meaning we don't know yet 
          (!whitelabelPageLoader && whitelabel===false) && 
          <React.Fragment>
            <Helmet>
              <title>Interactr - The Future Of Video</title>
              <link rel="icon" type="image/png" href="/interactr.png" />
            </Helmet>
          </React.Fragment>
        }
        {
          (whitelabel) &&
          <Helmet>
            <title>{ whitelabel.page_title }</title>
            <link rel="icon" type="image/png" href={whitelabel.icon} />
          </Helmet>
        }
        <div className={styles.inner}>
            {/* <Icon icon="circle-notch" spin size="4x" />
            <Icon icon="spinner" spin size="4x" /> */}
            <Icon icon="spinner-third"   size="4x" className={styles.icon}/>
          <p>Loading</p>
        </div>
      </div>
    )
  }
}
