import React from 'react';
import { connect } from 'react-redux';

import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { projectSelector, projectNodesSelector } from 'modules/project/projectSelectors';
import { sortedNodesAsArraySelector } from 'modules/node/nodeSelectors';
import { viewProjectSettingsPage, updateProject, saveProject } from 'modules/project/project';
import { updateNodesSortOrder } from 'modules/node/node';

import styles from './ProjectsPage.scss';
import FoldersManagementModal from './FoldersManagementModal';
import ProjectPage from './ProjectPage';
import FilterInput from 'components/FilterInput';
// import ProjectPreview from './ProjectPreview';
import ProjectSettingsForm from './ProjectSettingsForm';

// import Notification from 'components/Notification';
import Link from 'components/Link'
import _filter from "lodash/filter";
import _orderBy from "lodash/orderBy";
import Node from "../../composer/components/Node";
import cx from "classnames";
import {BooleanInput, Option, SelectInput, TextInput} from "../../../components/PropertyEditor";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import getAsset from 'utils/getAsset';
import Button from 'components/Buttons/Button';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import ProjectGroupItem from "./ProjectGroupItem";
import {browser} from "../../../utils/domUtils";
import {createPortal} from "react-dom";

@connect(sortedNodesAsArraySelector, { updateNodesSortOrder })
@connect(
    projectSelector,
    {
        viewProjectSettingsPage,
        updateProject,
        saveProject,
        updatePageLoadingState
    }
)
export default class ProjectChaptersPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            project: props.project,
            nodes: props.nodes,
            filteredNodes: props.nodes,
            filtering: false,
            orderBy: '',
            sortOrder: '',
            showEditModal: false
        };
    }

    componentWillMount() {
        const { updatePageLoadingState, project } = this.props;
        if (!project) {
            updatePageLoadingState(true);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.project !== nextProps.project) {
            this.setState({project: nextProps.project});
        }

        if (this.state.nodes !== nextProps.nodes) {
            this.setState({nodes: nextProps.nodes});
        }
    }

    componentDidMount() {
        const { viewProjectSettingsPage, match } = this.props;
        viewProjectSettingsPage(match.params.projectId);
    }

    handleSubmit = (e, data) => {
        e.preventDefault();
        this.props.updateProject(data);
        this.setState({ dirty: false });
    };

    updateProject = (data) => {
        this.props.updateProject(data);
    };

    updateUseAsChapter = (val, nodeId) => {
        const {nodes, updateProject} = this.props;
        const {project} = this.state;
        const node = nodes.find(node => nodeId === node.id);

        if (! Array.isArray(project.chapter_items)) {
            project.chapter_items = [];
        }

        const chapter_items = [...project.chapter_items];

        if (!val) {
            const chapterItemIndex = _findIndex(chapter_items,item => item.node_id === nodeId);
            chapter_items.splice(chapterItemIndex, 1);
            return updateProject({
                id: project.id,
                chapter_items: chapter_items
            });
        }

        updateProject({
            id: project.id,
            chapter_items: [
                ...chapter_items,
                {
                    node_id: nodeId,
                    title: node.name,
                    thumbnail: node.media.thumbnail_url,
                    video_id: node.media.id
                }
            ]
        });
    };

    updateChapterTitle = (val, nodeId) => {
        const {nodes} = this.props;
        const {project} = this.state;
        const node = nodes.find(node => nodeId === node.id);
        const chapter_items = [...project.chapter_items];

        const updatedChapterItems = _map(chapter_items, chapterItem => {
            if (chapterItem.node_id === node.id){
                return {
                    ...chapterItem,
                    title: val
                }
            }

            return chapterItem;
        });

        this.setState({
            project: {...project, chapter_items: updatedChapterItems}
        }, () => this.updateProject({
            id: project.id,
            chapter_items: updatedChapterItems
        }));
    };

    get getPortal () {
        // use this in case of browser support!
        const portal = document.createElement("div");
        document.getElementById("appRoot").appendChild(portal);

        return portal;
    }

    sortBySortOrderNumber(nodes) {
        return nodes.map((node, index) => {
            return {
                ...node,
                sort_order: ++index
            };
        });
    }

    reorder = result => {
        const {project: {id: projectId}} = this.props;
        const { filteredNodes, nodes, filtering, } = this.state;
        let copiedNodes = filtering ? [...filteredNodes] : [...nodes];

        let startIndex = result.source.index;
        let endIndex = result.destination.index;

        const [removed] = copiedNodes.splice(startIndex, 1);
        copiedNodes.splice(endIndex, 0, removed);

        const sortedNodesData = this.sortBySortOrderNumber(copiedNodes);

        this.setState(
            {nodes: sortedNodesData},
            () => {
                // send ordering to BE only if node.use_as_chapter === true
                if (removed.use_as_chapter) {
                    this.props.updateNodesSortOrder(sortedNodesData, projectId)
                }
            }
        )
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        this.reorder(result);
    };

    get nodesList() {
        const { filteredNodes, nodes: stateNodes, filtering, orderBy, sortOrder } = this.state;
        /* as videos data can arrive after first mounting we need to update
            list but only when not filtering which would show everything if filter not matched */
        let nodes = filtering ? filteredNodes : stateNodes;

        let disableDragDrop = filtering;

        // if selected sort by option and it's not dragging, disable drag & drop and order by selected option
        if (orderBy && orderBy !== 'dragging' && sortOrder) {
            disableDragDrop = true;
            nodes = _orderBy(nodes, [orderBy], sortOrder);
        }

        return (
            <DragDropContext onDragEnd={this.onDragEnd} >
                <Droppable droppableId="nodes-droppable" isDropDisabled={disableDragDrop}>
                    {(provided, snapshot) => (
                        <DraggableContent
                            project={this.state.project}
                            nodes={nodes}
                            provided={provided}
                            snapshot={snapshot}
                            filtering={disableDragDrop}
                            portal={this.getPortal}
                            updateChapterTitle={this.updateChapterTitle}
                            updateUseAsChapter={this.updateUseAsChapter}
                        />
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    handleFilter = ({ filteredData, filtering }) => {
        this.setState({ filteredNodes: filteredData, filtering });
    };

    get filters() {
        const { orderBy, nodes } = this.state;

        return (
            <div className={cx(styles.filterRow, 'grid')}>
                 <div className={cx(styles.filterRow_sortBy, 'col12')} style={{marginBottom: '25px'}}>
                     <FilterInput
                        data={nodes}
                        filterKey="name"
                        onFilter={this.handleFilter}
                        placeholder="Filter Nodes..."
                    />
                 </div>
                 {/*<div className={cx(styles.filterRow_sortBy, 'col12')} >*/}
                 {/*    <label style={{paddingTop: '5px'}}>Sort By</label>*/}
                 {/*    <Option*/}
                 {/*       Component={SelectInput}*/}
                 {/*       value={orderBy}*/}
                 {/*       className={styles.sortOptions}*/}
                 {/*       options={filterOptions()}*/}
                 {/*       onChange={(_, value) => this.setState({ orderBy: value, sortOrder: value === 'name' ? 'asc' : 'desc' })}*/}
                 {/*       clearable={false}*/}
                 {/*       searchable={false}*/}
                 {/*   />*/}
                 {/*</div>*/}
             </div>
        );

        function filterOptions() {
            return [
                {label: 'Dragging', value: 'dragging', clearableValue: false},
                {label: 'Node Name', value: 'name', clearableValue: false},
                {label: 'Uploaded Date', value: 'created_at', clearableValue: false}
            ];
        }
    }

    render() {
        const { projectGroups } = this.props;
        const {project} = this.state;

        if (!project) {
            return null;
        }

        return (
            <ProjectPage
                header="Project Chapters"
                headerInfo={<Link>watch the training</Link>}
                project={project}
                projectGroups={projectGroups}
                active="chapters"
            >
                <div className="grid">
                    <div className="col8">{this.nodesList}</div>
                    <div className="col4">{this.filters}</div>
                </div>
            </ProjectPage>
        );
    }
}

function DraggableContent({project, filtering, nodes, provided, portal, updateChapterTitle, updateUseAsChapter}) {
    const getItemStyles = (isDragging, draggableStyle) => ({
        // change background colour if dragging
        background: isDragging ? '#f1f1f1' : '',
        cursor: 'inherit',
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    return (
        <div
            style={{marginLeft: '30px'}}
            ref={provided.innerRef}
        >
            {nodes.map((node, index) => {
                return (
                    <Draggable
                        key={'draggable_node_'+node.id}
                        draggableId={node.id.toString()}
                        index={index}
                        isDragDisabled={filtering}
                    >
                        {(provided, snapshot) => {
                            const child = (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyles(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <NodeCard
                                        key={'node_card_'+node.id}
                                        project={project}
                                        node={node}
                                        updateChapterTitle={updateChapterTitle}
                                        updateUseAsChapter={updateUseAsChapter}
                                    />
                                </div>
                            );

                            if (!snapshot.isDragging || browser.name === 'chrome') {
                                return child;
                            }

                            // if dragging - put the item in a portal
                            return createPortal(child, portal);
                        }}
                    </Draggable>
                )
            })}
            {provided.placeholder}
        </div>
    );
}

function NodeCard({ project, node, updateUseAsChapter, updateChapterTitle }) {
    const chapterItem = _find(project.chapter_items, chapterItem => chapterItem.node_id === node.id);

    return (
        <li className="grid" style={{borderBottom: '1px solid #ccc', paddingTop: '15px', paddingBottom: '15px'}}>
            <div className="col2">
                <img src={node.media.thumbnail_url || getAsset('/img/no-thumbnail.png')}  className="img-fluid" style={{borderRadius: '15px', boxShadow: '0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1)'}} />
            </div>
            <div className="col2">
                <h3 style={{marginBottom: 0, marginTop: '25px'}}>{node.name}</h3>
            </div>
            <div className="col5">
                <Option
                    style={{marginTop: '10px'}}
                    label="Use as Chapter"
                    Component={BooleanInput}
                    value={node.use_as_chapter}
                    onChange={(e,val) => updateUseAsChapter(val, node.id)}
                />
            </div>
            <div className="col3" style={{paddingTop: '10px', marginBottom: '-25px'}}>
                {node.use_as_chapter && (
                    <Option
                        label="Chapter Name"
                        Component={TextInput}
                        value={chapterItem.title}
                        onChange={(e, val) => updateChapterTitle(val, node.id)}
                    />
                )}
            </div>
        </li>
    );
}
