import {takeLatest, put} from 'redux-saga/effects';
import {LOAD_MEDIA, receiveMedia} from 'modules/media/media';
import {VIEW_NODE_PAGE} from 'modules/node/node';
import {VIEW_MODAL_PAGE} from 'modules/modal/modal';
import fetch from 'utils/saga/fetch';
import {VIEW_PROJECT_SETTINGS_PAGE} from 'modules/project/project';

export default function* fetchMediaSaga() {
  yield takeLatest([LOAD_MEDIA, VIEW_NODE_PAGE, VIEW_MODAL_PAGE, VIEW_PROJECT_SETTINGS_PAGE], fetchMedia);
}

function* fetchMedia() {
  const res = yield fetch(`media`);
  const media = yield res.json();
  yield put(receiveMedia(media));
}
