import {takeEvery, put} from 'redux-saga/effects';
import {DELETE_PROJECT_GROUP, projectGroupDeleted} from 'modules/project/project';
import fetch from 'utils/saga/fetch';
import swal from 'sweetalert2';
import {error} from 'utils/alert'

export default function* deleteProjectGroupSaga() {
    yield takeEvery(DELETE_PROJECT_GROUP, deleteProjectGroup);
}

function* deleteProjectGroup({folderId}) {
    const conf = yield swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this folder.',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#ff6961',
        confirmButtonText: 'Yes, Delete It!',
    });

    const res = yield fetch(`projectGroup/${folderId}`, {
        method: 'DELETE'
    });
    const data = yield res.json();

    if (!data.success) {
        return error({text: data.message});
    }

    yield put(projectGroupDeleted(folderId));
}
