//DELETE_MEDIA_ITEM

import {call, put, takeLatest} from 'redux-saga/effects';
import {ATTEMPT_DELETE_MEDIA_ITEM, deleteMediaItem} from 'modules/media/media';
import fetch from 'utils/saga/fetch';
import {success, error} from 'utils/alert';

export default function* deleteMediaSaga() {
  yield takeLatest(ATTEMPT_DELETE_MEDIA_ITEM, deleteMedia);
}

function* deleteMedia({id}) {
  const res = yield fetch('media/'+id, {
    method: 'DELETE',
    body: {

    }
  });

  if (!res.ok) {
    error({text: 'You can\'t remove media that\'s being used in your project. You must delete any nodes using this media first then delete the media item.'});
  } else {
    yield put(deleteMediaItem(id));
    // Don't think we need to confirm to user as  the item will be deleted from the UI
    //success({text: 'Media item has been removed.'});
  }
}
