import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { createRouteParamSelector, idSelector } from 'utils/selectorUtils';
import { mediaSelector } from 'modules/media/mediaSelectors';
import { toJS } from 'utils/immutableUtils';
import { projectSelector } from 'modules/project/projectSelectors';
import sortBy from 'lodash/sortBy';

export const nodesStateSelector = state => state.nodes;

export const nodesSelector = createSelector(nodesStateSelector, state => ({
  nodes: state.getIn(['data', 'nodes'])
}));

export const nodeDurationSelector = createSelector(nodesStateSelector, state => state.get('duration'));

export const nodesAsArraySelector = createSelector(
  nodesStateSelector,
  mediaSelector,
  projectSelector,
  (state, { media }, { project }) => {
    const nodes = state
      .getIn(['data', 'nodes'])
      .toList()
      .toJS();
    const chapterItems = project && project.chapter_items;

    return {
      nodes: nodes.map(node => ({
        ...node,
        use_as_chapter: chapterItems && chapterItems.find(item => item.node_id === node.id) !== undefined,
        media: toJS(media.get(node.media_id.toString()))
      }))
    };
  }
);

export const sortedNodesAsArraySelector = createSelector(nodesAsArraySelector, ({ nodes }) => {
  let nodesUseAsChapterList = [];
  let nodesNoneUseAsChapterList = [];

  nodes.forEach(node => {
    // filter nodes that use_as_chapter === true
    if (node.use_as_chapter) {
      nodesUseAsChapterList.push(node);
    } else {
      nodesNoneUseAsChapterList.push(node);
    }
  });

  const sortedNodesUseAsChapter = sortBy(nodesUseAsChapterList, 'sort_order');

  return {
    // put disabled use_as_chapters nodes at the end of list
    nodes: [...sortedNodesUseAsChapter, ...nodesNoneUseAsChapterList]
  };
});

export const allNodesSelector = createSelector(nodesStateSelector, state => {
  const nodes = state
    .getIn(['all'])
    .toList()
    .toJS();
  return { nodes };
});

export const nodeIdSelector = createSelector(
  createRouteParamSelector('nodeId'),
  idSelector,
  (nodeId, id) => nodeId || id
);

export const currentProjectIdSelector = createSelector(nodesStateSelector, state => ({
  projectId: state.get('projectId')
}));

export const nodeSelector = createSelector(
  nodesSelector,
  nodeIdSelector,
  state => state.interactions.get('wizard'),
  mediaSelector,
  projectSelector,
  ({ nodes }, id, wizard, { media }, project) => {
    const node = nodes.get(id.toString());

    if (!node) return {};

    return {
      node: toJS(node),
      mediaItem: toJS(media.get(node.get('media_id').toString())),
      wizard,
      media,
      project
    };
  }
);

export const nodeElementGroupSelector = createSelector(nodesStateSelector, nodeIdSelector, (state, id) => {
  // When we create node first time, set default value an empty Map();
  const currentNodeElementGroups = state.getIn(['data', 'elementGroups', id]) || Map();

  return {
    elementGroups: currentNodeElementGroups
  };
});

const rawState = state => toJS(state);

// toSave prevents clashes in the toolbar Hacky as F*** but best we can do with this huge mess we call react
export const saveNodeSelector = createSelector(rawState, rawState => {
  const interactions = rawState.interactions.get('data'),
    recreateInteractions = interactions.valueSeq().map(interaction => {
      // remove element_group  properties from interaction
      let { element_group,  ...interactionJS } = interaction.toJS();
      return interactionJS;
    });

  return {
    interactionsToSave: toJS(recreateInteractions),
    elementsToSave: toJS(rawState.elements.get('data'))
  };
});

export const getCopyInteractionLayerSelector = (nodeId) => createSelector(
  state => state.nodes.getIn(['data', 'nodes']),
  ( projectNodes) => {
    if (projectNodes.size) {
      const nodes = toJS(projectNodes.filter(node => node.get('interaction_layer_id') && node.get('enable_interaction_layer')).toList());
      const currentNode = toJS(projectNodes.get(nodeId))
      return { nodes, currentNode };
    }
    return {};
  }
);
