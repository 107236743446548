import React from 'react';
import Button from 'components/Buttons/Button';
import { deleteUser, updateUser , selectUser, saveUser, loginAsUser} from 'modules/user/user';
import {connect} from 'react-redux';

@connect(null, {loginAsUser})
export default class UserDetails extends React.Component {
  _loginAsUser = () => {
    const {loginAsUser, id, isAuthSuperUser} = this.props;

    // If logged in user is agency NOT and admin, save the parent user data in local storage to show in logged in sub user account
    const saveOnStorage = ! isAuthSuperUser;

    loginAsUser(id, saveOnStorage);
  };

  render(){
    const {name, email, onSelect, id} = this.props;

    return(
      <div style={{marginTop: '15px', borderBottom: '#ccc solid 1px', paddingBottom: '15px'}} className="clearfix">

        <div className="grid">
            <div className="col6">
                <p style={{marginTop: 0}}>Name: {name}</p>
                <p>Email: {email}</p>
            </div>
            <div className="col6">
                <div style={{width: '100%', marginBottom:'10px'}} className="clearfix"><Button primary style={{float:'right'}} onClick={()=>onSelect(id)} small>Edit User</Button></div>
                <div style={{width: '100%'}} className="clearfix"><Button icon="sign-in-alt"  onClick={this._loginAsUser} style={{float:'right'}} small>Login As User</Button></div>
            </div>
        </div>
      </div>
    )
  }
}