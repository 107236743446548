import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { easings } from '../../../utils/animations';
import map from 'lodash/map';
import {
  Section,
  Option,
  SizeInput,
  TextInput,
  LargeTextInput,
  MultiSelect,
  BooleanInput,
  ColorInput
} from 'components/PropertyEditor/PropertyEditor';
// import {modalSelector} from 'modules/modal/modalSelectors';
import { updateModal, applyModalTemplate, copyModal, deleteModal } from 'modules/modal/modal';
import { withRouter } from 'react-router-dom';
import TemplateProperties from 'modules/template/components/TemplateProperties';
import TemplateListComponent from 'modules/template/components/TemplateListComponent';
import Button from 'components/Buttons/Button';
import { RangeInput } from 'components/PropertyEditor/PropertyEditor';
// import ModalElementList from './ModalElementList';
// import debounce from 'lodash/debounce';
import { updateNode } from 'modules/node/node';
import { userSelector } from 'modules/auth/authSelectors';
import LinkButton from '../../../components/Buttons/LinkButton';
import Icon from 'components/Icon';

@connect(
  null,
  // modalSelector,
  { updateModal, applyModalTemplate, copyModal, deleteModal, updateNode }
)
@connect(userSelector, {})
class ModalProperties extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTemplatesModal: false
    };
  }

  updateEnableInteractionLayer = (e, val) => {
    this.props.updateNode(this.props.node.id, { enable_interaction_layer: val });
  };

  handleSelectTemplate = id => {
    this.props.applyModalTemplate(id, { modalId: this.props.modal.id }, () => {

      // preview the animation on close
      var event = new Event('preview_animation');
      window.dispatchEvent(event);

      this.setState({ showTemplatesModal: false });
    });
  };

  changeHandler = (val, name) => {
    this.updateModal({ [name]: val });
  };

  updateModal = data => {
    const {
      modal: { id },
      updateModal
    } = this.props;
    updateModal(id, data);
  };

  handleCopy = () => {
    this.props.copyModal(this.props.modal.id);
  };

  handleDelete = () => {
    this.props.deleteModal(this.props.modal.id);
  };

  toggleTemplatesModal = status => () => {
    // console.log('status::', status);
    this.setState({ showTemplatesModal: status });
  };

  handleAnimationChange = property => (e, val) => {
    let {
      modal: { background_animation }
    } = this.props;

    background_animation = background_animation || {};
    if (property === 'name') {
      //background_animation.anime = background_animations[val.value].anime;
      background_animation[property] = val.value;
    } else {
      background_animation[property] = val.value ? val.value : val; // handle selects
    }

    this.updateModal({ background_animation });
  };

  render() {
    const { showTemplatesModal } = this.state;
    const { modal, node, user } = this.props;

    if (!modal) return null;

    const { name, backgroundColour, show_close_icon, size, border_radius, close_icon_color, interaction_layer } = modal;
    // gotta handle it not being set , like in a blank modal etc...
    const background_animation = modal.background_animation || {};

    return (
      <div>
        <div className="clearfix">
          <Button
            secondary
            icon="pen-square"
            small
            onClick={this.toggleTemplatesModal(true)}
            style={{ marginBottom: 10 }}
          >
            Change Preset
          </Button>
        </div>
        {showTemplatesModal && (
          <TemplateListComponent
            resource="modals"
            filter={interaction_layer ? 'interaction_layer' : undefined}
            interactionLayerPopup={interaction_layer}
            title="Change Preset"
            height={650}
            show={showTemplatesModal}
            onClose={this.toggleTemplatesModal(false)}
            onSelectTemplate={this.handleSelectTemplate}
          />
        )}
        <Section title="Popup Properties" icon="sliders-v" noHeading>
          {!interaction_layer ? (
            <div>
              <Option
                label="Name"
                value={name}
                Component={TextInput}
                onChange={(e, val) => this.changeHandler(val, 'name')}
              />
              <Option
                label="Size (%)"
                value={size}
                Component={RangeInput}
                onChange={(e, val) => this.changeHandler(val, 'size')}
              />
              <Option
                label="Roundness (px)"
                value={border_radius}
                Component={RangeInput}
                onChange={(e, val) => this.changeHandler(val, 'border_radius')}
              />
            </div>
          ) : (
            <Option
              label="Enable Interaction Layer"
              value={node.enable_interaction_layer}
              Component={BooleanInput}
              onChange={this.updateEnableInteractionLayer}
            />
          )}

          <div style={{ position: 'relative', zIndex: 200 }}>
            <Option
              label="Background Color"
              value={backgroundColour}
              Component={ColorInput}
              onChange={(e, val) => this.changeHandler(val, 'backgroundColour')}
            />
          </div>
        </Section>
        {!interaction_layer ? (
          <Section title="Close Icon" icon="times">
            <Option
              label="Show Close Icon"
              value={show_close_icon}
              Component={BooleanInput}
              onChange={(e, val) => this.changeHandler(val, 'show_close_icon')}
            />
            <div style={{ position: 'relative', zIndex: 100 }}>
              <Option
                label="Close Icon Color"
                value={close_icon_color}
                Component={ColorInput}
                onChange={(e, val) => this.changeHandler(val, 'close_icon_color')}
              />
            </div>
          </Section>
        ) : (
          <div>
            <Section title="Animation Properties" icon="camera-movie">
              {!!user.evolution_pro && (
                <div>
                  <Option
                    label="Background Animation"
                    name="background-animation-select"
                    value={background_animation.name}
                    options={map(window.background_animations, (b, i) => ({ label: b.label, value: i }))}
                    Component={MultiSelect}
                    onChange={this.handleAnimationChange('name')}
                  />
                  <Option
                    label="Animation Easing"
                    name="background-easing-select"
                    value={background_animation.easing}
                    options={easings}
                    Component={MultiSelect}
                    onChange={this.handleAnimationChange('easing')}
                  />
                  <Option
                    label="Animation Duration"
                    value={background_animation.duration}
                    Component={RangeInput}
                    min={0.1}
                    max={3.0}
                    step={0.1}
                    onChange={this.handleAnimationChange('duration')}
                  />
                </div>
              )}
              {!user.parent_user_id && !user.evolution_pro && (
                <div className="form-control">
                  <p>Background Animation Customization is only available to Evolution Pro Users</p>
                  <p>
                    <LinkButton to={'/upgrade'} small primary>
                      <Icon name={'arrow-up'} /> Click Here to Upgrade
                    </LinkButton>
                  </p>
                </div>
              )}
              <div className="form-control">
                <Button
                  secondary
                  icon="play"
                  small
                  onClick={() => {
                    var event = new Event('preview_animation');
                    window.dispatchEvent(event);
                  }}
                >
                  play Animation
                </Button>
              </div>
            </Section>

            <Section title="timer" icon="clock">
              <Option
                label="Show Timer"
                value={background_animation.use_timer}
                Component={BooleanInput}
                onChange={this.handleAnimationChange('use_timer')}
              />
              <Option
                label="Timer Duration"
                value={background_animation.timer_duration}
                Component={RangeInput}
                min={1}
                max={20}
                step={1}
                onChange={this.handleAnimationChange('timer_duration')}
              />
              <Option
                label="Play Timer Sound"
                value={background_animation.playSound}
                Component={BooleanInput}
                onChange={this.handleAnimationChange('playSound')}
              />
            </Section>
          </div>
        )}

        {/*<ModalElementList*/}
        {/*  elements={elements}*/}
        {/*  selectElement={this.props.selectElement}*/}
        {/*/>*/}

        {/*<Section title="Actions" icon="bars">*/}
        {/*  <Button onClick={()=>this.handleCopy()} icon="clone">*/}
        {/*    Copy*/}
        {/*  </Button>*/}
        {/*  <Button onClick={()=>this.handleDelete()} icon="trash-alt" red right>*/}
        {/*    Delete*/}
        {/*  </Button>*/}
        {/*</Section>*/}

        <TemplateProperties item={modal} update={this.updateModal} modalProperties />
      </div>
    );
  }
}

export default ModalProperties;
