import {takeLatest, put} from 'redux-saga/effects';
import {VIEW_SELECT_LIST, receiveLists} from 'modules/integration/integration';
import fetch from 'utils/saga/fetch';

export default function* fetchListsSaga() {
  yield takeLatest(VIEW_SELECT_LIST, fetchLists);
}

function* fetchLists({integrationType, subUserId, callback}) {
  // TODO: check if already fetched and cancel
  const integrationName = integrationType.replace('integration_', '');
  const res = yield fetch(`${integrationName}/getLists${subUserId ? '?user_id='+subUserId : ''}`);

  if(!res.ok) {
    yield put(receiveLists(null)) // terrible for loading mechanism , @todo this needs to be better
    return ;
  }
  
  const lists = yield res.json();
  
  if(lists)  {
    // handle callback case and don't put results into redux .
    if(callback) {
      yield callback(lists);
      yield put(receiveLists(null)) // terrible for loading mechanism , @todo this needs to be better      
      return ;
    }
    yield put(receiveLists(integrationType, lists));
  }
}
