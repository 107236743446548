import React from 'react';
import {connect} from 'react-redux'
import { confirm } from 'utils/alert';
import Icon from 'components/Icon';
import Rodal from 'rodal';
import Button from 'components/Buttons/Button';
import VideoPlayer from 'components/VideoPlayer';
import {attemptDeleteMediaItem} from 'modules/media/media';

@connect(null, {attemptDeleteMediaItem})
export default class LibraryEditMediaModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            media: props.media
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.media !== prevProps.media) {
            this.setState({media: this.props.media});
        }
    }

    getVideoURL(media) {
        return media.url || media.manifest_url;
    };

    deleteMedia = () => {
        const {media} = this.props;

        confirm({
            title: 'Are You Sure!',
            text: 'Are You Sure You Want To Delete This Video?',
            confirmButtonText: 'Yes, Delete It!',
            onConfirm: async () => {
                await this.props.attemptDeleteMediaItem(media.id);
                this.props.closeModal();
            }
        });
    };

    render(){
        const {editMediaName, updateMedia, closeModal}= this.props;
        const {media} = this.state;

        if (!media) return null;

        return(
            <Rodal
                visible={editMediaName}
                onClose={()=>closeModal()}
                height={395}
            >
                <div className="modal-heading">
                    <Icon name="pen-square" /> Edit Media
                </div>
                <div className="modal-body">
                    <div className="grid">
                        <div className="col12" style={{marginBottom: '20px'}}>
                            <VideoPlayer
                                url={this.getVideoURL(media)}
                                videoId={media.id}
                                imageURL={media.thumbnail_url}
                                controls
                                light={media.thumbnail_url || false} // stops preload and only shows thumbnail awesome for perf
                            />
                        </div>

                        <div className="col12">
                            <div className="form-control">
                                <input type="text" value={media.name} onChange={(e)=>this.setState({media: {...media, name: e.target.value} })} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button primary right onClick={()=> updateMedia(media.name, media.id)}>
                        Done
                    </Button>
                    <Button icon="trash-alt" red style={{marginRight: '0'}} onClick={this.deleteMedia}>
                        Delete
                    </Button>
                </div>
            </Rodal>
        )
    }
}