import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
// import Icon from 'components/Icon';
// import Page from 'components/Page';
import IntegrationsPage from 'modules/integration/components/IntegrationsPage';
import SubUserIntegrationsPage from 'modules/integration/components/SubUserIntegrationsPage';
import AccountDetailsPage from 'modules/account/components/AccountDetailsPage';
import AccountDetailsCredentials from './AccountDetailsCredentials';
import AccountVideoSettings from './AccountVideoSettings';
import AccountDetailsAffiliate from 'modules/account/components/AccountDetailsAffiliate';
import SidebarNav from 'components/SidebarNav';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { fetchAgency } from 'modules/agency/agency';
import { fetchCustomLists } from 'modules/customLists/customList';
import { userSelector } from 'modules/auth/authSelectors';
// import SidebarPage from 'components/SidebarPage';
// import styles from './AccountPage.scss';
// import PageBody from 'components/PageBody';

@connect(
  userSelector,
  { updatePageLoadingState, fetchAgency, fetchCustomLists }
)
class AccountPage extends React.Component {
  componentWillMount() {
    
    //  Add a little delay here makes the UI nicer and the loade
    setTimeout(() => {
      this.props.updatePageLoadingState(false);
    }, 1000);
  }

  render() {
    const hasParentUser = this.props.user.parent_user_id > 0;
    const pathname = window.location.href;


    return hasParentUser ? (
      <Switch>
        <Route path="/account/details" component={AccountDetailsPage} />
        <Route path="/account/integrations" component={SubUserIntegrationsPage} />
        <Redirect to="/account/details"/>
      </Switch>
    ) : (
      <Switch>
        <Route path="/account/integrations" component={IntegrationsPage} />
        <Route path="/account/affiliate" component={AccountDetailsAffiliate} />
        <Route path="/account/videosettings" component={AccountVideoSettings} />
        <Route path="/account/credentials" component={AccountDetailsCredentials} />
        <Route path="/account" component={AccountDetailsPage} />
      </Switch>
    );
  }
}

export default withRouter(AccountPage);

function AccountSidebar({ pathname }) {
  const items = [
    {
      active: pathname.indexOf('account/details'),
      to: '/account/details',
      icon: 'user',
      text: 'My account',
      summary: 'View & edit your account details'
    },
    {
      active: pathname.indexOf('account/integrations'),
      to: '/account/integrations',
      icon: 'code',
      text: 'Integrations',
      summary: 'Setup your Integrations'
    },
    {
      active: pathname.indexOf('account/affiliate'),
      to: '/account/affiliate',
      icon: 'users',
      text: 'Affiliate',
      summary: 'Become an Affiliate'
    },
    {
      active: pathname.indexOf('account/videosettings'),
      to: '/account/videosettings',
      icon: 'video',
      text: 'Video Settings',
      summary: 'Video related settings'
    },
    {
      active: pathname.indexOf('account/credentials'),
      to: '/account/credentials',
      icon: 'key',
      text: 'Credentials',
      summary: 'View your api credentials'
    },
    {
      active: pathname.indexOf('customlists'),
      to: '/customlists',
      icon: 'envelope-o',
      text: 'Custom Email Lists',
      summary: 'Custom Email Lists'
    }
  ];

  return <SidebarNav items={items} />;
}
