import React from 'react';
import styles from './DashboardPage.scss';
import cx from "classnames";
import Icon from "../../../components/Icon";
import {Link} from 'react-router-dom'

export default function DashboardCard (props) {

    const {bgColor, icon, heading, text, href, to } = props;

    const classes = cx(styles.stat, {
        [styles.greenStat]: bgColor === 'green',
        [styles.blueStat]: bgColor === 'blue'
    });

    const content = (
        <div className={classes}>
            <div className={styles.statIcon}>
                <Icon name={icon} size="1x"/>
            </div>
            <div className={styles.statContent}>
                <h2>{heading}</h2>
                <span>{text}</span>
            </div>
        </div>
    );

    return (
        <div className="col3">
            { (href) ?
                <a target="_blank" href={href}>{content}</a>
                :
                <Link to={to}>{content}</Link>
            }
        </div>
    );
}