import {takeEvery,put } from 'redux-saga/effects';
import {ADD_CUSTOM_LIST , receiveCustomList} from 'modules/customLists/customList'
import fetch from 'utils/saga/fetch'
import { error , success} from 'utils/alert'
import { updatePageLoadingState } from 'modules/pageLoader';


export default function* addCustomList(){
    yield takeEvery([ADD_CUSTOM_LIST], _addCustomList)
}

function* _addCustomList({customList, callback}){
    yield put(updatePageLoadingState(true));
    const res = yield fetch('customlists', {
        method: 'post',
        body: customList
    });

    if ( res.status !== 200 ) {
        yield put(updatePageLoadingState(false));
        error({text: 'Unable to save the custom list, Please try again later'})
    }
    
    const data = yield res.json();
    yield put(updatePageLoadingState(false));

    if (!data.success) {
        error({text: data.message});
        return;
    }

    yield put(receiveCustomList(data.customList));
    callback && callback(data.customList);
    success({text: data.message})
}