import config from 'config';

// Addded some extra input fields so we can call this method without having the project on the share page
export function generateEmbedCode(project, storagePath = config.STORAGE_PATH, height = null, width = null, projectPath = null) {
  if (!project) return null;

  // !!!!!!!! *******  Remember any changes here should be done in the share page too ******** !!!!!!!!!
  // The responsiveness of the iframe is handled by the script appended to it 
  return `<iframe class="_vs_ictr_player" src="${storagePath}${project.storage_path}/index.html?cb=${randomString()}" width=${project.embed_width} height=${project.embed_height} frameborder="0" allow="autoplay *" scrolling="no" ></iframe><script src="${config.WRAPPER_PATH}"></script>`;
}

export function previewUrl(projectId) {
  // return 'http://0.0.0.0:9198';
  return config.API.ROOT + `preview/${projectId}`;
}

export function publishedProjectUrl(hash) {
  hash = hash.replace(/\?.+/, ''); // strip query params if any 
  const cb = randomString();
  return config.STORAGE_PATH + `projects/${hash}/index.html?cacheBuster=${cb}`;
}


function randomString(){
  return  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

function getHashFromProject(project) {
  if(project.storage_path) {
    const split = project.storage_path.split('/');
    return split[1];
  }

  return '';
}

export function sharePageUrl(project, whitelabel = false) {
  const root =  (whitelabel) ? 'https://'+ whitelabel : window.location.origin;
  return root + '/share/' + getHashFromProject(project);
}