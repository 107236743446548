import React from 'react';
import styles from './TimelineBar.scss';
import TimelineDragResize from 'components/TimelineDragResize';
import cx from 'classnames';
import keyDown from 'react-keydown';
import {connect} from 'react-redux';
import {whiteLabelSelector} from "modules/pageLoader/pageLoaderSelectors";

@connect(whiteLabelSelector, {})
export default class TimelineBar extends React.Component {
  @keyDown('delete', 'backspace')
  delete(e) {
    if (this.props.selected) {
      this.props.onDelete();
    }
  }

  handleChange = (timeIn, timeOut) => {
      const {onChange} = this.props;
      onChange({timeIn, timeOut});
  };

  getDimensions() {
    // Note: from and to are times in seconds
    const {timelineDuration} = this.props;
    let from = parseFloat(this.props.from),
        to = parseFloat(this.props.to);

    const timelineHolder = document.getElementById("timeline-bar-holder");
    if (!timelineHolder || !timelineDuration) {
      return {x: 0, y: 0, w: 10, h: 30};
    }

    if (to > timelineDuration) {
      to = timelineDuration;
    }

    const ratio = timelineDuration / timelineHolder.offsetWidth;

    const x = !from ? 0 : from / ratio;
    const w = (to / ratio) - x;

    return {x, y: 0, w, h: 30};
  }

  parseVideoTime(time) {
    let theTime = Math.round(time * 100) / 100;
    let splitTime = theTime.toString().split('.');
    if (splitTime[0].length < 2) {
      splitTime[0] = "0" + splitTime[0];
    }

    if (typeof splitTime[1] === 'undefined') {
      splitTime[1] = '00';
    }

    if (splitTime[1].length === 1) {
      splitTime[1] = '0' +  splitTime[1];
    }

    return splitTime[0] + ':' + splitTime[1];
  }

  render() {
    const {children, onClick, timelineDuration, interactionId, disableResize, selected, whitelabel} = this.props;
    const {x, y, w, h} = this.getDimensions();

    const classes = cx(styles.TimelineBar, {
       [styles.selected]: selected
    });

    const whitelabelStyles =  (whitelabel) ? {
      backgroundColor: (selected) ? whitelabel.secondary_color : whitelabel.primary_color
    } : {};

    return (
      <div className={classes} onClick={onClick}>
        <TimelineDragResize
          key={interactionId}
          pos={{x, y}}
          itemWidth={w}
          className={cx(styles.bar)}
          style={whitelabelStyles}
          timelineDuration={timelineDuration}
          onChange={this.handleChange}
          onClick={onClick}
          disableResize={disableResize}
          selected={selected}
        >
          {children}
        </TimelineDragResize>
      </div>
    );
  }
}
