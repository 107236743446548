import React , {useState} from 'react';
import {BooleanInput, MultiSelect, Option, TextInput} from "../../../components/PropertyEditor";
import styles from './ManageUserProjects.scss'
import { useSelector} from "react-redux";
import {projectsSelector} from "modules/project/projectSelectors";
// import cx from 'classnames';
import _remove from 'lodash/remove';
import _filter from 'lodash/filter';

export default function ManageUserProjects ({selectedProjects, onChange}) {
  const [searchTerm , setSearchTerm] = useState('');
  const {projects } = useSelector(projectsSelector);

  const handleToggle = projectId => (e, value) => {

    if (value) {
      selectedProjects.push(projectId);
    } else {
      _remove(selectedProjects, id => projectId === id);
    }

    return onChange('projects')(e, selectedProjects);
  }


    const getFilteredProjects = () => {
      let filteredProjects = []
      if (!searchTerm) return {searching: false, filteredProjects: projects};

      filteredProjects = _filter(projects, project => project.title.includes(searchTerm));
      return {searching: true,  filteredProjects};
    }

    const renderProjects = (_projects) => {

      return _projects.map(p => (
        <React.Fragment key={p.id}>
          <div className="col8">{p.title}</div>
          <div className="col4 text-right">
              <Option
                  Component={BooleanInput}
                  value={selectedProjects.includes(p.id)}
                  onChange={handleToggle(p.id)}
              />
          </div>
        </React.Fragment>
      ))
    }


    let {searching, filteredProjects} = getFilteredProjects();

    if (!searching && !filteredProjects.length) {
        return (
            <div>
                <label className="mb-1">Projects this user can access</label>
                <div>No Projects</div>
            </div>
        )
    }

    return (
        <div>
            <Option
              label="Projects this user can access"
              value={searchTerm}
              Component={TextInput}
              placeholder="Filter by name"
              onChange={(e, val) => setSearchTerm(val)}
            />
          <div className={styles.container}>
            <div className="grid">
              {searching && !filteredProjects.length ?
                  <div>No Results</div>
                :
                renderProjects(filteredProjects)
              }
            </div>
          </div>
        </div>
    )
}