import {takeLatest, put} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import fetch from 'utils/saga/fetch';
import {FETCH_WHITELABEL, receiveWhitelabel, updateWhitelabelLoadingState, updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {error} from 'utils/alert';
import {Whitelabel} from 'utils/whitelabel';

export default function* fetchWhitelabelSaga() {
  yield takeLatest(
    [FETCH_WHITELABEL], fetchWhitelabel
  );
}

function* fetchWhitelabel() {
  // NOt sure why this was added here
  // if (window.location.hostname && (window.location.hostname.indexOf('0.0.0.0')) || window.location.hostname.indexOf('interactr.io')) {
  //   yield put(updateWhitelabelLoadingState(false));
  //   return;
  // }

  const res = yield fetch('whitelabel', {
    method: 'post',
    body: {
      hostname: window.location.hostname
    }
  });

  if (res.status !== 200) {
    error({text:'Unable to load app'});
    yield put(updateWhitelabelLoadingState(false));

  } else {
    const data = yield res.json();
    yield put(receiveWhitelabel(data));

    // new Whitelabel(data);

    // Wait a second before we hide to ensure the interactr stuff is gone
    yield delay(3000);
    yield put(updateWhitelabelLoadingState(false));
  }
}