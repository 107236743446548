import React from 'react';
import {connect} from 'react-redux';
import {updateElement, updateElementStateLoading} from 'modules/element/element';
import {addElementGroup} from 'modules/node/node';
import { updateInteraction } from "modules/interaction/interaction";
import Button from 'components/Buttons/Button';
import {selectedElementSelector} from 'modules/element/elementSelectors';
import ButtonElementProperties from 'modules/element/components/Properties/ButtonElementProperties';
import HotspotElementProperties from 'modules/element/components/Properties/HotspotElementProperties';
import ImageElementProperties from 'modules/element/components/Properties/ImageElementProperties';
import TextElementProperties from 'modules/element/components/Properties/TextElementProperties';
import CustomHtmlElementProperties from 'modules/element/components/Properties/CustomHtmlElementProperties';
import TriggerElementProperties from 'modules/element/components/Properties/TriggerElementProperties';
import FormElementProperties from 'modules/element/components/Properties/FormElementProperties';
import styles from './ElementProperties.scss'
import {
  BUTTON_ELEMENT,
  HOTSPOT_ELEMENT,
  IMAGE_ELEMENT,
  TEXT_ELEMENT,
  DYNAMIC_TEXT_ELEMENT,
  CUSTOM_HTML_ELEMENT,
  TRIGGER_ELEMENT,
  FORM_ELEMENT,
  elements as elementsMetaObj
} from 'shared/element';
import {
    Option,
    TextInput
} from 'components/PropertyEditor';
import Rodal from "rodal";
import Icon from "components/Icon";

@connect(selectedElementSelector, {
    updateElement,
    updateElementStateLoading,
    addElementGroup,
    updateInteraction
})
export default class ElementProperties extends React.Component {
  constructor(){
    super();
    this.state = {
      helpTextOpen: false,
      showElementGroupModal: false,
      elementGroupName: ''
    }
  }

  updateElement = data => {
    const {element} = this.props;
    const {element_type, id} = element;
    this.props.updateElement(element_type, id, data);
  };

  handleElementGroupModal = () => {
      this.setState((state, props) => ({
          showElementGroupModal: !state.showElementGroupModal
      }));
  };

  addNewElementGroup = () => {
      const {
          addElementGroup,
          interaction: { node_id }
      } = this.props;

      addElementGroup(node_id, this.state.elementGroupName);
      this.handleElementGroupModal();
  };

  renderProperties() {
    const {
        element,
        editingContext,
        interactionPropertyChangeHandler,
        interaction,
        elementGroups,
        updateInteraction,
        updateElementStateLoading,
        loading,
        node
    } = this.props;

    const props = {
      element,
      updateElement: this.updateElement,
      handleElementGroupModal: this.handleElementGroupModal,
      updateInteraction,
      updateElementStateLoading,
      editingContext,
      interactionPropertyChangeHandler,
      interaction,
      elementGroups,
      loading,
      node,
      interactionLayer: (node.interaction_layer_id === interaction.id)
    };

    switch (element.element_type) {
      case BUTTON_ELEMENT:
        return <ButtonElementProperties {...props} />;
      case HOTSPOT_ELEMENT:
        return <HotspotElementProperties {...props} />;
      case IMAGE_ELEMENT:
        return <ImageElementProperties {...props} />;
      case TEXT_ELEMENT:
        return <TextElementProperties {...props} />;
      case CUSTOM_HTML_ELEMENT:
        return <CustomHtmlElementProperties {...props} />;
      case TRIGGER_ELEMENT:
        return <TriggerElementProperties {...props} />;
      case FORM_ELEMENT:
        return <FormElementProperties {...props} />;
    }

    return null;
  }

  renderElementGroupModal()
  {
      return (
          <Rodal
              visible={this.state.showElementGroupModal}
              height={250}
              onClose={()=> this.handleElementGroupModal()}
          >
              <div className="modal-heading">
                  <Icon name="plus" /> Add New Element Group
              </div>
              <div className="modal-body">
                  <div className="form-control">
                      <Option
                          label="Element Group Name"
                          value={this.state.elementGroupName}
                          Component={TextInput}
                          onChange={(e)=>this.setState({elementGroupName: e.target.value})}
                      />
                  </div>
              </div>
              <div className="modal-footer">
                  <Button
                      primary
                      onClick={this.addNewElementGroup}
                  >Create</Button>
              </div>
          </Rodal>
      )
  }

  getElementType(elementType){
    if(this.props.element.dynamic) return 'Dynamic Text';
    return elementType;
  }

  getElementTypeDescription() {
    let {element_type: elementType, dynamic} = this.props.element;
    if(dynamic) elementType = DYNAMIC_TEXT_ELEMENT ;
    return elementsMetaObj[elementType].description ;
  }

  render(){
    const {element } = this.props;
    // let elementType = element.element_type.replace(/App\\|element/ig,'');
    // if(elementType === 'Trigger') elementType = 'Popup '+elementType;
    // elementType = elementType.concat(' Element');

    // Accordion Styles used for help text
    const style = {
      'height' : (this.state.helpTextOpen) ? 170 : 0,
      'padding' :  (this.state.helpTextOpen) ?  '10px 15px' : '0px 15px',
      'overflow' : 'hidden',
      'transition' : '.15s linear height'
    };

    return (
      <div>
        {this.renderProperties()}
          {this.renderElementGroupModal()}
      </div>
    )
  }
}
