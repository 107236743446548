import {takeEvery, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import swal from 'sweetalert2';
import {success, error, confirm} from 'utils/alert';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";
import {receiveNode, COPY_NODE} from "modules/node/node";

export default function* copyNodeSaga() {
  yield takeEvery(COPY_NODE, copyNode);
}

function* copyNode({id}) {
  const conf = yield swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to copy this node.',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#00b382',
    confirmButtonText: 'Yes, Please!',
  });

  yield put(updatePageLoadingState(true));

  const res = yield fetch(`nodes/copy/${id}`, {
    method: 'POST'
  });

  // Clear the loader
  yield put(updatePageLoadingState(false));

  if (res.status !== 200) {
    error({text:'Unable To Copy Node'})
  }

  // Receive the project
  const project = yield res.json();
  yield put(receiveNode(project));

}
