import React, { lazy } from 'react';
import {hot} from 'react-hot-loader/root';
import { Switch, Route } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import ErrorBoundary from 'components/ErrorBoundary'
import LoginPage from 'modules/auth/components/LoginPage';
import ForgottenPasswordPage from 'modules/auth/components/ForgottenPasswordPage';
import PasswordResetFromTokenPage from 'modules/auth/components/PasswordResetFromTokenPage';
import RegisterPage from 'modules/registration/components/RegisterPage';
import Spinner from 'components/Spinner';
import LoggedInPage from './LoggedInPage';
import SharePage from 'modules/sharePage/components/SharePage';
import PrivacyPolicy from 'modules/privacyPolicy/components/PrivacyPolicy';
import PageLoader from 'components/PageLoader';

// const LoginPage = React.lazy(() => import(/* webpackChunkName:"LoginPage" */ 'modules/auth/components/LoginPage'));
// const PrivacyPolicy = lazy(() =>
//   import(/* webpackChunkName:"PrivacyPolicy" */ 'modules/privacyPolicy/components/PrivacyPolicy')
// );
// const LoginPage = lazy(() => import(/* webpackChunkName:"Login" */'modules/auth/components/LoginPage')) ;
// const SharePage = lazy(() => import(/* webpackChunkName: "SharePage" */ 'modules/sharePage/components/SharePage'));
// const LoggedInPage = lazy(() => import(/* webpackChunkName:'LoggedInPage' */ './LoggedInPage'));

class RootPage extends React.Component {
  render() {
    return (
      <ErrorBoundary> 
        <PageLoader />
        <React.Suspense fallback={<Spinner fullpage />}>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/forgotten-password" component={ForgottenPasswordPage} />
            <Route path="/password/reset" component={PasswordResetFromTokenPage} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/share" component={SharePage} />
            <Route path="/privacy-policy/:userId(\d+)" component={PrivacyPolicy} />
            <Route component={LoggedInPage} />
          </Switch>
        </React.Suspense>

        <ReduxToastr timeOut={5000} newestOnTop={false} preventDuplicates progressBar />
      </ErrorBoundary>
    );
  }
}

// react-hot-loader recommends pluging hot watcher below all stateful and/or big libraries to avoid updating all of that on hot reloads
export default hot(RootPage);
