import {takeEvery, put} from 'redux-saga/effects';
import {DUPLICATE_INTERACTION, receiveInteraction} from 'modules/interaction/interaction';
import {splitElementFromObj} from 'modules/element/elementUtils';
import {receiveElement} from 'modules/element/element';
import fetch from 'utils/saga/fetch';

export default function* duplicateInteractionSaga() {
  yield takeEvery(DUPLICATE_INTERACTION, duplicateInteraction);
}

function* duplicateInteraction({id, callback}) {
  const res = yield fetch(`interactions/${id}/duplicate`, {
    method: 'POST'
  });
  // const data = yield res.json();
  const data = yield res.json();
  const [interaction, element] = splitElementFromObj(data);
  yield put(receiveElement(element));
  yield put(receiveInteraction(interaction));

  if(callback) callback(interaction)
}
