import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import formatDistance from 'date-fns/formatDistance';
import Rodal from 'rodal';

import { projectPath, projectStatsPath } from 'routeBuilders';
import { sharePageUrl } from 'modules/project/projectUtils';
import { error } from 'utils/alert';
import Label from 'components/Label';
import { deleteProject, copyProject, updateProject } from 'modules/project/project';
import { createProjectCardSelector } from 'modules/project/projectSelectors';
import { generateEmbedCode } from 'modules/project/projectUtils';
import PreviewProjectButton from 'modules/project/components/PreviewProjectButton'
import Button from 'components/Buttons/Button';
import Card from 'components/Card';
import CopyToClipboard from 'components/CopyToClipboard';
import Icon from 'components/Icon';
// import PrivacyLabel from './PrivacyLabel';
// import StatItem from '../../stat/components/statItem';
// import getAsset from 'utils/getAsset';
import analytics from 'shared/analytics';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import MoveToFolderModal from './MoveToFolderModal'


const _proptypes = {
  user: PropTypes.object.isRequired,
  whitelabel: PropTypes.any.isRequired,
  projectThumbnails: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  nodesCount: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired
};

@connect(
  createProjectCardSelector(),
  { deleteProject, copyProject, updateProject }
)
class ProjectCard extends React.Component {
  constructor() {
    super();

    this.state = {
      showEmbedCodeModal: false,
      data: {},
      gotData: false,
      moveToFolderModal: false
    };
  }

  handleClick = () => {
    this.props.history.push(projectPath({ projectId: this.props.project.id }));
  };

  handleDelete = () => {
    this.props.deleteProject(this.props.project.id);
  };

  handleCopy = () => {
    this.props.copyProject(this.props.project.id);
  };

  async componentDidMount() {
    const {project} = this.props;

    const queries =   [{
          name: 'plays',
          collection: 'ProjectView',
          api: 'Interactr',
          filters: {
            project_id: project.id
          },
          start_date: moment().subtract(1, 'years'),
          end_date: moment(),
        }];

    try {
      const { data } = await analytics.queries(queries);
      this.setState({ data, gotData: true });

    } catch (error) {
      this.setState({ fetchingStats: false, statsErrors: { views: true } });
    }
  }

  render() {
    const { project, projectThumbnails } = this.props;
    // const image = project.image_url ? project.image_url : getAsset('/img/project-default.jpg');

    return (
      <Fragment>
          <Card
              large={true}
              description={project.description}
              primaryButton={this.primaryButton}
              previewButton={ this.previewButton }
              title={this.title}
              images={projectThumbnails}
              meta={this.metaData}
              actions={this.actions}
          />
        {this.renderEmbedCodeModal()}

        <MoveToFolderModal
            handleMoveToFolderModal={value => {
              this.setState({moveToFolderModal: value});
            }}
            projectGroupID={project.project_group_id}
            updateProject={this.updateProject}
            moveToFolderModal={this.state.moveToFolderModal}
        />
      </Fragment>
    );
  }

  toggleIsFavorite = () => {
    const {
      project: { is_favourite, ...restOfData },
      updateProject
    } = this.props;
    updateProject({ ...restOfData, is_favourite: !is_favourite }, false);
  };

  updateProject = data => {
    const {
      updateProject,
      project: { id }
    } = this.props;
    updateProject({
      id,
      ...data
    });
  };

  get previewButton (){
    const {project} = this.props;
    return <PreviewProjectButton projectId={project.id} />
  }
  get primaryButton (){
    const {
      user: { read_only },
      project
    } = this.props;

    if (!project.published_at && read_only) {
      return null;
    }

    return {
      text: read_only ? 'Get Embed Code' : 'Select',
      icon: 'arrow-circle-right',
      action: () => {
        if (read_only) {
          this.setState({ showEmbedCodeModal: true });
        } else {
          this.handleClick();
        }
      }
    };
  };

  renderEmbedCodeModal = () => {
    return (
      <Rodal
        visible={this.state.showEmbedCodeModal}
        onClose={() => {
          this.setState({ showEmbedCodeModal: false });
        }}
        height={350}
      >
        <div className="modal-heading">
          <Icon name="code" /> Embed Code
        </div>

        <div className="modal-body">
          <CopyToClipboard value={generateEmbedCode(this.props.project)} />
        </div>

        <div className="modal-footer">
          <Button
            onClick={() => {
              this.setState({ showEmbedCodeModal: false });
            }}
          >
            Close
          </Button>
        </div>
      </Rodal>
    );
  };

  get metaData() {
    const { project } = this.props;
    const {gotData, data} = this.state;

    const lastPublished = project.published_at ? formatDistance(
        new Date(
            project.published_at.replace(/-/g, '/') // This formats the date for all browsers safari doesn't support dates with -
        ), new Date()
    ) : 'Never'

    const label = (project.legacy) ? 'Legacy' : 'Evolution';
    return (
      <ul>
        <ReactTooltip id='project-page' />
        {/* Todo: Handle these use a util for the time and fix stat item */}
        <li>{label}</li>
        <li title="Project views">
          {gotData ? <span>{ (data.plays) ? data.plays : 0 } Views</span> : <Icon name={'spinner-third'} spin={true} />}
        </li>
        <li title="Published last" data-tip={'Last Published'}>{lastPublished}</li>
        {/* // this statItem pulls in keen in 1st pain which pulls in C3 + D3 ,
        // removing this opens huge performance gain with code splitting as we
        // can pull those only when needed in stats page or project settings stats */}
        {/* <Icon name="eye" /> <StatItem projectId={project.id} /> */}
      </ul>
    );
  }

  get title() {
    const {project} = this.props;
    return (
      <React.Fragment >
        {project.is_favourite ? (
          <Icon name={['fas', 'star']} size="sm" style={{ color: 'gold', cursor:'pointer' }} onClick={this.toggleIsFavorite} />
        ) : (
          <Icon name={['fal', 'star']} size="sm" style={{cursor:'pointer'}} onClick={this.toggleIsFavorite} />
        )}
        <span> {project.title}</span>
      </React.Fragment>
    );
  }

  get actions(){
    const {user, project} = this.props;
    return (<ul>
      {user.parent_user_id === 0 ? (
        <li>
          <Icon
            name="clone"
            data-tip="Copy"
            size="sm"
            onClick={() => {
              this.handleCopy();
            }}
          />
        </li>
      ) : null}
      <li>
        <Icon
          name="folder"
          data-tip="Move to Folder"
          size="sm"
          onClick={() => {
            this.setState({moveToFolderModal: true})
          }}
        />
      </li>
      <li>
        <Icon
          name={'share-alt'}
          data-tip="Share"
          size="sm"
          onClick={() => {
            if (project.storage_path) {
              window.location.href = sharePageUrl(
                project,
                this.props.whitelabel ? this.props.whitelabel.domain : false
              );
            } else {
              error({
                text: 'You need to publish the project before you can view the share page'
              });
            }
          }}
        />
      </li>
      {!user.read_only && (
        <li>
          <Icon name="trash-alt" data-tip="Delete"
          size="sm" onClick={this.handleDelete} />
        </li>
      )}
    </ul>)
  }

}
ProjectCard.propTypes = _proptypes;
export default withRouter(ProjectCard);
