import React from 'react';
import styles from './NodePage.scss';
// import SimpleCard from '../../../components/SimpleCard';
// import topNavStyles from 'components/TopNav.scss';
// import AppLogo from '../../../components/AppLogo';
import PageBody from 'components/PageBody';
import NodeProperties from 'modules/node/components/NodeProperties';
// import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';
// import cx from 'classnames';
import LinkButton from '../../../components/Buttons/LinkButton';

export default function NodePageLayout({ node, leftSide, children, actionsComponent }) {
  return (
    <PageBody right={actionsComponent} meta={getMetaData(node)}>
      {/* {children} */}
      <div className="d-flex pr-4">
        <div className={styles.left}>{leftSide}</div>
        <div className={styles.right}>{children}</div>
      </div>
    </PageBody>
  );
}

function getMetaData(node) {
  const { id, name, description, project_id } = node;
  return (
    <div className={styles.meta_wrapper}>
      <div className={styles.meta_title}>
        <h2>
          <LinkButton to={`/projects/${project_id}`} small secondary>
            <Icon name={'arrow-left'} /> Back
          </LinkButton>
          {name}
        </h2>
      </div>
      {/*{!!description && <small className={cx('ellipsis-fade', styles.meta_description)}>{description}</small>}*/}
      <NodeProperties node={node} nodeId={id} />
    </div>
  );
}
