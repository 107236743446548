import {takeEvery, put} from 'redux-saga/effects';
import {FETCH_NODE, receiveNode} from 'modules/node/node';
import fetch from 'utils/saga/fetch';

export default function* fetchNodeSaga() {
  yield takeEvery(FETCH_NODE, fetchNode);
}

function* fetchNode({nodeId}) {
  const res = yield fetch(`nodes/${nodeId}`);
  const node = yield res.json();
  yield put(receiveNode(node));
}
