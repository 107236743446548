import React from 'react';
import DropMediaZone from 'modules/media/components/DropMediaZone';
import swal from 'sweetalert2';

const ACCEPT = ['image/jpeg', 'image/png', 'image/gif'];
const EXTENSIONS = ['jpeg', 'jpg', 'png', 'gif'];

const handleError = error => {
  swal({
    title: 'Upload failed',
    text: error,
    type: 'warning',
    confirmButtonColor: '#ff6961',
    confirmButtonText: 'Got it!'
  });
};
const handleSuccess = () => {
  swal({
    title: 'Success',
    text: 'Upload successful',
    type: 'success',
    confirmButtonText: 'Got it!'
  });
};

export default function DropImageZone({ onSuccess, onError, src, directory, children, style }) {
  return (
    <div>
      {src && (
        <div style={{ marginBottom: '15px' }} className="transparentBackground">
          <img src={src} style={{ maxWidth: '100%' }} />
        </div>
      )}
      <DropMediaZone
        accept={ACCEPT}
        extensions={EXTENSIONS}
        directory={directory}
        uploadSuccessEndpoint={'image/upload'}
        onSuccess={onSuccess || handleSuccess}
        onError={onError || handleError}
        heading={src ? 'Drop here to replace' : 'Drag and drop here'}
        inlineStyles={style}
      >
        {children}
      </DropMediaZone>
    </div>
  );
}
