import {delay} from 'redux-saga';
import {takeLatest, put} from 'redux-saga/effects';
import fetch from 'utils/saga/fetch';
import {UPDATE_MODAL, receiveModal} from 'modules/modal/modal';

export default function* storeModalSaga() {
  yield takeLatest(UPDATE_MODAL, storeModal);
}

function* storeModal({nodeId, modalId, data}) {
  // Debounce - takeLatest will cancel if UPDATE_MODAL called
  yield delay(500);

  // we actually update Redux and update DB here after that
  const res = yield fetch(`modals/${modalId}`, {
    method: 'POST',
    body: data
  });

  const modal = yield res.json();
  yield put(receiveModal(modal));
}
