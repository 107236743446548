import React from 'react';
import {connect} from 'react-redux';
import {whiteLabelSelector} from "modules/pageLoader/pageLoaderSelectors";
import getAsset from 'utils/getAsset';

@connect(whiteLabelSelector, {})
export default class AppLogo extends React.Component{
  render(){
    const {whitelabel, nobrand}= this.props;

    // don't render any logo until whitelabel is either true or false
    if(whitelabel !== null) {
        if (whitelabel.logo) {
            return <img src={whitelabel.logo}/>
        }

        if (nobrand) return null;

        return <img src={getAsset('/img/logo.png')}  />
    }

    return  null;
  }
}
