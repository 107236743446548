import React from 'react';
import cx from 'classnames';
import styles from './ElementItem.scss';
import Icon from 'components/Icon';
import CloneOnDrag from 'components/CloneOnDrag';
import Button from 'components/Buttons/Button';
import getAsset from 'utils/getAsset';

export default function ElementItem({ element, onDragged, onSelectElement, locked }) {
  const { name, icon, description } = element;
  const classes = cx({
    [styles.elementItem]: true
  });

  return (
    <div className={classes}>
      <CloneOnDrag offset={{ x: 2, y: 2 }} onStop={onDragged}>
          <div className={styles.elementInfo}>
              <img
                  src={getAsset(`/img/icons/${icon}`)}
                  className={styles.elementIcon}
                  data-tip={description}
              />
              <p><strong>{name}</strong></p>
              {/* <a
          className={styles.editIcon}
          data-html={true}
          data-place={'bottom'}
          data-tip={`<span style="font-size:1.1em; color:inherit;max-width: 250px;display:block;line-height: 1.3">${description}`}
        >
          <Icon name="info" />
        </a> */}
          </div>
      </CloneOnDrag>
      {locked ? (
        <a href="http://special.interactr.io/interactr-club/a.html" target="_blank" className={styles.locked}>
          <Icon name="lock" className={styles.lockedIcon} />
          <p style={{ marginTop: 5 }}>
            <strong>Exclusive Feature</strong>
          </p>
        </a>
      ) : null}
    </div>
  );
}
