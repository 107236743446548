import React from 'react';
import AccountNav from "./AccountNav";
import PageBody from 'components/PageBody';

export default class AccountDetailsAffiliate extends React.Component {
  render() {
    return (
        <PageBody
            heading="Your Details"
            right={<AccountNav active="affiliates" />}
        >
                <div style={{padding: '30px'}}>
                    <h2 className="form-heading">Affiliates</h2>
                        <p>
                            To promote interactr as an affiliate click this link: <a href="http://paykstrt.com/request/3310" target="_blank">http://paykstrt.com/request/3310</a>
                        </p>
                        <p>
                            <strong>Useful Links</strong>
                            <ul>
                                <li>Sales Page: <a href="http://special.interactr.io/" target="_blank">http://special.interactr.io/</a></li>
                                <li>OTO1 Page: <a href="http://special.interactr.io/interactr-club/a.html" target="_blank">http://special.interactr.io/interactr-club/a.html</a></li>
                                <li>OTO2 Page: <a href="http://special.interactr.io/agency-academy/a.html" target="_blank">http://special.interactr.io/agency-academy/a.html</a></li>
                            </ul>
                        </p>
                </div>
        </PageBody>
    );
  }
}
