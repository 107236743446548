import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.scss';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import VideoPlayer from 'components/VideoPlayer';

import Button from 'components/Buttons/Button';
import Icon from 'components/Icon';
import { clubUpgrade, localUpgrade } from '../utils/externalLinks';

const _proptypes = {
  title: PropTypes.string.isRequired,
  /** The primary button, main action can be any button component or an object with properties {text: button text, icon: fontawesome suppported icons, action: onClick handler } */
  primaryButton: PropTypes.oneOfType([PropTypes.object, PropTypes.element]).isRequired,
  noPreview: PropTypes.bool,
  /** the Preview button is Required to have an onClick method, defaults to play icon . if an previewHandler handler is provided this prop is optional */
  previewButton: PropTypes.element,
  /** The preview button click handler */
  previewHandler: PropTypes.func,
  actions: PropTypes.element,
  /** Images displayed on top of card , must be either one image url string or an array of them */
  images: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired
};

// TODO: Plug preview button with icon
export default function Card({
  title,
  meta,
  images,
  video,
  primaryButton,
  actions,
  noActionButton,
  previewButton,
  previewHandler,
  noPreview,
  description,
  otherHtml,
  large,
  downloadUrl,
  userIsClub,
  templateIsClub,
  templateIsLocal,
  userIsLocal,
  smallTitle,
  previewIconColor,
  layerPreview,
  // views = -1,
  children,
  style
}) {
  let userHasAccess = false;
  let upgradeUrl = clubUpgrade;

  if (!noPreview && !previewButton && !previewHandler) {
    throw 'Card component requires one of these props : a previewButton component or an previewHandler handler. use `noPreview` prop to disable it altogether.';
  }

  if (templateIsClub && userIsClub) {
    userHasAccess = true;
  }
  if (templateIsLocal && userIsLocal) {
    userHasAccess = true;
  }

  if (!templateIsLocal && !templateIsClub) {
    userHasAccess = true;
  }

  if (!userHasAccess && templateIsLocal) {
    upgradeUrl = localUpgrade;
  }

  const showPreviewButton = userHasAccess && !noPreview;

  return (
    <div className={styles.Card} style={{ ...style }}>
      <ReactTooltip />
      {otherHtml}

      {!!images && (
        <div className={styles.image}>
          <div className={styles.thumbnailsWrapper}>
            <Thumbnails
              images={images}
              className={cx(styles.cardThumbnail, { [styles.single]: !Array.isArray(images) })}
            />
            {showPreviewButton && (
              <article className={cx(styles.previewIcon)}>
                {renderPreviewButton(previewButton, previewIconColor)}
              </article>
            )}
          </div>
        </div>
      )}
      {!!layerPreview &&
      <div className={styles.video}>
        <div className={styles.videoWrapper}>
          <video src={layerPreview} loop autoPlay muted style={{maxWidth:'100%', height: 'auto'}} />
        </div>
      </div>
      }
      {!!video && (
        <div className={styles.video}>
          <div className={styles.videoWrapper}>
            <VideoPlayer
              url={video.url}
              videoId={video.id}
              imageURL={video.thumbnail_url}
              controls
              light={video.thumbnail_url || false} // stops preload and only shows thumbnail awesome for perf
            />
          </div>
        </div>
      )}
      <div className={cx(styles.content, { [styles.largeContent]: large })}>
        <div className={styles.info}>
          {title && <h4 className={styles.title}>{title}</h4>}
          {smallTitle && <h4 className={styles.small_title}>{smallTitle}</h4>}
          {/* {description && <p className={styles.description}>{description}</p>} */}
          {meta && <small className={styles.meta}>{meta}</small>}
          {downloadUrl && userIsClub ? (
            <div style={{ position: 'absolute', backgroundColor: 'white', top: '9px', right: '0' }}>
              <Button
                data-tip="Download Assets"
                primary
                onClick={() => {
                  var win = window.open(downloadUrl, '_blank');
                  win.focus();
                }}
              >
                {' '}
                <Icon name="cloud-download" style={{ marginRight: '0' }} />{' '}
              </Button>
            </div>
          ) : null}
        </div>
        <div className={styles.actionsBar}>
          {!noActionButton && (
            <div className={styles.mainAction}>
              {userHasAccess ? renderButton(primaryButton, large) : <UpgradeButton upgradeUrl={upgradeUrl} />}
            </div>
          )}
          {actions && <div className={styles.actions}>{actions}</div>}
        </div>
        {children}
      </div>
    </div>
  );
}

function Thumbnails({ images, className }) {
  return Array.isArray(images) ? (
    images.map(url => <img src={url} key={Math.random() * 100 + 1} className={className} alt="thumbnail" />)
  ) : (
    <img src={images} className={className} alt="thumbnail" />
  );
}

function renderPreviewButton(component, previewIconColor) {
  return component ? (
    component
  ) : (
    <Icon
      icon={['fas', 'play']}
      transform="shrink-12"
      color={previewIconColor || 'white'}
      mask={['fas', 'circle']}
      size="4x"
    />
  );
}

function renderButton(button, large, extraProps = {}) {
  return (
    button &&
    (button.type ? (
      button
    ) : (
      <Button small={!large} onClick={button.action} icon={button.icon} rightIcon primary {...extraProps}>
        {button.text}
      </Button>
    ))
  );
}

function UpgradeButton({ upgradeUrl }) {
  return renderButton({
    text: 'Upgrade',
    action() {
      window.location.href = upgradeUrl;
    }
  }, true);
}
Card.propTypes = _proptypes;
Card.defaultProps = {
  noActionButton: false
};
