import {fromJS} from "immutable";
import {keyBy} from 'utils/immutableUtils';

const initialState = fromJS({
    data: {},
    selected:0,
    user: {}
});

function userReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER:
            return state.mergeIn(['data', action.id], fromJS(action.data));
        case RECEIVE_USERS:
          return state.set('data', keyBy(action.users));
        case RECEIVE_USER:
            return state.setIn(
                ['data', action.user.id.toString()],
                fromJS(action.user)
            );
        case USER_DELETED:
            return state.deleteIn(['data', action.userId.toString()]);
        case SELECT_USER:
            return state.set('selected', fromJS(action.id));
    }

    return state;
}

export const reducers = {
    users: userReducer
};


export const RECEIVE_USERS = 'user:RECEIVE_USERS';
export function receiveUsers(users) {
    return {
        type: RECEIVE_USERS,
        users
    };
}

export const RECEIVE_USER = 'user:RECEIVE_USER';
export function receiveUser(user) {
    return {
        type: RECEIVE_USER,
        user
    }
}

export const FETCH_USERS = 'user:FETCH_USERS';
export function fetchUsers() {
    return {
        type: FETCH_USERS
    };
}


export const USER_SEARCH = 'user:USER_SEARCH';
export function userSearch(data, callback) {
  return {
    type: USER_SEARCH,
    data,
    callback
  };
}

export const LOGIN_AS_USER = 'user:LOGIN_AS_USER';
export function loginAsUser(userId, saveInStorage = false) {
  return {
    type: LOGIN_AS_USER,
    userId,
    saveInStorage
  };
}

export const DELETE_USER = 'user:DELETE_USER';
export function deleteUser(userId, callback) {
  return {
    type: DELETE_USER,
    userId,
    callback
  }
}

export const USER_DELETED = 'user:USER_DELETED';
export function userDeleted(userId) {
    return {
        type: USER_DELETED,
        userId
    };
}


export const UPDATE_USER = 'user:UPDATE_USER';
export function updateUser(id, data, delay = true) {
    return {
        type: UPDATE_USER,
        id,
        data,
        delay
    };
}
export const SELECT_USER = 'user:SELECT_USER';
export function selectUser(id){
    return {
        type: SELECT_USER,
        id
    }
}

export const SAVE_USER = 'user:SAVE_USER';
export function saveUser(id, data, callback) {
    return {
        type: SAVE_USER,
        id,
        data,
        callback
    };
}

export const CREATE_USER = 'user:CREATE_USER';
export function createUser(user, callback){
    return{
        type: CREATE_USER,
        user,
        callback
    }
}