import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import {
  projectSettingsPath,
  publishProjectPath,
  projectStatsPath,
  projectSharingPage,
  projectChaptersPage,
  nodePath,
  modalPath,
  toRoutePath
} from 'routeBuilders';
import PublishProjectPage from './PublishProjectPage';
import ProjectSettingsPage from './ProjectSettingsPage';
import ProjectCanvasPage from './ProjectCanvasPage';
import SharingProjectPage from './ProjectSharingPage/SharingProjectPage';
import ProjectChaptersPage from './ProjectChaptersPage';

import NodePage from 'modules/node/components/NodePage';
import ModalPage from 'modules/modal/components/ModalPage';
import LoadableProjectStatsPage from 'modules/project/components/ProjectStatsPage';

// const LoadableProjectStatsPage = React.lazy(() =>
//   import(/* webpackChunkName: 'ProjectStasPage'*/ 'modules/project/components/ProjectStatsPage')
// );

function ProjectOverview() {
  return (
    <Switch>
      <Route path={toRoutePath(modalPath, ['projectId', 'nodeId', 'modalId'])} component={ModalPage} />
      <Route path={toRoutePath(nodePath, ['projectId', 'nodeId'])} component={NodePage} />
      <Route path={toRoutePath(projectSettingsPath, ['projectId'])} component={ProjectSettingsPage} />
      <Route path={toRoutePath(projectChaptersPage, ['projectId'])} component={ProjectChaptersPage} />
      <Route path={toRoutePath(projectSharingPage, ['projectId'])} component={SharingProjectPage} />
      <Route path={toRoutePath(projectStatsPath, ['projectId'])} component={LoadableProjectStatsPage} />
      <Route path={toRoutePath(publishProjectPath, ['projectId'])} component={PublishProjectPage} />
      <Route component={ProjectCanvasPage} />
    </Switch>
  );
}

export default withRouter(ProjectOverview);
