import React from 'react';
import { connect } from 'react-redux';
import pickBy from 'lodash/pickBy';
import cx from 'classnames';

// Stuff
import { nodeSelector } from 'modules/node/nodeSelectors';
import { interactionLayerSelector } from 'modules/modal/modalSelectors';
import { updateNode } from 'modules/node/node';
import ClickableElementProperties from 'modules/element/components/Properties/ClickableElementProperties';
import {changeStartNode, updateProject} from 'modules/project/project';
import styles from './NodeProperties.scss';

// Components
import {
  // Section,
  Option,
  // SizeInput,
  // TextInput,
  // LargeTextInput,
  BooleanInput,
  SelectInput
} from 'components/PropertyEditor';
import Icon from 'components/Icon';
// import ErrorMessage from 'components/ErrorMessage';
// import { toJS } from 'immutable';

@connect(interactionLayerSelector)
@connect(nodeSelector, { updateNode, changeStartNode, updateProject })
export default class NodeProperties extends React.Component {
  handleChangeAction = ({ action: completeAction, actionArg: completeActionArg }) => {
    const { node, updateNode } = this.props;
    const data = pickBy({ completeAction, completeActionArg }, val => val !== undefined);

    updateNode(node.id, data);
  };

  changeSourceHandler = (e, val) => {
    const { node, project: {project}, updateNode, updateProject } = this.props;

    // TODO: Remove immutable stuff from components , checkout other users if the selector
    // and look for similar media lists 
    const oldMediaItem = this.props.media.get(node.media_id.toString()).toJS();
    const newMediaItem = this.props.media.get(val.toString()).toJS();

    /**
     * If selected node is START node and the old media thumbnail is equal to the project thumbnail update project thumbnail with new
     * this way we only replace project thumbnail if the user hasn't added a custom thumbnail
     */

    if (this.isStartNode() && oldMediaItem.thumbnail_url === project.image_url) {
      updateProject({
        id: project.id,
        image_url: newMediaItem.thumbnail_url
      })
    }

    updateNode(node.id, { media_id: val });
  }

  changeHandler = name => (e, val) => {
    const { node, updateNode } = this.props;
    updateNode(node.id, { [name]: val });
  };

  updateStartNode = (e, val) => {
    const { project, node } = this.props;

    if (val) {
      this.props.changeStartNode(project.project.id, node.id);
    } else {
      this.props.changeStartNode(project.project.id, 0);
    }
  };

  isStartNode() {
    const { project, node } = this.props;

    if (!project) return false;
    if (!project.project) return false;
    if (!node) return false;

    return parseInt(node.id) === parseInt(project.project.start_node_id);
  }

  /** When Interaction layer is setup and no timer is, end actions should be deactivated . */
  hasILAndNoTimer = () => {
    const { interactionLayerModal } = this.props;
    if (interactionLayerModal && interactionLayerModal.background_animation) {
      let bgAnimation = interactionLayerModal.background_animation;
      return !bgAnimation.use_timer;
    }
    return false;
  };
  render() {
    const { node } = this.props;

    if (!node) return null;

    const {
      name,
      description,
      has_chapters,
      chapters,
      id,
      modals,
      completeAction,
      completeActionArg,
      loop,
      media_id
    } = node;

    const startNode = this.isStartNode();
    let filteredMedia = [];
    const mediaItems = this.props.media.toList().toJS();

    mediaItems.forEach(media => {
      if (media.project_id === node.project_id) {
        filteredMedia.push({
          clearableValue: false,
          label: media.name,
          value: media.id
        });
      }
    });

    return (
      <div className={cx(styles.nodeProperties)}>
        {/* <Section title="Node Properties" icon="sliders-v"> */}
        {/* <Option label="Name" value={name} Component={TextInput} onChange={this.changeHandler('name')} />
          <Option
            label="Description"
            value={description}
            Component={LargeTextInput}
            onChange={this.changeHandler('description')}
          /> */}
        <div className="d-flex">
          <div className="w-50 mr-2">
            <Option
              label="Source Media"
              value={media_id}
              onChange={this.changeSourceHandler}
              Component={SelectInput}
              options={filteredMedia}
            />
            <div className="d-flex w-100">
              <Option
                label="Loop Video"
                value={loop}
                Component={BooleanInput}
                disabled={this.hasILAndNoTimer()}
                onChange={this.changeHandler('loop')}
              />
              <Option
                label="Start Node"
                value={startNode}
                Component={BooleanInput}
                style={{ marginLeft: 40 }}
                onChange={(e, val) => this.updateStartNode(e, val)}
              />
            </div>
          </div>

          {/* </Section> */}
          <ClickableElementProperties
            actionTitle="On Video End"
            actionType="node_complete"
            updateElement={this.handleChangeAction}
            disabled={this.hasILAndNoTimer()}
            element={{
              action: completeAction,
              actionArg: completeActionArg
            }}
            className="flex-1"
          />
        </div>
        {this.hasILAndNoTimer() && (
          <span> <Icon icon="exclamation-triangle" />If you have the interaction layer setup you must have a timer setup for end actions to work properly</span>
          // <ErrorMessage msg="If you have the interaction layer setup you must have a timer setup for end actions to work properly" centered={false} iconSize="sm"/>
        )}
      </div>
    );
  }
}
