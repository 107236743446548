import React from 'react';
import {connect} from 'react-redux';
import {Section} from 'components/PropertyEditor/PropertyEditor';
import Styles from './ModalProperties.scss';
import Icon from 'components/Icon';

export default class ModalElementList extends React.Component {
  editElement = (element) => {
    this.props.selectElement(element.element_type, element.element_id);
    this.cancelHoverItem(element.element_id);
  };

  getElementIcon = (element) => {
    switch (element.element_type) {
      case("App\\ButtonElement") :
        return 'square';
      case("App\\TextElement") :
        return 'font';
      case("App\\ImageElement") :
        return 'image';
      case("App\\FormElement") :
        return 'envelope-open';
      case("App\\CustomHtmlElement") :
        return 'code';
    }
    return '';
  };

  hoverItem = (id) => {
    // const item = $('div[data-elemkey="'+id+'"]');
    // if (item.length) {
    //   this.originBorderColor = item[0].children[0].children[0].style.borderColor;
    //   this.originBorderStyle = item[0].children[0].children[0].style.borderStyle;
    //   this.originBorderWidth = item[0].children[0].children[0].style.borderWidth;
    //
    //   item[0].children[0].children[0].style.border = '1px dashed red';
    // }
  };

  cancelHoverItem = (id) => {
    // const item = $('div[data-elemkey="'+id+'"]');
    // if (item.length) {
    //   item[0].children[0].children[0].style.border = '';
    //
    //   if (this.originBorderColor) {
    //     item[0].children[0].children[0].style.borderColor = this.originBorderColor;
    //   }
    //
    //   if (this.originBorderStyle) {
    //     item[0].children[0].children[0].style.borderStyle = this.originBorderStyle;
    //   }
    //
    //   if (this.originBorderWidth) {
    //     item[0].children[0].children[0].style.borderWidth = this.originBorderWidth;
    //   }
    // }
  };

  renderItem = (element)=> {
    const {name, id, element_id} = element;
    return(
      <li key={id} onClick={()=> this.editElement(element)} onMouseOver={() => this.hoverItem(element_id)} onMouseOut={() => this.cancelHoverItem(element_id)}>
        <Icon name={this.getElementIcon(element)} />
        {name}
      </li>
    )
  };


  render(){


    const {modal} = this.props
    const elements = modal ? modal.elements : [];

    return(
      <Section title="Elements" icon="list" >
        { Array.isArray(elements) && elements.length > 0 ?
          <ul className={Styles.elementsList}>
            {elements.map( (element) => this.renderItem(element))}
          </ul> :
          <p>You've not created any elements yet. Click Add New Element above to create your first element.</p>
        }
      </Section>
    )
  }
}