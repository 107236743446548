import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import {MenuLink} from './Link';
import Button from './Buttons/Button';

const _propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  icon : PropTypes.any,
};


const MenuItem = ({ className, icon,children, ...props }) => {
  return (
    <li className={className}>
      <MenuLink to={props.to} href={props.href}  {...props}>
        {icon && <Icon fixedWidth name={icon} />}
        {children}
      </MenuLink>
    </li>
  );
};

MenuItem.propTypes = _propTypes;


export default MenuItem;
