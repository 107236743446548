import React from 'react';
import {connect} from 'react-redux';
import {SelectInput} from 'components/PropertyEditor';
import {projectModalsSelector} from 'modules/modal/modalSelectors';
import {addModalToElement} from 'modules/modal/modal';
import Spinner from 'components/Spinner';
import {withRouter} from 'react-router-dom';
import Button from 'components/Buttons/Button';

@connect(projectModalsSelector, {addModalToElement})
class SelectModal extends React.Component {
  handleAdd = () => {
    const {projectId, nodeId} = this.props.match.params;
    const {addModalToElement, element_id, element_type} = this.props;
    addModalToElement(projectId, nodeId, element_type, element_id);
  };

  render() {
    const {modals, loading, element_type, ...props} = this.props;

    if (loading) return <Spinner />;

    const options = modals.reduce(
      (options, modal) => {
        return {...options, [modal.id]: modal.name};
      },
      {'': 'Select Popup'}
    );

    return (
      <div>
        {/* TODO not sure this is needed anymore  */}
        {/*<SelectInput {...props} options={options} />*/}
        {/*<Button*/}
        {/*  onClick={this.handleAdd}*/}
        {/*  primary*/}
        {/*  style={{float: 'right', marginRight: '0'}}*/}
        {/*  icon="plus"*/}
        {/*>*/}
        {/*  Change Template*/}
        {/*</Button>*/}
      </div>
    );
  }
}

export default withRouter(SelectModal);