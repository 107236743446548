import React from 'react';
import { connect } from 'react-redux';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { userSelector } from 'modules/auth/authSelectors';
import PageBody from 'components/PageBody';

@connect(userSelector, { updatePageLoadingState })
export default class UpgradePage extends React.Component {
  render() {
    this.props.updatePageLoadingState(false);
    const user = this.props.user;

    return (
      <PageBody heading="Upgrade">
        <div className="grid" style={{ marginLeft: '30px' }}>
          <div className="w-100">
            <h3>Available Upgrades</h3>
          </div>
          <div className="w-100">
            {!!user.parent_user_id ? (
              <div>
                <h4>No Upgrades</h4>
              </div>
            ) : !user.evolution ? (
              <div>
                <h4>Interactr Evolution</h4>
                <a href="https://interactrevolution.com/upgrade/evolution">Upgrade Here</a>
              </div>
            ) : (
              <div>
                <h4>Interactr Evolution Pro</h4>
                {!user.evolution_pro ? (
                  <a href="https://interactrevolution.com/upgrade/pro">Upgrade Here</a>
                ) : (
                  <p>You already have Evolution Pro</p>
                )}

                <h4>Interactr Evolution Agency Club</h4>
                {!user.evolution_club ? (
                  <a href="https://interactrevolution.com/upgrade/agency-club">Upgrade Here</a>
                ) : (
                  <p>You already have Evolution Club</p>
                )}
              </div>
            )}
          </div>
        </div>
      </PageBody>
    );
  }
}
