import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import map from 'lodash/map';
import PropTypes from 'prop-types';

// stuff
import styles from './ElementToolbar.scss';
import { toggleElementWizard } from 'modules/interaction';

// components
// import ElementWizardModal from 'modules/interaction/components/ElementWizard/ElementWizardModal';
import ElementToolbarItem from './ElementToolbarItem';

import { elements } from 'shared/element';

const props = {
  /** Whether to use the wizard or not like for modal elements */
  useWizard: PropTypes.bool.isRequired,
  /** Callback fired when not using the element wizard */
  onAdd: PropTypes.func,
  elementsMeta: PropTypes.object
};

export default function ElementToolbar({ useWizard , onAdd, elementsMeta }) {
  const dispatch = useDispatch();

  function handleSelectElement(elementType, elementPosition) {
    if (useWizard)
      dispatch(toggleElementWizard({ open: true, elementType, elementPosition }));
    else if (typeof onAdd === 'function') onAdd(elementType, elementPosition);
  }

  // Default to all elements if not passed as prop
  if (!elementsMeta) {
    elementsMeta = map(elements, (values, key) => {
      return { ...values, type: key };
    });
  }

  return (
    <div className={styles.ElementToolbar}>
      {map(elementsMeta, element => {
        return <ElementToolbarItem key={element.type} element={element} onSelect={handleSelectElement} />;
      })}
    </div>
  );
}
ElementToolbar.propTypes = props;
ElementToolbar.defaultProps = {
  useWizard : true
}