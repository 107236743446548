import {takeLatest, put} from 'redux-saga/effects';
import {CHANGE_START_NODE, updateProject} from 'modules/project/project';

export default function* changeStartNodeSaga() {
  yield takeLatest([CHANGE_START_NODE], changeStartNode);
}

function* changeStartNode({projectId, startNodeId}) {
  yield put(updateProject({id: projectId, start_node_id: startNodeId}));
}
