import { createSelector } from 'reselect';
import { toJS } from 'utils/immutableUtils';

const stateSelector = state => state.customLists;

export const customListsSelector = createSelector(
  stateSelector,
  state => ({
    customLists: toJS(state.get('customLists'))
  })
);
