import React from 'react';
import PropTypes from 'prop-types';
import NativeListener from 'react-native-listener';
// Stuff
import { BUTTON_ELEMENT, TEXT_ELEMENT } from 'shared/element';
import styles from './ElementWizard.scss';
// Components
import { Option, TextInput } from 'components/PropertyEditor';
import ButtonElement from 'modules/element/components/Element/ButtonElement';
import TextElement from 'modules/element/components/Element/TextElement';
import ErrorMessage from 'components/ErrorMessage';

const textStep = {
  /** Called after updating any element data  */
  updateElement: PropTypes.func.isRequired,
  updateInteraction: PropTypes.func.isRequired,
  /** Element data that populates the actions  */
  element: PropTypes.object.isRequired,
  interaction: PropTypes.object
};

const supportingElements = [BUTTON_ELEMENT, TEXT_ELEMENT];

export default function TextStep({ interaction, element, updateElement }) {
  const handleKeyDown = e => {
    e.stopPropagation();
  };

  const handleUpdate = ({ html, width, height, posX, posY }) => {
    // positions shouldn't be updated
    updateElement({ width, height });
    html && updateElement({ html });
    // updateElement({ html: editor.getContent() });
  };

  const noTextSupport = !supportingElements.includes(interaction.element_type);

  return noTextSupport ? (
    <ErrorMessage msg="This Element doesn't support text" />
  ) : (
    <div className={styles.step_wrapper}>
      <h3>Resize or double click to edit text</h3>
      <div className={styles.element_preview}>
        <ElementPreview
          positionable={false}
          type={interaction.element_type}
          {...element}
          posX={20} 
          posY={20}
          onUpdate={handleUpdate}
        />
      </div>
    </div>
  );
}

TextStep.propTypes = textStep;

function ElementPreview({ type, ...props }) {
  switch (type) {
    case BUTTON_ELEMENT:
      return <ButtonElement {...props} />;
    case TEXT_ELEMENT:
      return <TextElement {...props} />;

    default:
      return <p>Preview unavailable</p>;
  }
}
