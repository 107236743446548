import React from 'react';
import {connect} from 'react-redux';
import styles from './Node.scss';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';
import {NODE, dragDataSetter} from 'modules/composer/dragging';
import {nodeSelector} from 'modules/node/nodeSelectors';
import {deleteNode, copyNode, updateNode} from 'modules/node/node';
import {withRouter} from 'react-router-dom';
import {nodePath} from 'routeBuilders';
import NodeControl from './NodeControl';
import getAsset from 'utils/getAsset';

const setNodeData = dragDataSetter(NODE);

export const WIDTH = 142;
export const HEIGHT = 85;
export const TRUE_HEIGHT = HEIGHT + 20 + 20; // this gives us room for the name (20)  + (shadow (20) in firefox)
const NODE_HEIGHT = TRUE_HEIGHT + 35;

@connect(nodeSelector, {deleteNode, copyNode, updateNode})
@onClickOutside
class Node extends React.Component {
  state = {
    selected: false,
    editing: false,
  };

  handleMouseDown = () => {
    this.setState({selected: true});
  };

  handleClickOutside(e) {
    if (!e.shiftKey) {
      this.setState({selected: false});
    }
  }

  handleDoubleClick = e => {
    this.goToNode();
  };

  goToNode = () => {
    const {history, id, match: {params: {projectId}}} = this.props;
    history.push(nodePath({nodeId: id, projectId}));
  };

  handleRef = node => setNodeData.call(this, node);

  handleDelete = () => {
    if (this.state.selected) {
      const {deleteNode, node: {id}} = this.props;
      deleteNode(id);
    }
  };

  handleCopy = () => {
    if (this.state.selected) {
      const {copyNode, node: {id}} = this.props;
      copyNode(id);
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    if (['Delete', 'Backspace'].includes(e.key)) {
      this.handleDelete();
    }
  };

  render() {
    const {node: {posX: x, posY: y, name, id}, mediaItem, editNodeName} = this.props;

    if (!mediaItem) {
      return null;
    }

    const classes = cx(styles.Node, {
      [styles.selected]: this.state.selected
    });

    if (! mediaItem.thumbnail_url){
      mediaItem.thumbnail_url = getAsset('/img/no-thumb.jpg');
    }

    return (
      <foreignObject
        key="node"
        width={WIDTH + 10}
        height={NODE_HEIGHT}
        x={x}
        y={y}
        ref={this.handleRef}
      >
        <div className={styles.wrapper}>
          <div className={styles.name} onDoubleClick={()=>editNodeName(name, id)}>{name}</div>
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{width: WIDTH, height: HEIGHT}}
            className={classes}
            onMouseDown={this.handleMouseDown}
            onDoubleClick={this.handleDoubleClick}
          >
            {mediaItem.thumbnail_url && <img
              draggable={false}
              className={styles.image}
              src={mediaItem.thumbnail_url}
            />}
          </div>
          <NodeControl
            id={id}
            selected={this.state.selected}
            select={this.goToNode}
            onDelete={this.handleDelete}
            onEdit={()=>editNodeName(name, id)}
            onCopy={this.handleCopy}
          />
        </div>
      </foreignObject>
    );
  }
}

export default withRouter(Node);
