import React, {Component} from 'react';
import times from 'lodash/times';
import styles from './Timeline.scss';
import Time from 'components/Time';
import PlayHead from './PlayHead';
// import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icon'
import {BooleanInput, Option} from "../PropertyEditor";

export default class Timeline extends Component {
  state = {
    muted: false,
    grid: false
  };

  static defaultProps = {
    markerCount: 6
  };

  renderAxis() {
    const {duration, markerCount} = this.props;
    return (
      <div className={styles.axis}>
        {times(markerCount, i => (
          <div className={styles.marker} key={i}>
            <Time s={i * duration / markerCount} />
          </div>
        ))}
      </div>
    );
  }

  // x position relative to Timeline root el
  // Our media lib can handle seeking by percentage
  handleScrub = newX => {
    const timelineHolder = document.getElementById("timeline-bar-holder");
    this.props.onScrub(newX / timelineHolder.offsetWidth);
  };

  toggleMuted = () => {
    this.setState(prevState => {
      return {muted: !prevState.muted};
    });

    // TODO Mute / Unmute the video
  };

  skipToEnd = () => {
    this.props.onScrub(0.99);
  };

  skipToStart = () => {
    this.props.onScrub(0);
  };

  getXForPlayed = (played) => {
    const {duration} = this.props;

    const timelineHolder = document.getElementById("timeline-bar-holder");
    if (!timelineHolder) {
      return 0;
    }

    const ratio = duration / timelineHolder.offsetWidth;
    return played === 0 ? 0 : played / ratio;
  };


  render() {
    const {duration, playing, played, play, pause, children, muted, grid, toggleMuted, toggleGrid} = this.props;
    return (
      <div>
          <div className={styles.Timeline}>
            <div className={styles.controls}>
              <div className={styles.controlSection}>
                <div className={styles.videoTime}>
                  <Time s={played} /> / <Time s={duration} />
                  <Icon
                      pointer
                      icon={muted ? "volume-mute" : "volume-up"}
                      onClick={()=>toggleMuted()}
                      size="lg"
                      className={styles.muteButton}
                  >
                    {muted ? 'Unmute Video' : 'Mute Video'}
                  </Icon>
                </div>
              </div>
              <div className={styles.controlSection}>
                <PlayPause
                  playing={playing}
                  play={play}
                  pause={pause}
                  skipToEnd={this.skipToEnd}
                  skipToStart={this.skipToStart}
                />
              </div>
              <div className={styles.controlSection}>
                <div className={styles.right}>
                  <Option
                      label="Grid"
                      value={grid}
                      Component={BooleanInput}
                      style={{ marginLeft: 40 }}
                      onChange={() => toggleGrid()}
                  />
              {/*    <Icon*/}
              {/*      pointer*/}
              {/*      icon={muted ? "volume-mute" : "volume-up"}*/}
              {/*      onClick={()=>toggleMuted()}*/}
              {/*      size="lg"*/}
              {/*    >*/}
              {/*      {muted ? 'Unmute Video' : 'Mute Video'}*/}
              {/*    </Icon>*/}
                </div>
              </div>
            </div>
         <div className={styles.axisWrapper}>
           <div className={styles.playerWrapper}>
             <PlayHead
               pause={pause}
               x={this.getXForPlayed(played)}
               onChange={this.handleScrub}
             />{' '}
           </div>
           {this.renderAxis()}
         </div>
        </div>
        <div className={styles.interactionsWrapper} >
          <div className={styles.interactions}  id="timeline-bar-holder">{children}</div>
        </div>
      </div>
    );
  }
}

function PlayPause({playing, play, pause, skipToStart, skipToEnd}) {
  return (
    <div className={styles.PlayPause}>
      <Icon pointer  size="sm" icon="fast-backward" onClick={skipToStart} />
      {playing ? (
        <Icon pointer icon={['far','pause-circle']} size="2x" secondary onClick={pause} />
      ) : (
        <Icon pointer icon={['far','play-circle']}  size="2x"  secondary onClick={play} />
      )}
      <Icon pointer  size="sm" icon="fast-forward" onClick={skipToEnd} />
    </div>
  );
}
