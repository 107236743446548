import React from 'react';
import 'tui-chart/dist/maps/world';
import {MapChart} from '@toast-ui/react-chart';
import find from 'lodash/find';

export default class ProjectViewsByDeviceChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            codes:[
                'AE','AF', 'AL','AM','AO', 'AR', 'AT', 'AU', 'AZ', 'BA', 'BE', 'BF',
                'BG','BI','BJ', 'BN', 'BO', 'BR', 'BS', 'BT', 'BW', 'BY', 'BZ','CA',
                'CD','CF','CG','CH','CI','CL', 'CM','CN','CO', 'CR', 'CU', 'CY','CZ',
                'DE', 'DJ', 'DK', 'DO', 'DZ','EC', 'EE', 'EG','ER', 'ES','ET', 'FI',
                'FJ','FK', 'FR', 'GA', 'GB', 'GE', 'GH', 'GL', 'GM', 'GN', 'GQ', 'GR',
                'GT', 'GW', 'GY', 'HN','HR', 'HT', 'HU','ID', 'IE', 'IL', 'IN', 'IQ',
                'IR', 'IS', 'IT', 'JM', 'JO', 'JP', 'KE', 'KG','KH', 'KP', 'KR', 'KW',
                'KZ', 'LA', 'LB', 'LK', 'LR', 'LS','LT', 'LU','LV', 'LY', 'MA', 'MD',
                'ME','MG', 'MK','ML', 'MM', 'MN', 'MR', 'MW','MX', 'MY','MZ','NA','NC',
                'NE','NG','NI', 'NL', 'NO','NP', 'NZ', 'OM', 'PA', 'PE', 'PG','PH', 'PK',
                'PL','PR', 'PT', 'PY', 'QA', 'RO', 'RS', 'RU', 'RW','SA', 'SB', 'SD', 'SE',
                'SI','SK', 'SL', 'SN', 'SO', 'SR', 'SV', 'SY', 'SZ', 'TG', 'TH', 'TJ',
                'TL','TM','TN', 'TR', 'TT', 'TZ', 'UG', 'US', 'UY', 'UZ', 'VE', 'VN', 'VU',
                'XK', 'YE', 'ZA', 'ZM', 'ZW'
            ]
        }
    }

    getCodeValue(code){
        const {data}  = this.props;
        const res = find(data.location, (l)=>{
            return (l.country_code === code)
        });

        return (res) ? res.count : 0;
    }

    render(){
        var data = {
            series: this.state.codes.map(c =>{
                return {
                    code: c,
                    data: this.getCodeValue(c)
                }
            })
        };

        var options = {
            chart: {
                width: 900,
                height: 700,
                title: 'Views By Location',
                // format: '0.00'
            },
            map: 'world',
            legend: {
                align: 'bottom'
            }
        };

        return(
            <MapChart
                data={data}
                options={options}
            />
        );
    }
}