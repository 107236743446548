import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { toastr } from 'react-redux-toastr';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import Button from 'components/Buttons/Button';
import IconButton from 'components/Buttons/IconButton';
import {updateProject} from "../project";

import {Option, BooleanInput, SelectInput, LargeTextInput} from 'components/PropertyEditor';
import { defaultFont } from 'shared/fonts';
import SelectFont from 'modules/project/components/SelectFont';
import TemplateProperties from 'modules/template/components/TemplateProperties';
import { TextInput } from '../../../components/PropertyEditor';
import styles from './ProjectSettingsForm.scss';
import { recreateSelectOptions } from '../../../utils/domUtils';
import Icon from 'components/Icon';
import VideoThumbnailSelector from "./VideoThumbnailSelector";

const _proptypes = {
  saving: PropTypes.bool
};

@connect(null, {updateProject})
export default class ProjectSettingsForm extends React.Component {
  static defaultProps = {
    submitLabel: 'Save Changes'
  };

  constructor({ project }) {
    super(...arguments);
  }

  validInputs = () => {
    const { fbPixelId } = this.props.project;
    // permits user to see multiple errors as opposed to just 1 at a time
    let valid = true;

    // Keep piling rules , most severe first
    if (fbPixelId) {
      if (!Number.isInteger(Number(fbPixelId))) {
        valid = false;
        toastr.error('Invalid Pixel ID', 'The FB Pixel ID is a Number Sequence!');
      }
    }

    return valid;
  };

  handleSubmit = e => {
    const { onSubmit } = this.props;
    e.preventDefault();
    if (onSubmit && this.validInputs()) onSubmit(e, this.props.project);
  };

  updateProject = data => {
    const {project: {id}, updateProject} = this.props;
    updateProject({id, ...data})
  };

  changeHandler = name => (_, val) => {
    this.updateProject({ [name]: val });
  };

  handleChangeBrandingImage = ({ src } = {}) => {
    this.updateProject({branding_image_src: src});
  };

  get filteredGroups() {
    return recreateSelectOptions(
      this.props.projectGroups,
      { key: 'id', label: 'title' },
      { key: 'noFolder', value: 'No Folder' }
    );
  }

  render() {
    const { project, choosingThumbnail, languageOptions } = this.props;

    return (
      <div
        className={cx(styles.settingsForm_wrapper, 'clearfix')}
        style={{ transition: 'all 0.3s', opacity: choosingThumbnail ? 0.3 : 1 }}
      >
        <form className={styles.form} onSubmit={this.handleSubmit}>
          <div className={styles.form_left}>
            <h3 className="form-heading">Project Settings</h3>
            {this.renderProjectSettings()}

            {/*<h2 className="form-heading">Player Settings</h2>*/}
            {/*{this.renderPlayerSettings()}*/}

            {/*<h2 className="form-heading">Thumbnail Settings</h2>*/}
            {/*{this.renderThumbnailSettings()}*/}
            <div className="form-control" style={{ marginTop: '50px' }}>
              <Button primary large icon="save" loading={this.props.saving}>
                Save Changes
              </Button>
            </div>
          </div>

          <div className={styles.form_right}>

          <h3 className="form-heading">Facebook Pixel</h3>
          {this.renderPixelSettings()}
          </div>

        </form>

        {/* Move this out the way UI isn't important it's just a UI thing */}
        <div style={{ marginTop: '150px' }}>
          {// must be outside of form otherwise triggers onChange
          project && (
            <TemplateProperties
              item={project}
              update={this.updateProject}
              languageOptions={languageOptions}
            />
          )}
        </div>
      </div>
    );
  }

  renderThumbnailSettings = () => {
    const { project } = this.props;

    return (
      <VideoThumbnailSelector
        value={project.image_url}
        setThumbnailAsGenerating={() => this.setState({ image_url: '' })}
        project={project}
      />
    );
  };

  renderProjectSettings = () => {
    const {title, description, project_group_id, font} = this.props.project;

    return (
      <div className="grid">
        <div className="col12">
          <div className="form-control">
            <Option
                label="Name"
                Component={TextInput}
                value={title}
                onChange={this.changeHandler('title')}
            />
          </div>

          <div className="form-control">
            <Option
                label="Description"
                Component={LargeTextInput}
                value={description}
                onChange={this.changeHandler('description')}
            />
          </div>

          <div className="form-control">
            <div className="grid">
              <div className="col9">
                <Option
                    label="Project Folder"
                    Component={SelectInput}
                    value={project_group_id}
                    options={this.filteredGroups}
                    onChange={this.changeHandler('project_group_id')}
                />
              </div>
              <div className="col3" style={{ marginTop: '26px' }}>
                <Button type="button" onClick={() => this.props.handleFoldersManagementModal(true)} >
                  <Icon name="plus" style={{marginRight: 0}} />
                </Button>
              </div>
            </div>
          </div>

          <div className="form-control">
            <Option
                label="Font"
                Component={SelectFont}
                value={font}
                onChange={this.changeHandler('font')}
            />
          </div>

        </div>
        <div className="col6">

        </div>




        {/*<div className="form-control">*/}
        {/*  <Option*/}
        {/*    label="Enable Chapters"*/}
        {/*    Component={BooleanInput}*/}
        {/*    value={this.state.chapters}*/}
        {/*    onChange={this.changeHandler('chapters')}*/}
        {/*  />*/}
        {/*</div>*/}


      </div>
    );
  };

  renderPlayerSettings = () => {
    return (
      <div>
        {/*<div className="form-control">*/}
        {/*<Option*/}
        {/*label="Allow fullscreen"*/}
        {/*Component={BooleanInput}*/}
        {/*value={this.state.allow_fullscreen}*/}
        {/*onChange={this.changeHandler('allow_fullscreen')}*/}
        {/*/>*/}
        {/*</div>*/}

        {/*<div className="form-control">*/}
        {/*  <Option*/}
        {/*    label="Autoplay"*/}
        {/*    Component={BooleanInput}*/}
        {/*    value={this.state.autoplay}*/}
        {/*    onChange={this.changeHandler('autoplay')}*/}
        {/*  />*/}
        {/*</div>*/}

        {/*<div className="form-control">*/}
        {/*  <Option*/}
        {/*    label="Show Player Seek Bar"*/}
        {/*    Component={BooleanInput}*/}
        {/*    value={this.state.show_controls}*/}
        {/*    onChange={this.changeHandler('show_controls')}*/}
        {/*  />*/}
        {/*</div>*/}

        {/* Hide this for now, we can set this up later */}
        {/*<div className="form-control">*/}
        {/*<Option*/}
        {/*label="Player skin"*/}
        {/*Component={SelectInput}*/}
        {/*value={this.state.player_skin}*/}
        {/*options={skins}*/}
        {/*onChange={this.changeHandler('player_skin')}*/}
        {/*/>*/}
        {/*</div>*/}
      </div>
    );
  };

  renderPixelSettings = () => {
    const {fbPixelId} = this.props.project;

    return (
      <div>
        <div className="form-control">
          <Option
            label="Enter Your FB Pixel Below"
            Component={TextInput}
            value={fbPixelId}
            onChange={this.changeHandler('fbPixelId')}
          />
          <div>
            <p>When your enter your pixel above the following events will be posted to your FB Pixel.</p>
            <p style={{ marginBottom: 0 }}>
              <strong>Project View</strong>
            </p>
            <ul style={{ marginTop: '5px' }}>
              <li>
                <strong>Event: </strong>track
              </li>
              <li>
                <strong>Label: </strong>viewContent
              </li>
              <li>
                <strong>Microdata: </strong> {'{projectName}'}
              </li>
            </ul>

            <p style={{ marginBottom: 0 }}>
              <strong>Node / Video Play</strong>
            </p>
            <ul style={{ marginTop: '5px' }}>
              <li>
                <strong>Event: </strong>trackCustom
              </li>
              <li>
                <strong>Label: </strong>videoPlay
              </li>
              <li>
                <strong>Microdata: </strong> {'{projectName, nodeId, nodeName, videoId, videoName}'}
              </li>
            </ul>

            <p style={{ marginBottom: 0 }}>
              <strong>Node / Video Ended</strong>
            </p>
            <ul style={{ marginTop: '5px' }}>
              <li>
                <strong>Event: </strong>trackCustom
              </li>
              <li>
                <strong>Label: </strong>videoEnded
              </li>
              <li>
                <strong>Microdata: </strong> {'{projectName, nodeId, nodeName, videoId, videoName}'}
              </li>
            </ul>

            <p style={{ marginBottom: 0 }}>
              <strong>Something Clicked</strong>
            </p>
            <ul style={{ marginTop: '5px' }}>
              <li>
                <strong>Event: </strong>trackCustom
              </li>
              <li>
                <strong>Label: </strong>interaction
              </li>
              <li>
                <strong>Microdata: </strong> {'{projectName, name, action, actionArg}'}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };
}
