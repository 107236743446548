import React from 'react';
import {connect} from 'react-redux';
import Page from 'components/Page';
import ElementEditor from 'modules/element/components/ElementEditor';
import ElementContainerActions from 'modules/element/components/ElementContainerActions';
import ElementProperties from 'modules/element/components/Properties/ElementProperties';
// import {modalPageSelector} from 'modules/modal/modalSelectors';
import ModalProperties from 'modules/modal/components/ModalProperties';
import {
  saveModal,
  viewModalPage,
  deleteModalElement,
  duplicateModalElement
} from 'modules/modal/modal';
import interactionStyles from 'modules/interaction/components/InteractionProperties.scss';
import Icon from 'components/Icon';
import {deselectElement, selectElement} from 'modules/element/element';
import {updatePageLoadingState} from 'modules/pageLoader/pageLoader';
import PreviewProjectButton from 'modules/project/components/PreviewProjectButton';
import PublishButton from 'modules/project/components/PublishButton';

// TODO: this is a bit hacky
import styles from 'modules/node/components/NodePage.scss';
import EditModalSidebar from "../../node/components/EditModalSidebar";
import NodePageLayout from "../../node/components/NodePageLayout";
import LinkButton from "components/Buttons/LinkButton";
import SaveButton from "components/Buttons/SaveButton";

import getAsset from 'utils/getAsset';

// @connect(modalPageSelector, {
//   saveModal,
//   viewModalPage,
//   deleteModalElement,
//   duplicateModalElement,
//   deselectElement,
//   selectElement,
//   updatePageLoadingState
// })
export default class ModalPage extends React.Component {
  constructor(){
    super();
    this.state = {
        projectId: 0,
        nodeId: 0
    }
  };
  componentDidMount() {
    this.props.viewModalPage(this.props.match.params.projectId);

    this.setState({
        projectId: this.props.match.params.projectId,
        nodeId: this.props.match.params.nodeId
    })
  }

  componentWillMount(){
    if(this.props.loading) {
      this.props.updatePageLoadingState(false);
    }
  }

  backToModal = () => {
    this.props.deselectElement();
  };

  handleCopy = () => {
    this.props.duplicateModalElement(this.props.selectedModalElementId);
  };

  handleDelete = () => {
    this.props.deleteModalElement(
      this.props.modal.id,
      this.props.selectedModalElementId
    );
  };

  render() {
    const {modal, loading, editingElement, updatePageLoadingState} = this.props;
    const {projectId, nodeId} = this.state;

    const urlSplit = window.location.href.split('/');
    let url = '';
    let mediaId = 0;

    this.props.nodes.forEach((node)=>{
      if (node.id === parseInt(urlSplit[6])) {
        mediaId = node.media_id;
      }
    });
//debugger;
    if (mediaId) {
      this.props.media.forEach((media)=>{
        if (media.id === mediaId) {
          const thumb = (media.thumbnail_url) ? media.thumbnail_url : getAsset('/img/no-thumb.jpg');
          url = thumb;
        }
      })
    }
    //debugger;
    if (loading) {
      return null;
    }

    if (! loading) {
      setTimeout(()=>{
        updatePageLoadingState(false)
      }, 1000)
    }

    const width = 720;
    const height = 405;


    // Custom Modal styles
    let padding = (100 - modal.size) / 2 + '%';
    const modalStyles = {
      top : padding,
      bottom : padding,
      left : padding,
      right : padding,
      borderRadius : modal.border_radius + 'px',
      backgroundColor: modal.backgroundColour,
    };

    const closeIconStyle = {
        color: modal.close_icon_color
    };

    return (
      <NodePageLayout
          back={<LinkButton default to={`/projects/${projectId}/nodes/${nodeId}`}><Icon name="arrow-left" /> Back To Node</LinkButton>}
          buttons={
              <div>
                  <SaveButton saveAction={()=>this.props.saveModal(modal.id, modal)}/>
              </div>
          }
        left={this.leftSidebar()}
        right={<EditModalSidebar {...this.props} />}
      >
          <div style={{display: 'flex',  justifyContent:'center', height: '100%', width: '100%'}}>
              <div style={{height, width, position: 'relative'}}>
                  <div className={styles.modalOverlay}>
                      <img src={url} style={{position: 'absolute', height: '100%', width: '100%'}} />
                      {/* Need to make the background colour editable here */}
                      <div className={styles.modalInner}  style={modalStyles}>
                          {
                              modal.show_close_icon ?
                                  <div className={styles.modalClose} style={closeIconStyle}>
                                      <Icon name="times" />
                                  </div>
                                  : null
                          }

                          <ElementEditor
                              className={styles.stacked}
                              elements={modal.elements}
                          />
                      </div>
                  </div>
              </div>
          </div>
      </NodePageLayout>
    )
  }

    renderElementTypeIcon(element){
      // TODO come back to this is we have time
      console.log(element);
    }

  leftSidebar = ()=>{
    const {editingElement, modal} = this.props;

    return   editingElement ? (
        <div>
            <a className={interactionStyles.backToVideo} onClick={this.backToModal}>
                <Icon name="angle-left" />Back To Modal
            </a>

            <span className={styles.elementIcon}>
              {this.renderElementTypeIcon(editingElement)}
             </span>
            <div className="clearfix"></div>


            <ElementProperties editingContext="modal" />
            <ElementContainerActions
                onCopy={this.handleCopy}
                onDelete={this.handleDelete}
            />
        </div>
    ) : (
        <ModalProperties elements={modal.elements} selectElement={this.props.selectElement}/>
    )
  };
}
