import React from 'react';
import { Section, Option, MultiSelect, RangeInput } from 'components/PropertyEditor';
import {easings} from '../../../../utils/animations';
import Button from 'components/Buttons/Button';
import map from "lodash/map";
import LinkButton from "../../../../components/Buttons/LinkButton";
import Icon from 'components/Icon';

export default function AnimationElementProperties({ element, updateElement, user }) {
  function getAnimationProperty(property) {
    return element && element.animation ? element.animation[property] : '';
  }
  const changeHandler = property => (e, val) => {
    if (element) {
      const animation = element.animation || {};
      animation[property] = val.value ? val.value : val; // handle selects
      updateElement({ animation });
    }
  };
  return (
    <Section title="Animation Properties" icon="camera-movie">
        {
            (!!user.evolution_pro) &&
                <div>
                    <Option
                        label="Animation Type"
                        name="background-animation-select"
                        value={getAnimationProperty('name')}
                        options={map(element_animations, (b,i)=>( {label: b.label, value:i} ) )}
                        Component={MultiSelect}
                        onChange={changeHandler('name')}
                    />
                    <Option
                        label="Animation Easing"
                        name="background-easing-select"
                        value={getAnimationProperty('easing')}
                        options={easings}
                        Component={MultiSelect}
                        onChange={changeHandler('easing')}
                    />
                </div>
        }
        {
            (! user.parent_user_id && ! user.evolution_pro) &&
            <div className="form-control">
                <p>Animation types for elements is only available to Evolution Pro Users</p>
                <p>
                    <LinkButton to={'/upgrade'} small primary><Icon name={'arrow-up'} /> Click Here to Upgrade</LinkButton>
                </p>
            </div>
        }

      <Option
        label="Animation Duration"
        value={getAnimationProperty('duration')}
        max={3}
        min={0.1}
        step={0.1}
        Component={RangeInput}
        onChange={changeHandler('duration')}
      />
      <Option
        label="Animation Delay"
        value={getAnimationProperty('delay')}
        max={10}
        min={0.1}
        step={0.1}
        Component={RangeInput}
        onChange={changeHandler('delay')}
      />
      <div className="form-control">
        <Button
          secondary
          icon="play"
          small
          onClick={() => {
            var event = new Event('preview_animation');
            window.dispatchEvent(event);
          }}
        >
          play Animation
        </Button>
      </div>
    </Section>
  );
}
