import {takeLatest, put} from 'redux-saga/effects';
import {ADD_NODE, receiveNode} from 'modules/node/node';
import fetch from 'utils/saga/fetch';
import {markStartNode} from 'modules/project/project';

export default function* addNodeSaga() {
  yield takeLatest(ADD_NODE, addNode);
}

function* addNode({node}) {
  const res = yield fetch('nodes', {
    method: 'POST',
    body: node
  });
  const data = yield res.json();
  yield put(receiveNode(data));
  yield put(markStartNode(node.project_id));
}
