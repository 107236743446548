import React, { useState } from 'react';
// import Rodal from 'components/Rodal';
// import Button from 'components/Buttons/Button';
import PreviewButton from 'components/Buttons/PreviewButton';
// import buttonStyles from 'components/Buttons/Button.scss';
import ProjectPreview from './ProjectPreview';
// import Icon from 'components/Icon';

function PreviewProjectButton({ projectId, startNodeId, projectTemplate,   ...props }) {


  return (
    <PreviewButton
      previewTitle={startNodeId ? 'Node Preview' : "Project Preview"}
      previewContent={
        <ProjectPreview
          projectId={projectId}
          startNodeId={startNodeId}
          // reloader={showModal}
          projectTemplate={projectTemplate}
        />
      }
      {...props}
    />
  );
}


export default PreviewProjectButton;
