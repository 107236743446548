import React from 'react';
import {connect} from 'react-redux';
import PositionableElementProperties from './PositionableElementProperties';
import ClickableElementProperties from './ClickableElementProperties';
import DropImageZone from 'modules/media/components/DropImageZone';
import {Section} from 'components/PropertyEditor';
import ImageElementModal from './ImageElementModal';
import {userSelector} from "../../../auth/authSelectors";
import YouzignModal from 'components/YouzignModal';
import AnimationElementProperties from "./AnimationElementProperties";

@connect(userSelector, {})
export default class ImageElementProperties extends React.Component {
  state = {
    addImageModal: false,
    addFromYouZign: false
  };
  handleSuccess = (options) => {
    this.props.updateElement({
      src: options.src,
      height: options.height,
      width: options.width
    });
  };

  render() {
    const {addImageModal, addFromYouZign} = this.state;
    const {src} = this.props.element;
    const {user} = this.props;

    return (
      <div>
        <ImageElementModal
          showStockList={addImageModal}
          close={()=>this.setState({addImageModal: false})}
          submit={this.handleSuccess}
        />
        <YouzignModal
          showMe={addFromYouZign}
          source="youzign"
          close={()=>this.setState({addFromYouZign: false})}
          submit={this.handleSuccess}
        />
        <ClickableElementProperties {...this.props} />
          {(this.props.interactionLayer) && <AnimationElementProperties {...this.props} />}
        <PositionableElementProperties {...this.props} />
        <Section title="Image" icon="image">
          <label>Image Src</label>
          {(user.parent_user_id === 0 || user.is_club) ?
            <span style={{display: 'block', padding: '15px 0px'}}>
              Add From:<br/>
              <a className="link" onClick={() => this.setState({addImageModal: true})}>Image Library</a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              {(user.parent_user_id === 0 ) ? <a className="link" onClick={() => this.setState({addFromYouZign: true})}>Youzign</a> : null}
            </span>
            : null
          }
          <span>Upload From Computer</span>
          <DropImageZone
            directory="imageElements"
            onSuccess={this.handleSuccess}
            src={src}
          />
        </Section>
      </div>
    );
  }
}
