import {fromJS} from 'immutable';
import {AUTHENTICATE} from 'modules/auth/auth';

const initialState = fromJS({
  error: null,
  loading: false
});

function registratonReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER:
      return initialState.set('loading', true);
    case REGISTRATION_FAILED:
      return initialState.set('error', action.error);
    case AUTHENTICATE:
      return initialState;
  }
  return state;
}

export const reducers = {
  registration: registratonReducer
};

export const REGISTER = 'registration:REGISTER';
export function register(user) {
  if (!user.email || !user.password ||  !user.transaction_id) {
    throw 'Cannot register without email and password';
  }
  return {
    type: REGISTER,
    user
  };
}

export const REGISTRATION_FAILED = 'registration:REGISTRATION_FAILED';
export function registrationFailed(error) {
  return {
    type: REGISTRATION_FAILED,
    error
  };
}
