import {takeEvery, put} from 'redux-saga/effects';
import {DELETE_PROJECT, projectDeleted} from 'modules/project/project';
import fetch from 'utils/saga/fetch';
import swal from 'sweetalert2';
import {error} from 'utils/alert'

export default function* deleteProjectSaga() {
  yield takeEvery(DELETE_PROJECT, deleteProject);
}

function* deleteProject({projectId}) {
  const conf = yield swal({
    title: 'Are you sure?',
    text: 'Are you sure you want to delete this project.',
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: '#ff6961',
    confirmButtonText: 'Yes, Delete It!',
  });

  const res = yield fetch(`projects/${projectId}`, {
    method: 'DELETE'
  });
  const data = yield res.json();

  if (!data.success) {
      return error({text: data.message});
  }

  yield put(projectDeleted(projectId, data.projectsCount));
}
