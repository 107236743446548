import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Breadcrumb.scss';
import { BreadcrumbSelector } from 'modules/project/projectSelectors';
import { connect } from 'react-redux';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';
import { projectsPath } from '../routeBuilders';
import Icon from './Icon';

@connect(
  BreadcrumbSelector,
  {}
)
class Breadcrumb extends React.Component {
  constructor() {
    super();

    let urlArray = window.location.href.split('/');

    // Remove the stuff before what we need
    urlArray.splice(0, 3);

    this.state = {
      urlArray: urlArray
    };
  }

  shouldComponentUpdate(newProps, newState) {
    return window.location.href.split('/') !== newState.urlArray;
  }

  componentWillReceiveProps() {
    let urlArray = window.location.href.split('/');

    // Remove the stuff before what we need
    urlArray.splice(0, 3);

    this.setState({ urlArray });
  }

  getItemName(type, id) {
    let name = type;
    if (id) {
      const { projects } = this.props.projects;
      const nodes = this.props.nodes;

      if (type === 'nodes' && nodes) {
        forEach(nodes, node => {
          if (parseInt(node.id) === parseInt(id)) {
            name = node.name;
          }
        });
      }

      if (type === 'projects' && projects) {
        forEach(projects, project => {
          if (parseInt(project.id) === parseInt(id)) {
            name = project.title;
          }
        });
      }

      if (type === 'modals' && projects) {
        forEach(projects, project => {
          forEach(project.modals, modal => {
            if (parseInt(modal.id) === parseInt(id)) {
              name = modal.name;
            }
          });
        });
      }

      // This part picks up if the
      let isIdInt = parseInt(id);

      if (!isIdInt) {
        name = this.capitalizeFirstLetter(id);
      }
    } else {
      // If no id with the url then we use the type (/projects) for
      // example
      name = this.capitalizeFirstLetter(type);
    }

    return name;
  }

  configureStateToItems = () => {
    let items = [];
    let key = 0;
    let depth = 1;
    let name = null;

    while (key <= this.state.urlArray.length) {
      if (typeof this.state.urlArray[key] !== 'undefined') {
        name = this.getItemName(this.state.urlArray[key], this.state.urlArray[key + 1]);

        items.push({
          depth: depth,
          path: this.state.urlArray[key],
          id: this.state.urlArray[key + 1],
          name
        });

        // Used to build out the link at the end
        depth++;
      }

      key = key + 2;
    }

    return [name, items];
  };

  getLink = (items, item) => {
    let url = '';
    let depth = 1;
    let urlArrayKey = 0;

    while (depth <= item.depth) {
      url = url + '/' + this.state.urlArray[urlArrayKey];
      url = url + '/' + this.state.urlArray[urlArrayKey + 1];

      depth++;
      urlArrayKey = urlArrayKey + 2;
    }

    return url;
  };

  capitalizeFirstLetter(string) {
    if (window.location.pathname === projectsPath()) {
      return 'Folder';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  generateRandomKey() {
    return Math.floor(Math.random() * 90000) + 10000;
  }

  // @todo Refactor this mess 😫
  generateFoldersTree(items) {
    const {
      folderId,
      projects: { projectGroups }
    } = this.props;

    if (folderId) {
      const folder = projectGroups[folderId];

      if (!isEmpty(folder)) {
        items.push({
          name: folder.title
        });
      }
    } else {
      items.push({
        name: 'All Projects'
      });
    }

    return items.map((item, idx) => (
      <React.Fragment key={this.generateRandomKey()}>
        {idx > 0 && <Icon icon="angle-right" size="sm" className={styles.icon} />}
        <li>
          <Link to={projectsPath()}>{item.name}</Link>
        </li>
      </React.Fragment>
    ));
  }

  renderItems = () => {
    let [name, items] = this.configureStateToItems();

    if (name === 'Folder') return this.generateFoldersTree(items);

    // The breadscrumb will always have it's own page ex. projects > {projectname} > {nodename}cts therefore
    const routePage = this.state.urlArray[0];

    const page = {
      id: 0,
      name: this.capitalizeFirstLetter(routePage),
      link: '/' + routePage
    };

    if (typeof this.state.urlArray[1] !== 'undefined') {
      items.unshift(page);
    } else {
      items = [page];
    }

    return items.map((item, idx) => {
      const link = item.link ? item.link : this.getLink(items, item);
      const key = this.generateRandomKey();
      return (
        <React.Fragment key={key}>
          {idx > 0 && <Icon icon="angle-right" size="sm" className={styles.icon} />}
          <li>
            <Link to={link}>{item.name}</Link>
          </li>
        </React.Fragment>
      );
    });
  };

  render() {
    return <ul className={styles.wrapper}>{this.renderItems()}</ul>;
  }
}

export default withRouter(Breadcrumb);
