import {call, put, takeLatest} from 'redux-saga/effects';
import {ADD_MEDIA_URL, receiveMediaItem, updateMediaUploadingState} from 'modules/media/media';
import fetch from 'utils/saga/fetch';
import swal from 'sweetalert2';

export default function* addMediaUrlSaga() {
  yield takeLatest(ADD_MEDIA_URL, addMediaUrl);
}

function* addMediaUrl(action) {
  const {url, stockVideo, stockVideoID, projectId, thumbnail_url, mediaId, name , callback} = action;

  let postVars = {
    url,
    projectId,
    name,
  };

  if (stockVideo && stockVideoID) postVars.stockVideoID = stockVideoID;

  if(mediaId) postVars.mediaId = mediaId;

  if(thumbnail_url) postVars.thumbnail_url = thumbnail_url ;

  const res = yield fetch('media/url', {
    timeout: 100000,
    method: 'POST',
    body: postVars
  });

  const {errorMessage, item} = yield res.json();
  if (errorMessage) {
    swal({
      title: 'Error',
      text: errorMessage,
      type: 'warning',
      confirmButtonColor: '#ff6961',
      confirmButtonText: 'Got it!',
    });
    
    yield put(updateMediaUploadingState(false))
  }

  if(item) yield put(receiveMediaItem(item, item.project_id));
  if(callback) callback();
}
