import React from 'react';
import Measure from 'react-measure';
import { DraggableCore } from 'react-draggable';

export default class InteractiveSvg extends React.Component {
  state = {
    dragging: false,
    allowPan: true,
    width: '100%',
    height: '100%'
  };

  onDragStart = (e, ...args) => {
    let allowPan = true;

    const { onDragStart } = this.props;

    if (onDragStart) {
      const result = onDragStart(e, ...args);
      // Allow onDragStart to stop event propagation
      if (result === false) {
        // not sure if this allowPan is needed. seems to work without
        // allowPan = false;

        return false;
      }
    }
    this.setState({ dragging: true, allowPan });
  };

  onDragMove = (e, data) => {
    // First check if the state is dragging, if not we can just return
    // so we do not move unless the user wants to move
    const { dragging } = this.state;
    if (!dragging) {
      return;
    }

    const { onDragMove } = this.props;
    onDragMove && onDragMove(e, data);
  };

  onDragEnd = (...args) => {
    this.setState({ dragging: false });

    const { onDragEnd } = this.props;
    onDragEnd && onDragEnd(...args);
  };

  handleMeasure = ({ width, height }) => {
    this.setState({ width, height });
  };

  handleResize = ({ bounds, entry }) => {
    return this.handleMeasure(bounds || entry);
  };

  getViewBox() {
    const { zoom, pan } = this.props;
    let { width, height } = this.state;

    if (isNaN(width)) {
      return;
    }

    return `${pan.x} ${pan.y} ${width / zoom} ${height / zoom}`;
  }

  render() {
    const { children, onWheel, id, ...props } = this.props;
    const { height, width } = this.state;

    return (
      <Measure bounds onResize={this.handleResize}>
        {({ measureRef }) => (
          <div
            ref={measureRef}
            style={{
              // Take up available space
              width: '100%',
              height: '100%',

              // Stop from growing
              // maxHeight: height,
              // maxWidth: width,
              overflow: 'hidden',

              // Draggable looks at offset parent
              position: 'relative'
            }}
          >
            <DraggableCore bounds="parent" onStart={this.onDragStart} onStop={this.onDragEnd} onDrag={this.onDragMove}>
              <svg id={id} height={height} width={width} onWheel={onWheel} viewBox={this.getViewBox()}>
                <g>{children}</g>
              </svg>
            </DraggableCore>
          </div>
        )}
      </Measure>
    );
  }
}
