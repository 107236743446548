import {takeLatest, put} from 'redux-saga/effects';
import {UPDATE_NODES_SORTING, receiveProjectNodes} from 'modules/node/node';
import fetch from 'utils/saga/fetch'
import { receiveElements } from 'modules/element/element';
import { processElements } from 'modules/element/elementUtils';
import {error} from "../../../utils/alert";

export default function* updateNodesSortingSaga() {
    yield takeLatest([UPDATE_NODES_SORTING], updateNodesSorting);
}

function* updateNodesSorting({nodes, projectId}) {
    const res = yield fetch('nodes/sorting', {
        method: 'PUT',
        body: { nodes, projectId }
    });

    if (!res.ok) {
        error({text: 'Error sorting nodes, one of nodes does not exist'});
    }

    const data = yield res.json();

    let [newNodes, elements] = processElements(data, 'interactions', (memo, node) => {
        let [modals, modalElements] = processElements(node.modals);
        // update node modals with normalized version
        memo[0][memo[0].length - 1].modals = modals;
        memo[1] = memo[1].concat(modalElements);
        return memo;
    });

    yield put(receiveElements(elements));
    yield put(receiveProjectNodes(newNodes));
}
