import React from 'react';
import PropTypes from 'prop-types';
import { deleteUser, updateUser, createUser, fetchUsers } from 'modules/user/user';
import { connect } from 'react-redux';
import Rodal from 'rodal';
import Button from 'components/Buttons/Button';
import UserDetails from './UserDetails';
import { usersSelector } from '../userSelectors';
import { viewProjectsPage } from 'modules/project/project';
import UserForm from './UserForm';

@connect(usersSelector, { fetchUsers, viewProjectsPage, createUser })
export default class UserManagementModal extends React.Component {
  state = {
    showUserModal: false,
    selectedUser: 0,
  };

  componentDidMount() {
    const { forAgencyUsers, isAuthSuperUser, fetchUsers, viewProjectsPage } = this.props;
    if (!isAuthSuperUser) {
      fetchUsers();
    }

    if (forAgencyUsers) {
      // this is how we load in the projects
      viewProjectsPage();
    }
  }

  render() {
    const { users, isAuthSuperUser, forAgencyUsers, width, height } = this.props;
    const { showUserModal, selectedUser } = this.state;

    return (
      <div>
        <Rodal
          visible={showUserModal}
          onClose={() => this.setState({ showUserModal: false })}
          height={height || 500}
          width={width || 600}
        >
          <UserForm
            isAuthSuperUser={isAuthSuperUser}
            forAgencyUsers={forAgencyUsers}
            selectedUser={selectedUser}
            onChange={this.handleChange}
            reset={() => {
              this.setState({ showUserModal: false });
              this.setState({ selectedUser: 0 });
            }}
          />
        </Rodal>

        <h3 className="form-heading">
          Users
          <Button
            primary
            onClick={() => this.setState({ showUserModal: true })}
            style={{ position: 'absolute', top: '-3px', left: '73px' }}
            small
          >
            Create New
          </Button>
        </h3>
        <div style={{ paddingTop: '10px' }}>
          {Array.isArray(users) &&
            users.map((user) => (
              <div key={user.id} style={{ marginBottom: '20px' }}>
                <UserDetails
                  {...user}
                  isAuthSuperUser={isAuthSuperUser}
                  onSelect={(userId) => {
                    this.setState({ selectedUser: userId });
                    this.setState({ showUserModal: true });
                  }}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
}
UserManagementModal.defaultProps = {
  isAuthSuperUser: false,
};
UserManagementModal.propTypes = {
  users: PropTypes.array,
  isAuthSuperUser: PropTypes.bool,
  forAgencyUsers: PropTypes.bool,
  width: PropTypes.any,
  height: PropTypes.any,
};
