import { fromJS } from 'immutable';
import { keyBy } from 'utils/immutableUtils';
import isString from 'lodash/isString';

const initialState = fromJS({
  data: {},
  loading: false,
  uploading: false,
  selectedMedia: null
});

function mediaReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MEDIA_UPLOADING_STATE:
      return state.set('uploading', action.uploading);
    case LOAD_MEDIA:
      return state.set('loading', true).set('uploading', false);
    case ADD_MEDIA_URL:
      return state.set('uploading', true);
    case RECEIVE_MEDIA_ITEM:
      let itemId = action.item.id;
      if (!isString(itemId)) {
        itemId = itemId.toString();
      }
      return state.mergeIn(['data', itemId], action.item).set('uploading', false);
    case RECEIVE_MEDIA:
      return state.set('data', keyBy(action.media));
    case DELETE_MEDIA_ITEM:
      return state.deleteIn(['data', action.id]);
    case UPDATE_MEDIA_THUMBNAIL:
      let mediaId = action.mediaId;
      if (!isString(mediaId)) {
        mediaId = mediaId.toString();
      }

      if (!state.get('data').get(mediaId)) {
        return state;
      }

      return state.setIn(['data', mediaId, 'thumbnail_url'], action.thumbnail);

    case UPDATE_MEDIA_ITEM:
      const oldItem = state.get('data').get(action.id);
      if (!oldItem) return state; // no item to update found .
      const newItem = { ...oldItem.toJS(), ...action.item };
      return state.mergeIn(['data', action.id.toString()], newItem).set('uploading', false);
    case UPDATE_SELECTED_MEDIA:
      const mediaItem = action.mediaId ? state.getIn(['data', action.mediaId.toString()]) : null;
      return state.set('selectedMedia', mediaItem);
  }
  return state;
}

export const reducers = {
  media: mediaReducer
};

export const LOAD_MEDIA = 'media:LOAD_MEDIA';
export function loadMedia() {
  return {
    type: LOAD_MEDIA
  };
}

export const RECEIVE_MEDIA = 'media:RECEIVE_MEDIA';
export function receiveMedia(media) {
  return {
    type: RECEIVE_MEDIA,
    media
  };
}

export const UPDATE_SELECTED_MEDIA = 'media:UPDATE_SELECTED_MEDIA';
export function updateSelectedMedia(mediaId) {
  return {
    type: UPDATE_SELECTED_MEDIA,
    mediaId
  };
}

export const DROP_MEDIA_ON_COMPOSER = 'media:DROP_MEDIA_ON_COMPOSER';
export function dropMediaOnComposer(mediaId, pos) {
  return {
    type: DROP_MEDIA_ON_COMPOSER,
    mediaId,
    pos
  };
}

export const ADD_MEDIA_URL = 'media:ADD_MEDIA_URL';
export function addMediaUrl(url, { stockVideoID, thumbnail_url, projectId, name, stockVideo }, callback) {
  return {
    type: ADD_MEDIA_URL,
    stockVideoID,
    stockVideo,
    url,
    thumbnail_url,
    projectId,
    name,
    callback
  };
}

export function editMediaUrl(url, { projectId, mediaId }, callback) {
  return {
    type: ADD_MEDIA_URL,
    url,
    projectId,
    mediaId,
    callback
  };
}

export const RECEIVE_MEDIA_ITEM = 'media:RECEIVE_MEDIA_ITEM';
export function receiveMediaItem(item, projectId) {
  return {
    type: RECEIVE_MEDIA_ITEM,
    item,
    projectId
  };
}

export const UPDATE_MEDIA_ITEM = 'media:UPDATE_MEDIA_ITEM';
export function updateMediaItem(id, item, shouldUpdateDB = true, callback) {
  return {
    type: UPDATE_MEDIA_ITEM,
    id,
    item,
    callback,
    // sometimes this should only update local store not DB,
    //check saga for more info
    shouldUpdateDB
  };
}

export const UPDATE_MEDIA_THUMBNAIL = 'media:UPDATE_MEDIA_THUMBNAIL';
export function updateMediaThumbnail(projectId, mediaId, thumbnail, isStartNode) {
  return {
    type: UPDATE_MEDIA_THUMBNAIL,
    projectId,
    mediaId,
    thumbnail,
    isStartNode
  };
}

export const ATTEMPT_DELETE_MEDIA_ITEM = 'media:ATTEMPT_DELETE_MEDIA_ITEM';
export function attemptDeleteMediaItem(id) {
  return {
    type: ATTEMPT_DELETE_MEDIA_ITEM,
    id
  };
}

export const DELETE_MEDIA_ITEM = 'media:DELETE_MEDIA_ITEM';
export function deleteMediaItem(id) {
  return {
    type: DELETE_MEDIA_ITEM,
    id
  };
}

export const COPY_VIDEO_TO_PROJECT = 'media:COPY_VIDEO_TO_PROJECT';
export function copyVideoToProject(projectId, mediaId) {
  return {
    type: COPY_VIDEO_TO_PROJECT,
    projectId,
    mediaId
  };
}

export const CREATE_VIDEO_VERSION = 'media:CREATE_VIDEO_VERSION';
export function createVideoVersion(version, mediaId) {
  return {
    type: CREATE_VIDEO_VERSION,
    version,
    mediaId
  };
}

export const UPDATE_MEDIA_UPLOADING_STATE = 'media:UPDATE_MEDIA_UPLOADING_STATE';
export function updateMediaUploadingState(uploading) {
  return { type: UPDATE_MEDIA_UPLOADING_STATE, uploading };
}