// if (process.env.NODE_ENV === 'development')
  console.log('checking env variables were loaded ... \n', 'the ENV variable is : ', process.env.NODE_ENV);

export default {
  ENV: process.env.NODE_ENV,
  API: {
    ROOT: process.env.API__ROOT,
    NODE_API_ROOT: process.env.API__NODE_API_ROOT,
    REQUEST_TIME_OUT: process.env.API__REQUEST_TIME_OUT
  },
  AWS: {
    ACCESS_KEY: process.env.AWS__ACCESS_KEY,
    APP_BUCKET_URL: process.env.AWS__APP_BUCKET_URL,
    BUCKET_NAME: process.env.AWS__BUCKET_NAME,
    BUCKET_REGION: process.env.AWS__BUCKET_REGION
  },
  PXA: {
    API_KEY: process.env.PXA__API_KEY,
    ROOT: process.env.PXA__ROOT,
    IMAGES_ROOT: process.env.PXA__IMAGES_ROOT,
    IMAGE_SIZE: process.env.PXA__IMAGE_SIZE
  },
  TINYMCE_KEY: process.env.TINYMCE_KEY,
  STORAGE_PATH: process.env.STORAGE_PATH,
  WRAPPER_PATH: process.env.WRAPPER_PATH,
  ANALYTICS_SERVICE_URL : process.env.ANALYTICS_SERVICE_URL,
  ANALYTICS_INTERACTR_KEY : process.env.ANALYTICS_INTERACTR_KEY,
};

export const ourDomains = [
  'dev.interactr.io',
  'interactrapp.com',
  'custom.interactrapp.com',
  '0.0.0.0',
  'localhost',
  'd30psvioc2rsr6.cloudfront.net',
  'app.interactr.io'
];