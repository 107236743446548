import {takeLatest, put} from 'redux-saga/effects';
import {APPLY_MODAL_TEMPLATE} from 'modules/modal/modal';
import receiveModal from './utils/receiveModal';
import fetch from 'utils/saga/fetch';

export default function* applyModalTemplateSaga() {
  yield takeLatest(APPLY_MODAL_TEMPLATE, applyModalTemplate);
}

function* applyModalTemplate({modalId, templateId, projectId, callback}) {
  const res = yield fetch(`modals/applyTemplate`, {
    method: 'POST',
    body: {templateId, modalId, projectId}
  });

  const modal = yield res.json();

  yield receiveModal(modal);
  
  if(callback) callback()
}
