import {take, call, takeLatest} from 'redux-saga/effects';
import {BEGIN_DRAG} from 'modules/composer/composer';
import {findDragData} from 'modules/composer/dragging';
import drawConnector from './dragHandlers/drawConnector';
import moveNode from './dragHandlers/moveNode';

export default function* dragSaga() {
  yield takeLatest(BEGIN_DRAG, drag);
}

function* drag(action) {
  // Check out the target, see if it (or a parent) has a dragType
  const dragData = findDragData(action.target);
  if (!dragData) return;

  const handler = dragHandlers[dragData.dragType];
  if (!handler) {
    throw new Error(`No handler for ${dragData.dragType}`);
  }

  yield call(handler, action, dragData);
}

const dragHandlers = {
  CONNECTOR: drawConnector,
  NODE: moveNode
};
