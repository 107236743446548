import {takeLatest, put} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import {UPDATE_PROJECT, SAVE_PROJECT, receiveProject, receiveProjectGroups} from 'modules/project/project';
import fetch from 'utils/saga/fetch';
import history from 'browserHistory';
import {projectPath} from 'routeBuilders';
import {loadMedia} from 'modules/media/media';
import { error} from 'utils/alert';
import {updatePageLoadingState} from "modules/pageLoader/pageLoader";

export default function* saveProjectSaga() {
  yield takeLatest([UPDATE_PROJECT, SAVE_PROJECT], saveProject);
}

function* saveProject(action) {
  yield delay(500);
  const backgroundSave =  (typeof action.project.title === 'undefined');

  if (action.project.project_group_id === 'noFolder') {
      action.project.project_group_id = null;
  }


  if (! backgroundSave && action.showLoading) {
    yield put(updatePageLoadingState(true));
  }

  const res = yield fetch('projects', {
    method: 'PUT',
    body: action.project
  });

  if(action.showLoading) yield put(updatePageLoadingState(false));

  if (res.status !== 200) {
    error({text:'Unable To Save Project'})
    if(action.callback) action.callback()
    return ;
  }

  const {project, projectGroups} = yield res.json();

  yield put(loadMedia()); // load media for new project 
  yield put(receiveProject(project));

  /**
   * Update Project Groups only if project.project_group_id has been updated
   */
  if (projectGroups) {
    yield put(receiveProjectGroups(projectGroups));
  }

  if(action.callback) action.callback(project);

  if (action.redirectAfterSave) {
    history.push(projectPath({projectId: project.id}));
  }
}
