import React from 'react';
import config from 'config';
import Script from 'react-load-script';

export default function SharePagePlayer({src}) {
  return (
    <div>
      <iframe
        id="player"
        className="_vs_ictr_player"
        src={src}
        width="1000"
        height="562"
        frameBorder="0"
        allow="autoplay *"
        scrolling="no"
      />
      <Script
        url={config.WRAPPER_PATH}
        //TODO We should handle error loading the wrapper script as this will result in a very weird looking share page :)
        onError={(err) => console.error('Wrapper Script loader error => ', err)}
        onLoad={() => console.info('Wrapper Loaded successfully')}
        // onCreate={}
      />
    </div>
  );
}
