import {takeEvery, put} from 'redux-saga/effects';
import {DUPLICATE_MODAL_ELEMENT, receiveModalElement} from 'modules/modal/modal';
import {splitElementFromObj} from 'modules/element/elementUtils';
import {receiveElement} from 'modules/element/element';
import fetch from 'utils/saga/fetch';

export default function* duplicateModalElementSaga() {
  yield takeEvery(DUPLICATE_MODAL_ELEMENT, duplicateModalElement);
}

function* duplicateModalElement({id}) {
  const res = yield fetch(`modals/elements/${id}/duplicate`, {
    method: 'POST'
  });
  const data = yield res.json();
  const [modalElement, element] = splitElementFromObj(data);
  yield put(receiveElement(element));
  yield put(receiveModalElement(modalElement));
}
