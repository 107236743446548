import React from 'react';
import PositionableElementProperties from './PositionableElementProperties';
import {Section, Option, LargeTextInput, BooleanInput} from 'components/PropertyEditor';

export default class CustomHtmlElementProperties extends React.Component {
  updateHtml = (e, html) => {
    //console.log(html);
    this.props.updateElement({html});
  };

  render() {
    const {element: {html}} = this.props;

    return (
      <div>
        <PositionableElementProperties {...this.props} />
        <Section title="Custom HTML" icon="code">
          <Option
            label="HTML"
            value={html}
            Component={LargeTextInput}
            onChange={this.updateHtml}
          />
        </Section>

        {/* We just show this if we have an interaction (So not on a modal) */}
        {this.props.interaction &&
        <Section title="Additional Options" icon="sliders-v">
          <Option
            label="Pause Video When Shown"
            value={this.props.interaction.pause_when_shown}
            onChange={this.props.interactionPropertyChangeHandler('pause_when_shown')}
            Component={BooleanInput}
          />
        </Section>
        }
      </div>
    );
  }
}
