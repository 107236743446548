import {take, put, call, fork, cancel, select} from 'redux-saga/effects';
import {delay} from 'redux-saga';
import {UPDATE_USER} from 'modules/user/user';
import {userSelector} from 'modules/user/userSelectors';
import fetch from 'utils/saga/fetch';
import {error} from 'utils/alert';

const DELAY = 1400;

// {id: Task}
let tasks = {};

// Stores  with a debounce.
export default function* updateUserSaga() {
    while (true) {
        const action = yield take([UPDATE_USER]);
        const {id} = action;

        // cancel any current task
        const task = tasks[id];
        if (task) yield cancel(task);

        // start new task
        tasks[id] = yield fork(updateUserTask, action);
    }
}

// we run one of these tasks for each element id
// it waits a while then stores changes so that we don't fire loads of
// requests at once.
function* updateUserTask(action) {
    // the action.delay will be false if user updated boolean toggle input
    if (action.delay) {
        yield delay(DELAY);
    }

    yield call(storeUser, action);
}

function* storeUser(action) {
    const {id, data} = action;
    const url = 'user/' + id;

    const res = yield fetch(url, {
        method: 'PUT',
        body: {id, ...data}
    });

  if (res.status !== 200) {
    error({text:'Unable to update user info.'});
  }
}
