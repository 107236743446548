import { fromJS } from 'immutable';
import { keyBy } from 'utils/immutableUtils';
import { VIEW_PROJECT_PAGE } from 'modules/project/project';
import { VIEW_MODAL_PAGE } from 'modules/modal/modal';

const initialState = fromJS({
  projectId: null,
  loading: true,
  duration: null, // duration of current node - only currently used for addInteractionSaga
  data: {
    nodes: {},
    elementGroups: {}
  }, // stores nodes for active project only , old implementation @todo : handle all nodes here
  all: {} // for storing all nodes , hacky and ugly should use data for everything and pick the project ones using selectors
});

function nodesReducer(state = initialState, action) {
  switch (action.type) {
    // Only store nodes for current project
    case VIEW_PROJECT_PAGE:
    case VIEW_NODE_PAGE:
      if (action.projectId !== state.get('projectId')) {
        // @todo this is a bug that the whole Nodes Redux state management relies upon
        // Fix after redoing nodes state stuff , next line is the fix
        // return state.set('projectId', action.projectId);
        return state.set('projectId', action.projectId).set('data', initialState.get('data'));
      }
      return state;
    case RECEIVE_ALL_NODES:
      // remove interactions from node
      // as they're stored by the interactions reducer
      const nodes = action.nodes.map(({ interactions, ...node }) => node);

      return state.set('all', keyBy(nodes));

    case RECEIVE_ACTIVE_PROJECT_NODES:
      const elementGroups = {};
      // remove interactions and element groups from node
      // as they're stored by the interactions reducer
      let projectNodes = action.nodes.map(({ interactions, element_groups, ...node }) => {
        elementGroups[node.id.toString()] = keyBy(element_groups);
        return node;
      });
      return state
        .setIn(['data', 'nodes'], keyBy(projectNodes))
        .setIn(['data', 'elementGroups'], fromJS(elementGroups));

    case RECEIVE_NODE:
      // strip interactions
      let { interaction, ...node } = action.node;
      return state.setIn(['data', 'nodes', node.id.toString()], fromJS(node));

    case RECEIVE_ELEMENT_GROUP:
      const { node_id, id } = action.elementGroup;
      return state.setIn(['data', 'elementGroups', node_id.toString(), id.toString()], fromJS(action.elementGroup));

    // case UPDATE_ELEMENT_GROUP:
    //   const { nodeId, elementGroupId, timeIn, timeOut } = action.data;
    //   return state.updateIn(['data', 'elementGroups', nodeId.toString(), elementGroupId.toString()], elementGroup => {
    //     return elementGroup.mapEntries(([key, value]) => {
    //       switch (key) {
    //         case 'timeIn':
    //           value = timeIn;
    //           break;
    //         case 'timeOut':
    //           value = timeOut;
    //           break;
    //       }
    //       return [key, value];
    //     });
    //   });

    case MOVE_NODE:
      return state.updateIn(['data', 'nodes', action.id], node => {
        return node.update('posX', x => x + action.dx).update('posY', y => y + action.dy);
      });

    // case ADD_NODE:
    //   const id = 'a' + Math.random() * 1000;
    //   return state.setIn(['data', id], fromJS({...action.node, id}));
    // case UPDATE_NODE_LOCALLY:
    case UPDATE_NODE:
      return state.mergeIn(['data', 'nodes', action.id], fromJS(action.data));

    case NODE_DELETED:
      return state.deleteIn(['data', 'nodes', action.id]);

    case GOT_DURATION:
      return state.set('duration', action.duration);
  }

  return state;
}

export const reducers = {
  nodes: nodesReducer
};

export const MOVE_NODE = 'node:MOVE_NODE';
export function moveNode(id, dx, dy) {
  return {
    type: MOVE_NODE,
    id,
    dx,
    dy
  };
}

export const ADD_NODE = 'node:ADD_NODE';
export function addNode(node) {
  if (!node.project_id) {
    throw 'node must have project_id';
  }
  if (!node.media_id) {
    throw 'node must have media_id';
  }

  return {
    type: ADD_NODE,
    node
  };
}

export const VIEW_NODE_PAGE = 'node:VIEW_NODE_PAGE';
export function viewNodePage(projectId) {
  return {
    type: VIEW_NODE_PAGE,
    projectId
  };
}
export const RECEIVE_ALL_NODES = 'node:RECEIVE_ALL_NODES';
export function receiveAllNodes(nodes) {
  return {
    type: RECEIVE_ALL_NODES,
    nodes
  };
}

export const RECEIVE_ACTIVE_PROJECT_NODES = 'node:RECEIVE_ACTIVE_PROJECT_NODES';
export function receiveProjectNodes(nodes) {
  return {
    type: RECEIVE_ACTIVE_PROJECT_NODES,
    nodes,
    overwrite: true
  };
}

export const RECEIVE_NODE = 'node:RECEIVE_NODE';
export function receiveNode(node) {
  return {
    type: RECEIVE_NODE,
    node
  };
}

export const RECEIVE_ELEMENT_GROUP = 'node:RECEIVE_ELEMENT_GROUP';
export function receiveElementGroup(elementGroup) {
  return {
    type: RECEIVE_ELEMENT_GROUP,
    elementGroup
  };
}

export const UPDATE_ELEMENT_GROUP = 'node:UPDATE_ELEMENT_GROUP';
export function updateElementGroup(data, cb) {
  return {
    type: UPDATE_ELEMENT_GROUP,
    data,
    cb
  };
}

export const UPDATE_NODE = 'node:UPDATE_NODE';
export function updateNode(id, data, localStateOnly) {
  return {
    type: UPDATE_NODE,
    id : id,
    data,
    localStateOnly
  };
}

export const UPDATE_NODES_SORTING = 'node:UPDATE_NODES_SORTING';
export function updateNodesSortOrder(nodes, projectId) {
  return {
    type: UPDATE_NODES_SORTING,
    nodes,
    projectId
  }
}

export const SAVE_NODE = 'node:SAVE_NODE';
export function saveNode(data) {
  return {
    type: SAVE_NODE,
    id: data.node.id,
    data,
    pageLoader: true
  };
}

// // for cases where we need to update the store without hitting backend
// export const UPDATE_NODE_LOCALLY = 'node:UPDATE_NODE_LOCALLY';
// export function updateNodeLocally(...args) {
//   return {...updateNode(...args), type: UPDATE_NODE_LOCALLY};
// }

export const DELETE_NODE = 'node:DELETE_NODE';
export function deleteNode(id) {
  return {
    type: DELETE_NODE,
    id
  };
}

export const COPY_NODE = 'node:COPY_MODE';
export function copyNode(id) {
  return {
    type: COPY_NODE,
    id
  };
}

export const NODE_DELETED = 'node:NODE_DELETED';
export function nodeDeleted(id) {
  return {
    type: NODE_DELETED,
    id
  };
}

export const ADD_CHAPTER = 'node:ADD_CHAPTER';
export function addChapter(nodeId, chapter) {
  return {
    type: ADD_CHAPTER,
    nodeId,
    chapter
  };
}

export const FETCH_NODE = 'node:FETCH_NODE';
export function fetchNode(nodeId) {
  return { type: FETCH_NODE, nodeId };
}

export const FETCH_PROJECT_NODES = 'node:FETCH_PROJECT_NODES';
export function fetchProjectNodes(projectId, overwrite) {
  return { type: FETCH_PROJECT_NODES, projectId, overwrite };
}

export const GOT_DURATION = 'node:GOT_DURATION';
export function gotDuration(duration) {
  return {
    type: GOT_DURATION,
    duration
  };
}

export const ADD_ELEMENT_GROUP = 'node:ADD_ELEMENT_GROUP';
export function addElementGroup(nodeId, name, callback) {
  return {
    type: ADD_ELEMENT_GROUP,
    nodeId,
    name,
    callback
  };
}
