import React from 'react';
import {connect} from 'react-redux';
import Connector from './Connector';
import {connectorsSelector} from 'modules/composer/composerSelectors';
import {WIDTH, TRUE_HEIGHT} from './Node';

@connect(connectorsSelector)
export default class Connectors extends React.Component {
  // hide disconnected if we already have at least 1 connection
  hideIfDisconnected = () => {
    return this.props.connectors.some(connector => !!connector.to.nodeId);
  };
  render() {
    const {nodeId, x, y, connectors} = this.props;

    if (!connectors) return null;

    return connectors.map((connector, idx) => (
      <Connector
        from={{x: x + WIDTH, y: y + TRUE_HEIGHT / 2}}
        // to={{x: connector.linkTo.x, y: connector.linkTo.y + HEIGHT / 2}}
        to={connector.to}
        key={connector.element_type + connector.id}
        id={connector.id}
        element_type={connector.element_type}
        idx={idx}
        nodeId={nodeId}
        name={connector.name}
        hideIfDisconnected={this.hideIfDisconnected()}
      />
    ));
  }
}
