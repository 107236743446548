import React, {Component} from 'react';
import {connect} from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import keyDown from 'react-keydown';
import {nodeSelector} from 'modules/node/nodeSelectors';
import {CONNECTOR, dragDataSetter} from 'modules/composer/dragging';
import {TRUE_HEIGHT} from 'modules/composer/components/Node';
import {removeConnector} from 'modules/composer/composer';
import {composerStateSelector} from 'modules/composer/composerSelectors';
import {nodeAndParents} from 'utils/domUtils';

const setConnectorData = dragDataSetter(CONNECTOR);

//TODO: split into smart/dumb components
@connect(
  (state, props) => {
    let {to, from, element_type, id} = props;

    let {dragType, drag, dragData} = composerStateSelector(state);

    if (to || dragType === CONNECTOR) {
      let toPos;

      if (to.nodeId) {
        const {node} = nodeSelector(state, {id: to.nodeId});
        if (!node) return {toPos: null};

        const {posX: x, posY: y} = node;
        toPos = {x, y: y + TRUE_HEIGHT / 2};
      }

      if (to.pos) {
        toPos = to.pos;
      }

      // this one is currently being dragged
      if (
        dragType === CONNECTOR &&
        dragData &&
        dragData.id == id &&
        (!dragData.element_type || dragData.element_type == element_type)
      ) {
        toPos = {
          x: (toPos ? toPos.x : from.x) + drag.x,
          y: (toPos ? toPos.y : from.y) + drag.y
        };
      }

      return {
        toPos
      };
    }
  },
  {removeConnector}
)
@onClickOutside
export default class Connector extends Component {
  state = {
    selected: false
  };

  handleCircleRef = ref => setConnectorData.call(this, ref);

  handleClick = e => {
    this.setState({selected: true});
  };

  handleClickOutside = e => {
    this.setState({selected: false});
  };

  @keyDown('delete', 'backspace')
  handleDelete() {
    if (this.state.selected) {
      const {nodeId, element_type, id, to} = this.props;
      if (to.nodeId) {
        this.props.removeConnector(element_type, id);
      }
    }
  }

  render() {
    let {toPos, from, name, element_type, hideIfDisconnected} = this.props;
    const {selected} = this.state;

    const pathString = toPos && pathBetween(from, toPos);

    if (hideIfDisconnected && !toPos) {
      return null;
    }

    /**
     * Easy way to tweak the position of the connector on the node
     */
    const connectorPos = toPos
      ? {
          x: toPos.x - 1,
          y: toPos.y
        }
      : {
          x: from.x + 10,
          y: from.y
        };

    const strokeColour = element_type ? '0, 162, 225' : '0, 179, 130';

    return (
      <g onClick={this.handleClick} ref={ref => (this.ref = ref)}>
        {pathString && (
          <path
            d={pathString}
            stroke={`rgba(${strokeColour}, ${selected ? 0.5 : 0.2})`}
            strokeLinejoin="round"
            strokeWidth={10}
            fill="none"
            // onClick={this.handleClick}
          />
        )}
        <circle
          ref={this.handleCircleRef}
          r={14}
          cx={connectorPos.x}
          cy={connectorPos.y}
          fill="#eafdf4"
        />
        {/*<text x={from.x} y={from.y} textAnchor="middle" stroke="#002033" fontSize={20}>*/}
        {/*{name}*/}
        {/*</text>*/}
        <use
          ref={this.handleCircleRef}
          x={connectorPos.x - 8}
          y={connectorPos.y - 8}
          xlinkHref="#connector-arrow"
        />
      </g>
    );
  }
}

function pathBetween(from, to) {
  const padding = 20;
  const xDist = to.x - from.x;
  const yDist = to.y - from.y;

  const start = `M${from.x} ${from.y} `;

  if (to.x > from.x) {
    return `${start} h ${xDist / 2} v ${yDist} h ${xDist / 2}`;
  }

  const long = xDist + padding * (xDist > 0 ? 2 : -2);
  return `${start} h ${padding} v ${yDist / 2} h ${long} v ${yDist / 2} h ${
    padding
  }`;
}

// function distance({x, y}, {x: x2, y: y2}) {
//   return Math.sqrt(Math.pow(x2 - x, 2) + Math.pow(y2 - y, 2));
// }

//
// function bezierCurve(from, to) {
//   const dist = distance(from, to);
//   const {x, y} = from;
//   console.log(from, to);
//   return `M${x} ${y}, C ${x + dist * 0.25} ${y}, ${to.x -
//     dist * 0.75} ${to.y}, ${to.x} ${to.y}`;
// }
