import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import ReactPlayer from 'react-player';
// stuff
import styles from './NodePage.scss';
import { viewNodePage, gotDuration, saveNode } from 'modules/node/node';
import { selectElement } from 'modules/element/element';
import { nodeSelector, saveNodeSelector } from 'modules/node/nodeSelectors';
import { updatePageLoadingState } from 'modules/pageLoader/pageLoader';
import { toggleElementWizard } from 'modules/interaction';

// Components
import InteractionEditor from 'modules/interaction/components/InteractionEditor';
import InteractionTimeline from 'modules/interaction/components/InteractionTimeline';
import InteractionProperties from 'modules/interaction/components/InteractionProperties';
import Spinner from 'components/Spinner';
import ElementsPanelContainer from './ElementsPanelContainer';
import { elementSelectedStateSelector } from 'modules/element/elementSelectors';
import { selectedInteractionSelector, interactionsForNodeSelector } from 'modules/interaction/interactionSelectors';
import NodePageLayout from './NodePageLayout';
import ElementWizardContainer from 'modules/interaction/components/ElementWizard/ElementWizardContainer';
import NodeActionsComponent from './NodeActionsComponent';
import { selectInteraction } from 'modules/interaction/interaction';

// @TODO :  REMOVE THE PLAYER CODE INTO IT'S OWN COMPONENT
@connect(nodeSelector, { viewNodePage, gotDuration, updatePageLoadingState, toggleElementWizard })
@connect(selectedInteractionSelector)
@connect(interactionsForNodeSelector, {selectInteraction})
@connect(elementSelectedStateSelector, { selectElement })
export default class NodePage extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      showElementsPanel: false,
      duration: 0,
      playedSeconds: 0,
      playing: false,
      ready: false,
      muted: false,
      grid: false
    };
  }

  componentDidMount() {
    const {
      viewNodePage,
      node,
      match: {
        params: { projectId }
      }
    } = this.props;
    if (!node) {
      viewNodePage(projectId);
    }

    this.props.selectInteraction(null);

    window.currentVideoPlayedSeconds = 0;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.ready && this.props.mediaItem.id !== prevProps.mediaItem.id) {
      this.setState({ ready: false, duration: 0 });
    }
    window.currentVideoPlayedSeconds = this.state.playedSeconds;
  }

  handlePlay = () => {
    this.setState({ playing: true });
  };

  handlePause = () => {
    this.setState({ playing: false });
  };

  handleProgress = ({ playedSeconds }) => {
    if (playedSeconds == undefined) return;

    if (this.state.ignoreNextProgress) {
      this.setState({ ignoreNextProgress: false });
      return;
    }
    this.setState({ playedSeconds });
  };

  jumpToTime = time => {
    // this.setState({playedSeconds: time});
    // this.seekTo(time);
  };

  handleReady = () => {
    this.setState({ ready: true });

    // // vimeo sucks and doesn't respect playing value
    // // Gota do this to clear the big play button on youtube
    // if (this.isVimeo() || this.isYoutube()) {
    //   this.setState({ playing: true });

    //   setTimeout(() => {
    //     this.setState({ playing: false });
    //     this.seekTo(0);
    //   }, 1000);
    // }
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleToggleGrid = () => {
    this.setState({ grid: !this.state.grid });
  };

  isVimeo = () => {
    return (
      this.props.mediaItem &&
      this.props.mediaItem.stream_url &&
      this.props.mediaItem.stream_url.includes('vimeo') &&
      !this.props.mediaItem.url
    );
  };

  isYoutube = () => {
    return (
      this.props.mediaItem && this.props.mediaItem.stream_url && this.props.mediaItem.stream_url.includes('youtube')
    );
  };

  handleDuration = duration => {
    // if (this.gettingDuration) {
    //   this.mediaPlayer.seekTo(0);
    //   this.setState({playing: false});
    //   this.gettingDuration = false;
    // }
    this.setState({ duration });
    this.props.gotDuration(duration);
  };

  handleScrub = percentage => {
    // On scrub we want to show the new playedSeconds straight away rather
    // than when the video finally loads at the new time.
    percentage = Math.min(percentage, 1);
    const playedSeconds = Math.max(this.state.duration * percentage, 0);
    this.setState({ playedSeconds });

    if (percentage === 1) {
      this.setState({ ignoreNextProgress: true });
    }

    this.seekTo(percentage);
  };

  projectBranding = () => {
    const { project } = this.props;

    if (!project || !project.project) return null;

    if (!project.project.branding_image_src) return null;

    return (
      <img
        style={{ maxWidth: '100px', position: 'absolute', zIndex: '99', top: '10px', left: '10px' }}
        src={project.project.branding_image_src}
      />
    );
  };

  reservedForPlayerControls = () => {
    return (
      <div className="reservedForPlayerControls">
        <p>Area Reserved For Player Controls</p>
      </div>
    );
  };

  // bad perf if not debounced
  seekTo = debounce(percentage => {
    this.mediaPlayer.seekTo(percentage);
  }, 400);


  _togglelementsPanel = bool => () => this.setState({ showElementsPanel: bool });

  render() {
    const {
      mediaItem,
      match: {
        params: { nodeId }
      },
      project,
      node,
      wizard,
      selectedInteraction,
      selectedModal,
      elementGroups,
      interactionElementGroup,
      interactions,
      selectElement,
      toggleElementWizard
    } = this.props;

    const { ready, playing, duration, playedSeconds, muted, grid, showElementsPanel } = this.state;


    // this should get loaded at some point.
    if (!mediaItem) {
      return null;
    }

    if (!project.project) return null;
    const projectId = project.project.id;

    const { url, hls_stream, compressed_mp4, stream_url, compressed_url, manifest_url } = mediaItem;

    const width = 720;
    const height = 405;

    let videoUrl = url;
    if (hls_stream) videoUrl = hls_stream;
    else if (compressed_mp4) videoUrl = compressed_mp4;
    else if (stream_url) videoUrl = stream_url;
    else if (manifest_url) videoUrl = manifest_url;


    return (
      <NodePageLayout
        heading="Node Page"
        node={node}
        actionsComponent={
          <NodeActionsComponent
            project={project.project}
            node={node}
            user={project.user}
            selectedInteraction={selectedInteraction}
            selectedModal={selectedModal}
            interactions={interactions}
            onScrub={this.handleScrub}
          />
        }
        leftSide={
          <React.Fragment>
            <div className={styles.nodeToolbar}>
              {wizard.open && (
                <ElementWizardContainer
                  node={node}
                  elementGroups={elementGroups}
                  mediaItem={mediaItem}
                  elementType={wizard.elementType}
                  elementPosition={wizard.elementPosition}
                  close={() => toggleElementWizard({ open: false })}
                />
              )}
              {selectedInteraction ? (
                <InteractionProperties
                  node={node}
                  interaction={selectedInteraction}
                  modal={selectedModal}
                  interactionElementGroup={interactionElementGroup}
                  elementGroups={elementGroups || []}
                  className={styles.nodeEditor}
                  selectElement={selectElement}
                />
              ) : (
                <ElementsPanelContainer
                  onHide={this._togglelementsPanel(false)}
                  className={cx(styles.elementsBoard, styles.showElements)}
                  {...this.props}
                />
              )}
            </div>
          </React.Fragment>
        }
      >
        <section className={styles.editor}>
          <div className={styles.playerWrapper} style={{ height, width }}>
            {this.projectBranding()}
            <ReactPlayer
              key={this.props.node.id}
              controls={false}
              onReady={this.handleReady}
              className={styles.player}
              ref={ref => (this.mediaPlayer = ref)}
              width="100%"
              height="100%"
              // if we don't set playing to true until ready, getDuration is never triggered for vimeo
              playing={playing}
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              onDuration={this.handleDuration}
              onProgress={this.handleProgress}
              url={videoUrl}
              config={{
                youtube: { preload: true, playerVars: { modestbranding: 1 } },
                vimeo: { preload: true }
              }}
              muted={muted}
            />
            <InteractionEditor
              className={styles.stacked}
              nodeId={nodeId}
              interactions={interactions}
              elementGroups={elementGroups}
              selectedInteraction={selectedInteraction}
              selectedModal={selectedModal}
              played={playedSeconds}
              grid={grid}
              play={this.handlePlay}
            />
            {this.reservedForPlayerControls()}
          </div>
          <div className={styles.timeline}>
            {ready ? (
              <InteractionTimeline
                nodeId={nodeId}
                duration={duration}
                played={playedSeconds}
                onScrub={this.handleScrub}
                pause={this.handlePause}
                play={this.handlePlay}
                toggleMuted={this.handleToggleMuted}
                toggleGrid={this.handleToggleGrid}
                grid={grid}
                muted={muted}
                playing={playing}
                jumpToTime={this.jumpToTime}
              />
            ) : (
              <div style={{ marginTop: '200px' }}>
                <Spinner />
              </div>
            )}
          </div>
        </section>
      </NodePageLayout>
    );
  }
}
