import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {ELEMENT_EDITOR_DOM_ID} from 'modules/interaction';
import {fontSelector} from 'modules/project/projectSelectors';
import {loadFont} from 'shared/fonts';
import ElementContainer from './Element/ElementContainer';
import {deselectElement} from 'modules/element/element';
import nodePageStyles from "../../node/components/NodePage.scss";
import getAsset from 'utils/getAsset';


const _props = {
  /** These are the wrapper for element from DB can be either an interaction or a modalElement */
  elementContainers : PropTypes.array.isRequired,
  onSelectElement: PropTypes.func.isRequired,
  onDeleteElement: PropTypes.func.isRequired,
}

@connect(fontSelector, {deselectElement})
export default class ElementEditor extends React.Component {

  handleClick = e => {
    if (e.target === this.editorRef || e.target === this.gridRef) {
      // click on editor itself. unselect element
      const {onSelectEditor, deselectElement} = this.props;
      onSelectEditor && onSelectEditor();
      deselectElement();
    }
  };
  // callHandlerOr = (handlerName, otherwise) = (...args) => {
  //   const handler = this.props[handlerName];
  //   if (handler) {
  //     return handler(...args);
  //   }

  //   otherwise(...args);
  // }

  render() {
    const {
      elementContainers,
      className,
      onSelectElement,
      onDeleteElement,
      font,
      animated,
      grid,
      ...props
    } = this.props;

    let styles = {
      overflow: 'hidden',
      //background: 'rgba(0,0,0,0.1)'
    };

    if (font) {
      // make font available
      loadFont(font);

      // styles.fontFamily = `"${font}", sans-serif`;
    }

    return (
      <div
        id={ELEMENT_EDITOR_DOM_ID}
        className={className}
        style={styles}
        ref={ref => (this.editorRef = ref)}
        onClick={this.handleClick}
      >
        <React.Fragment>
          {grid && (
              <img src={getAsset('/img/grid.png')} ref={ref => this.gridRef = ref} className={nodePageStyles.gridOverlay}/>
          )}
          {elementContainers.map(({element_id, element_type}) => (
            <ElementContainer
              key={element_type + element_id}
              id={element_id}
              element_type={element_type}
              projectFont={font}
              animated={animated}
              onSelect={onSelectElement}
              onDelete={onDeleteElement}
            />
          ))}
        </React.Fragment>
      </div>
    );
  }
}
ElementEditor.defaultProps = {}